<template>
  <div class="mt-4 flex w-full justify-between px-4 py-4 pad:hidden mobile:hidden">
    <div v-for="(item, index) in sellingPointList" :key="index" class="flex w-1/3 flex-col gap-2">
      <div class="text-center">
        <img class="h-12 w-12" :src="item.icon" alt="" />
      </div>
      <div class="text-center text-level-4 text-text-tertiary">
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CONTENT_TYPE, type ItemType } from '@/types';
import { SellingPoint } from '~/components/project-details/constant';

type PropsType = {
  detail: ItemType;
};
const props = defineProps<PropsType>();

const sellingPointList = computed(() => {
  // xthings 的卖点
  if (props.detail.type === CONTENT_TYPE.XTHINGS) {
    return props.detail.productInfo?.iconTexts;
  }
  // bundle 的卖点
  if (props.detail.type === CONTENT_TYPE.BUNDLE) {
    return SellingPoint.bundlePointList;
  }
  // project 的卖点
  return SellingPoint.pointList;
});
</script>

<style lang="less" scoped></style>
