<template>
  <a-upload-dragger
    v-model:file-list="uploadFileList"
    class="h-full"
    :support-server-render="true"
    :capture="undefined"
    :show-upload-list="false"
    :custom-request="() => {}"
    :multiple="true"
    :accept="accept"
    :before-upload="beforeUpload"
    @dragover.prevent="() => {}"
    @change="handleChange"
  >
    <Content :accept="accept" />
  </a-upload-dragger>
</template>
<script setup lang="ts">
import { beforeUpload } from '@/components/share/utils';
import { type UploadChangeParam, type UploadFile } from 'ant-design-vue';
import { ImageType, VideoType } from '../../../const';
import { FileStatus } from '../../../interface';
import { useLibrary } from '../useLibrary';
import Content from './Content.vue';
const { allFileList, uploadFileList, libraryUploadMedia } = useLibrary();
const accept = `${VideoType},${ImageType}`;
const handleChange = (info: UploadChangeParam<UploadFile>) => {
  if (!info.file.status) {
    return false;
  }
  info.fileList.length && libraryUploadMedia(info);
};
const addOfflineListener = () => {
  window.addEventListener('offline', handleOfflineFileStatus);
};
const removeOfflineListener = () => {
  window.removeEventListener('offline', handleOfflineFileStatus);
};

const handleOfflineFileStatus = () => {
  allFileList.value.forEach((file: any) => {
    if (file.status === FileStatus.Uploading) {
      Object.assign(file, { status: FileStatus.Error });
    }
  });
};
onMounted(() => {
  addOfflineListener();
});
onUnmounted(() => {
  removeOfflineListener();
});
</script>
