<template>
  <div id="notice-icon" class="notice-message-list cursor-pointer">
    <a-popover
      v-model:open="noticeShow"
      :auto-adjust-overflow="false"
      placement="bottomRight"
      trigger="hover"
      :arrow="false"
      overlay-class-name="notice-message-list-popover"
      :get-popup-container="(triggerNode: any) => triggerNode.parentNode"
    >
      <a-badge
        class="custom-badge flex h-10 w-10 items-center justify-center rounded-full bg-[#f0f1f5] text-[40px]"
        :count="noticeStore.count"
        :overflow-count="99"
      >
        <icon-font class="icon-message text-[26px] text-text-primary" type="icon-message-line" />
      </a-badge>
      <template #content>
        <a-config-provider>
          <template #renderEmpty>
            <a-empty :image="emptyImg" class="empty-img">
              <template #description>
                <span class="text-level-4 text-text-tertiary mobile:text-level-7">No data</span>
              </template>
            </a-empty>
          </template>
          <div class="list-content">
            <a-list :data-source="noticeStore.noticeList" :loading="isLoading">
              <template #renderItem="{ item }">
                <a-list-item class="notice-message-list-item w-[430px]">
                  <div class="flex w-full flex-col gap-2">
                    <div class="notice-list-item-wrap-1 flex items-center justify-between">
                      <AuthorUserImg
                        class="user-img"
                        img-size="32px"
                        :user-id="item.createdBy"
                        :user-img="item.createdByHeadpic"
                        :user-name="item.createdByName"
                      />
                      <span class="time text-uni-3">
                        {{ moment(item.createdAt * 1000).format('YYYY/MM/DD') }}
                      </span>
                    </div>
                    <NuxtLink
                      class="two-line-ellipsis block pl-10 text-text-secondary"
                      :to="item.localUrl"
                      :target="item.localTarget"
                      @click="handleNoticeClick(item)"
                    >
                      <span v-if="item.title" class="text-level-4 font-semibold text-text-secondary">{{ item.title }}&nbsp;</span>
                      <span class="font-sans text-level-4 text-text-primary" v-html="item.content"></span>
                    </NuxtLink>
                  </div>
                </a-list-item>
              </template>
            </a-list>
          </div>
          <NuxtLink
            :to="'/notice-center?active=all'"
            :class="'load-more-wrap block h-[50px] cursor-pointer border-0 border-t border-solid border-background-4 text-center text-level-5 font-semibold leading-[50px] text-text-primary hover:text-text-secondary'"
            @click="handleCloseNotice"
          >
            All Messages
          </NuxtLink>
        </a-config-provider>
      </template>
    </a-popover>
  </div>
</template>

<script lang="ts" setup>
import { tagNoticeReadApi } from '@/api';
import emptyImg from '@/assets/img/df-ip/no_data.png';
import { useNotice } from '@/stores/notice';
import moment from 'moment';
import { userGuide } from '~/stores/userGuide';
const userGuideInfo = userGuide();

const noticeStore = useNotice();

const isLoading = ref(false);
const handleGetNoticeList = async () => {
  try {
    isLoading.value = true;
    await noticeStore.updateCount();
  } catch (error) {
    console.warn(error);
  } finally {
    isLoading.value = false;
  }
};

const noticeShow = ref(false);
watch(
  () => noticeShow.value,
  (val: boolean) => {
    if (val) {
      handleGetNoticeList();
      userGuideInfo.hide(); // 隐藏新手引导
    }
  },
);
const handleCloseNotice = () => {
  noticeShow.value = false;
};

const handleNoticeClick = async (item: any) => {
  try {
    isLoading.value = true;
    await tagNoticeReadApi({
      id: item.id,
      type: item.type,
    });
    noticeStore.updateCount();
    handleCloseNotice();
  } catch (error) {
    console.warn(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  nextTick(() => {
    noticeStore.updateCount();
  });
});
</script>

<style lang="less">
.notice-message-list-popover {
  min-width: 300px;
  border-radius: 12px;
  .ant-list-empty-text {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .empty-img {
    .ant-empty-image {
      height: auto;
      margin-bottom: 16px;
      img {
        width: 120px;
        height: 120px;
      }
    }
    .ant-empty-description {
      margin-bottom: 0;
    }
  }
  .ant-popover-inner {
    border-radius: 12px;
    min-width: 360px;
    padding: 0;
  }
  .ant-list-items {
    padding: 16px;
  }
  .notice-message-list-item {
    word-break: break-word;
  }
  .notice-message-list-item:first-of-type {
    padding-top: 0;
  }
  .notice-message-list-item:last-of-type {
    padding-bottom: 0;
    border-block-end: none !important;
  }
  .list-content {
    max-height: calc(100vh - 60px);
    position: relative;
    padding-bottom: 52px;
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .load-more-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

:deep(.ant-badge-count) {
  box-shadow: none;
}
</style>
