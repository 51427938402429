<template>
  <div class="absolute flex h-full w-full flex-col" @click.stop>
    <div class="text-level-1 font-semibold">All Media</div>
    <div class="flex-1 overflow-y-scroll">
      <a-card class="tab-board flex h-full flex-col" :tab-list="tabs" :active-tab-key="activeKey" @tab-change="onChange">
        <template #customTab="item">
          {{ item.tab }}
        </template>
        <template #default>
          <UploadList v-show="activeKey === Tabs.Upload" :drag-group="dragGroup" :accept="accept" />
          <AiMakeList v-show="activeKey === Tabs.AiMake" :drag-group="dragGroup" />
        </template>
      </a-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { handleSensorEditorPopShow } from '@/components/share/utils';
import AiMakeList from './AiMakeList.vue';
import UploadList from './UploadList.vue';
defineProps({
  // eslint-disable-next-line vue/require-default-prop
  accept: {
    type: String,
  },
});
enum Tabs {
  Upload = 'upload',
  AiMake = 'aimake',
}
const activeKey = ref('upload');
const tabs = [
  {
    key: 'upload',
    tab: 'Upload',
  },
  {
    key: 'aimake',
    tab: 'AImake',
  },
];
const dragGroup = {
  name: 'project',
  pull: 'clone',
  put: false,
};
const onChange = (key: string) => {
  activeKey.value = key;
  if (activeKey.value === Tabs.Upload) {
    handleSensorEditorPopShow('All Media');
  } else {
    handleSensorEditorPopShow('All Media AImake');
  }
};
</script>
<style scoped lang="less">
.tab-board {
  box-shadow: none;
  :deep(.ant-card-body) {
    padding: 0;
    flex: 1;
  }
  :deep(.ant-card-head) {
    padding: 0;
    .ant-tabs {
      .ant-tabs-tab {
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: theme('colors.text.primary');
          }

          &::after {
            background-color: theme('colors.red.default');
          }
        }
      }
    }
  }
}
</style>
