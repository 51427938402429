import type { ReportItem } from './types';

export const reportOptions: ReportItem[] = [
  {
    label: 'Spam',
    key: 'spam',
    placement: 'right',
    tip: 'May include fake profiles, promotional spam, or other irrelevant content.',
  },
  {
    label: 'Copyrights issue',
    key: 'copyright_issue',
    placement: 'left',
    tip: 'May include someone uploading photos or files with a copyright issue',
  },
  {
    label: 'Fraud/Scam',
    key: 'fraud',
    placement: 'right',
    tip: 'This includes posting content that attempts to defraud or exploit others for money, property, or information.',
  },
  {
    label: 'Harmful or illegal',
    key: 'harmful_or_illegal',
    placement: 'left',
    tip: 'May be threatening, harassing, hateful, terrorist, or other illegal content.',
  },
  {
    label: 'Offensive',
    key: 'offensive',
    placement: 'top',
    tip: 'May include nudity, graphic violence, mature language or other things that make you uncomfortable',
  },
  {
    label: 'Other',
    key: 'other',
    placement: 'top',
    tip: 'If you are unsure, tell us more about your issue in your own words.',
  },
];

/**
 * 下架原因
 */
export const OffShelfOptions = [
  { label: 'Design file issue', key: 'designFileIssue' },
  { label: 'Copyrights issue', key: 'copyrightsIssue' },
  { label: 'Fraud/Scam', key: 'fraudScam' },
  { label: 'Harmful or illegal', key: 'harmfulOrIllegal' },
  { label: 'Offensive', key: 'offensive' },
  { label: 'Other', key: 'other' },
];
