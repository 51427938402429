import { getDefaultLicense } from '@/api/index';
import { parseUrl } from '@/api/project';
import { isBlobUrl } from '@/components/share/utils';
import { ModeType } from '@/types/share';
import { type UploadFile } from 'ant-design-vue';
import { useLayoutStore } from '~/stores/layout';
import { RESPONSE_CODE } from '~/stores/types';

export const useFormIntegration = () => {
  const router = useRouter();
  const initMode = () => {
    const { query } = useRoute();
    let { id, mode } = query;
    id = (id ?? '') as string;
    mode = (mode ?? ModeType.Free) as ModeType;
    return { id, mode, infoType: query.infoType };
  };
  const successNavigate = async ({ type, id, mode, hasAudit }: { type: string; id: string | number; mode: string; hasAudit: boolean }) => {
    await message.success('Published Successfully', 1);
    await router.replace({
      path: `/share/success`,
      query: {
        id,
        mode,
        type,
        hasaudit: hasAudit ? 'true' : 'false',
      },
    });
  };
  return {
    initMode,
    successNavigate,
  };
};

// 上传图片的时候，处理heic图片
export const handelErrorImg = async (fileList: any) => {
  const list: any = [];
  const isVideo = (file: any) => file.type.includes('video');
  for await (const file of fileList) {
    const fileFormats = Object.assign(
      {},
      {
        uid: file.uid,
        name: file.name,
        type: file.type,
        size: file.size,
        originFileObj: file.originFileObj ? file.originFileObj : file,
      },
    );
    if (file.status !== 'removed') {
      try {
        const res = isVideo(file) ? file : ((await formatImgType(fileFormats)) as UploadFile);
        list.push(res);
      } catch (err) {
        file.status = 'removed';
        message.warning({
          key: 'image',
          content: 'Upload failed: The file format does not match its extension. Please use a valid file and try again.',
        });
      }
    }
  }
  return list;
};
// 判断文件格式是否符合限制的格式类型
export const isValiType = (file: UploadFile, accept: string) => {
  const { name } = file;
  const fileType = '.' + getFileType(name)?.toLowerCase();
  const isVali = accept.split(',').includes(fileType);
  return isVali;
};
export const getDefaultLincense = async () => {
  try {
    const { data: getDefaultLicenseResp } = await getDefaultLicense();

    if (getDefaultLicenseResp.value?.code === RESPONSE_CODE.SUCCESS) {
      const defaultLicenseId = getDefaultLicenseResp.value?.data?.id ?? 0;
      return defaultLicenseId;
    }
  } catch (error) {
    console.error('getDefault-error', error);
  }
};
export const autoFillBaseData = async () => {
  const route = useRoute();
  const handleRemixAuto = async () => {
    try {
      const remix = sessionStorage.getItem('remixBaseOnInfo');
      let autoRemix = {};
      if (remix) {
        const { value: remixUrl } = JSON.parse(remix);
        const formattedRemixUrl = Array.isArray(remixUrl) ? remixUrl.join(',') : remixUrl;
        sessionStorage.removeItem('remixBaseOnInfo');
        const layoutStore = useLayoutStore();
        layoutStore.setRemixBaseOnLink([]);
        const { data: getOriginalResp } = await parseUrl({ url: formattedRemixUrl });
        if (getOriginalResp.value?.code === RESPONSE_CODE.SUCCESS) {
          const option = getOriginalResp.value.data;
          autoRemix = { source: 'remix', originals: [...option] };
        }
      }
      return autoRemix;
    } catch (error) {
      console.error('handleRemixAuto-error', error);
    }
  };
  // 活动页配置tag
  const setActivityTag = () => {
    const { tag } = route.query;
    const defaultTag = tag ? (tag as string).split('/') : [];
    if (defaultTag.length) {
      return { contentTags: [defaultTag[0]] };
    }
    return {};
  };
  let recentForm = {};
  try {
    recentForm = { ...(await handleRemixAuto()), ...setActivityTag() };
    return recentForm;
  } catch (error) {
    console.error('getLatestUse-error', error);
  }
};

export const filterUploadingFiles = (form: any) => {
  // 过滤未上传完成的媒体文件
  const filterMediaItems = (items: any) =>
    items.filter((item: any) => {
      if (typeof item === 'string') {
        return !!item && !isBlobUrl(item);
      }
      return item?.url || item?.videoUrl;
    });

  // 处理步骤中的媒体文件
  const processSteps = (steps: any) =>
    steps.map((step: any) => ({
      ...step,
      imageList: step.imageList?.filter((item: any) => item.url) || [],
      images: step.images?.filter((img: string) => !!img) || [],
    }));

  return {
    ...form,
    cover: form.cover && !isBlobUrl(form.cover) ? form.cover : '',
    images: filterMediaItems(form?.images || []),
    videos: filterMediaItems(form?.videos || []),
    attachFiles: filterMediaItems(form?.attachFiles || []),
    steps: processSteps(form?.steps || []),
  };
};
