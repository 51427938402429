import { getUnreadNoticeListApi } from '@/api/notice';
import { defineStore } from 'pinia';
const siteUrl = import.meta.env.VITE_PUBLIC_SITE_URL;

export const useNotice = defineStore('notice', {
  state: () => {
    return {
      // 铃铛的消息数
      count: 0,
      // 铃铛的消息列表
      list: [],
    };
  },
  getters: {
    /**
     * 如果是在电脑端xcs，站内链接使用_self打开，站外链接使用_blank打开
     * 如果是在电脑浏览器，所有链接都使用_blank打开
     * 如果是在移动端，浏览器或xcs，所有链接都使用_self打开
     */
    noticeList: (state) => {
      return state.list.map((item: any) => {
        let localTarget = '_self';
        const innerWeb = item.link?.startsWith(siteUrl);
        const isMobile = isAppWebkit() || isIpad();
        if (isElectron()) {
          localTarget = innerWeb ? '_self' : '_blank';
        } else {
          localTarget = isMobile ? '_self' : '_blank';
        }
        let localUrl = item.link?.includes(siteUrl) ? item.link?.replace(siteUrl, '') : item.link;
        if (item.link?.includes('/user-center?active=credits')) {
          localUrl = localUrl.replace('?', '?source=all messages card&');
        }

        return {
          ...item,
          localTarget,
          localUrl,
        };
      });
    },
  },
  actions: {
    async updateCount() {
      try {
        const { data } = await getUnreadNoticeListApi({
          current: 1,
          pageSize: 5,
        });
        if (data.value?.code === 0) {
          this.count = data.value.data.pagination.total;
          this.list = data.value.data.list || [];
        }
      } catch (error) {
        console.warn(error);
      }
    },
  },
});
