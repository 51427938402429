import dayjs from 'dayjs';

export function useForceLogin() {
  const record = useStorage('force-login', {
    isForceLogin: false,
    time: 0,
  });

  const { $client } = useNuxtApp();

  const forceLogin = () => {
    const isForceLogin = record.value.isForceLogin;
    record.value.isForceLogin = true;
    record.value.time = dayjs().unix();
    if (isForceLogin || getUToken()) {
      return;
    }
    $client.openModal(() => {
      location.reload();
    });
  };
  return { forceLogin, record, isForceLogin: computed(() => record.value.isForceLogin) };
}
