<template>
  <a-config-provider :theme="Theme">
    <a-input class="df-input" v-bind="$attrs" :size="size">
      <template v-for="slotName in Object.keys($slots)" #[slotName]>
        <slot :name="slotName"></slot>
      </template>
    </a-input>
  </a-config-provider>
</template>

<script setup lang="ts">
import type { SizeType } from 'ant-design-vue/es/config-provider';
defineOptions({
  inheritAttrs: false,
});
type PropType = {
  size?: SizeType;
};
const props = withDefaults(defineProps<PropType>(), {
  size: 'large',
});
const padding = computed(() => {
  const small = '4.5px 6.5px';
  const middle = '8.5px 10.5px';
  const large = '11.5px 14.5px';
  switch (props.size) {
    case 'small':
      return small;
    case 'middle':
      return middle;
    default:
      return large;
  }
});
const Theme = {
  components: {
    Input: {
      colorTextPlaceholder: '#9e9e9e', // 输入框placeholder文字颜色
      colorText: '#191919', // 输入框文字颜色
      colorBorder: 'transparent', // 输入框边框颜色
      colorBgContainer: '#f5f7fb', // 输入框背景颜色
      controlOutlineWidth: 0, // 输入框聚焦时的box-shadow宽度
      lineWidth: 1.5,
      lineHeightLG: '22px',
      lineHeight: '20px',
      colorTextDescription: '#9e9e9e',
      colorTextDisabled: '#cdcdcd',
      colorBgContainerDisabled: '#f0f1f5',
    },
  },
} as any;
</script>
<style scoped lang="less">
.df-input {
  padding: v-bind(padding);
  &:focus {
    color: #1a1a1a;
    background-color: #ffffff;
  }
  &.ant-input {
    border-radius: 8px;
  }
  &.ant-input[disabled] {
    border-color: #cdcdcd;
  }
  &.ant-input-status-error {
    background-color: #ffffff;
  }
}
</style>
