export default {
  padding: '4px 0',
  optionIconSizeSmall: '14px',
  optionIconSizeMedium: '16px',
  optionIconSizeLarge: '16px',
  optionIconSizeHuge: '18px',
  optionSuffixWidthSmall: '14px',
  optionSuffixWidthMedium: '14px',
  optionSuffixWidthLarge: '16px',
  optionSuffixWidthHuge: '16px',
  optionIconSuffixWidthSmall: '32px',
  optionIconSuffixWidthMedium: '32px',
  optionIconSuffixWidthLarge: '36px',
  optionIconSuffixWidthHuge: '36px',
  optionPrefixWidthSmall: '14px',
  optionPrefixWidthMedium: '14px',
  optionPrefixWidthLarge: '16px',
  optionPrefixWidthHuge: '16px',
  optionIconPrefixWidthSmall: '36px',
  optionIconPrefixWidthMedium: '36px',
  optionIconPrefixWidthLarge: '40px',
  optionIconPrefixWidthHuge: '40px'
};