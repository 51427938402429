export default {
  headerFontSize1: '30px',
  headerFontSize2: '22px',
  headerFontSize3: '18px',
  headerFontSize4: '16px',
  headerFontSize5: '16px',
  headerFontSize6: '16px',
  headerMargin1: '28px 0 20px 0',
  headerMargin2: '28px 0 20px 0',
  headerMargin3: '28px 0 20px 0',
  headerMargin4: '28px 0 18px 0',
  headerMargin5: '28px 0 18px 0',
  headerMargin6: '28px 0 18px 0',
  headerPrefixWidth1: '16px',
  headerPrefixWidth2: '16px',
  headerPrefixWidth3: '12px',
  headerPrefixWidth4: '12px',
  headerPrefixWidth5: '12px',
  headerPrefixWidth6: '12px',
  headerBarWidth1: '4px',
  headerBarWidth2: '4px',
  headerBarWidth3: '3px',
  headerBarWidth4: '3px',
  headerBarWidth5: '3px',
  headerBarWidth6: '3px',
  pMargin: '16px 0 16px 0',
  liMargin: '.25em 0 0 0',
  olPadding: '0 0 0 2em',
  ulPadding: '0 0 0 2em'
};