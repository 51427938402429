<template>
  <div id="remix-base-on" class="remix-base mb-8 border-0 border-b border-solid border-[rgba(0,0,0,0.1)] pb-8">
    <h3 class="mb-4 text-xl font-bold text-text-primary pad:text-level-2 mobile:text-level-2">This remix is based on</h3>

    <a-list
      :grid="{
        gutter: 16,
        column: 1,
        xs: 1,
        sm: 1,
        md: listCount,
        lg: listCount,
        xl: listCount,
        xxl: listCount,
        xxxl: listCount,
      }"
      :data-source="remixList"
      :row-key="(item: any) => item.objectId"
    >
      <template #renderItem="{ item }">
        <a-list-item class="item-box">
          <div
            class="remix-item flex gap-3 rounded-xl border border-solid border-[rgba(0,0,0,0.1)] p-3"
            :class="{
              'hover:cursor-pointer': !item.createdByDeleted && item.objectType !== 'link' && !isPreview,
            }"
            @click="handleRemixClick(item)"
          >
            <div class="remix-item-cover overflow-hidden rounded">
              <img
                v-if="item.cover && !item.createdByDeleted"
                class="h-full w-full object-cover"
                :src="getOssProcessedImage(item.cover, 'm_fill,w_100,h_75', 'webp')"
                alt=""
              />
              <icon-font v-else class="cover-img" type="icon-link" />
            </div>
            <div class="remix-item-info">
              <div v-if="item.createdByDeleted" class="remix-item-title" title="The original project has been deleted">
                The original project has been deleted
              </div>
              <div v-else class="remix-item-title" :title="item.title">
                {{ item.title }}
              </div>
              <template v-if="!item.createdByDeleted">
                <AuthorUserImg
                  v-if="item.createdByName && item.createdByHeadpic"
                  class="remix-item-avatar"
                  img-size="20px"
                  :clickable="false"
                  :user-id="-1"
                  :user-img="item.createdByHeadpic"
                  :user-name="item.createdByName"
                />
                <span v-else class="remix-item-link">
                  {{ item.link }}
                </span>
              </template>
            </div>
          </div>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script setup lang="ts">
import type { DetailProvide } from '@/components/project-details/types';
import { CONTENT_TYPE } from '~/types';

type PropsType = {
  remixList: any[];
};
const props = withDefaults(defineProps<PropsType>(), {
  remixList: () => [],
});
const { isPreview } = inject('detail-common') as DetailProvide;

const listCount = computed(() => (props.remixList.length > 1 ? 2 : 1));

function handleRemixClick(item: any) {
  // 删除和外链，预览的项目不可点击
  if (item.createdByDeleted || item.objectType === 'link' || isPreview) {
    return;
  }

  const typeArr = [CONTENT_TYPE.XTHINGS, CONTENT_TYPE.HOWTOS, CONTENT_TYPE.INSPIRATION, CONTENT_TYPE.PROJECT, CONTENT_TYPE.DESIGNS];
  if (typeArr.includes(item.objectType)) {
    item.link && window.open(item.link, '_blank');
  }
}
</script>

<style lang="less" scoped>
.remix-basebox {
  font-weight: theme('fontWeight.semibold');
  font-size: 20px;
  color: #222;
}
.remix-item-cover {
  width: 100px;
  height: 75px;
  flex-shrink: 0;
  background-color: #e3e3e3;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .cover-img {
    font-size: 24px;
    height: 24px;
  }
}
.remix-item-info {
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}
.remix-item-title {
  width: 100%;
  font-weight: theme('fontWeight.semibold');
  font-size: 16px;
  color: #212121;
  // 显示两行，其余省略号， 打断链接防止自适应失效
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.remix-item-avatar {
  width: max-content;
  :deep(.user-name) {
    font-size: 14px;
    color: #555;
    font-weight: 400;
    max-width: unset;
  }
}
.remix-item-link {
  font-size: 14px;
  color: #555;
  font-weight: 400;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>
