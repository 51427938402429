<template>
  <a-modal v-model:open="visible" :title="videoName" :footer="null" wrap-class-name="video-modal" @cancel="handleClose">
    <video v-if="videoUrl" controls width="100%" height="auto">
      <source :src="videoUrl" type="video/mp4" />
    </video>
  </a-modal>
</template>
<script setup lang="ts">
const videoUrl = ref('');
const visible = ref(false);
const videoName = ref('');
const open = (url: string, name: string) => {
  visible.value = true;
  videoUrl.value = url;
  videoName.value = name;
};
const handleClose = () => {
  visible.value = false;
  videoUrl.value = '';
};
defineExpose({
  open,
});
</script>
<style lang="less">
.video-modal {
  .ant-modal-body {
    padding-top: 0;
  }
}
</style>
