import { getScenarioAssociation } from '@/api/share';
import { useShareProject } from '@/stores/shareProject.js';
import { storeToRefs } from 'pinia';

export interface Power {
  id: number;
  name: string;
  code: string;
}
export interface Machines {
  id: number;
  name: string;
  icon?: string;
  powers: Array<Power>;
}
export interface ScenariosItem {
  id: number;
  code: string;
  name: string;
}
export interface MachineItem {
  id: number;
  code: string;
  name: string;
  icon: string;
  extId: string;
}
export interface PowerItem {
  id: number;
  code: string;
  name: string;
}
export interface AssociationItem {
  code: string;
  machines: Array<{
    code: string;
    laserPowers: string[];
  }>;
}

export interface AssociationSceneItem {
  code: string;
  powers: Array<{
    code: string;
    scenarios: string[];
  }>;
}

const scenarioMapData = ref({
  scenariosList: [] as ScenariosItem[], // 场景列表
  machinesList: [] as MachineItem[], // 机型列表
  powersList: [] as PowerItem[], // 加工头列表
  associationList: [] as AssociationItem[], // 场景-机型-加工头关联数据
  associationSceneList: [] as AssociationSceneItem[], // 机型-加工头-场景关联数据
});
export const useScenariosMachine = () => {
  const useShareProjectInfo = useShareProject();

  const { baseData } = storeToRefs(useShareProjectInfo);
  const getScenarioAssociationApi = async () => {
    const res = await getScenarioAssociation.fetch();
    if (res.code === 0) {
      const { scenarios, machines, powers, association, associationScenarios } = res.data;
      scenarioMapData.value.scenariosList = scenarios || [];
      scenarioMapData.value.machinesList = machines || [];
      scenarioMapData.value.powersList = powers || [];
      scenarioMapData.value.associationList = association || [];
      scenarioMapData.value.associationSceneList = associationScenarios || [];
      baseData.value.baseMachinesList = machines || [];
    }
  };
  const getScenariosList = (machines: Machines[]) => {
    let scenarioCodes: string[] = [];
    const { associationSceneList, machinesList, scenariosList } = scenarioMapData.value;
    machines.forEach((machine: Machines) => {
      const machineCode = machinesList.find((item: any) => item.id === machine.id)?.code;
      const powerCodes = machine.powers.map((item: any) => item.code);
      const powersScenariosArr = associationSceneList.find((item: any) => item.code === machineCode)?.powers;
      powersScenariosArr?.forEach((power) => {
        if (powerCodes.includes(power.code)) {
          scenarioCodes.push(...power.scenarios);
        }
      });
    });
    scenarioCodes = [...new Set(scenarioCodes)];
    const scenarioNames = scenariosList.filter((item: any) => scenarioCodes.includes(item.code))?.map((item: any) => item.name);
    return scenarioNames;
  };

  return {
    getScenarioAssociationApi,
    scenarioMapData,
    getScenariosList,
  };
};
