<template>
  <div id="df-card-wrap" class="card-list-block df-card-wrap">
    <template v-for="(item, key) in list" :key="key">
      <slot name="card" :item="item">
        <BlockCardItem v-bind="$attrs" :making-item="item" :is-show-user-card="isShowUserCard" />
      </slot>
    </template>
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { debounce } from 'lodash';
import BlockCardItem from './BlockCardItem.vue';

type PropsType = {
  list?: any;
  isShowUserCard?: boolean; // 是否展示用户卡片
};
withDefaults(defineProps<PropsType>(), {
  list: [],
  isShowUserCard: false,
});
defineOptions({
  inheritAttrs: false,
});

// 根据容器宽度设置列数
function getColumns(width: number) {
  const breakpoints = [
    { maxWidth: 480, columns: 'repeat(1, minmax(0, 1fr))' },
    { maxWidth: 720, columns: 'repeat(2, minmax(0, 1fr))' },
    { maxWidth: 1120, columns: 'repeat(3, minmax(0, 1fr))' },
    { maxWidth: 1520, columns: 'repeat(4, minmax(0, 1fr))' },
    { maxWidth: 1600, columns: 'repeat(5, minmax(0, 1fr))' },
    // { maxWidth: 1800, columns: '1fr 1fr 1fr 1fr 1fr 1fr' },
  ];
  for (const breakpoint of breakpoints) {
    if (width <= breakpoint.maxWidth) {
      return breakpoint.columns;
    }
  }
  return 'repeat(auto-fit, minmax(320px, 1fr))';
}
const updateSize = () => {
  const cardListBlock = document.querySelectorAll('.df-card-wrap') as any;
  cardListBlock.forEach((item: any) => {
    const width = Number(window.getComputedStyle(item).width.slice(0, -2));
    item.style.gridTemplateColumns = getColumns(width);
  });
};

onMounted(() => {
  updateSize();
  window.addEventListener(
    'resize',
    debounce(() => {
      updateSize();
    }, 200),
  );
});
onActivated(() => {
  updateSize();
});
onUnmounted(() => {
  window.removeEventListener('resize', updateSize);
});
</script>
<style lang="less" scoped>
.card-list-block {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 16px;
}
</style>
