<template>
  <div class="steps-info flex gap-16">
    <div class="left flex-[1_0_0%]">
      <div v-for="(item, index) in steps" :id="`step${index + 1}`" :key="index">
        <StepItem :item="item" :index="index + 1" :type="contentType" />
      </div>
    </div>
    <div class="right sticky top-[160px] h-fit w-[260px] pad:hidden mobile:hidden">
      <SideAnchor class="side-anchor w-full" :anchor-list="anchorList" :title="'Instruction'" @anchor-click="onAnchorClick" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';
import { type CONTENT_TYPE } from '@/types';
import StepItem from './StepItem.vue';

const detailsStore = useDetailsStore();
const { sensorContentDetailStepClick } = useSensors();

const detail = computed(() => detailsStore.detail);
const steps = computed(() => {
  const list = detail.value.steps ?? [];
  return list.filter((s) => s.content || s.groupParaSettings?.length || s.images?.length || s.paraSettings?.length || s.title || s.videos?.length);
});
const contentType = computed(() => detail.value.type as CONTENT_TYPE);
const anchorList = computed(() => {
  return steps.value.map((stepItem, stepIndex) => {
    return {
      name: `Step${stepIndex + 1}` + (stepItem.title ? `: ${stepItem.title}` : ''),
      id: `step${stepIndex + 1}`,
      key: `step${stepIndex + 1}`,
      desc: stepItem.title || '',
    };
  });
});

function onAnchorClick(anchor: any) {
  // console.log('anchor :>> ', anchor);
  const params = {
    element_name: anchor.key,
    content_id: detail.value.id,
    content_name: detail.value.title,
    service_type: detail.value.type,
    content_type: detail.value.forSale ? 'sale' : 'free',
  };
  sensorContentDetailStepClick(params);
}
</script>

<style lang="less" scoped>
:deep(.side-anchor) {
  .side-anchor-wrapper {
    padding: 0;
    background-color: #fff;
    .anchor-content {
      .title {
        color: theme('colors.text.title');
        font-weight: theme('fontWeight.bold');
        font-size: 20px;
        padding: 0;
      }
      .anchor-list {
        padding: 0;
        .scrollbar-track {
          background-color: #f8f8f8;
        }
        .list {
          margin-left: 16px;
          .a-item {
            margin-bottom: 16px;
            .anch-name {
              font-size: 16px;
              line-clamp: 1;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              word-break: break-word;
            }
          }
        }
      }
    }
  }
}
</style>
