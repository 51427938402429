<template>
  <AdBanner
    v-if="showAdBanner"
    :data-sensors-exposure-event-name="SENSORS_EVENT.BECOME_CREATOR_POP_SHOW"
    :data-sensors-exposure-property-source="source"
    class="overflow-hidden"
    :pc-img="adConfig?.pcImage"
    :mobile-img="adConfig?.mobileImage"
    :link="adConfig?.link"
    :background-color="adConfig?.color"
    :closable="adConfig.canClose"
    @feedback="handleAdBannerFeedback"
    @close="sensorsBecomeCreatorClick({ source: source as any, element_id: 'close' })"
    @click="sensorsBecomeCreatorClick({ source: source as any, element_id: 'Become Creator' })"
  />
</template>

<script lang="ts" setup>
import { isEmpty, isNil } from 'lodash-es';
import { useAdvertising } from '~/components/ad/ad.hook';
import type { AdvertsRequest } from '~/components/ad/interface';
import { SENSORS_EVENT } from '~/types';

const props = defineProps({
  adKey: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  source: {
    type: String,
    required: true,
  },
});

const route = useRoute();

const { sensorsBecomeCreatorClick, sensorsBecomeCreatorCloseReason } = useSensors();

const { getAdvertsApi, setAdCookie, getAdCookie } = useAdvertising();
const adConfig = ref<any>(null);
const adCookie = ref(getAdCookie(props.adKey as any, 'BOTTOM_BANNER'));
const showAdBanner = computed(() => isNil(adCookie.value) && !isEmpty(adConfig.value));

const handleAdBannerFeedback = (feedback: string) => {
  adCookie.value = setAdCookie(props.adKey as any, 'BOTTOM_BANNER', feedback); // 记录用户反馈
  sensorsBecomeCreatorCloseReason({
    source: props.source as any,
    element_id: feedback,
  });
};

const initAdBanner = async () => {
  const params: AdvertsRequest = {
    type: [props.type as any],
  };
  if (!isNil(route.query.adid)) {
    params.id = Number(route.query.adid);
  }
  const res = await getAdvertsApi(params);
  adConfig.value = res?.[0];
};

await initAdBanner();
</script>

<style scoped lang="less"></style>
