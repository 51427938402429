<template>
  <div v-if="allItems.length > 0" class="featured-collections mt-10">
    <div class="m-auto px-10 pb-4 pt-8" :style="{ maxWidth: 'var(--maxBoxWidth)' }">
      <div class="mb-4 flex items-center justify-between">
        <h5 class="mb-0 px-2 text-level-1 font-bold">Featured Collections</h5>
        <NuxtLink to="/collection/allcollections">
          <df-btn type="primary" @click="onSeeMoreClick">See more</df-btn>
        </NuxtLink>
      </div>
      <Lane :items="items" :gap="16" :column="6">
        <template #item="{ item, index }">
          <template v-if="index < SHOW_LEN">
            <div v-if="!mounted" class="rounded-xl bg-[#FFF] p-2">
              <RatioBox :padding-top="'66.66%'">
                <img-group :imgs="[LOADING, LOADING, LOADING]" :draggable="false" :lazy="false" class="h-full overflow-hidden rounded-lg" />
              </RatioBox>
              <div class="flex w-full flex-col justify-center gap-2 p-2 mobile:gap-1">
                <div class="h-[22px]">
                  <img :src="LOADING" alt="loading" class="h-full w-full rounded-sm" />
                </div>
                <div class="flex h-4 items-center">
                  <img :src="LOADING" alt="loading" class="h-full w-[90px] rounded-sm" />
                  <img :src="LOADING" alt="loading" class="h-full w-[90px] rounded-sm" />
                </div>
              </div>
            </div>
            <block-collection-item v-else :making-item="item" :draggable="false" source="homepage" class="duration-200 hover:translate-y-[-4px]" />
          </template>

          <NuxtLink v-else to="/collection/allcollections" class="see-more-card block h-full w-full rounded-xl p-2" @click="onSeeMoreClick">
            <div
              class="relative h-full w-full overflow-hidden rounded-lg"
              :style="{
                backgroundImage: `url(${SEE_MORE_PNG})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }"
            >
              <div class="absolute inset-0 bg-[#000]/70 backdrop-blur-[12px]"></div>
              <df-btn type="filled" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">See more</df-btn>
            </div>
          </NuxtLink>
        </template>
      </Lane>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCollectionFeaturedListApi, refreshCollectionListApi } from '~/api';
import Lane from '~/components/common/lane/index.vue';

import LOADING from '~/assets/img/common/loading.gif';
import SEE_MORE_PNG from '~/assets/img/block-card/SeeMore.png';

const SHOW_LEN = 10;
const allItems = useState<any[]>(() => range(10).map(() => ({})));
const items = computed(() => {
  const items = allItems.value.slice(0, SHOW_LEN);
  if (allItems.value.length >= SHOW_LEN) {
    items.push({});
  }
  return items;
});

const load = async (random: boolean) => {
  const res = await getCollectionFeaturedListApi.fetch({
    top: 20,
    sortType: 'hottest',
    collectionMinMakingCount: 4,
    isRandomTop: random,
  });
  if (res.code !== RespCode.SUCCESS) {
    return;
  }
  allItems.value = deduplicate(res.data.list);
};
const refresh = async () => {
  const res = await refreshCollectionListApi.fetch({
    collectionIds: allItems.value.slice(0, 10).map((item: any) => item.id),
  });
  if (res.code === 0) {
    const list = res.data.list ?? [];
    const all = toRaw(allItems.value);
    list.forEach((item, index) => {
      all[index] = item;
    });
    allItems.value = [...all];
  }
};
const deduplicate = (list: any[]) => {
  const ids = new Set<number>();
  return list.filter((item: any) => {
    if (ids.has(item.id)) {
      return false;
    }
    ids.add(item.id);
    return true;
  });
};

// 首页有缓存需要重新获取数据
const mounted = ref(false);
onMounted(async () => {
  try {
    await load(true);
  } finally {
    mounted.value = true;
  }
});
// 路由激活时刷新
onActivated(() => {
  if (mounted.value) {
    refresh();
  }
});

const { sensorsSeeMore } = useSensors();
const onSeeMoreClick = () => {
  sensorsSeeMore({
    block_id: '' as any,
    block_name: 'Featured Collections',
    clickSource: 'homepage',
  });
};
</script>

<style scoped lang="less">
.featured-collections {
  background-image: url('~/assets/img/collection-bg.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

:deep(.df-lane-wrap) {
  .df-lane {
    @media (max-width: 767px) {
      --c: 1;
    }
    @media (min-width: 768px) {
      --c: 2;
    }
    @media (min-width: 992px) {
      --c: 3;
    }
    @media (min-width: 1200px) {
      --c: 4;
    }
    @media (min-width: 1600px) {
      --c: 5;
    }

    .df-lane-track {
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    .df-lane-item {
      @media (min-width: 768px) {
        padding-top: 4px;
        padding-bottom: 16px;
      }
      @media (max-width: 767px) {
        display: none;
        width: 100%;
        margin: 12px 0 0;
        &:first-child {
          margin: 0;
        }
        &:nth-of-type(-n + 5) {
          display: block;
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
  .df-lane-arrow {
    top: calc(50% - 16px);
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.see-more-card {
  background-color: #fff;
  transition-property: box-shadow, transform;
  transition-duration: 0.2s;
  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    transform: translateY(-4px);
  }
}
</style>
