<template>
  <section v-if="tag" class="mb-8">
    <h3 class="mb-0 text-xl font-bold text-text-primary pad:text-level-2 mobile:text-level-2">AImake Info</h3>

    <div class="mt-4 rounded-xl border border-solid border-[rgba(0,0,0,0.1)] p-6 pad:p-3 mobile:p-3">
      <div class="flex items-center gap-3">
        <div class="img-box relative h-[100px] w-[100px] flex-shrink-0 rounded-lg">
          <img v-lazy="getOssProcessedImage(aiImages[0], 'm_fill,w_400,h_400', 'webp')" class="h-full w-full rounded-lg object-cover" alt="" />
        </div>
        <div>
          <span class="inline-flex rounded bg-red-sublest px-2 py-1 font-bold text-red-default">{{ tag }}</span>
          <div class="use-word-break third-line-ellipsis mt-2 text-level-2 text-text-primary" :title="promptText">
            {{ promptText }}
          </div>
        </div>
      </div>
      <div
        :class="'mt-3 inline-flex cursor-pointer items-center justify-center gap-2 rounded-full bg-background-2 px-4 py-2.5 hover:bg-background-3'"
        @click="handleGenerateSimilar"
      >
        <div class="inline-flex h-5 w-5">
          <img :src="FirePng" class="w-full object-cover" alt="" />
        </div>
        <span class="text-level-4 font-bold text-text-primary">Generate Similar Images</span>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useAiGenerate } from '@/components/project-details/common/ai-generate';
import { generateMethodTextMap } from '@/config/aimake/common';
import { useDetailsStore } from '@/stores/details';
import FirePng from '~/assets/img/project-detail/fire.png';

const { handleTryAi } = useAiGenerate();
const detailsStore = useDetailsStore();
const { sensorGenerateSimilarClick } = useSensors();

const detail = computed(() => detailsStore.detail);
const aiImages = computed(() => detail.value.aiImages || []);
const aiExtra = computed(() => detail.value.aiExtra ?? {});
const tag = computed(() => {
  // 如果 style 存在， 则使用 style
  if (aiExtra.value.style) {
    return `Style: ${aiExtra.value.style}`;
  }
  // 否则，如果 scene 存在， 则使用 scene 的映射
  if (aiExtra.value.scene) {
    return generateMethodTextMap[aiExtra.value.scene];
  }
  // 异常情况
  return '';
});
const promptText = computed(() => {
  const prompt = aiExtra.value.prompt || '';
  return `[Prompt] ${prompt}`;
});

function handleGenerateSimilar() {
  sensorGenerateSimilarClick({ source: 'Infopic' });
  handleTryAi(aiExtra.value);
}
</script>

<style lang="less" scoped>
.img-box {
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 8px;
  }
}
</style>
