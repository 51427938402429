<template>
  <div class="flex flex-wrap gap-2">
    <div
      v-for="(item, index) in list"
      :key="index"
      :class="['btn-group', disabled.includes(item) ? 'btn-group__disabled' : '']"
      @click="handleClick(item)"
    >
      <slot :item="item">
        <span>{{ item }}</span>
        <icon-font class="ml-2" type="icon-close-line" />
      </slot>
    </div>
    <slot name="extra"></slot>
  </div>
</template>
<script setup lang="ts">
type Type = {
  list?: any[];
  disabled?: string[];
};
const props = withDefaults(defineProps<Type>(), {
  list: () => [],
  disabled: () => [],
});
const emit = defineEmits(['remove']);
const handleClick = (val: string) => {
  if (props.disabled.includes(val)) {
    return;
  }
  emit('remove', val);
};
</script>
<style scoped lang="less">
.btn-group {
  display: flex;
  cursor: pointer;
  max-width: 100%;
  align-items: center;
  justify-content: left;
  border: 1.5px solid rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  padding: 0 10.5px;
  height: 48px;
  &:hover {
    border-color: rgba(25, 25, 25, 1);
  }
  &:active {
    background-color: #e9eaf0;
  }
  &__disabled {
    cursor: not-allowed;
    border-color: #cdcdcd;
    color: #cdcdcd;
    background-color: #f0f1f5;
    &:hover {
      border-color: #cdcdcd;
    }
  }
}
</style>
