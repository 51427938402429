import protocolCheck from 'custom-protocol-check';
import { isEmpty, isString } from 'lodash-es';
import { PromiseController } from './fns/promise';

export function callApp(uri: string, timeout = 3000) {
  const controller = new PromiseController();
  protocolCheck(
    uri,
    () => controller.reject(new Error('call app timeout')),
    () => controller.resolve(),
    timeout,
    () => controller.reject(new Error('unsupported URL Schema')),
  );
  return controller.promise;
}

export function callAppStore(appId: string) {
  return callApp(`itms-apps://itunes.apple.com/cn/app/id${appId}`);
}

/**
 * type: webCommandOpenUrl | webCommandOpenProject
 * token: string 登录态 token
 * url: string URL
 * name: 打开project时需要
 */
export async function callXcs(opts: { type: 'webCommandOpenUrl' | 'webCommandOpenProject'; token?: string; url?: string; name?: string }) {
  opts.token = getUToken() ?? opts.token;
  if (isEmpty(opts.token)) {
    throw new Error('token is empty');
  }
  const uri = `XCSApp://?data=${btoa(encodeURIComponent(JSON.stringify(opts)))}`;
  if (isInPCApp()) {
    window.parent.postMessage(opts, '*');
  } else if (isInIPadApp()) {
    window.open(import.meta.env.VITE_AI_PAINT, '_blank');
  } else {
    await callApp(uri);
  }
}

export function callNewPage(
  opts?:
    | {
        href: string;
        timeout?: number;
        target?: '_blank' | '_self' | '_parent' | '_top' | null;
      }
    | string,
) {
  const _opts = call(() => {
    const defuOpts = { href: '', timeout: 1000, target: '' };
    if (isString(opts)) {
      return { ...defuOpts, href: opts };
    } else {
      return { ...defuOpts, ...opts };
    }
  });

  const controller = new PromiseController();
  const a = document.createElement('a');
  a.href = _opts.href;
  a.target = _opts.target ?? '';
  setTimeout(() => {
    controller.reject(new Error(`[callNewPage]: call ${_opts.href} timeout`));
  }, _opts.timeout);
  window.addEventListener(
    'visibilitychange',
    () => {
      controller.resolve();
    },
    { once: true },
  );
  a.click();
  return controller.promise;
}
