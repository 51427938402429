<template>
  <div class="mb-6 flex items-center justify-between rounded-lg bg-[#e9f8ec] px-4 py-3">
    <div class="flex items-center gap-2">
      <icon-font class="text-2xl text-status-success" type="icon-success-01" />
      <span class="text-level-4 text-uni-13">Machine,settings, material etc. inside the XCS file can be parsed automatically.</span>
    </div>
    <icon-font class="cursor-pointer text-2xl text-uni-7" type="icon-close-line" @click="handleClose" />
  </div>
</template>
<script setup lang="ts">
const emit = defineEmits(['close']);
const handleClose = () => {
  emit('close');
};
</script>
