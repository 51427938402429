<template>
  <div id="footer-tool-bar" class="sticky bottom-0 z-[60] bg-[#fff] shadow-[0_-4px_20px_0px_rgba(0,0,0,0.08)] pc:h-20 not-pc:py-3">
    <div class="footer-bar-page mx-auto flex h-full max-w-[1200px] px-10 pad:px-5 mobile:px-5">
      <div class="flex w-full items-center justify-between gap-[40px]">
        <div class="two-line-ellipsis flex-[1_0_0%] text-[16px] font-bold text-[#212121]" :title="projectTitle">
          {{ projectTitle }}
        </div>
        <div class="flex items-center gap-4">
          <div v-if="newAttachFiles.length > 0 || isOpenBtnShow" class="button-wrap flex flex-wrap justify-center gap-3">
            <!-- 下载 按钮 -->
            <div v-if="newAttachFiles.length > 0" class="flex-[1_0_0%]">
              <df-btn
                :title="downloadBtnTitle"
                class="download flex w-full items-center justify-center !px-3"
                type="highlight"
                size="large"
                :disabled="isPreview || isDownLoadAllLoading || isDownloadLoading"
                @click="onDownloadClick"
              >
                <div v-if="isDownloadLoading || isDownLoadAllLoading" class="flex items-center gap-1">
                  <df-spin :stroke-color="'#fff'" :width="'14px'" />
                  <span>{{ p }}%</span>
                  <span class="text-level-2 font-bold">Downloading</span>
                </div>
                <template v-if="!(isDownloadLoading || isDownLoadAllLoading)">
                  <icon-font class="!ms-0 h-6 text-[24px]" type="icon-a-30-Download" />
                  <span class="text-level-2 font-bold">{{ downloadBtnTitle }}</span>
                </template>
              </df-btn>
            </div>
            <!-- 打开xcs文件按钮 -->
            <div v-if="isOpenBtnShow" class="flex-[1_0_0%] mobile:hidden">
              <ClientOnly>
                <df-btn
                  class="flex w-full items-center justify-center !px-3"
                  type="default"
                  size="large"
                  :loading="isCallingXcs"
                  :disabled="isPreview"
                  @click="onOpenClick"
                >
                  <icon-font v-if="!isCallingXcs" class="h-6 text-[24px]" type="icon-XCS" />
                  <span class="text-level-2 font-bold">{{ isXcsFileAll ? 'Open All in XCS' : 'Open in XCS' }}</span>
                </df-btn>
              </ClientOnly>
            </div>
            <!-- Import to Canvas 按钮 -->
            <div v-if="isImportToCanvasBtnShow" class="flex-[1_0_0%] mobile:hidden">
              <ClientOnly>
                <df-btn
                  class="flex w-full items-center justify-center !px-3"
                  type="default"
                  size="large"
                  :loading="importToCanvasLoading"
                  :disabled="isPreview"
                  @click="onImportToCanvas"
                >
                  <icon-font v-if="!importToCanvasLoading" class="h-6 text-[24px]" type="icon-Export-line" />
                  <span class="text-level-2 font-bold">Import to Canvas</span>
                </df-btn>
              </ClientOnly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFile } from '@/components/project-details/header/hooks/designFiles';

type PropsType = {
  isPreview?: boolean;
};
withDefaults(defineProps<PropsType>(), {
  isPreview: false,
});

const {
  newAttachFiles,
  downloadBtnTitle,
  isDownloadLoading,
  isDownLoadAllLoading,
  projectTitle,
  isOpenBtnShow,
  onDownloadClick,
  p,
  isCallingXcs,
  onOpenClick,
  isXcsFileAll,
  isImportToCanvasBtnShow,
  importToCanvasLoading,
  onImportToCanvas,
} = useFile();
</script>

<style lang="less" scoped>
.button-wrap {
  .download {
    :deep(.ant-btn) {
      .action {
        margin-left: 0;
      }
    }
  }
}
</style>
