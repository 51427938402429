<template>
  <div class="aimake-header-user-credits flex-r-c">
    <div class="credits flex-r-c">
      <img class="credits-img" :src="creditsIcon" />
      <span class="credits-text">{{ creditsValue }}</span>
    </div>

    <div class="line"></div>

    <div class="button flex-r-c" @click="handlePurchase">
      <span class="button-text font-semibold">Buy</span>
      <IconFont class="button-icon" type="icon-arrow_right" size="16" />
    </div>
  </div>
</template>
<script setup lang="ts">
import creditsIcon from '@/assets/img/credit/credit-icon.png';
import { useArtimindAiStore } from '@/stores/aimake';
import { useSensors } from '~/composables/sensors/aimake';
import { useCredit } from '~/stores/credit';
import { useFeedbackModal } from '~/stores/subscription';
const { sensorClickHeaderBuy } = useSensors();

const { isLogin } = useLoginStatus();
const feedbackModal = useFeedbackModal();
const artimindAiStore = useArtimindAiStore();
const creditStore = useCredit();

const creditsValue = ref(0);

const isInit = ref(false);

const handlePurchase = () => {
  const traceInfo = {
    source: 'generate',
  };

  const sensorParams = {
    ...traceInfo,
    name: 'headerPurchaseCredits',
    is_modal: 1,
  };

  sensorClickHeaderBuy(sensorParams);

  artimindAiStore.toggleCacheOnOff();

  creditStore.openPurchaseModal({
    trace: {
      source: 'AImake_header',
    },
  });
};

const watchTimer = ref<any>();

watch(
  () => artimindAiStore.credits,
  (val) => {
    clearTimeout(watchTimer.value);

    watchTimer.value = setTimeout(() => {
      const tar_val = Number(val);
      const cur_val = Number(creditsValue.value);

      let unit = 1;

      if (Math.abs(tar_val - cur_val) > 50) {
        unit = 100;
      }

      call(function render() {
        if (creditsValue.value >= tar_val) {
          creditsValue.value = tar_val;
          return;
        }

        if (tar_val - cur_val < 0) {
          creditsValue.value -= unit;
        } else {
          creditsValue.value += unit;
        }

        requestAnimationFrame(render);
      });

      // 刷新DF积分
      creditStore.refreshCreditCnt();
    }, 10);
  },
  {
    immediate: true,
  },
);

watch(
  () => feedbackModal.status,
  (val) => {
    if (['succeed', 'failed'].includes(val)) {
      isLogin.value && artimindAiStore.getCredits();
    }
  },
  {
    immediate: true,
  },
);

onMounted(async () => {
  isLogin.value && (await artimindAiStore.getCredits());
  isInit.value = true;
});
</script>

<style lang="less" scoped>
.aimake-header-user-credits {
  height: 40px;
  padding: 8px;
  background-color: #f5f7fb;
  border-radius: 8px;

  .credits {
    gap: 4px;
  }

  .credits-img {
    width: 24px;
    height: 24px;
  }

  .credits-text {
    color: #1a1a1a;
    font-size: 14px;
  }

  .line {
    width: 1px;
    height: 16px;
    margin-left: 16px;
    background-color: rgba(26, 26, 26, 0.1);
  }

  .button {
    padding-left: 16px;
    cursor: pointer;
    color: #ff7c23;
  }

  .button-text {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
