import { type MaybeRef } from 'vue';
import type { Fn } from '~/types/fns';
import { isFunction } from 'lodash-es';

export function useTargetVisible(target: MaybeRef, cb?: Fn<[], void>) {
  const visible = ref(false);
  const observer = useIntersectionObserver((entries) => {
    visible.value = entries.some((entry) => entry.isIntersecting);
  });

  if (isFunction(cb)) {
    watch(visible, cb);
  }

  if (isRef(target)) {
    watch(target, (newValue) => {
      observer.disconnect();
      newValue && observer.observe(getComponentRootEl(newValue));
    });
  } else {
    observer.observe(getComponentRootEl(target));
  }
  return computed(() => visible.value);
}
