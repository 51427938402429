<template>
  <div class="info-table rounded-xl border border-solid border-[rgba(0,0,0,0.1)]">
    <InfoLine v-for="item in items" :key="item.key" :item-info="item" />

    <div class="flex items-center rounded-b-xl bg-background-2 px-6 py-3 text-text-tertiary">
      <InfoCircleOutlined />
      <div class="ml-1">
        Also compatible with other machines. Visit the
        <a
          class="inline-flex underline hover:underline"
          target="_blank"
          href="https://mt.xtool.com/?utm_source=projects&utm_medium=projects_EasySet&_channel_track_key=aQL5PvQI"
        >
          <span>Material EasySet Library</span>
        </a>
         for more.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import defaultMaterial from '@/assets/img/default-material.png';
import materialsImg from '@/assets/img/materials-customize.svg';
import { type DetailProvide } from '@/components/project-details/types';
import { useDetailsStore } from '@/stores/details';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { isNil } from 'lodash-es';
import { CONTENT_TYPE } from '~/types';
import InfoLine from './InfoLine.vue';

const { isPreview } = inject('detail-common') as DetailProvide;

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const hasRight = computed(() => detail.value.hasRight);
const hasInstruction = computed(() => detail.value.hasInstruction);
const isXthings = computed(() => detail.value.type === CONTENT_TYPE.XTHINGS);
const scenarios = computed(() => {
  const list = detail.value.scenarios ?? [];
  if (list.length > 0) {
    return list.join('、');
  }
  return 'Laser';
});
const software = computed(() => {
  const list = detail.value.software ?? [];
  return list.join('、');
});
const devicePowersInfos = computed(() => detail.value.devicePowersInfos ?? []);
const machineInfo = computed(() => {
  // 是否有该字段，有则是新版，没有则是旧版
  const hasMachine = !isNil(detail.value.machines);
  if (hasMachine) {
    const machines = detail.value.machines ?? [];
    return machines.map((item: any) => {
      const powers = item.powers ?? [];
      const extraName = powers.map((power: any) => power.name).join('、');
      // 添加 icon 和 adLink
      const m = devicePowersInfos.value.find((info: any) => info.id === item.id);
      if (m) {
        item.icon = item.icon || m.extra?.icon;
        item.adLink = m.extra?.adLink;
      }
      return {
        name: item.name,
        extraName: extraName,
        icon: item.icon,
        adLink: item.adLink,
        size: 32,
        previewSize: 160,
        hover: Boolean(item.icon),
      };
    });
  } else {
    const device = detail.value.device ?? {};
    const deviceName = device.name || '';
    const powerName = detail.value.powerName || '';
    return [
      {
        name: deviceName,
        extraName: powerName,
        icon: device.extra?.icon,
        adLink: device.extra?.adLink,
        size: 32,
        previewSize: 160,
      },
    ];
  }
});
const xthingMachineList = computed(() => {
  return devicePowersInfos.value.map((item: any) => {
    const tags = Array.isArray(item.powerNames) ? item.powerNames.filter((power: string) => Boolean(power)) : [];
    return {
      name: item.name,
      icon: item.extra?.icon,
      size: 40,
      previewSize: 150,
      hover: tags.length > 0 ? true : false,
      tags,
    };
  });
});
const accessoryInfos = computed(() => {
  const accessoryInfos = detail.value.accessoryInfos ?? [];
  return accessoryInfos.map((item: any) => {
    return {
      name: item.name,
      icon: item.icon,
      adLink: '',
      size: 32,
      previewSize: 160,
      hover: !isPreview,
    };
  });
  // return {
  //   name: `xTool D1 Pro X-axis + Y-axis Limboard`,
  //   icon: 'xxx',
  //   adLink: '',
  //   size: 32,
  //   previewSize: 160,
  // };
});
// 材料列表：通过 materials 和 materialInfos 字段构建
const materialsList = computed(() => {
  const materialInfos = detail.value.materialInfos ?? [];
  const materials = detail.value.materials ?? [];
  return materials.map((material) => {
    const target = materialInfos.find((item) => item.name === material);
    if (target) {
      return {
        name: target.name,
        image: target.image || defaultMaterial,
        icon: target.icon,
        adLink: target.extra?.adLink,
        size: 16,
        previewSize: 150,
        hover: Boolean(target.id) || Boolean(target.extra?.adLink),
      };
    } else {
      // 自定义耗材
      return {
        name: material,
        image: defaultMaterial,
        icon: materialsImg,
        size: 16,
        previewSize: 150,
        hover: false,
      };
    }
  });
});
// 材料其他说明
const materialInfo = computed(() => detail.value.materialInfo || '');
const difficultyLevel = computed(() => detail.value.difficultyLevel);

// 构建items数组
const items = computed(() => {
  const list: Record<string, any>[] = [];

  // 教程
  if (!hasInstruction.value) {
    list.push({ key: 'instruction', label: 'Instruction', type: 'text', text: 'Not included' });
  }
  // 应用场景
  list.push({ key: 'scenario', label: 'Application scenario', type: 'text', text: scenarios.value });
  // 软件
  if (software.value) {
    list.push({ key: 'softwareName', label: 'Software', type: 'text', text: software.value });
  }
  // 设备
  if (isXthings.value) {
    list.push({ key: 'device', label: 'Machine & Laser Power', type: 'list-xthing-machine', list: xthingMachineList.value });
  } else {
    list.push({ key: 'device', label: 'Machine & processing module', type: 'list-machine', list: machineInfo.value });
  }
  // 配件
  if (accessoryInfos.value.length > 0) {
    list.push({
      key: 'accessories',
      label: 'Accessories',
      type: 'list-accessories',
      list: accessoryInfos.value,
    });
  }
  // 材料
  if (materialsList.value.length > 0) {
    list.push({
      key: 'materials',
      label: 'Material used',
      type: 'list-material',
      list: materialsList.value,
      extra: {
        text: materialInfo.value,
      },
    });
  }
  // 难度
  if (hasRight.value && difficultyLevel.value) {
    list.push({
      key: 'difficultyLevelId',
      label: 'Difficulty Level',
      type: 'level',
      info: difficultyLevel.value,
    });
  }
  // 时间
  if (hasRight.value && detail.value.makingTime > 0) {
    list.push({
      label: 'Total time',
      type: 'time',
      info: {
        text: detail.value.makingTime,
      },
    });
  }
  return list;
});
</script>

<style lang="less" scoped>
.info-table {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}
</style>
