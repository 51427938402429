import { isArray, isNumber } from 'lodash-es';

export function range<R = number>(rn?: [number, number] | number, handle?: (item: number) => R): R[] {
  const _rn: any = [0, 0];
  if (isArray(rn)) {
    _rn[0] = rn[0];
    _rn[1] = rn[1];
  } else if (isNumber(rn)) {
    _rn[1] = rn;
  }
  const _handle = handle ?? defuHandle;
  const res: R[] = [];
  while (_rn[0] < _rn[1]) {
    res.push(_handle(_rn[0]) as any);
    _rn[0] = _rn[0] + 1;
  }
  return res;
}

function defuHandle(item: number) {
  return item;
}
