import { handleSensorEditorPopShow } from '@/components/share/utils';
import { handelErrorImg } from '@/pages/share/useFormIntegration';
import { type UploadChangeParam, type UploadFile } from 'ant-design-vue';
import { debounce } from 'lodash-es';
import { ImgLimitSize, VideoLimitWidth } from '../../const';
import { FileStatus } from '../../interface';
import { getVideoCover } from '../../utils';
export enum PanelType {
  All = 'all',
  Video = 'video',
}
export enum SourceType {
  Library = 'library',
  Other = 'other',
}
const panel = ref(PanelType.All);
const drawerShow = ref(false);
const source = ref<SourceType>();
const allFileList = ref<any>([]);
const uploadFileList = ref<any>([]);
const isDrgging = ref(false); // 为了可拖拽到编辑器，且蒙层可点击关闭，在拖拽时隐藏蒙层
let restFileList: UploadFile[] = [];
let uploadCallBack: ((files: any) => void) | null = null;
// const libraryCallBack: ((files: any) => void) | null = null;
const delUids = ref<string[]>([]);
export const useLibrary = () => {
  const progress = reactive({
    total: [] as string[],
    finish: [] as string[],
  });
  const { handleUploadFile } = useUpload();
  const asyncPool = new AsyncPool(6);
  const dragUploadFile = (files: any, cb: (files: any) => void) => {
    changeSourceType(SourceType.Other);
    toggleDrawer();
    uploadFileList.value = [...files];
    cb && (uploadCallBack = cb);
  };
  // 点击打开媒体库
  const chooseFromLibrary = (cb: (files: any) => void) => {
    changeSourceType(SourceType.Other);
    toggleDrawer();
    changePanelType(PanelType.All);
    cb && (uploadCallBack = cb);
  };
  // 媒体库选择确认
  const libraryChooseConfirm = (files: any) => {
    files.length && uploadCallBack && uploadCallBack(files);
    toggleDrawer();
  };
  const uploadVideoCover = (cover: any, file: any) => {
    if (cover) {
      const coverFile = base64ToFile(cover);
      handleUploadFile({
        file: coverFile,
        contentDisposition: '',
        onProgress: (percent: number) => {
          Object.assign(file, { percent });
        },
        onFinish: (url: any) => {
          Object.assign(file, { thumbnailUrl: url });
        },
        onError: (err: any) => {
          console.log('err', err);
        },
      });
    }
  };
  const handleUploadServer = async (uid: string) => {
    try {
      uploadFileList.value.forEach(async (file: any) => {
        if (uid === file.uid) {
          if (file.type.indexOf('video') !== -1) {
            const coverBase64 = await getVideoCover(file.originFileObj);
            Object.assign(file, { thumbnailUrl: coverBase64, type: 'video' });
            uploadVideoCover(coverBase64, file);
          } else {
            Object.assign(file, { thumbnailUrl: fileToBlob(file) });
          }
          asyncPool.run(async () => {
            const contentDisposition = '';
            await handleUploadFile({
              file,
              contentDisposition,
              onProgress: (percent: number) => {
                Object.assign(file, { percent });
              },
              onFinish: (url: string) => {
                progress.finish.push(uid);
                file.originFileObj.url = url;
                if (file.type === 'video') {
                  Object.assign(file, { videoUrl: url });
                }
              },
              onError: () => {},
            });
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleUploadChange = debounce(async (info: UploadChangeParam<UploadFile<any>>) => {
    let addList = info.fileList
      .filter((item: any) => {
        const imgExceedLimit = item.type?.includes('image') && item?.size > ImgLimitSize;
        const videoExceedLimit = item.type?.includes('video') && item?.size > VideoLimitWidth;
        return !restFileList.find((file) => file.uid === item.uid) && !imgExceedLimit && !videoExceedLimit;
      })
      .map((item) => {
        return {
          ...item,
          thumbnailUrl: fileToBlob(item),
        };
      });
    if (addList.length) {
      addList = await handelErrorImg(addList);
      uploadFileList.value = [...addList];
      restFileList = [...info.fileList];
      nextTick(() => {
        addList.forEach(async (item) => {
          await handleUploadServer(item.uid || '');
        });
        imgUploadDone();
      });
    }
  }, 500);
  // watch(progress, () => {
  //   console.log('🚀 ~ watch ~ progress:', progress);
  // });
  // 从媒体库上传图片
  const libraryUploadMedia = (info: UploadChangeParam<UploadFile<any>>) => {
    handleUploadChange(info);
    changeSourceType(SourceType.Library);
  };
  const toggleDrawer = () => {
    drawerShow.value = !drawerShow.value;
    if (!drawerShow.value) {
      source.value = undefined;
    }
  };
  const changeSourceType = (type: SourceType) => {
    !source.value && (source.value = type);
  };
  const changePanelType = (val: PanelType) => {
    if (val === PanelType.All) {
      handleSensorEditorPopShow('All Media');
    } else if (val === PanelType.Video) {
      handleSensorEditorPopShow('All Media URL');
    }
    panel.value = val;
  };
  // 图片上传确认
  const imgUploadDone = () => {
    uploadFileList.value.length && allFileList.value.push(...uploadFileList.value);
    uploadFileList.value = [];
  };
  // 图片上传取消
  const imgUploadCancel = () => {
    if (source.value === SourceType.Library) {
      changePanelType(PanelType.All);
    } else {
      toggleDrawer();
    }
    uploadFileList.value = [];
  };
  // 视频上传完成
  const videoUploadDone = (files: any) => {
    allFileList.value.push({ ...files });
    changePanelType(PanelType.All);
  };
  // 视频上传取消
  const videoUploadCancel = () => {
    changePanelType(PanelType.All);
  };
  // 媒体库选择上传图片
  const libraryUploadImg = () => {
    // changeSourceType(SourceType.Library);
    // changePanelType(PanelType.All);
  };
  // 媒体库选择上传视频
  const libraryUploadVideo = () => {
    changePanelType(PanelType.Video);
  };
  // 打开媒体库
  const openLibrary = () => {
    toggleDrawer();
    changePanelType(PanelType.All);
  };
  // 关闭媒体库
  const closeLibrary = () => {
    toggleDrawer();
  };
  // 媒体库选择图片｜视频
  const libraryConfirm = () => {
    // libraryCallBack && libraryCallBack(files);
  };
  // 媒体库删除图片
  const libraryDeleteImg = (file: any) => {
    allFileList.value = allFileList.value.filter((item: any) => item.uid !== file.uid);
    delUids.value.push(file.uid);
  };
  const onDragStart = () => {
    isDrgging.value = true;
  };

  const onDragEnd = () => {
    isDrgging.value = false;
  };
  const resetLibraryFileList = () => {
    allFileList.value = [];
  };
  const uploadingLen = computed(() => {
    return allFileList.value.filter((item: any) => item.status === FileStatus.Uploading)?.length || 0;
  });

  return {
    source,
    panel,
    drawerShow,
    allFileList,
    uploadFileList,
    isDrgging,
    delUids,
    progress,
    uploadingLen,
    dragUploadFile,
    imgUploadDone,
    imgUploadCancel,
    videoUploadDone,
    videoUploadCancel,
    libraryUploadImg,
    libraryUploadVideo,
    openLibrary,
    libraryConfirm,
    libraryDeleteImg,
    libraryUploadMedia,
    closeLibrary,
    chooseFromLibrary,
    libraryChooseConfirm,
    onDragStart,
    onDragEnd,
    resetLibraryFileList,
  };
};
