<template>
  <div>
    <div class="text-level-1 font-semibold">URL</div>
    <div class="mb-3 mt-4 flex">
      <df-input v-model:value="videoUrl" class="mr-2" placeholder="Please paste a URL" size="large" autocomplete="“off”" />
      <df-btn :disabled="!isVideoUrlValid" :type="isVideoUrlValid ? 'primary' : 'default'" size="large" shape="default" @click="handlePreviewClick">
        Add
      </df-btn>
    </div>
    <div>
      <iframe v-if="videoItem.uid" class="media-size" :src="videoItem.videoUrl" allowfullscreen referrerpolicy="no-referrer" frameborder="0"></iframe>
      <div v-else class="media-size flex items-center justify-center rounded-lg bg-background-2 text-text-tertiary">
        <span v-if="previewLoading">
          <a-spin />
          Loading Preview...
        </span>
        <span v-else-if="previewError" class="px-5">Fail to parse. Please confirm the link is correct and ensure the video is public.</span>
        <span v-else>Video Preview</span>
      </div>
    </div>
    <div class="absolute bottom-[80px] left-0 right-0">
      <df-btn type="highlight" :disabled="!videoItem.uid" block @click="handleDone">Done</df-btn>
      <!-- <df-btn block @click="handleCancel">Cancel</df-btn> -->
      <df-btn block class="mt-3" @click="handleCancel">Cancel</df-btn>
    </div>
  </div>
</template>
<script setup lang="ts">
import { handleSensorEditClick } from '@/components/share/utils';
import type { VideoType } from '@/types/common';
import axios from 'axios';
import { v4 } from 'uuid';
import { useLibrary } from '../useLibrary';
const { videoUploadDone, videoUploadCancel } = useLibrary();
const videoItem = ref<VideoType>({
  uid: '',
  videoUrl: '',
  thumbnailUrl: '',
  title: '',
  type: 'video',
});
const previewLoading = ref(false);
const previewError = ref(false);
const videoUrl = ref('');
const videoUrlPattern = /^https:\/\/([\s\S])+/;
const isVideoUrlValid = computed(() => videoUrlPattern.test(videoUrl.value));
const youtubeHtmlReg = /<iframe[^>]*src="([^"]*)"/;
const handlePreviewClick = async () => {
  handleSensorEditClick({ name: 'All Media Upload URL Add' });
  try {
    previewLoading.value = true;
    previewError.value = false;
    const resp: any = await axios.get(`https://youtube.com/oembed?url=${videoUrl.value}`);
    const { status, data } = resp;
    if (status === 200) {
      const htmlStr = data.html;
      const matchedArr = htmlStr.match(youtubeHtmlReg);
      if (matchedArr?.length === 2) {
        videoItem.value.uid = v4();
        videoItem.value.videoUrl = matchedArr[1];
        videoItem.value.thumbnailUrl = data.thumbnail_url;
        videoItem.value.title = data.title;
      } else {
        previewError.value = true;
        videoItem.value.uid = '';
      }
    } else {
      previewError.value = true;
      videoItem.value.uid = '';
    }
  } catch (error) {
    previewError.value = true;
    videoItem.value.uid = '';
  } finally {
    previewLoading.value = false;
  }
};

const handleDone = () => {
  videoUploadDone(videoItem.value);
};

const handleCancel = () => {
  videoUploadCancel();
};
</script>
<style scoped lang="less">
.media-size {
  height: 176px;
  width: 100%;
}
:deep(.ant-btn.ant-btn-lg) {
  border-radius: 8px;
  height: 48px;
}
</style>
