<template>
  <a-modal
    v-model:open="visible"
    class="crop-modal modal-content"
    :centered="true"
    :closable="false"
    :title="title"
    :mask-closable="false"
    :destroy-on-close="true"
    :footer="null"
    :width="cropContentWidth"
    :style="modalStyle"
  >
    <template v-if="type === ModalType.CROP">
      <div class="mb-4 flex justify-center gap-3 mobile:flex-wrap mobile:justify-start">
        <div
          v-for="item in sizeTypes"
          :key="item.label"
          class="size-btn"
          :class="[isSelectSize(item.value) ? 'select-btn' : 'common-btn', cover && !isSelectSize(item.value) ? 'disabled-btn' : '']"
          @click="handleSize(item.value, item.label)"
        >
          <icon-font :type="item.icon" class="text-xl mobile:hidden" />
          {{ item.label }}
        </div>
      </div>
      <div ref="contentRef" class="relative" style="height: 100%">
        <div v-if="!imageBase64" class="flex h-full items-center justify-center">
          <a-spin />
        </div>
        <VueCropper
          v-else
          ref="cropperRef"
          class="cropper-content"
          style="background-image: none"
          :img="imageBase64"
          :auto-crop="option.autoCrop"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :fixed="option.fixed"
          :center-box="option.centerBox"
          :mode="option.mode"
          :output-type="option.outputType"
          :info-true="option.infoTrue"
          :full="option.full"
          :info="option.info"
          :fixed-number="option.fixedNumber"
          :can-move="option.canMove"
          :can-move-box="option.canMoveBox"
          @real-time="onRealTimePreview"
        />
        <div class="zoom-pannel text-level-4 font-semibold text-uni-13">
          <icon-font class="zoom-pannel__btn" type="icon-subtract-line" @click.stop="changeZoom(-1)" />
          <span class="select-none">{{ zoomScale }}%</span>
          <icon-font class="zoom-pannel__btn" type="icon-Add" @click.stop="changeZoom(1)" />
        </div>
      </div>
      <div>
        <div class="mt-2 flex items-center justify-between">
          <span class="select-none text-level-4 text-uni-7/30">Screenshot resolution {{ cutSize.w }}*{{ cutSize.h }}</span>
          <div
            :class="'water-border inline-flex cursor-pointer items-center justify-center gap-2 rounded-lg px-4 py-[10px] text-level-4 font-semibold text-uni-13'"
            @click="addWatermark"
          >
            <icon-font type="icon-Consumables" class="text-xl" />
            <span class="select-none">Add Watermark</span>
          </div>
        </div>
        <div class="mt-3 flex items-center justify-center gap-4">
          <df-btn class="w-[77px]" @click="handleClose">Cancel</df-btn>
          <df-btn class="w-[77px]" type="primary" @click="handleFinish">Save</df-btn>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex h-full flex-col">
        <div class="relative h-full overflow-hidden bg-uni-7/60">
          <div class="absolute left-0 top-0 flex h-full w-full items-center justify-center">
            <img :src="waterImgUrl" class="h-auto max-h-full w-auto max-w-full" />
          </div>
        </div>
        <span class="mt-3 text-level-4 text-uni-7/30">After saving the watermark, the original image will be replaced</span>
        <div class="mt-3 flex items-center justify-center gap-4">
          <df-btn class="w-[90px]" @click="handlePrevious">Cancel</df-btn>
          <df-btn class="w-[77px]" type="primary" @click="handleSaveWater">Save</df-btn>
        </div>
      </div>
    </template>
  </a-modal>
</template>
<script setup lang="ts">
import { handleSensorEditClick, handleSensorEditorPopShow } from '@/components/share/utils';
import { useUserInfo } from '@/stores/user';
import type { UploadFile } from 'ant-design-vue';
const useUserInfoStore = useUserInfo();
const watermarkText = computed(() => {
  const { name, id } = useUserInfoStore.myInfo;
  return `${name} ${id}`;
});
const enum ModalType {
  CROP = 'crop',
  WATERMARK = 'watermark',
}
const type = ref(ModalType.CROP);
const title = computed(() => {
  return type.value === ModalType.CROP ? 'Crop Image' : 'Add Watermark';
});

const cover = ref(false);
const visible = ref(false);
const file = ref();
type emitsType = {
  (event: 'finish', blob: Blob, file: any): void;
};
const emit = defineEmits<emitsType>();
const cropperRef = ref();
const contentRef = ref();

// 样式相关
const useCropStyle = () => {
  const cropContentWidth = ref('100%');
  const MODAL_MAX_HEIGHT = 800;
  const MODAL_MIN_HEIGHT = 620;
  const MODAL_PADDING = 40;
  const modalStyle = computed(() => {
    const viewportHeight = window.innerHeight;
    const maxHeight = Math.min(MODAL_MAX_HEIGHT, viewportHeight - MODAL_PADDING * 2); // 80px 为上下各40px的边距
    const minHeight = MODAL_MIN_HEIGHT;
    const height = Math.max(minHeight, maxHeight);
    return {
      height: `${height}px`,
    };
  });
  const changeContentWidth = () => {
    const height = contentRef.value.clientHeight;
    cropContentWidth.value = `${(height * 4) / 3 + 48}px`;
  };
  return { modalStyle, cropContentWidth, changeContentWidth };
};
const { modalStyle, cropContentWidth, changeContentWidth } = useCropStyle();

// 裁剪框配置相关
const useCropBaseOptions = () => {
  const DEFAULT_CROP_WIDTH = 1000;
  const DEFAULT_CROP_HEIGHT = 1000;
  const fixedNumber = ref([0, 0]);
  const baseOption = computed(() => {
    return {
      autoCrop: true,
      outputType: 'webp',
      fixed: true,
      centerBox: true,
      mode: ratio.value,
      infoTrue: true,
      full: true,
      info: false,
      canMove: true,
      canMoveBox: false,
      autoCropWidth: DEFAULT_CROP_WIDTH,
      autoCropHeight: DEFAULT_CROP_HEIGHT,
    };
  });
  const defaultOption = computed(() => ({
    ...baseOption.value,
    fixedNumber: fixedNumber.value,
  }));
  const coverOption = computed(() => ({
    ...baseOption.value,
    fixedNumber: [4, 3],
    canMove: true,
  }));

  // 裁剪尺寸
  const originSize: Ref<[number, number]> = ref([1, 1]);
  const setOriginSize = (size: [number, number]) => {
    originSize.value = size;
  };
  const ratio = computed(() => {
    const [width, height] = originSize.value;
    const mode = height / width > 3 / 4 ? 'auto 80%' : '80% auto';
    return mode;
  });
  const sizeTypes: any = computed(() => {
    return [
      {
        label: 'Original size',
        value: originSize.value,
        icon: 'icon-square',
      },
      {
        label: '4:3',
        value: [4, 3],
        icon: 'icon-rectangle-2',
      },
      {
        label: '1:1',
        value: [1, 1],
        icon: 'icon-square',
      },
      {
        label: '3:5',
        value: [3, 5],
        icon: 'icon-rectangle',
      },
    ];
  });
  const changeFixedNumber = (fixedVal: [number, number]) => {
    fixedNumber.value = fixedVal;
  };
  const option = computed(() => (cover.value ? coverOption.value : defaultOption.value));
  const isSelectSize = (size: [number, number]) => {
    return option.value.fixedNumber.join(',') === size.join(',');
  };
  return { option, changeFixedNumber, sizeTypes, originSize, setOriginSize, isSelectSize };
};
const { option, changeFixedNumber, sizeTypes, originSize, setOriginSize, isSelectSize } = useCropBaseOptions();

const useCropSize = () => {
  const cutSize = reactive({
    w: 0,
    h: 0,
  });
  const originalScale = ref(0);
  const zoomScale = ref(0);
  let scaleInit = false;
  const changeZoom = (num: number) => {
    cropperRef.value?.changeScale(num);
  };
  const calculateZoom = () => {
    if (!scaleInit) {
      scaleInit = true;
      return;
    }
    if (cropperRef.value?.scale === 1) {
      return;
    }
    if (!originalScale.value || !zoomScale.value) {
      originalScale.value = cropperRef.value.scale;
    }
    zoomScale.value = Math.floor(100 * (cropperRef.value.scale / originalScale.value));
  };

  const resetZoom = () => {
    zoomScale.value = 0;
    originalScale.value = 0;
    scaleInit = false;
  };
  const handleSize = (size: [number, number], label: string) => {
    if (cover.value) {
      return;
    }
    changeFixedNumber(size);
    cropperRef.value?.refresh();
    handleSensorEditClick({ name: `Crop ${label}` });
  };
  const setOriginalSize = (width: number, height: number) => {
    setOriginSize([width, height]);
    changeFixedNumber(originSize.value);
    cropperRef.value?.refresh();
  };
  const onRealTimePreview = (data: any) => {
    const { w, h } = data;
    if (w && h) {
      cutSize.w = Math.floor(w);
      cutSize.h = Math.floor(h);
    }
    calculateZoom();
  };

  return { cutSize, zoomScale, onRealTimePreview, changeZoom, resetZoom, handleSize, setOriginalSize };
};
const { cutSize, zoomScale, onRealTimePreview, changeZoom, resetZoom, handleSize, setOriginalSize } = useCropSize();

// 水印相关逻辑
const useWaterMark = () => {
  const waterImgUrl: Ref<any> = ref('');
  // 根据图片分辨率计算水印间隔
  const getFillGap = (width: number, height: number) => {
    const hMultiple = calculateMutiple(height);
    const wMultiple = calculateMutiple(width);
    const fHeight = height / hMultiple;
    const fWidth = width / wMultiple;
    return { fHeight: Math.floor(fHeight), fWidth: Math.floor(fWidth) };
  };
  const calculateMutiple = (size: number) => {
    if (size > 1600) {
      return 5;
    } else if (size > 500) {
      return 4;
    } else {
      return 3;
    }
  };
  const getWaterMarkSize = (width: number) => {
    if (width < 1000) {
      return 16;
    }
    const scale = ((width * 2) / 1000).toFixed(1);
    const font = Math.ceil(Number(scale)) * 14;
    return font;
  };
  const transImageToWatermarkBase64 = (image: HTMLImageElement) => {
    let canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    let ctx = canvas.getContext('2d');
    const font = getWaterMarkSize(canvas.width);
    if (ctx) {
      ctx?.drawImage(image, 0, 0, image.width, image.height);
      ctx.fillStyle = 'rgba(222,222,222,0.2)';
      ctx.textAlign = 'left';
      ctx.font = `${font}px font-sans`;
      const h = image.height;
      const w = image.width;
      const { fHeight, fWidth } = getFillGap(w, h);
      let odd = true;
      for (let hh = -h / 2; hh <= h * 1.5; hh += fHeight) {
        for (let ww = -w / 2; ww <= w * 1.5; ww += fWidth) {
          ctx.setTransform(1, 0, 0, 1, 0, 0);
          ctx.translate(ww, hh);
          ctx.rotate(-0.4);
          if (odd) {
            ctx.fillText(watermarkText.value, fWidth / 2, fHeight / 2);
          }
          odd = !odd;
        }
      }
    }
    return canvas.toDataURL('image/webp');
  };
  const addWatermark = async () => {
    const blobUrl = fileToBlob(await handleCropImg());
    let image = new Image();
    const originUrl = blobUrl;
    image.src = originUrl;
    image.crossOrigin = '*';
    image.onload = () => {
      waterImgUrl.value = transImageToWatermarkBase64(image);
      type.value = ModalType.WATERMARK;
    };
    handleSensorEditClick({ name: 'Add Watermark' });
  };
  const handlePrevious = () => {
    type.value = ModalType.CROP;
  };
  const handleSaveWater = () => {
    const blobFile = base64ToFile(waterImgUrl.value) as Blob;
    emit('finish', blobFile, { ...file.value });
    type.value = ModalType.CROP;
    visible.value = false;
    handleSensorEditClick({ name: 'Add Watermark Save' });
  };
  return { waterImgUrl, addWatermark, handlePrevious, handleSaveWater };
};
const { waterImgUrl, addWatermark, handlePrevious, handleSaveWater } = useWaterMark();

// 裁剪图片相关
const useCropImage = () => {
  const imageBase64 = ref('');
  const transImageToBase64 = (image: HTMLImageElement) => {
    let canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    let ctx = canvas.getContext('2d');
    ctx?.drawImage(image, 0, 0, image.width, image.height);
    return canvas.toDataURL('image/webp');
  };
  watch(file, () => {
    getBase64();
  });
  const getBase64 = () => {
    let image = new Image();
    if (file.value?.originFileObj?.url || file.value?.url) {
      const originUrl = file.value?.url;
      image.src = originUrl;
      image.crossOrigin = '*';
      image.onload = () => {
        const { width, height } = image;
        setOriginalSize(width, height);
        imageBase64.value = transImageToBase64(image);
      };
    }
  };
  const handleCropImg = () => {
    return new Promise((resolve) => {
      cropperRef.value?.getCropBlob((data: any) => {
        resolve(data);
      });
    });
  };
  const handleFinish = async () => {
    const data = (await handleCropImg()) as Blob;
    emit('finish', data, { ...file.value });
    type.value = ModalType.CROP;
    visible.value = false;
    const fixedItem = sizeTypes.value.find((item: any) => item.value.join(',') === option.value.fixedNumber.join(','));
    const fixedLabel = fixedItem?.label;
    handleSensorEditClick({ name: `Crop ${fixedLabel} save` });
  };
  return { imageBase64, handleCropImg, handleFinish };
};
const { imageBase64, handleCropImg, handleFinish } = useCropImage();

const open = (item: UploadFile, isCover?: boolean) => {
  file.value = { ...item };
  cover.value = !!isCover ? true : false;
  visible.value = true;
  nextTick(() => {
    changeContentWidth();
  });
};
defineExpose({
  open,
});

watch(visible, () => {
  if (!visible.value) {
    imageBase64.value = '';
    resetZoom();
    resetBaseData();
  } else {
    handleSensorEditorPopShow('Crop Cover');
  }
});
const resetBaseData = () => {
  imageBase64.value = '';
};
const handleClose = () => {
  visible.value = false;
  // resetBaseData();
};
</script>
<style lang="less" scoped>
.crop-modal {
  .size-btn {
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    padding: 8px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .select-btn {
    border: 1px solid #191919;
  }
  .common-btn {
    background: #f5f7fb;
  }
  .disabled-btn {
    color: #cdcdcd;
    background-color: #f0f1f5;
    cursor: not-allowed;
  }
  .water-border {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.slide-content {
  display: flex;
  align-items: center;
  width: 184px;
  height: 40px;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 12px;
  border-radius: 40px;
}
.zoom-pannel {
  position: absolute;
  left: 50%;
  bottom: 8px;
  transform: translatex(-50%);
  border-radius: 8px;
  padding: 6px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 113px;
  &__btn {
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    &:hover {
      background-color: #f0f1f5;
    }
  }
}
@media screen and(max-width: 767px) {
  .crop-modal {
    .size-btn {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less">
.modal-content {
  .crop-point {
    background-color: #ffffff;
    opacity: 1;
  }
  .cropper-view-box {
    outline-color: #ffffff;
  }
  .point4,
  .point5 {
    height: 24px;
    border-radius: 4px;
    margin-top: -12px;
  }
  .point2,
  .point7 {
    width: 24px;
    border-radius: 4px;
    margin-left: -12px;
  }
}
.cropper-content {
  .cropper-modal {
    // background-color: white;
    background: rgba(0, 0, 0, 0.6);
  }
}
.crop-modal {
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-slider {
    // width: 100px;
    flex: 1;
    margin: 0;
  }
  .ant-modal-body {
    padding-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .ant-modal-header {
    margin-bottom: 0;
  }
  .ant-slider .ant-slider-track {
    background-color: white;
  }
  .ant-slider .ant-slider-rail {
    background-color: rgba(255, 255, 255, 0.6);
  }
  .ant-slider-horizontal .ant-slider-track {
    height: 2px;
  }
  .ant-slider-horizontal .ant-slider-rail {
    height: 2px;
  }
  .ant-slider .ant-slider-handle::after {
    box-shadow: none;
  }
}
</style>
