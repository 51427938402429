import type { RouterConfig } from '@nuxt/schema';

const customRoutes = [
  {
    name: 'column-all',
    path: '/column/all',
    component: () => import('~/pages/column/all.vue'),
    meta: {
      keepAlive: true,
    },
  },
  // howtos类型(已更名为project, 保留howtos路由兼容存量数据)
  {
    name: 'howtos',
    path: '/howtos/:id',
    component: () => import('~/pages/project/detail/[type]/[id]/index.vue'),
  },
  {
    name: 'howtos-comment',
    path: '/howtos/:id/comment/:commentId',
    component: () => import('~/pages/project/detail/[type]/[id]/comment/[commentId].vue'),
  },
  // xthings类型(已归类到howtos，并更名为project, 保留xthings路由兼容存量数据)
  {
    name: 'xthings',
    path: '/xthings/:id',
    component: () => import('~/pages/project/detail/[type]/[id]/index.vue'),
  },
  {
    name: 'xthings-comment',
    path: '/xthings/:id/comment/:commentId',
    component: () => import('~/pages/project/detail/[type]/[id]/comment/[commentId].vue'),
  },
  // inspiration类型(已归类到project, 保留inspiration路由兼容存量数据)
  {
    name: 'inspiration',
    path: '/inspiration/:id',
    component: () => import('~/pages/project/detail/[type]/[id]/index.vue'),
  },
  {
    name: 'inspiration/comment',
    path: '/inspiration/:id/comment/:commentId',
    component: () => import('~/pages/project/detail/[type]/[id]/comment/[commentId].vue'),
  },
  // project类型
  {
    name: 'project',
    path: '/project/:id',
    component: () => import('~/pages/project/detail/[type]/[id]/index.vue'),
  },
  {
    name: 'project-comment',
    path: '/project/:id/comment/:commentId',
    component: () => import('~/pages/project/detail/[type]/[id]/comment/[commentId].vue'),
  },
  // designs类型
  {
    name: 'designs',
    path: '/designs/:id',
    component: () => import('~/pages/project/detail/[type]/[id]/index.vue'),
  },
  {
    name: 'designs-comment',
    path: '/designs/:id/comment/:commentId',
    component: () => import('~/pages/project/detail/[type]/[id]/comment/[commentId].vue'),
  },
  // bundle类型
  {
    name: 'bundle',
    path: '/bundle/:id',
    component: () => import('~/pages/project/detail/[type]/[id]/index.vue'),
  },
  {
    name: 'bundle-comment',
    path: '/bundle/:id/comment/:commentId',
    component: () => import('~/pages/project/detail/[type]/[id]/comment/[commentId].vue'),
  },
  {
    name: 'audit-type-id',
    path: '/audit/:type/:id',
    component: () => import('~/pages/audit/[type]/[id]/index.vue'),
    meta: { auth: true },
  },
  // {
  //   name: '/pay/status',
  //   path: '/pay/status/:id',
  //   component: () => import('~/pages/project/pay/[...id].vue'),
  // },
  {
    name: '/pay/status',
    path: '/pay/status',
    component: () => import('~/pages/project/pay/index.vue'),
  },
  {
    name: 'discussion',
    path: '/discussion',
    component: () => import('~/pages/discussion/index.vue'),
  },
  {
    name: 'discussion/detail',
    path: '/discussion/:id',
    component: () => import('~/pages/discussion/detail/index.vue'),
  },
  {
    name: 'discussion/detail/reply',
    path: '/discussion/:id/reply/:replyId',
    component: () => import('~/pages/discussion/reply/[replyId]/[id].vue'),
  },
  {
    name: 'edit/discussion',
    path: '/:type(create|edit)/discussion/:id?',
    component: () => import('~/pages/discussion/edit/index.vue'),
    meta: { auth: true },
  },
  {
    name: 'create/xthings',
    path: '/create/xthings',
    component: () => import('~/pages/xthings/editor/[[id]].vue'),
    meta: { auth: true },
  },
  {
    name: 'edit/xthings',
    path: '/edit/xthings/:id',
    component: () => import('~/pages/xthings/editor/[[id]].vue'),
    meta: { auth: true },
  },
  {
    name: 'all',
    path: '/all',
    component: () => import('~/pages/all/index.vue'),
  },
  {
    name: 'shareProject',
    path: '/share/inspiration',
    component: () => import('~/pages/share/project/index.vue'),
    meta: { auth: true },
  },
  // designs类型合并到project，加一个重定向兼容老地址的跳转
  {
    path: '/share/designs',
    redirect: '/share/project',
  },
];
export default <RouterConfig>{
  routes: (_routes) => {
    // 自定义路由不需要重复，过滤
    // 在自己负责的页面新增配置 definePageMeta({ customRoute: true })
    // todo
    return [
      ..._routes.filter((route) => {
        return !route.meta?.customRoute;
      }),
      ...customRoutes,
    ];
  },
};
