<template>
  <div class="media-pendant" @click="openLibrary">
    <div class="media-pendant__block icon-left">
      <img class="media-pendant__img" :src="pendantImgs[1] || IconLeft" alt="media-library" />
    </div>
    <div class="media-pendant__block icon-right">
      <img class="media-pendant__img" :src="pendantImgs[0] || IconRight" alt="media-library" />
    </div>
    <div class="media-pendant__block icon-bottom">
      <img class="media-pendant__img" :src="IconBottom" alt="media-library" />
      <div class="icon-bottom__status">
        <span>Media</span>
      </div>
    </div>
    <div v-if="uploadingLen" class="icon-loading"><a-spin class="relative top-0.5" size="small" /></div>
  </div>
</template>
<script setup lang="ts">
// import ShareMedia from '~/assets/img/share-media.png';
import IconBottom from '~/assets/img/media-library/icon-bottom.png';
import IconLeft from '~/assets/img/media-library/icon-left.png';
import IconRight from '~/assets/img/media-library/icon-right.png';
import { isImageType } from '../../utils';
import { useLibrary } from './useLibrary';
const { openLibrary, uploadingLen, allFileList } = useLibrary();
const pendantImgs = computed(() => {
  return allFileList.value.slice(0, 2).map((file: any) => {
    if (isImageType(file)) {
      return file.url || file.thumbnailUrl;
    } else {
      return file.thumbnailUrl || file.url;
    }
  });
});
</script>
<style scoped lang="less">
.media-pendant {
  position: relative;
  width: 105px;
  height: 91px;
  &__img {
    width: 100%;
    height: 100%;
  }
  cursor: pointer;
  .icon-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 54px;
    z-index: 103;
    &__status {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #191919;
      font-size: 16px;
      font-weight: theme('fontWeight.semibold');
    }
  }
  .icon-left {
    position: absolute;
    top: 0;
    left: 4px;
    width: 80px;
    height: 80px;
    rotate: -10deg;
    z-index: 101;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px;
    img {
      border-radius: 4px;
    }
  }
  .icon-right {
    position: absolute;
    top: 0;
    right: 8px;
    width: 80px;
    height: 80px;
    rotate: 10deg;
    z-index: 102;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px;
    img {
      border-radius: 4px;
    }
  }
  .icon-loading {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    z-index: 103;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
}
</style>
