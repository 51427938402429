import { defineStore } from 'pinia';

export const useReportData = defineStore('report-data', () => {
  const { sensorCaseExposure } = useSensors();
  let timer: ReturnType<typeof setTimeout> | null = null; // 使用更具体的类型
  type ReportType = {
    isReportAll?: boolean;
    delay?: number;
    immediate?: boolean;
  };
  const report = (opts?: ReportType | boolean) => {
    let defaultOptions: ReportType = { isReportAll: false, immediate: false, delay: 120 }; // 默认值
    if (!opts) {
      opts = defaultOptions;
    }
    defaultOptions = typeof opts === 'boolean' ? { ...defaultOptions, isReportAll: opts } : opts;
    const { isReportAll, delay, immediate } = defaultOptions;

    if (immediate) {
      task();
    } else {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        task();
      }, delay);
    }

    function task() {
      const hasList = (window as any).df_shen?.hasNewList();
      if (!hasList) {
        return;
      }

      const newList = (window as any).df_shen?.getNewList();
      if (isReportAll) {
        // 全部上报(理论上来说不会超过10条，但如果超过10条，就分批上报)
        let list = JSON.parse(JSON.stringify(newList));
        while (list.length > 10) {
          const rList = list.slice(0, 10);
          list = list.slice(10);
          checkAndReport(pickFieldsAndToString(rList));
        }
        checkAndReport(pickFieldsAndToString(list)); // 上报剩下的
        (window as any).df_shen?.clearList();
        // console.log('离开页面, 上报全部已收集的数据 :>> ', newList);
      } else {
        // 每存 10 条报一次
        if (newList.length >= 10) {
          const reportList = newList.slice(0, 10);
          (window as any).df_shen?.setNewList(newList.slice(10));
          (window as any).df_shen?.setSendedList(reportList);
          checkAndReport(pickFieldsAndToString(reportList));
        } else {
        }
      }
    }
  };

  /**
   * @description: 选择需要上报的字段
   */
  function pickFieldsAndToString(list: any[]) {
    const newList = list.map((item: any) => ({
      content_id: item.content_id,
      content_show_type: item.content_show_type,
      show_source: item.show_source,
      block_id: item.block_id,
      block_name: item.block_name,
      service_type: item.service_type.type,
      content_type: item.content_type,
      algorithm_type: item.algorithm_type,
      algorithm_version: item.algorithm_version,
      search_result_ranking: item.search_result_ranking,
      anonymous_id: item.anonymous_id,
    }));
    return JSON.stringify(newList);
  }

  /**
   * @description: 检查数据的长度是否超过4096，并且上报。 如果超过4096，就分成2次上报
   */
  function checkAndReport(str: string) {
    if (str.length > 4096) {
      const list = JSON.parse(str);
      const len = list.length;
      const num = Math.ceil(len / 2);
      sensorCaseExposure({
        objectList: JSON.stringify(list.slice(0, num)),
      });
      sensorCaseExposure({
        objectList: JSON.stringify(list.slice(num)),
      });
    } else {
      sensorCaseExposure({
        objectList: str,
      });
    }
  }

  return {
    report,
  };
});
