// stores/xcsBridge.ts
import { defineStore } from 'pinia';

/**
 * @makeblock/xcs-app-bridge 是为了在pad的xcs中，下载社区文件有所反馈而接入的
 */
export const useXcsBridgeStore = defineStore('xcsBridgeStore', () => {
  async function getAppApi() {
    const { AppApi } = await import('@makeblock/xcs-app-bridge');
    return AppApi;
  }

  return {
    getAppApi,
  };
});
