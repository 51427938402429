import { defineStore } from 'pinia';
import { Modal } from 'ant-design-vue';
import { getOrderDetailApi, getMyCreditsApi, purchaseApi, getCreditsConfigApi } from '~/api/credit';
import type { CreditConfig, PurchaseCreditsHooks, PurchaseCreditsTrace, PurchaseCreditsOptions } from '@/components/credits/types';
import { assign } from 'lodash-es';

export const useCredit = defineStore('credit', () => {
  const showModal = ref(false);
  const opts = reactive({
    msg: '',
  });

  const hooks = shallowRef<PurchaseCreditsHooks>({});
  const trace = shallowRef<PurchaseCreditsTrace>({});

  // 用户拥有的 Credits 数量
  const count = ref(0);

  // Credits 配置
  const config = ref<CreditConfig>([]);

  const init = async () => {
    await Promise.all([refreshCreditCnt(), refreshCreditConfig()]);
  };
  const refresh = async () => {
    await init();
  };

  // 刷新点数
  const refreshCreditCnt = async () => {
    try {
      if (!getUToken()) {
        return;
      }
      const res = await getMyCreditsApi.fetch();
      count.value = res.data.cnt;
    } catch (err) {
      console.error(err);
    }
  };
  const refreshCreditConfig = async () => {
    try {
      const res = await getCreditsConfigApi.fetch({
        current: 1,
        pageSize: 6,
      });
      const list = res.data.list ?? [];
      config.value = list.map((item) => ({
        count: item.credits,
        give: item.giftCredits || 0,
        price: item.totalPrice / 100,
      }));
      console.log('Credit config >>>>> ', config.value);
    } catch (err) {
      console.error(err);
    }
  };

  const purchase = async (count: number) => {
    if (isInPCApp()) {
      await xcsPurchase(count, false);
    } else {
      await webPurchase(count);
    }
  };
  const webPurchase = async (count: number) => {
    try {
      const { payUrl } = await getPayUrl(count);
      callNewPage({
        href: payUrl,
        target: '_self',
        timeout: 10 * 1000,
      });
    } catch (err) {
      message.error((err as Error).message || 'Unknown system error!');
      hooks.value.onFailed?.();
    }
  };
  const xcsPurchase = async (count: number, retry = false) => {
    try {
      const { orderNumber, payUrl } = await getPayUrl(count);
      callNewPage({
        href: payUrl,
        target: '_blank',
        timeout: 10 * 1000,
      });
      if (!retry) {
        closePurchaseModal();
        Modal.confirm({
          title: 'Have you completed the payment?',
          centered: true,
          okText: 'Yes',
          cancelText: 'No, I want to retry',
          transitionName: '',
          async onOk() {
            if (await checkOrder(orderNumber)) {
              await refresh();
              hooks.value.onSucceeded?.();
            } else {
              hooks.value.onFailed?.();
            }
          },
          onCancel() {
            xcsPurchase(count, true);
            return Promise.reject();
          },
        });
      }
    } catch (err) {
      message.error((err as Error).message || 'Unknown system error!');
      hooks.value.onFailed?.();
    }
  };

  const route = useRoute();
  const getPayUrl = async (count: number) => {
    const original = encodeURIComponent(location.pathname + location.search);

    const price = config.value.find((item) => item.count === count)?.price ?? 0;
    const _trace = encodeURIComponent(
      JSON.stringify({
        ...trace.value,
        element_name: `${count}$${price}`,
      }),
    );
    const openFrom = (route.query.openFrom ?? '') as string;
    const calledBy = isInPCApp() || (openFrom.startsWith('xcs') && route.path === '/credits/purchase') ? 'xcs' : '';

    const params = {
      count,
      successUrl: getPassportLink(),
      cancelUrl: getPassportLink(`${location.pathname}${location.search}`),
      rParam: getRedirectUrl(`/pay/status?type=credits&calledBy=${calledBy}&original=${original}&trace=${_trace}`),
    };
    console.log('Purchase credits params >>>>> ', params);
    const res = await purchaseApi.fetch(params);
    if (res.code !== RespCode.SUCCESS) {
      throw new Error(res.message || 'Unknown system error!');
    }
    return res.data;
  };
  const checkOrder = async (orderNumber: string) => {
    try {
      const res = await getOrderDetailApi.fetch({ orderNumber });
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message || '订单查询失败');
      }
      return res.data.status === 'successed';
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const { sensorsCreditsPopShow, sensorsCreditsPopClick } = useSensors();
  const openPurchasePage = (_opts?: PurchaseCreditsOptions) => {
    opts.msg = _opts?.msg || '';
    hooks.value = _opts?.hooks || {};
    trace.value = assign(_opts?.trace || {}, { entry: 'page' });

    sensorsCreditsPopShow({
      ...trace.value,
    });
  };
  const openPurchaseModal = (_opts: PurchaseCreditsOptions) => {
    opts.msg = _opts?.msg || '';
    hooks.value = _opts?.hooks || {};
    trace.value = assign(_opts?.trace || {}, { entry: 'popup' });
    showModal.value = true;

    sensorsCreditsPopShow({
      ...trace.value,
    });
  };
  const closePurchaseModal = () => {
    showModal.value = false;
    sensorsCreditsPopClick({
      ...trace.value,
      element_name: 'close',
    });
  };

  return {
    count,
    config,
    init,
    refresh,
    refreshCreditCnt,
    refreshCreditConfig,
    purchase,
    showModal,
    opts,
    trace,
    openPurchasePage,
    openPurchaseModal,
    closePurchaseModal,
  };
});

const getPassportLink = (redirect?: string) => {
  const passportUrl = import.meta.env.VITE_PASSPORT_SITE_URL;
  const token = getUToken();
  console.log('Passport redirect token >>>>> ', token);
  if (!token) {
    throw new Error('token is required');
  }

  if (!redirect) {
    return `${passportUrl}/en/authcb?ut=${token}`;
  } else {
    const redirectUrl = getRedirectUrl(redirect);
    const url = `${passportUrl}/en/authcb?ut=${token}&r=${encodeURIComponent(redirectUrl)}`;
    console.log('Passport redirect url >>>>> ', url);
    return url;
  }
};

const getRedirectUrl = (path: string) => {
  const siteUrl = import.meta.env.VITE_PASSPORT_ENV === 'dev' && !isInApp() ? location.origin : import.meta.env.VITE_PUBLIC_SITE_URL;
  return `${siteUrl}${path}`;
};
