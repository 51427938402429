import { isNil } from 'lodash-es';

type Task = () => Promise<any | void>;

export class AsyncPool {
  #limit: number;
  #taskQ: Task[];
  #workers: Promise<void>[] = [];
  #container?: Promise<void>;
  #resolve?: () => void;
  #done: boolean;

  /**
   * @param limit - 最大并发数
   */
  constructor(limit: number) {
    this.#limit = limit;
    this.#taskQ = [];
    this.#done = false;
  }

  /**
   * @description - 运行任务
   * @param task - 任务函数
   */
  run(task: Task) {
    if (!this.#container) {
      this.#reset();
      this.#container = new Promise<void>((resolve) => {
        this.#resolve = resolve;
      });
    }

    this.#taskQ.push(task);

    if (this.#workers.length < this.#limit) {
      const worker = new Promise<void>((resolve) => {
        this.#next(() => {
          const index = this.#workers.findIndex((item) => item === worker);
          if (index !== -1) {
            this.#workers.splice(index, 1);
          }
          resolve();
        });
      });
      this.#workers.push(worker);
    }
  }

  /**
   * @description - 等待所有任务完成
   */
  all() {
    if (this.#container) {
      this.#container.then(() => {
        this.#reset();
      });
    }
    return this.#container;
  }

  /**
   * @description - 取消所有任务
   */
  abort() {
    this.#done = true;
    this.#resolve?.();
  }

  #reset() {
    this.#done = false;
    this.#taskQ = [];
    this.#workers = [];
    this.#container = undefined;
    this.#resolve = undefined;
  }

  #next(resolve: () => void) {
    if (this.#done) {
      return;
    }
    const task = this.#taskQ.shift();
    if (isNil(task)) {
      return;
    }
    task().then(() => {
      if (!this.#next(resolve)) {
        resolve();
      }
      if (this.#workers.length <= 0) {
        this.#resolve?.();
      }
    });
    return task;
  }
}
