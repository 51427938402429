<template>
  <div class="instruction m-auto max-w-[1200px] px-10 pb-12 pt-8 pad:px-5 mobile:px-5">
    <FilesInfo v-if="instructionType === 'file'" />
    <StepsInfo v-if="instructionType === 'step'" />
  </div>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';
import FilesInfo from './instruction/FilesInfo.vue';
import StepsInfo from './instruction/StepsInfo.vue';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const instructionType = computed(() => detail.value.instructionType);
</script>

<style lang="less" scoped></style>
