<template>
  <div v-if="recommendList.length" class="collection-content">
    <div class="collection-wrapper mx-auto w-full max-w-[1920px] px-40 pad:px-20">
      <CommonLane :items="recommendList">
        <template #item="{ item }">
          <div
            class="inline-flex h-full w-full cursor-default"
            :style="{
              paddingTop: 'var(--pt)',
            }"
          >
            <NuxtLink
              :to="item.targetLink"
              :target="item.target"
              class="col-item-wrapper flex h-full w-full items-center justify-center"
              @click="onColItemClick(item)"
            >
              <div class="col-item inline-flex cursor-pointer flex-col items-center justify-center gap-5">
                <div
                  class="col-item-img rounded-full border-[4px] border-solid border-[#fff]"
                  :style="{
                    width: 'var(--colImgWidth)',
                    height: 'var(--colImgWidth)',
                  }"
                >
                  <img v-lazy="item.pcImage" class="h-full w-full rounded-full object-cover" :alt="item.copyWriting" />
                </div>
                <div class="col-item-title text-level-4 font-semibold text-text-secondary">{{ item.copyWriting }}</div>
              </div>
            </NuxtLink>
          </div>
        </template>

        <template #arrow="{ prev, next, allowPrev, allowNext }">
          <ClientOnly>
            <icon-font
              v-if="allowPrev"
              :class="'arrow absolute left-[-80px] z-[1] h-12 w-12 translate-x-[-50%] translate-y-[-50%] cursor-pointer select-none justify-center rounded-full bg-[#fff] text-[26px] pad:left-[-40px]'"
              :style="{
                top: 'calc(var(--pt) + var(--colImgWidth) / 2)',
              }"
              type="icon-icon-right-m"
              @click="prev"
            />
          </ClientOnly>
          <ClientOnly>
            <icon-font
              v-if="allowNext"
              :class="'arrow absolute right-[-80px] z-[1] h-12 w-12 translate-x-[50%] translate-y-[-50%] cursor-pointer select-none justify-center rounded-full bg-[#fff] text-[26px] pad:right-[-40px]'"
              :style="{
                top: 'calc(var(--pt) + var(--colImgWidth) / 2)',
              }"
              type="icon-a-icon-right-m1"
              @click="next"
            />
          </ClientOnly>
        </template>
      </CommonLane>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCollection } from './hooks';

const { getRecommendList, recommendList, onColItemClick } = useCollection();

await getRecommendList();

// 激活时重新获取数据
onActivated(() => {
  getRecommendList();
});
</script>

<style lang="less" scoped>
.collection-content {
  --pt: 48px;
  --colImgWidth: 112px;
}

.collection-wrapper {
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .col-item-wrapper {
    .col-item-img {
      transition-property: filter;
      transition-duration: 0.2s;
    }
    .col-item {
      transition-property: transform;
      transition-duration: 0.2s;
    }
    &:hover {
      .col-item-img {
        filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.08));
      }
      .col-item {
        transform: translateY(-4px);
      }
    }
  }
}
.arrow {
  transition: filter 0.2s;
}
.arrow:hover {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
}

:deep(.df-lane-wrap) {
  .df-lane {
    @media (min-width: 768px) and (max-width: 991px) {
      --c: 4;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      --c: 5;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      --c: 6;
    }
    @media (min-width: 1600px) {
      --c: 7;
    }
  }
}
</style>
