import { request, REQUEST_SEVER } from '@/utils/request';

export const getMakingDetailApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}`,
    method: 'GET',
  });
};

// 删除创作
export const deleteMakingApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}`,
    method: 'DELETE',
  });
};
// 沉帖
export const downMakingApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}/down`,
    method: 'POST',
  });
};
/**
 * 恢复沉贴
 */
export const recoverMakingApi = defineFindApi({
  url: `/community/v1/web/making/:id/cancel-down`,
  method: HttpMethod.POST,
  paramTy: ParamTy.Query<{
    id: number;
  }>(),
  respTy: RespTy.Json<any>(),
});

// 查询活动是否在线
export const queryActivityOnlineApi = defineFindApi({
  url: `/community/v1/web/front-config/:key`,
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    key: string;
  }>(),
  respTy: RespTy.Json<any>(),
});

/**
 * 获取详情页底部的推荐列表
 */
export const getDesignRecommendListApi = defineFindApi({
  url: '/community/v1/recommend/makings/similar',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    current?: number;
    pageSize?: number;
    mid: number;
  }>(),
  respTy: RespTy.Json<{
    list: any[];
    pagination: {
      current: number;
      pageSize: number;
      total: number;
    };
  }>(),
});

/**
 * 上架付费创作
 */
export const onShelvesMakingApi = (data: { id: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${data.id}/on-shelves`,
    method: 'POST',
  });
};

/**
 * 下架付费创作
 */
export const offShelvesMakingApi = (data: {
  id: number; // 创作id
  type: string; // 下架类型
  remark?: string; // 下架原因(other时必传)
}) => {
  const id = data.id;
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}/off-shelves`,
    method: 'POST',
    data: {
      type: data.type,
      remark: data.remark,
    },
  });
};

/**
 * 获取创作的审核详情页信息
 */
export const getMakingAuditDetailApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}/audit`,
    method: 'GET',
  });
};
/**
 * 获取模板id
 */
export const getTemplateIdApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v2/web/making/copy/${id}`,
    method: 'GET',
    respTy: RespTy.Json<any>(),
  });
};
