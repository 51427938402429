<template>
  <div class="drawer">
    <div v-if="drawerShow && !isDrgging" class="mask-show" @click="handleClose"></div>
    <div class="drawer-box" :class="{ show: drawerShow }">
      <div class="relative h-full">
        <slot></slot>
      </div>
      <img class="drawer-close" :src="IconFoldImg" with="24" height="64" @click="handleClose" />
    </div>
  </div>
</template>
<script setup lang="ts">
import IconFoldImg from '~/assets/img/media-library/icon-fold.png';
import { useLibrary } from './useLibrary';
const { drawerShow, isDrgging } = useLibrary();
const handleClose = () => {
  drawerShow.value = false;
};
</script>
<style scoped lang="less">
.drawer {
  width: 100%;
  display: flex;
  flex-direction: column;
  .mask-show {
    position: fixed;
    z-index: 998;
    left: 0;
    right: 0;
    bottom: 0;
    top: 64px;
    backface-visibility: hidden;
  }
  .drawer-box {
    position: fixed;
    z-index: 999;
    top: 64px;
    bottom: 0;
    padding: 24px;
    width: 360px;
    background: #ffffff;
    transition: all 0.3s ease;
    right: -360px;
    overflow-y: auto;
    overflow-x: visible;
  }
  .drawer-close {
    position: absolute;
    left: -24px;
    top: 120px;
    width: 24px;
    height: 64px;
    background-color: #ffffff;
    border-radius: 18px 0px 0px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .show {
    right: 0;
    overflow: visible;
    filter: drop-shadow(5px 5px 10px #ccc);
  }
}
</style>
