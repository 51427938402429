// 获取板块内容
import { Methods, request, REQUEST_SEVER } from '@/utils/request';
import type { Paging } from '~/types';
import type { Nil } from '~/types/fns';
import { defineCommonApi, defineFindApi } from '~/utils/fetch/define-api';

export const getCreatorCenterColumnsApi = defineFindApi({
  url: '/community/v1/web/document/blocks',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any[]>(),
});

export const getCreatorCenterBannerApi = defineCommonApi({
  url: '/efficacy/v1/data/type/community_creator_center_banner/items',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any[]>(),
});

export const getCreatorDataApi = defineFindApi({
  url: '/community/v1/web/creator/data',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    forSale?: '0' | '1' | '' | Nil;
    startTime: number;
    endTime: number;
  }>(),
  respTy: RespTy.Json<{
    view: number;
    like: number;
    favorite: number;
    download: number;
    comment: number;
    sales: number;
  }>(),
});

/**
 * 获取图表数据
 */
export const getChartDataApi = defineFindApi({
  url: '/community/v1/web/creator/data/trend',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    forSale: string;
    startTime: number;
    endTime: number;
  }>(),
  respTy: RespTy.Json<any>(),
});

/**
 * 草稿列表
 */
export const getCreatorDraftListApi = defineFindApi({
  url: '/community/v1/web/my/draft',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    current: number;
    pageSize: number;
    type?: string;
    contentTags?: string;
  }>(),
  respTy: RespTy.Json<{
    list: any[];
    pagination: {
      current: number;
      total: number;
      pageSize: number;
    };
  }>(),
});

/**
 * 获取销量排行榜数据
 */
export const getSalesRankList = defineFindApi({
  url: '/community/v1/web/creator/data/sales-ranking',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<any>(),
  respTy: RespTy.Json<{
    list: any[];
    pagination: {
      current: number;
      total: number;
      pageSize: number;
    };
  }>(),
});

// 首次提交创作者审核申请
export const uploadCreatorApplyApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/creator',
    method: Methods.POST,
    data,
  });
};

// 查询创作者审核申请状态
export const getCreatorApplyResultApi = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/creator/audit-result',
    method: Methods.GET,
  });
};

// 二次提交创作者审核申请
export const updateCreatorApplyApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/creator',
    method: Methods.PUT,
    data,
  });
};

// 新增收款人信息
export const uploadPayoutInfoApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/creator/payee/information',
    method: Methods.POST,
    data,
  });
};

// 修改收款人信息
export const updatePayoutInfoApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/creator/payee/information',
    method: Methods.PUT,
    data,
  });
};

// 查询收款人信息
export const getPayoutInfoApi = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/creator/payee/information',
    method: Methods.GET,
  });
};

// 设置stripe账号
export const uploadPayoutProcessStripeApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/creator/stripe',
    method: Methods.POST,
    data,
  });
};
// 创作者收入信息
export const getCreatorEarningInfoApi = defineFindApi({
  url: '/community/v1/web/creator/earninfo',
  method: HttpMethod.GET,
  respTy: RespTy.Json<{
    allEarnings: string; // 所有收入
    unPaidEarnings: string; // 未提现收入
    individualSales: string; // 销量收入
    newCreatorEarnings: string; // 新人激励
    salesBoostEarnings: string; // 销量激励
    canWithdraw: boolean; // 是否可以提现
  }>(),
});

// 创作者提现列表
export const getCreatorWithdrawListApi = defineFindApi({
  url: '/community/v1/web/creator/withdraws',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<
    Omit<Paging, 'total'> & {
      startTime?: string;
      endTime?: string;
    }
  >(),
  respTy: RespTy.Json<{
    pagination: Paging;
    list: Array<{
      transactionId: string; // 事务id
      amount: string; // 总金额
      fee: string; // 手续费
      status: string; // 状态，枚举：Paid-in已到账、Under Review审核中、Rejected已拒绝
      payment: string; // 提现金额
      addTime: string; // 时间戳(秒) --返回字符串，避免超过JS最大值出错
      reason: string; // 驳回原因
    }>;
  }>(),
});

// 设置银行卡支付
export const setCreatorBankCardApi = defineFindApi({
  url: '/community/v1/web/creator/bank',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json<{
    accountName: string;
    address: string;
    bank: string;
    accountNumber: string;
    swiftCode: string;
    routingNumber?: string;
    bankAddress: string;
  }>(),
  respTy: RespTy.Json<any>(),
});

// 设置paypal支付
export const setCreatorPaypalApi = defineFindApi({
  url: '/community/v1/web/creator/paypal',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json<{
    email: string;
    lastName: string;
    firstName: string;
    currency: string;
  }>(),
  respTy: RespTy.Json<any>(),
});

// 删除绑定类型
export const deleteCreatorPayeeBindApi = defineFindApi({
  url: '/community/v1/web/creator/pay',
  method: HttpMethod.DELETE,
  paramTy: ParamTy.Json<{
    payType: string;
  }>(),
  respTy: RespTy.Json<any>(),
});

// 导出提现列表
export const exportCreatorWithdrawListApi = defineFindApi({
  url: '/community/v1/web/creator/export',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    startTime?: string;
    endTime?: string;
  }>(),
  respTy: RespTy.Blob(),
});

// 创作者提现
export const creatorWithdrawApi = defineFindApi({
  url: '/community/v1/web/creator/withdraw',
  method: HttpMethod.POST,
  respTy: RespTy.Json<any>(),
});

// 批量设置价格
export const setOriginPriceApi = defineFindApi({
  url: '/community/v1/web/creator/batch-price',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json<{
    makingIds: string[];
    price: number;
  }>(),
  respTy: RespTy.Json<any>(),
});
// 获取已参加折扣活动案例
export const getDiscountItemsApi = defineFindApi({
  url: '/community/v1/web/creator/discount/items',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any>(),
});

// 新增折扣活动
export const addDiscountApi = defineFindApi({
  url: '/community/v1/web/creator/discount',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json<{
    title: string;
    discountPercent: number;
    startTime: number;
    endTime: number;
    makingIds: number[];
  }>(),
  respTy: RespTy.Json<any>(),
});

// 修改折扣活动
export const updateDiscountApi = defineFindApi({
  url: '/community/v1/web/creator/discount',
  method: HttpMethod.PUT,
  paramTy: ParamTy.Json<{
    id: number;
    title: string;
    discountPercent: number;
    startTime: number;
    endTime: number;
    makingIds: number[];
  }>(),
  respTy: RespTy.Json<any>(),
});

// 删除折扣活动
export const deleteDiscountApi = defineFindApi({
  url: '/community/v1/web/creator/discount/:id',
  method: HttpMethod.DELETE,
  paramTy: ParamTy.Json<{
    id: number;
  }>(),
  respTy: RespTy.Json<any>(),
});

// 获取活动折扣列表
export const getDiscountListApi = defineFindApi({
  url: '/community/v1/web/creator/discounts',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    current: number;
    pageSize: number;
  }>(),
  respTy: RespTy.Json<any>(),
});

// 获取折扣活动详情
export const getDiscountDetailApi = defineFindApi({
  url: '/community/v1/web/creator/discount/:id',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    id: number;
  }>(),
  respTy: RespTy.Json<any>(),
});
