<template>
  <div ref="elRef">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { isNil, throttle } from 'lodash-es';

const props = defineProps({
  blockName: {
    type: String,
    default: '',
  },
});

const { sensorsBlockExposureStayTime } = useSensors();
const trace = () => {
  const data = {
    block_name: props.blockName,
    event_duration: exposureTicker.delta,
  };
  sensorsBlockExposureStayTime(data);
};

const elRef = ref<HTMLElement>();
const windowRef = useGlobalThis();

const isExposure = () => {
  const el = elRef.value as HTMLElement;
  if (isNil(el)) {
    return false;
  }
  const y = el.getBoundingClientRect().y;

  // 目标元素覆盖视口高度的 80% 以上，认为是曝光
  return y / window.innerHeight <= 0.2;
};

// 曝光计时器
const exposureTicker = useTicker(1000);
// 事件计时器, 超过 30s 未发生事件，认为用户已离开页面
const eventTicker = useTicker(() => done(), 30 * 1000);
const pause = () => {
  exposureTicker.pause();
  eventTicker.pause();
};
const done = () => {
  if (exposureTicker.isActive && eventTicker.isActive) {
    pause();
    trace();
  }
};

const onEvent = throttle(() => {
  if (isExposure()) {
    eventTicker.reset();
    exposureTicker.isActive || exposureTicker.reset();
  } else {
    done();
  }
}, 500);

useEventListener(windowRef, 'scroll', onEvent);
useEventListener(windowRef, 'mousemove', onEvent);

onDeactivated(done);
onUnmounted(done);
</script>
