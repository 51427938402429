import { useShareProject } from '@/stores/shareProject';
import { type UploadFile } from 'ant-design-vue';
import { MakingType, ModeType } from '~/types/share';
import { ImgLimitSize, VideoLimitWidth } from './const';
export const getVideoCover = (file: any) => {
  return new Promise((resolve) => {
    const FileUrl = URL.createObjectURL(file); // 文件转bold流
    const videoElement = document.createElement('video'); // 创建一个视频控件
    videoElement.src = FileUrl; // 控件视频地址
    videoElement.autoplay = true; // 是否自动播放
    videoElement.addEventListener('canplay', function () {
      const canvasWidth = 500;
      // 获取视频原始宽高
      const videoWidth = videoElement.videoWidth;
      const videoHeight = videoElement.videoHeight;
      // 监听播放
      const canvas = document.createElement('canvas'); // 创建一个画板
      canvas.width = canvasWidth; // 画板宽
      canvas.height = Math.floor(videoHeight * (canvasWidth / videoWidth)); // 画板的高
      // 通过画板的drawImage属性对图片进行处理
      canvas?.getContext('2d')?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      // 处理完毕通过toDataURL属性转为图片格式拿去base64图片路径
      const imgs = canvas.toDataURL('image/png');
      videoElement.pause(); // 暂停播放
      resolve(imgs);
    });
  });
};

export const getMakingType = (path: string) => {
  switch (path) {
    case '/share/project':
      return 'project';
    case '/share/bundle':
      return 'bundle';
    default:
      return 'project';
  }
};
let cachedShareProjectInfo: any = null;
export const getSensorBaseData = () => {
  if (!cachedShareProjectInfo) {
    cachedShareProjectInfo = useShareProject();
  }
  const route = useRoute();
  const { id } = route.query;
  const type = getMakingType(route.path);
  const mode = type === MakingType.Bundle ? ModeType.Sale : cachedShareProjectInfo.mode;
  return {
    sevice_type: type,
    content_type: String(mode),
    content_id: Number(id),
  };
};
// 编辑器内点击元素
export const handleSensorEditClick = ({ name, status, content }: { name: string; status?: number; content?: string }) => {
  const { sensorEditorClick } = useSensors();
  const { sevice_type, content_type, content_id } = getSensorBaseData();
  sensorEditorClick({
    element_name: name,
    sevice_type,
    content_type,
    content_id,
    status,
    element_content: content,
  });
};
// 编辑器内弹窗曝光
export const handleSensorEditorPopShow = (name: string) => {
  const { sensorEditorPopShow } = useSensors();
  const { sevice_type, content_type, content_id } = getSensorBaseData();
  sensorEditorPopShow({
    element_name: name,
    sevice_type,
    content_type,
    content_id,
  });
};
// 编辑器内弹窗关闭
export const handleSensorPopClose = (name: string) => {
  const { sensorEditorPopClose } = useSensors();
  const { sevice_type, content_type, content_id } = getSensorBaseData();
  sensorEditorPopClose({
    element_name: name,
    sevice_type,
    content_type,
    content_id,
  });
};
// 编辑器内上传图片成功
export const handlePictureSuccessUpload = (name: string, source: string) => {
  const { sensorEditorPicUpload } = useSensors();
  const { sevice_type, content_type, content_id } = getSensorBaseData();
  sensorEditorPicUpload({
    element_name: name,
    sevice_type,
    content_type,
    content_id,
    source,
  });
};

export const beforeUpload = (file: UploadFile) => {
  const { size, type } = file;
  const isImage = isImageType(file);
  const isVideo = type?.indexOf('video') !== -1;
  if (!isImage && !isVideo) {
    message.error('Format do not meet the requirements');
    return Promise.reject();
  } else if ((isImage && size && size > ImgLimitSize) || (isVideo && size && size > VideoLimitWidth)) {
    message.error('Uploaded content exceeded size limit');
    return Promise.reject();
  }
  return true;
};

export const validateMediaUploaded = (mediaUrls: any = []) => {
  const hasUploading = mediaUrls.some((url: string) => {
    return !url || isBlobUrl(url); // 有未上传完成的文件
  });
  // console.log(mediaUrls);

  if (hasUploading) {
    message.warning('The file has not been fully uploaded');
    return Promise.reject();
  }
  return Promise.resolve();
};
export const isBlobUrl = (url: string = '') => {
  return url.indexOf('blob:') !== -1;
};

export const isImageType = (file: UploadFile) => {
  const { type, name } = file;
  const isHeic = name?.toUpperCase()?.includes('.HEIC');
  const isImage = type?.includes('image');
  return isHeic || isImage;
};
