<template>
  <div class="relative flex h-full w-full items-center justify-center">
    <div v-if="isLoading" class="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-text-primary">
      <df-spin :stroke-color="'#fff'" width="64px" stroke-width="4px" />
    </div>

    <div :id="`youtube-player-${index}`" class="h-full w-full"></div>
  </div>
</template>

<script setup lang="ts">
type PropsType = {
  itemInfo: any;
  index: number;
};
const props = withDefaults(defineProps<PropsType>(), {
  itemInfo: () => ({}),
  index: 0,
});

const isLoading = ref(true);
let player: any;
let YTConstructor: any;

function initPlayer() {
  // 定义onYouTubeIframeAPIReady函数， 脚本加载完成后，会被自动调用
  (window as any).onYouTubeIframeAPIReady = () => {
    YTConstructor = (window as any).YT;
    if (!YTConstructor) {
      return;
    }
    // console.log(props.itemInfo);
    const videoId = getYouTubeVideoID(props.itemInfo.url);
    // console.log('videoId :>> ', videoId);
    player = new YTConstructor.Player(`youtube-player-${props.index}`, {
      height: '100%',
      width: '100%',
      videoId: videoId,
      events: {
        'onReady': onPlayerReady,
        // 'onStateChange': onPlayerStateChange,
      },
    });
    // player.loadVideoByUrl({
    //   mediaContentUrl: props.itemInfo.url,
    // });
  };
  (window as any).onYouTubeIframeAPIReady();
}
// 从YouTube视频链接中提取视频ID
function getYouTubeVideoID(url: string) {
  const regex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|live\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regex);
  // console.log('match :>> ', match);
  if (match && match[2]?.length === 11) {
    return match[2];
  } else {
    return null;
  }
}
function onPlayerReady() {
  // console.log('event :>> ', event);
  // console.log('onPlayerReady');
  isLoading.value = false;
}
// let done = false;
// function onPlayerStateChange(event: any) {
//   console.log('event :>> ', event);
//   console.log('onPlayerStateChange');
//   // if (!YTConstructor) {
//   //   return;
//   // }
//   // if (event.data === YTConstructor.PlayerState.PLAYING && !done) {
//   //   player?.stopVideo();
//   // }
// }
function stopVideo() {
  if (player && player.stopVideo) {
    try {
      player.stopVideo();
    } catch (err) {
      console.log('err :>> ', err);
    }
  }
}

onMounted(() => {
  initPlayer();
});
onBeforeUnmount(() => {
  if (player && player.destroy) {
    player.destroy();
  }
});

defineExpose({
  stopVideo,
});
</script>

<style scoped lang="less"></style>
