import { type AsyncData } from '#app';
import { useFetch } from '#imports';
import { message } from 'ant-design-vue';
import qs from 'qs';
import { type BaseResp } from '~/api/type';
import { useMiddleCookie } from '~/composables/cookie';
import { RESPONSE_CODE } from '~/stores/types';
const parseQueryString = (queryString: string) => {
  const params: any = {};

  queryString.split('&').forEach((param) => {
    const [key, value] = param.split('=');
    const decodedKey = decodeURIComponent(key as string);
    const decodedValue = decodeURIComponent(value as string);

    if (params.hasOwnProperty(decodedKey)) {
      if (Array.isArray(params[decodedKey])) {
        params[decodedKey].push(decodedValue);
      } else {
        params[decodedKey] = [params[decodedKey], decodedValue];
      }
    } else {
      params[decodedKey] = decodedValue;
    }
  });

  return params;
};
export enum Methods {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}
export enum REQUEST_SEVER {
  DEFAULT = 'default',
  COMMON = 'common',
  DTC = 'dtc',
}

export const ResponseMessage: any = {
  '10000': 'Unknown system error!',
  '10001': 'Database run error!',
  '10002': 'Database run error!',
  '10003': 'Invalid request parameters！',
  '20101': 'Please login!',
  '20102': 'You do not have permission to perform this operation!',
  '91001': 'You have already purchased this project, you can use it directly.',
  '91010': 'This project has been removed and is not available for purchase.',
  '91011': `You have already purchased all the items in this bundle. There's no need for a repeat purchase.`,
  '90101': 'You do not have permission to perform this operation!',
  '90102': 'You do not have permission to perform this operation on published content!',
  '90103': 'You do not have permission to perform this operation on unpublished content!',
  '90104': 'Record not exist!',
  '90105': 'Already published, please do not repeat the same operation!',
  '90106': 'Please engage more with our community before creating a discussion.',
  '90201': 'Please participate in the activity within the event time!',
  '90202': 'Please participate in the activity within the event time!',
  '90203': 'Activity not exist!',
  '90903': 'The content under review cannot be edited.',
  '91801': 'A minimum of 20 is required for withdrawal.',
  '91904': 'Already subscribed successfully, please do not re-submit your order.',
  '30101': 'Sorry, you don’t have enough xTool points to redeem. Please continue earning xTool points to qualify for redemption.',
  '30102': 'You haven’t signed up for an xTool account, please try signing up again.',
  '30201': 'Sorry, you haven’t bind for an xTool account.',
  '30202': 'Sorry, the product is currently out of stock and cannot be redeemed.',
  '30207': 'Sorry, the product is not available and cannot be redeemed',
  '91909': 'The creation has been published.',
  '91916': 'Your input in Summary module contains sensitive words. Please modify it.',
};

type CreateRequestType = {
  serverBaseUrl: string;
  clientBaseUrl: string;
};
interface RequestParam {
  url: string;
  method: Methods;
  params?: any;
  data?: any;
  isServerRequest?: boolean;
}
export const request: any = {};
const createRequest = (key: string, options: CreateRequestType) => {
  request[key] = <T>(requestParam: RequestParam, config?: any): AsyncData<BaseResp<T>, any> => {
    const baseURL = process.client ? options.clientBaseUrl : options.serverBaseUrl;
    const cookie = useMiddleCookie('utoken');
    const headers: HeadersInit = new Headers();
    if (cookie.value) {
      headers.append('utoken', `${cookie.value}`);
    }

    return useFetch(requestParam.url, {
      key: requestParam.url,
      baseURL,
      method: requestParam.method,
      params: requestParam.params,
      body: requestParam.data,
      watch: false,
      ...config,
      async onResponse({ response }) {
        const code = response._data.code;
        if (code === RESPONSE_CODE.NOT_EXIST) {
          useErrorPage({
            statusCode: 404,
          });
          return;
        }
        if (import.meta.client) {
          const { $client } = useNuxtApp();
          if (code === 20101) {
            // 未打开登录弹窗时
            if (!document.querySelector('#passport-modal-container')) {
              $client.logout();
              $client.openModal(() => {
                location.reload();
              });
            }
          } else if (response.status === 200) {
            if (ResponseMessage[code]) {
              message.error(ResponseMessage[code]);
            }
          }
        }
      },
      async onRequest({ options }) {
        options.headers = headers;
        // 处理数组格式get请求的数据转换
        // 可以在api的第二个参数中配置isNeedConvertParams = true开启转换
        if (options.method === 'GET' && config?.isNeedConvertParams && options.params) {
          const queryString = qs.stringify(options.params, {
            arrayFormat: 'brackets',
            encode: false,
          });
          options.params = parseQueryString(queryString);
        }
      },
    }) as AsyncData<BaseResp<T>, any>;
  };
};

createRequest(REQUEST_SEVER.DEFAULT, {
  clientBaseUrl: import.meta.env.VITE_REQUEST_BASE_URL,
  serverBaseUrl: import.meta.env.VITE_REQUEST_BASE_URL_SERVER,
});
createRequest(REQUEST_SEVER.COMMON, {
  clientBaseUrl: import.meta.env.VITE_REQUEST_COMMON_URL,
  serverBaseUrl: import.meta.env.VITE_REQUEST_COMMON_URL_SERVER,
});
createRequest(REQUEST_SEVER.DTC, {
  clientBaseUrl: import.meta.env.VITE_REQUEST_DTC_URL,
  serverBaseUrl: import.meta.env.VITE_REQUEST_DTC_URL_SERVER,
});
