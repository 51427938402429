import type { Fn } from '~/types/fns';

export function useIntersectionObserver(
  cb: Fn<[entries: IntersectionObserverEntry[], observer: IntersectionObserver], void>,
  options: IntersectionObserverInit = {},
) {
  if (!isInClient()) {
    return null as unknown as IntersectionObserver;
  }
  const ob = new IntersectionObserver(cb, options);
  tryOnUnMounted(() => {
    ob.disconnect();
  });
  return ob;
}

export function useResizeObserver(cb: Fn<[entries: ResizeObserverEntry[], observer: ResizeObserver], void>) {
  if (!isInClient()) {
    return null as unknown as MutationObserver;
  }
  const ob = new ResizeObserver(cb);
  tryOnUnMounted(() => {
    ob.disconnect();
  });
  return ob;
}

export function useMutationObserver(cb: Fn<[mutations: MutationRecord[], observer: MutationObserver], void>) {
  if (!isInClient()) {
    return null as unknown as MutationObserver;
  }
  const ob = new MutationObserver(cb);
  tryOnUnMounted(() => {
    ob.disconnect();
  });
  return ob;
}

export function useTargetSizeObserver(cb: Fn<[], void>, type: 'self' | 'content' = 'self') {
  if (!isInClient()) {
    return null as unknown as TargetSizeObserver;
  }
  const ob = new TargetSizeObserver(cb, type);
  tryOnUnMounted(() => {
    ob.disconnect();
  });
  return ob;
}

export class TargetSizeObserver {
  #ro: ResizeObserver;
  #mo?: MutationObserver;

  constructor(cb: Fn<[], void>, type: 'self' | 'content' = 'self') {
    this.#ro = new ResizeObserver(cb);
    if (type === 'content') {
      this.#mo = new MutationObserver(cb);
    }
  }

  public observe(el: HTMLElement) {
    this.#ro.observe(el);
    this.#mo?.observe(el, {
      childList: true,
      subtree: true,
      attributes: true,
    });
  }

  public disconnect(): void {
    this.#ro.disconnect();
    this.#mo?.disconnect();
  }
}
