<template>
  <div class="files-info">
    <div v-for="file in videoFiles" :key="file.uid" class="mb-4 overflow-hidden rounded-lg">
      <RatioBox padding-top="54.46%">
        <iframe class="video-iframe h-full w-full" allowfullscreen referrerpolicy="no-referrer" :src="file.videoUrl" frameborder="0"></iframe>
      </RatioBox>
    </div>

    <div class="mb-4 overflow-hidden rounded-lg">
      <PhotoList v-if="imageFiles.length > 0" :images="imageFiles" :is-preview="true" />
    </div>

    <div v-for="file in fileFiles" :key="file.uid" class="">
      <FileItem :file-info="file" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';
import FileItem from './FileItem.vue';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const tutorialFiles = computed(() => {
  const list = detail.value.tutorialFiles ?? [];
  const fileRegx = /\.(pdf|txt|docx|doc)$/;
  return list.map((file) => {
    if (file.type === 'video') {
      return { ...file, fileType: 'video' };
    }
    if (fileRegx.test(file.name)) {
      return { ...file, fileType: 'file' };
    }
    return { ...file, fileType: 'image' };
  });
});
const videoFiles = computed(() => tutorialFiles.value.filter((file) => file.fileType === 'video'));
const imageFiles = computed(() => tutorialFiles.value.filter((file) => file.fileType === 'image').map((img) => img.url));
const fileFiles = computed(() => tutorialFiles.value.filter((file) => file.fileType === 'file'));
</script>

<style lang="less" scoped></style>
