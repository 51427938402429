<template>
  <div id="mobile-header" class="mobile-header">
    <div class="mobile-header-wrap px-[20px]">
      <div class="mb-collapse-icon relative">
        <a-dropdown
          v-model:open="menuVisible"
          :trigger="['click']"
          :align="{ points: ['tl', 'bl'], offset: [-20, 22], overflow: { adjustX: false, adjustY: false } }"
          :get-popup-container="(n: any) => n.parentNode"
        >
          <a-badge :dot="noticeStore.count > 0">
            <icon-font id="mb-target-jump" type="icon-Navigation-line" class="cursor-pointer text-2xl text-text-primary" />
          </a-badge>
          <template #overlay>
            <div class="dropdown-menu" :style="{ '--h': `${h}px` }">
              <div v-if="isLogin" class="user-center">
                <div class="info">
                  <ClientOnly>
                    <a-avatar :size="48" :src="getOssProcessedImage(userInfoStore.myInfo.headpic.split('?')[0], 'm_fill,w_60,h_60', 'webp')" />
                  </ClientOnly>
                  <p class="one-line-ellipsis">
                    {{ userInfoStore.myInfo.name }}
                  </p>
                </div>
                <div class="flex h-16 items-center justify-between rounded-[8px] bg-[#FF7C23] bg-opacity-10 px-3" @click="onCreditClick">
                  <div class="flex items-center">
                    <img :src="CREDIT_ICON" alt="" class="h-6 w-6 object-cover" />
                    <div class="ml-1">
                      <span class="text-level-3 font-bold text-[#FF7C23]">{{ creditService.count }}</span>
                      <span class="ml-1 text-level-8 text-text-primary">Credits</span>
                    </div>
                  </div>
                  <df-btn type="special" size="mini" class="!h-6 !text-[12px]" @click.stop="onPurchase">Buy</df-btn>
                </div>
                <NuxtLink to="/notice-center" class="uc-btn" @click="menuVisible = false">
                  <icon-font type="icon-message-line" />
                  Notification
                  <a-badge :count="noticeStore.count" :style="{ marginLeft: '8px' }" />
                </NuxtLink>
                <NuxtLink to="/rewards" class="uc-btn" @click="menuVisible = false">
                  <icon-font type="icon-coin-line" />
                  My Points
                  <span class="point">{{ userInfoStore.shopifyUserInfo.points || 0 }}&nbsp;Points</span>
                </NuxtLink>
                <NuxtLink to="/user-center?active=projects" class="uc-btn" @click="menuVisible = false">
                  <icon-font type="icon-planet-line" />
                  My Space
                </NuxtLink>
                <NuxtLink to="/creator/center/dashboard" class="uc-btn" @click="handleCreatorCenterClick">
                  <icon-font type="icon-a-CreatorCenter-line" />
                  Creator Center
                </NuxtLink>
                <ClientOnly>
                  <a v-if="showSettings" :href="passportSettingsUrl" class="uc-btn">
                    <icon-font type="icon-settings-line" />
                    Settings
                  </a>
                </ClientOnly>
              </div>
              <div class="bg"></div>
              <div @click.stop="handleSubmenuClick">
                <a-collapse v-model:activeKey="activeKey" ghost expand-icon-position="end" accordion>
                  <template #expandIcon="slotProps">
                    <icon-font type="icon-arrow-down" :rotate="slotProps?.isActive ? -180 : 0" />
                  </template>
                  <a-collapse-panel
                    v-for="seriesItem in xsMenus"
                    :key="seriesItem.key"
                    :show-arrow="Boolean(seriesItem.children && seriesItem.children.length > 0)"
                  >
                    <template #header>
                      <div v-if="seriesItem.children" class="header-link">
                        {{ seriesItem.title }}
                      </div>
                      <NuxtLink
                        v-if="!seriesItem.children"
                        data-type="link"
                        :to="`${seriesItem.path}`"
                        :class="['header-link', formatActive(seriesItem.path) ? 'activeRoute' : '']"
                      >
                        {{ seriesItem.title }}
                      </NuxtLink>
                    </template>
                    <div
                      v-for="subMenu in seriesItem.children"
                      :key="subMenu.key"
                      :class="['title-link', formatActive(subMenu.path) ? 'activeRoute' : '']"
                    >
                      <NuxtLink data-type="link" :to="`${subMenu.path}`">
                        {{ subMenu.title }}
                      </NuxtLink>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
              <a-button v-if="!isLogin" class="action-btn" type="primary" @click="login">Login</a-button>
              <a-button v-if="isLogin" class="action-btn" @click="logout">
                <icon-font type="icon-logout-box-line" />
                Logout
              </a-button>
            </div>
          </template>
        </a-dropdown>
      </div>
      <NuxtLink to="/" class="absolute left-1/2 w-[153px] -translate-x-1/2">
        <Logo class="w-full" />
      </NuxtLink>
      <ClientOnly>
        <div v-if="!menuVisible" class="space-x-4">
          <icon-font v-if="currentPath !== '/search-result'" type="icon-search-line" class="text-2xl text-text-primary" @click="search" />
          <icon-font type="icon-a-ShareCreationh5" class="text-2xl text-text-primary" @click="check" />
        </div>
      </ClientOnly>
    </div>
    <ClientOnly>
      <MbEntryModal v-model:visible="layoutStore.isShowMbEditorEntry" />
      <CommonActCreateMbModal v-model:visible="layoutStore.isActMbModalShow" />
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import MbEntryModal from '@/components/share/create/MbEntryModal.vue';
import { useNotice } from '@/stores/notice';
import { useUserInfo } from '@/stores/user';
import CREDIT_ICON from 'assets/img/credit/credit-icon.png';
import { useSensors } from '~/composables';
import { useCredit } from '~/stores/credit';
import { useLayoutStore } from '~/stores/layout';

const { $client } = useNuxtApp();
const { isLogin, broadcastLogout } = useLoginStatus();
const { sensorsUserCreditClick } = useSensors();

type MenuItem = {
  title: string;
  key: string;
  path: string;
  children?: MenuItem[];
};
const xsMenus = ref<MenuItem[]>([
  {
    title: 'Projects',
    key: 'project',
    path: '/all?types=project&sortType=recommend',
  },
  {
    title: 'Premium',
    key: 'premium',
    path: '/premium',
  },
  {
    title: 'Blog',
    key: 'forum',
    path: '/discussion',
  },
  {
    title: 'AImake',
    key: 'aimake',
    path: '/aimake',
  },

  /*   {
    title: 'Contests',
    key: 'contest',
    path: '/contest',
  }, */
]);

const menuVisible = ref(false);
const handleSubmenuClick = (e: any) => {
  if (e.target.dataset.type === 'link') {
    menuVisible.value = false;
  }
};

const activeKey = ref(['explore']);
const router = useRouter();
const route = useRoute();
const formatActive = (key: string) => {
  const currentRoute = router.currentRoute.value.path;
  return currentRoute.indexOf(key) > -1;
};
const currentPath = ref(route.path);

const search = () => {
  window.open(`/search-result`);
};

const login = () => {
  menuVisible.value = false;
  $client.openModal(() => {
    location.reload();
  });
};
const logout = async () => {
  menuVisible.value = false;
  try {
    broadcastLogout();
    await $client.logout();
  } finally {
    userInfoStore.resetUserInfo();

    const { sensors }: any = useSensors();
    sensors.logout();
    // 如果在个人中心退出登录自动跳转首页
    if (route.path === '/user-center') {
      window.location.href = '/';
    }
    window.location.reload();
  }
};

const layoutStore = useLayoutStore();

const check = () => {
  if (isLogin.value) {
    layoutStore.openMbEditorEntryModal();
    return;
  }
  $client.openModal(() => {
    location.reload();
  });
};
const userInfoStore = useUserInfo();
const noticeStore = useNotice();

// const handleSetAccount = () => {
//   const url = $client.getUrl('profile/settings');
//   console.log('url :>> ', url);
//   // createAlink(url, '_blank');
//   window.open(url, '_blank');
// };

const { sensorCreatorCenterEntryClick } = useSensors();
const handleCreatorCenterClick = () => {
  sensorCreatorCenterEntryClick();
  menuVisible.value = false;
};

const creditService = useCredit();
const onCreditClick = () => {
  sensorsUserCreditClick({
    element_name: 'usercard',
  });

  menuVisible.value = false;
  navigateTo({
    path: '/user-center',
    query: {
      active: 'credits',
      source: 'usercard',
    },
  });
};
const onPurchase = () => {
  sensorsUserCreditClick({
    element_name: 'Buy',
  });

  menuVisible.value = false;
  creditService.openPurchaseModal({
    trace: {
      source: 'usercard',
    },
  });
};

const passportSettingsUrl = ref('');

const h = ref<number | null>(null);
const updateHeight = () => {
  h.value = window.innerHeight - 66;
};
onMounted(() => {
  updateHeight();
  window.addEventListener('resize', updateHeight);

  passportSettingsUrl.value = $client?.getUrl('profile/settings') || '';
});
onUnmounted(() => {
  window.removeEventListener('resize', updateHeight);
});
watch(
  () => menuVisible.value,
  () => {
    if (menuVisible.value) {
      document.body.setAttribute('style', 'height:100vh;overflow:hidden');
    } else {
      document.body.setAttribute('style', 'height:auto;overflow:visible');
    }
  },
);

const showSettings = ref(true);
onMounted(() => {
  nextTick(() => {
    showSettings.value = !(isElectronXcs() || isAppWebkit()); // 在电脑xcs、手机/iPad app中不显示设置按钮
  });
});
</script>

<style lang="less" scoped>
.mobile-header {
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}
.mobile-header-wrap {
  width: 100%;
  height: 66px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  z-index: 10;
  width: 100vw;
  height: var(--h, calc(100vh - 66px));
  overflow: scroll;
  //overscroll-behavior: contain;
  background-color: #fff;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  .active {
    background: #f4f4f4;
  }
  .title-link {
    height: 48px;
    line-height: 48px;
    padding: 0 40px;
    font-weight: theme('fontWeight.semibold');
    a {
      color: #212121;
      font-size: 14px;
      font-weight: theme('fontWeight.semibold');
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #eee;
    }
  }
  .header-link {
    color: #212121;
    font-weight: theme('fontWeight.semibold');
    font-size: 16px;
  }
  .activeRoute {
    color: #212121 !important;
  }
  :deep(.ant-collapse-item-active) {
    .header-link {
      color: #999;
    }
  }
  :deep(.ant-collapse-no-arrow .ant-collapse-header) {
    &:hover {
      background-color: #eee;
    }
    .ant-collapse-header-text {
      width: 100%;
      height: 100%;
    }
    .header-link {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #212121;
    }
  }
  .user-center {
    padding-top: 20px;
    .info {
      background: #fff;
      height: 56px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin-bottom: 8px;
      .ant-avatar {
        margin-right: 12px;
      }
      p {
        color: #212121;

        font-size: 18px;
        font-weight: theme('fontWeight.semibold');
        margin: 0;
        width: calc(~'100% - 60px');
      }
    }
    .uc-btn {
      position: relative;
      padding: 0 20px;
      display: flex;
      align-items: center;
      height: 52px;
      line-height: 52px;
      color: #000;
      font-weight: theme('fontWeight.semibold');
      font-size: 16px;

      .anticon {
        font-size: 24px;
        margin-right: 8px;
      }
      .point {
        position: absolute;
        right: 20px;
        color: theme('colors.text.tertiary');
        font-size: 14px;
        font-weight: 400;
      }
      :deep(.ant-badge-count) {
        border-radius: 15px;
      }
    }
  }
  .bg {
    width: 100%;
    height: 20px;
    background-color: #f4f4f4;
  }
}
:deep(.ant-collapse-icon-position-right) {
  > .ant-collapse-item > .ant-collapse-header {
    padding-left: 20px;
  }
}

:deep(.ant-collapse) {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0 20px;
      align-items: center;
      height: 48px;
      .ant-collapse-arrow {
        font-size: 24px;
        color: #999;
      }
    }
  }
}

:deep(.ant-collapse-ghost) > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
  background: #f8f8f8;
}
.action-btn {
  margin: 20px;
  width: calc(~'100% - 40px');
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .anticon {
    font-size: 20px;
  }

  &.subscription {
    color: #a56409 !important;
    background: linear-gradient(106.36deg, #ffe1ae 0%, #f6d07e 94.4%);
  }
}

:deep(.purchase-btn) {
  border: 1.5px solid #ff7c23 !important;
  color: #ff7c23 !important;
  background-color: transparent !important;
  &:hover {
    border: 1.5px solid #ff964f !important;
    background-image: linear-gradient(0deg, #ff7c23, #ff7c23), linear-gradient(0deg, #ff964f, #ff964f);
    background-clip: text;
  }
}
//:deep(.ant-badge-dot) {
//  box-shadow: none;
//  width: 8px;
//  height: 8px;
//  right: 0;
//}
</style>
<style lang="less">
.ant-dropdown {
  z-index: 2000;
}
</style>
