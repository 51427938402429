<template>
  <SecondaryAddBtn v-if="showSecondaryBtn" class="mb-8" title="Add Material Info" @click="handleAdd" />
  <a-form-item v-else name="materialInfo" label="Material info">
    <df-textarea
      v-model:value="materialInfo"
      placeholder="Please enter material descriptions"
      :auto-size="{ minRows: 3, maxRows: 20 }"
      :maxlength="700"
    />
  </a-form-item>
</template>
<script lang="ts" setup>
import { type PropType } from 'vue';
import SecondaryAddBtn from '../SecondaryAddBtn.vue';
const showSecondaryBtn = ref(true);
const materialInfo = defineModel('materialInfo', { type: String as PropType<any>, default: () => '' });
watchEffect(() => {
  if (!showSecondaryBtn.value) {
    return;
  }
  if (materialInfo.value) {
    showSecondaryBtn.value = false;
  }
});
const handleAdd = () => {
  showSecondaryBtn.value = false;
};
</script>
