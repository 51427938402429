<template>
  <div class="information">
    <!-- Digital Downloads -->
    <FileInfos v-if="!hasRight" :detail="detail" />

    <!-- summary -->
    <SummaryInfo v-if="detail.summary && !isXthings" :detail="detail" />

    <!-- overview -->
    <Overview v-if="isXthings" />

    <!-- AImake Prompts -->
    <AiPrompts v-if="hasAi" />

    <!-- basic info -->
    <BasicInformation for-sale />

    <!-- bundle -->
    <BundleList />

    <!-- setting -->
    <SettingInfo />

    <!-- bom -->
    <Bom v-if="isShowBom" :bom="detail.bom" :is-preview="isPreview" :detail="detail" />

    <!-- Customization tags -->
    <CustomTags />

    <!-- contentTags -->
    <ContentTags />

    <!-- license -->
    <License v-if="detail.license" />

    <!-- xthings 富文本区域 -->
    <ProductPattern v-if="isXthings && !hasRight" />

    <!-- ai声明 -->
    <AiAssistance v-if="detail.aiAssistance" />
  </div>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';
import { CONTENT_TYPE } from '~/types';
import AiAssistance from './AiAssistance.vue';
import AiPrompts from './AiPrompts.vue';
import BasicInformation from './BasicInformation.vue';
import Bom from './Bom.vue';
import BundleList from './BundleList.vue';
import ContentTags from './ContentTags.vue';
import CustomTags from './CustomTags.vue';
import FileInfos from './FileInfos.vue';
import License from './License.vue';
import Overview from './Overview.vue';
import ProductPattern from './ProductPattern.vue';
import SettingInfo from './SettingInfo.vue';
import SummaryInfo from './SummaryInfo.vue';

type Props = {
  isPreview?: boolean;
};
withDefaults(defineProps<Props>(), {
  isPreview: false,
});

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const contentType = computed(() => detail.value.type as CONTENT_TYPE);
const isXthings = computed(() => contentType.value === CONTENT_TYPE.XTHINGS);
const isShowBom = computed(() => detail.value.bom?.hasBom);
const hasRight = computed(() => detail.value.hasRight);
const hasAi = computed(() => (detail.value.aiImages || []).length > 0);
</script>

<style lang="less" scoped></style>
