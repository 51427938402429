<template>
  <div
    :class="'bg-u[#fff] mt-4 flex items-center justify-between rounded-xl border border-solid border-[rgba(26,26,26,0.1)] px-4 py-2 pad:px-2 mobile:px-2'"
  >
    <div class="flex items-center justify-center gap-2">
      <div class="inline-flex h-5 w-5">
        <img :src="FirePng" class="w-full object-cover" alt="" />
      </div>
      <span class="text-text-primary pad:text-level-7 mobile:text-level-7">This project is created with AImake</span>
    </div>
    <div class="flex items-center justify-center gap-1" @click="onClickTryAi">
      <span
        :class="'cursor-pointer whitespace-nowrap text-level-7 font-semibold text-text-secondary hover:text-text-primary pad:text-level-7 mobile:text-level-7'"
      >
        Try AImake
      </span>
      <icon-font class="text-[12px]" type="icon-a-30-arrow-right-s-line" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type DetailProvide } from '@/components/project-details/types';
import FirePng from '~/assets/img/project-detail/fire.png';
import { type ItemType } from '~/types';

const { isPreview } = inject('detail-common') as DetailProvide;

type PropsType = {
  detail: ItemType;
};
const props = defineProps<PropsType>();

const { sensorTryAiMakeClick } = useSensors();

const isForSale = computed(() => props.detail.forSale);

function onClickTryAi() {
  if (isPreview) {
    return;
  }
  sensorTryAiMakeClick({ is_free: !isForSale.value ? 1 : 0 });
  createAlink('/aimake/home', '_blank');
}
</script>

<style lang="less" scoped></style>
