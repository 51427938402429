<template>
  <a-form-item name="origPriceCent" label="Price" class="price-content">
    <div class="mb-3 mt-[-6px] text-text-tertiary mobile:text-[12px]">
      Price in USD. If you are selling the project on other platforms, please enter the minimum price to guarantee price consistency.
      <span v-if="!disabled">
        Suggest Original Price Range:
        <span class="text-[#FF7C23]">${{ minSuggestPrice }}-${{ maxSuggestPrice }}</span>
      </span>
    </div>
    <a-dropdown :open="isFocus" :arrow="false">
      <df-input
        v-model:value="price"
        size="large"
        placeholder="0"
        :class="['pl-3', isFocus ? 'focus' : '']"
        onkeyup="value=value.replace(/[^\d\.]/g,'') "
        :disabled="disabled"
        @focus="isFocus = true"
        @blur="blurChange"
        @change="onChangeKeyword"
      >
        <template #prefix>
          <ClientOnly>
            <span :class="['font-sans text-[16px] text-[#1A1A1A]', disabled ? 'text-[#CDCDCD]' : '']">$</span>
          </ClientOnly>
        </template>
        <template #suffix>
          <span :class="['font-sans text-[16px] text-[#1A1A1A]', disabled ? 'text-[#CDCDCD]' : '']">USD</span>
        </template>
      </df-input>
      <template #overlay>
        <div
          v-show="showSearchSelect && isFocus && historyPrice.length > 0"
          class="w-full rounded-[12px] bg-[#fff] p-0.5"
          :style="{
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 20px 0 rgba(0, 0, 0, 0.1)',
          }"
        >
          <div v-for="item in historyPrice" :key="item" :class="['cursor-pointer py-3 pl-3 hover:bg-background-2']" @click.stop="choosePrice(item)">
            $ {{ item.toFixed(2) }}
          </div>
        </div>
      </template>
    </a-dropdown>
  </a-form-item>
</template>
<script setup lang="ts">
import { getPriceHistory } from '@/api/project';
type PropsType = {
  origPriceCent: number;
  disabled?: boolean;
  type: string;
};
const props = withDefaults(defineProps<PropsType>(), {
  disabled: () => false,
});
const historyPrice = ref([] as number[]);

const origPriceCent = defineModel('origPriceCent', { type: Number, default: () => 0 });

const isFocus = ref(false);
const price = ref(null as any);
const showSearchSelect = ref(true);
const minSuggestPrice = ref(0);
const maxSuggestPrice = ref(0);
const getPrice = async () => {
  try {
    const res = await getPriceHistory(props.type);
    const data = res.data.value.data;
    minSuggestPrice.value = data.minSuggestPrice / 100;
    maxSuggestPrice.value = data.maxSuggestPrice / 100;
    historyPrice.value = data.latestPrices?.map((item: any) => item / 100) ?? [];
  } catch (e) {
    console.log(e);
  }
};
// 获取接口请求回来的初始价格
const initFun = () => {
  initPrice = watch(
    () => origPriceCent.value,
    (val) => {
      if (val && !isFocus.value) {
        price.value = String((val / 100).toFixed(2));
        if (!props.disabled) {
          initPrice && initPrice(); // 停止监听
        }
      }
    },
    {
      immediate: true,
    },
  );
};
function onChangeKeyword() {
  showSearchSelect.value = false;
  origPriceCent.value = Math.round(Number(price.value) * 100);
}
const blurChange = () => {
  setTimeout(() => {
    isFocus.value = false;
    const reg = '^\\d+(\\.\\d+)?$';
    if (!new RegExp(reg).test(price.value)) {
      price.value = '';
    } else {
      price.value = parseFloat(price.value).toFixed(2);
    }
    origPriceCent.value = Math.round(Number(price.value) * 100);
    if (origPriceCent.value >= 100 * 100 && origPriceCent.value <= 500 * 100) {
      message.warning('Price exceeds 99.9% of content on the site, may affect sale success.', 1);
    }
  }, 200);
};
const choosePrice = (item: any) => {
  price.value = item;
};
let initPrice = null as any;
onMounted(() => {
  nextTick().then(() => {
    initFun();
    if (!props.disabled) {
      getPrice();
    }
  });
});
</script>
