<template>
  <div class="loader"></div>
</template>

<script lang="ts" setup>
type PropsType = {
  width?: string | number;
  strokeWidth?: string | number;
  strokeColor?: string;
};
const props = withDefaults(defineProps<PropsType>(), {
  width: '20px', // 默认宽度
  strokeWidth: '3px', // 默认边框宽度
  strokeColor: '#ff0035', // 默认边框颜色
});

const w = computed(() => (typeof props.width === 'number' ? props.width + 'px' : props.width));
const s = computed(() => (typeof props.strokeWidth === 'number' ? props.strokeWidth + 'px' : props.strokeWidth));
const c = computed(() => props.strokeColor);
</script>

<style scoped lang="less">
.loader {
  width: v-bind(w);
  height: v-bind(w);
  border: v-bind(s) solid v-bind(c);
  border-radius: 50%;
  animation:
    l20-1 1s infinite linear alternate,
    l20-2 2s infinite linear;
}
@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}
@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}
</style>
