<template>
  <a-form-item name="contentTags" label="Tags" :rules="rules.contentTags ? rules.contentTags : []" class="new-ant-style">
    <ShareCommonSelectBtn v-if="contentTags.length" class="mb-3" :list="contentTags" @remove="handleRemove" />
    <df-select
      v-model:value="selected"
      mode="multiple"
      :max-tag-count="10"
      popup-class-name="part-box-select "
      :max-tag-text-length="20"
      placeholder="Press Enter or add , or # to separate tags"
      :default-active-first-option="false"
      :search-value="searchWord"
      :not-found-content="searchWord ? undefined : null"
      :options="tagList"
      @change="handleTagChange"
      @search="getTagList"
      @focus="handleShowTagDropdown(true)"
      @blur="handleShowTagDropdown(false)"
      @input-key-down="enterTag"
    >
      <template #removeIcon>x</template>
      <template v-if="searchWord.trim() !== ''" #notFoundContent>
        <a-empty v-if="!loading" :image="emptyImg" class="tag-empty-img">
          <template #description>
            <p>No tag is matched,</p>
            <p>
              press"
              <span>Enter</span>
              " to generate a custom tag
            </p>
          </template>
        </a-empty>
      </template>
    </df-select>
    <div v-if="aiTagShow" class="mt-3 flex flex-wrap items-center gap-2 text-[#191919]">
      <template v-if="coverAutoTag.initAi && coverAutoTag.loading">
        <img :src="coverAutoTag.shareAiGif" alt="" width="20" height="20" />
        <span>AI Generating...</span>
      </template>
      <div
        v-for="item in topTagList"
        :key="item"
        class="cursor-pointer rounded-full bg-background-2 px-3 py-[6px] hover:bg-background-3 hover:text-black-hover"
        @click="handleAddTopTag(item)"
      >
        <icon-font class="mr-2" type="icon-Add" />
        <span>{{ item }}</span>
      </div>
    </div>
  </a-form-item>
</template>
<script setup lang="ts">
import { getTagCount } from '@/api/common';
import emptyImg from '@/assets/img/no-data.png';
import { handleSensorEditClick } from '@/components/share/utils';
import type { CoverAutoTag } from '@/types/share';
type PropsType = {
  rules: any;
  coverAutoTag: CoverAutoTag;
};

const props = defineProps<PropsType>();

const contentTags = defineModel('contentTags', { type: Array as PropType<any>, default: () => [] });
const selected = ref<string[]>([]);
const searchWord = ref('');
const tagList = ref([] as any);
const loading = ref(false);
const aiTagShow = computed(() => (props.coverAutoTag.initAi && props.coverAutoTag.loading) || topTagList.value.length);
let isDown = false;
const getTagList = async (value: string) => {
  try {
    loading.value = true;
    tagList.value = [];
    searchWord.value = value;

    if (value === '') {
      return;
    }
    const res = await getTagCount({
      q: value,
      objectType: 'making',
    });
    loading.value = false;
    const data = res.data.value.data.map((tag: any) => ({
      label: `${tag.tag} (${tag.nums})`,
      value: tag.tag,
    }));
    tagList.value = data;
  } catch (error) {
    loading.value = false;
  }
};
const handleTagChange = () => {
  if (selected.value.length) {
    const selContent = selected.value
      .map((tag: any) => {
        return tag.split('').splice(0, 50).join('');
      })
      .filter((item: any) => !!item.trim());
    searchWord.value = '';
    contentTags.value = [...new Set([...contentTags.value, ...selContent])];
  }
  searchWord.value = '';
  selected.value = [];
};
const handleShowTagDropdown = (bool: boolean) => {
  if (!bool) {
    tagList.value = [];
  }
};

const enterTag = (key: any) => {
  if (key.code === 'ArrowDown' || key.code === 'ArrowUp') {
    // 下拉菜单打开时，上下键滚动，按enter时应该选择下拉菜单的内容
    isDown = true;
  }
  if (key.code === 'Enter' && !isDown) {
    const tag = searchWord.value.replace(/[#,@]/g, '#');
    const arr = tag.split('#').filter((ele: string) => ele.trim() !== '');
    contentTags.value = [...new Set([...contentTags.value, ...arr])];
    searchWord.value = '';
    selected.value = [];
  }
};
const handleRemove = (val: string) => {
  contentTags.value = contentTags.value.filter((item: string) => item !== val);
};
const topTagList = computed(() => {
  return [...props.coverAutoTag.tags].filter((item: any) => {
    const matchTag = contentTags.value.includes(item);
    return !matchTag;
  });
});

const handleAddTopTag = (tagName: string) => {
  contentTags.value = [...new Set([...contentTags.value, tagName])];

  handleSensorEditClick({ name: 'AI tag Use', content: tagName });
};
</script>
<style scoped lang="less">
.tag-empty-img {
  padding-bottom: 24px;
  .ant-empty-image {
    width: 64px;
    height: 64px;
    margin: 0 auto;
  }
  img {
    width: 64px;
  }
  p {
    margin: 0;
    color: rgba(34, 34, 34, 0.3);
    // margin-bottom: 8px;
    span {
      color: #555;
    }
  }
}
</style>
