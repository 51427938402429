<template>
  <div class="xthings-setting flex flex-col gap-2">
    <div class="flex gap-2">
      <div
        v-for="setting in paraSettings"
        :key="setting.uid"
        class="cursor-pointer rounded border-[1.5px] border-solid border-[rgba(0,0,0,0.1)] p-2"
        :class="{
          'border-[rgba(0,0,0,1)]': setting.title === active,
        }"
        @click="active = setting.title"
      >
        {{ setting.title }}
      </div>
    </div>
    <template v-for="setting in paraSettings" :key="setting.uid">
      <div v-if="setting.title === active">
        <TextSetting v-model:value="setting.custom" :index="0" :setting="handleSetting(setting)" is-preview :show-icon="false" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import TextSetting from '@/components/share/project/instruction/textSetting.vue';
// import XcsSetting from '@/components/share/project/instruction/xcsSetting.vue';

type PropsType = {
  paraSettings: any[];
};

const props = defineProps<PropsType>();
const { paraSettings } = toRefs(props);

const active = ref(paraSettings.value[0]?.title);
const handleSetting = (setting: any) => {
  const newSetting = JSON.parse(JSON.stringify(setting));
  if (!['F1', 'F1 Ultra'].includes(newSetting.title)) {
    newSetting.laserType = '';
  }
  newSetting.title = '';
  newSetting.deviceName = '';
  newSetting.powerName = '';
  return newSetting;
};
</script>

<style lang="less" scoped>
.xthings-setting + .xthings-setting {
  margin-top: 16px;
}
</style>
