<template>
  <div class="df-header-wrap">
    <div class="df-header">
      <div class="df-header__left">
        <div class="df-header__logo flex items-center">
          <h1 class="hidden"><a href="//designfind.com/">DesignFind</a></h1>
          <a href="/" class="inline-flex items-center" @click.prevent="navigateTo('/')">
            <Logo class="h-10" />
          </a>
        </div>
        <DfNav />
      </div>
      <div class="df-header__right flex items-center justify-end">
        <ClientOnly>
          <Search class="mr-4 flex-1" />
          <div>
            <CreateGuide />
          </div>
          <Share class="mr-4" />
          <User class="mr-4" />
          <NoticeMessageList v-if="isLogin" />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CreateGuide from './CreateGuide.vue';
import Search from './Search.vue';
import Share from './Share.vue';
import User from './User.vue';
import DfNav from './nav/index.vue';

const { isLogin } = useLoginStatus();
</script>

<style scoped lang="less">
.df-header-wrap {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 40px;
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(16px);

  @media (max-width: 992px) {
    display: none;
  }

  .df-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__left {
      position: relative;
      z-index: 1;
      flex-shrink: 0;
      display: flex;
      flex-wrap: nowrap;

      @media (max-width: 1027px) {
        width: calc(100% - 350px);
      }
    }
    &__right {
      @media (min-width: 1028px) {
        flex: 1;
        padding-left: 40px;
      }

      position: relative;
      z-index: 2;

      //background: #fff;
    }
  }
}
</style>
