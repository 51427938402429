/**
 * 解析json，支持指定默认值（解析失败或不允许null时使用默认值）
 */
export function parseJSON(
  text: string | null,
  options: { defaultValue: any; allowNull?: boolean } = {
    defaultValue: null,
  },
) {
  if (typeof text !== 'string') {
    text = String(text);
  }
  let value;
  try {
    value = JSON.parse(text);
    if (value === null) {
      const allowNull =
        options.defaultValue === null || (options.allowNull ?? false);
      if (!allowNull) {
        value = options.defaultValue;
      }
    }
  } catch (e) {
    value = options.defaultValue;
  }
  return value;
}
