export const usePdfJs = () => {
  async function getPdfJs() {
    const pdfjs = await import('pdfjs-dist');
    const workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url);
    // console.log('workerSrc :>> ', workerSrc.toString());
    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc.toString();
    return pdfjs;
  }

  return {
    getPdfJs,
  };
};
