<template>
  <div id="footer-tool-bar" class="sticky bottom-0 z-20 bg-[#fff] shadow-[0_-4px_20px_0px_rgba(0,0,0,0.08)] pc:h-20 not-pc:py-3">
    <Base v-if="isXThins" />
    <CreditsBuy v-else />
  </div>
</template>

<script setup lang="ts">
import Base from './Base.vue';
import CreditsBuy from './CreditsBuy.vue';
import { useDetailsStore } from '~/stores/details';
import { CONTENT_TYPE } from '~/types';

const detailService = useDetailsStore();
const isXThins = computed(() => detailService.detail.type === CONTENT_TYPE.XTHINGS);
</script>

<style lang="less" scoped></style>
