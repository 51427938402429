<template>
  <div class="desc-info">
    <h1 class="third-line-ellipsis mb-0 text-[32px] font-bold leading-10 text-[#212121] pad:text-[24px] mobile:text-[24px]" :title="detail.title">
      {{ detail.title || 'No Title' }}
    </h1>

    <!-- remix 标识 -->
    <div
      v-if="isRemixed"
      class="mt-4 inline-flex cursor-pointer rounded-full bg-background-2 px-3 py-0.5 text-level-4 text-text-tertiary hover:underline"
      @click="handleRemixedClick"
    >
      <span>Remixed by</span>
    </div>

    <!-- 文章数据 -->
    <ArticleData :is-preview="isPreview" />

    <!-- AI home 页面入口 -->
    <AiEntry v-if="hasAi && !isAdmin && !isCreator" :detail="detail" />

    <!-- 定价设置 -->
    <PriceEntry v-if="isShowChangePrice" />

    <!-- 卖点 -->
    <SellerPoint v-if="isForSale && !hasRight" :is-preview="isPreview" />
    <!-- 设计文件 -->
    <DesignFiles v-else :is-preview="isPreview" />

    <!-- 互动 -->
    <Interactive :is-preview="isPreview" />
  </div>
</template>

<script setup lang="ts">
import type { DetailProvide } from '@/components/project-details/types';
import { SourceType } from '@/components/project-details/types';
import { useDetailsStore } from '@/stores/details';
import { useUserInfo } from '@/stores/user';
import AiEntry from './AiEntry.vue';
import ArticleData from './ArticleData.vue';
import DesignFiles from './DesignFiles.vue';
import Interactive from './Interactive.vue';
import PriceEntry from './PriceEntry.vue';
import SellerPoint from './SellerPoint.vue';

const { isPreview } = inject('detail-common') as DetailProvide;

const emits = defineEmits(['scrollBaseRemix']);

const detailsStore = useDetailsStore();
const userInfoStore = useUserInfo();

const detail = computed(() => detailsStore.detail);
const hasRight = computed(() => detail.value.hasRight);
const isForSale = computed(() => detail.value.forSale);
const isRemixed = computed(() => detail.value.source === SourceType.REMIX);
const hasAi = computed(() => (detail.value.aiImages || []).length > 0);
const isAdmin = computed(() => userInfoStore.isAdmin);
const isCreator = computed(() => detail.value.isCreator);
const isShowChangePrice = computed(() => isForSale.value && (isAdmin.value || isCreator.value));

function handleRemixedClick() {
  emits('scrollBaseRemix');
}
</script>

<style lang="less" scoped></style>
