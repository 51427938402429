<template>
  <div class="nav-project-detail flex w-full">
    <div v-if="!isNil(catalogs)" ref="elRef" class="left flex pt-4">
      <div
        v-for="catalog in catalogs"
        :key="catalog.id"
        class="catalog-item flex flex-shrink-0 flex-grow-0 flex-col"
        :style="{ width: `${w / catalogs.length}px` }"
      >
        <h5 class="nav-ellipsis w-full px-[12px] text-level-2 font-bold text-text-primary">{{ catalog.catalogName }}</h5>
        <div class="flex flex-col">
          <a
            v-for="(item, index) in catalog.items"
            :key="index"
            :href="item.path"
            :class="[
              'nav-ellipsis',
              'h-[36px] w-fit max-w-full px-[12px] py-[8px]',
              'text-sm font-normal leading-tight text-[#565656]',
              'hover:rounded-[26px] hover:bg-[#F0F1F5] hover:text-[#191919]',
              'transition-all ease-in-out',
            ]"
            @click.prevent="myNavigateTo(item.path, `${catalogType}-${item.name}`)"
          >
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>
    <div v-if="!isNil(imgInfo) && !isEmpty(imgInfo.image)" class="right flex justify-center pt-4">
      <a class="all-card rounded-xl text-text-primary shadow hover:text-text-primary" :href="link" @click.prevent="onClick">
        <div class="cover w-full p-[8px]">
          <div class="relative h-auto w-full overflow-hidden rounded-[7.24px] pt-[45.39%]">
            <img :src="imgInfo.image" alt="" class="absolute left-0 top-0 h-full w-full cursor-pointer object-cover" />
          </div>
        </div>
        <div class="content px-[16px] pb-[16px] pt-[8px]">
          <h5 class="truncate break-all text-level-2 font-bold">{{ imgInfo.title }}</h5>
          <p class="desc text-level-5">
            {{ imgInfo.desc }}
          </p>
          <ClientOnly>
            <df-btn type="primary" size="mini">View</df-btn>
          </ClientOnly>
        </div>
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { isEmpty, isNil } from 'lodash-es';
import type { PropType } from 'vue';
import { type MenuCtx, MENU_CTX_KEY, useNav } from './hooks';
import type { NavConfig } from './types';

const props = defineProps({
  config: {
    type: Object as PropType<NavConfig>,
    default: () => ({}),
  },
});

const route = useRoute();

const ctx = inject(MENU_CTX_KEY, {} as MenuCtx);

// eslint-disable-next-line vue/no-setup-props-destructure
const { catalogType, catalogs, imgInfo } = props.config;

const defuLink = '/all?types=project&sortType=recommend&page=1';
const link = ref(imgInfo?.link ?? defuLink);
const onClick = () => {
  if (link.value === defuLink) {
    myNavigateTo(link.value);
    return;
  }
  const host = `${location.protocol}//${location.host}`;
  const isFullPath = /^https?:\/\//.test(link.value);
  if (!isFullPath) {
    return;
  }
  if (link.value.startsWith(host)) {
    const path = link.value.replace(host, '');
    if (route.fullPath === path) {
      ctx.close();
    } else {
      myNavigateTo(path);
    }
  } else {
    window.open(link.value, '_blank');
  }
};

const { myNavigateTo } = useNav();

const elRef = shallowRef<HTMLElement>();
const [w] = useTargetSize(elRef, 'self');
</script>

<style scoped lang="less">
.nav-project-detail {
  @media (max-width: 992px) {
    flex-direction: column;
  }
  .left {
    width: 67.23%;
    .catalog-item {
      padding-right: 16px;
    }
    @media (max-width: 992px) {
      width: 100%;
      .catalog-item:last-child {
        padding-right: 0;
      }
    }
  }
  .right {
    flex: 1;
    @media (max-width: 992px) {
      flex: none;
      width: 100%;
    }
    .all-card {
      flex-direction: column;

      .desc {
        overflow: hidden;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webki-box-orient: vertical;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      @media (max-width: 992px) {
        flex-direction: row;
      }

      @media (min-width: 1200px) {
        width: 396px;
      }
      @media (max-width: 1199px) {
        width: 100%;
      }
      @media (max-width: 992px) {
        display: flex;
        .cover {
          flex-shrink: 0;
          width: 304px;
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }
    }
  }
}
.nav-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
