<template>
  <SideDrawer class="library-drawer">
    <Material v-if="panel === PanelType.All" />
    <UploadVideo v-if="panel === PanelType.Video" />
  </SideDrawer>
</template>
<script setup lang="ts">
enum PanelType {
  All = 'all',
  Video = 'video',
}
const props = defineProps<{
  initMedias: any;
}>();
import SideDrawer from './SideDrawer.vue';
import Material from './material/index.vue';
import { useLibrary } from './useLibrary';
import UploadVideo from './video/index.vue';
const { panel, allFileList, delUids } = useLibrary();
const emit = defineEmits(['fileChange']);
watch(
  () => allFileList.value,
  () => {
    emit('fileChange', allFileList.value);
  },
  { deep: true },
);
watch(
  () => props.initMedias,
  () => {
    updateAllFileList([...(props.initMedias || [])]);
  },
  { deep: true },
);
const updateAllFileList = (list: any) => {
  const addMediaList = list.filter((item: any) => {
    return !allFileList.value.some((file: any) => file.uid === item.uid || delUids.value.includes(item.uid));
  });
  allFileList.value.push(...addMediaList);
};
</script>
<style scoped lang="less">
.library-drawer {
  :deep(.ant-upload-drag) {
    text-align: left;
    border: none;
    background: #ffffff;
    .ant-upload {
      padding: 0;
    }
  }
}
</style>
