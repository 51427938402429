import { getAimakeGenerateSamePath } from '@/utils/aimake';
import qs from 'qs';

export const useAiGenerate = () => {
  function handleTryAi(data: any) {
    const { $client } = useNuxtApp();
    const { isLogin } = useLoginStatus();

    if (!isLogin.value) {
      $client.openModal(() => {
        location.reload();
      });
      return;
    }

    const info = getAimakeGenerateSamePath(data);
    const query = info.query ? `?${qs.stringify(info.query)}` : '';
    createAlink(info.path + query, '_blank');
  }

  return {
    handleTryAi,
  };
};
