<template>
  <div class="flex h-full flex-col justify-between">
    <div>
      <BaseEmpty />
      <div class="mt-8 flex justify-center gap-3" @click.stop>
        <a-upload
          v-model:file-list="uploadFileList"
          name="file"
          :show-upload-list="false"
          :accept="accept"
          :multiple="true"
          :before-upload="beforeUpload"
          :custom-request="() => {}"
          @change="handleChange"
        >
          <df-btn class="w-[150px]" type="primary" @click="handleUploadFile">Upload File</df-btn>
        </a-upload>
        <df-btn class="w-[150px]" @click="handleUploadUrl">URL</df-btn>
      </div>
    </div>

    <div class="mt-3 h-6 self-end text-level-7 text-text-tertiary">Try dragging images or videos into the upload area</div>
  </div>
</template>
<script setup lang="ts">
import { beforeUpload } from '@/components/share/utils';
import { type UploadChangeParam, type UploadFile } from 'ant-design-vue';
import BaseEmpty from '../BaseEmpty.vue';
import { useLibrary } from '../useLibrary';
defineProps({
  // eslint-disable-next-line vue/require-default-prop
  accept: {
    type: String,
  },
});
const { libraryUploadVideo, libraryUploadImg, libraryUploadMedia, uploadFileList } = useLibrary();
const handleUploadFile = () => {
  libraryUploadImg();
};

const handleUploadUrl = () => {
  libraryUploadVideo();
};
const handleChange = (info: UploadChangeParam<UploadFile>) => {
  if (!info.file.status) {
    return false;
  }
  info.fileList.length && libraryUploadMedia(info);
};
</script>
