<template>
  <div class="interactive-wrapper">
    <!-- 点赞/收藏/分享 -->
    <div v-if="isPublish" class="relative mt-6 flex gap-4">
      <a-tooltip placement="bottom">
        <template #title>{{ isMyLike ? 'Unlike' : 'Like' }}</template>
        <div class="w-1/3">
          <ActionButton
            shape="default"
            class="action-btn like-btn"
            :size="60"
            :active="isMyLike"
            :disabled="isPreview"
            icon="icon-a-30-like"
            active-icon="icon-a-30-like-2"
            :text="likeCount"
            @click="handleToggleAction('like')"
          />
        </div>
      </a-tooltip>

      <a-tooltip placement="bottom">
        <template #title>
          {{ isMyFavorite ? 'Remove from your Favorites' : 'Add to Favorites' }}
        </template>
        <div class="w-1/3">
          <ActionButton
            shape="default"
            class="action-btn"
            :size="60"
            :active="isMyFavorite"
            icon="icon-bookmark-line2"
            active-icon="icon-a-bookmark-fill"
            :disabled="isPreview"
            :text="favoriteCount"
            @click="handleToggleAction('favorite')"
          />
        </div>
      </a-tooltip>

      <a-tooltip placement="bottom" title="Share">
        <div class="w-1/3" title="Share">
          <ActionButton v-if="!isMounted" shape="default" class="action-btn" :size="60" icon="icon-share-line" :disabled="isPreview" />
          <CommonSharePot v-else :trigger="'click'" :title="shareTitle" :description="shareDescription" :jump="false">
            <ActionButton shape="default" class="action-btn" :size="60" icon="icon-share-line" :disabled="isPreview" />
          </CommonSharePot>
        </div>
      </a-tooltip>

      <!-- 跳跃的元素(会被克隆) -->
      <!-- <div id="jump-element" class="jump-box w-[56px] rounded-lg bg-status-alert py-0.5 text-[#fff]">
        <div class="jump-inner flex items-center justify-center gap-1">
          <icon-font class="text-[16px]" type="icon-database-2-fill" />
          <span class="font-semibold">+10</span>
        </div>
      </div> -->
    </div>

    <!-- 更新时间/沉贴/恢复 -->
    <div class="report-wrapper mt-4 flex items-center justify-between">
      <div class="page-footer text-[14px] text-text-tertiary">
        <ClientOnly>
          <div :title="getUpdateTime(detail.updatedAt).format('YYYY-MM-DD HH:mm:ss')">
            <span>
              {{ detail.status === 'published' ? 'Edited ' : 'Last edited ' }}
            </span>
            <span>
              {{ getUpdateTime(detail.updatedAt).format('YYYY/MM/DD') }}
            </span>
          </div>
        </ClientOnly>
      </div>
      <div class="flex items-center justify-between">
        <!-- 沉贴/恢复 -->
        <a-popconfirm
          v-if="isAdmin && !isForSale"
          :open="downBtnVisiable"
          title="Are you sure？"
          @confirm="onToggleFreeMaking"
          @open-change="handleVisiableChange"
        >
          <a-button
            class="detail-down h-[22px] cursor-pointer p-0 text-[#ff4d4f] hover:opacity-85"
            type="text"
            :title="isOnLine ? '沉贴' : '恢复'"
            :disabled="isPreview"
          >
            <ClientOnly>
              <icon-font
                class="h-5 text-[20px]"
                :class="[isOnLine ? 'text-[#ff4d4f]' : 'text-[#00755f]']"
                :type="isOnLine ? 'icon-Frame1' : 'icon-unlist'"
              />
            </ClientOnly>
          </a-button>
        </a-popconfirm>
        <!-- 下架/上架 -->
        <template v-if="isAdmin && isForSale && !audit">
          <a-tooltip placement="bottom">
            <template #title>
              {{ isCaseOn ? 'remove' : 'unlist' }}
            </template>
            <a-button class="remove-btn flex items-center p-0" type="text" :disabled="isPreview" @click="onToggleDetail">
              <icon-font
                class="h-5 text-[20px]"
                :class="[isCaseOn ? 'text-[#ff4d4f]' : 'text-[#00755f]']"
                :type="isCaseOn ? 'icon-remove' : 'icon-unlist'"
              />
            </a-button>
          </a-tooltip>
        </template>

        <a-space v-if="!isCreator" class="report-btn-group">
          <a-button type="text" class="report-btn text-[14px] text-text-tertiary" :disabled="isPreview" @click="openReportModal">
            <WarningOutlined class="h-[16px] text-[16px]" />
            <span class="font-sans">Report</span>
          </a-button>
        </a-space>
      </div>
      <ReportModal ref="reportModalRef" @send="getReportStatus" />
    </div>

    <!-- 上架/下架弹窗 -->
    <ClientOnly>
      <ReportShelfModal ref="toggleShelfRef" @sended="isCaseOnComputed = false" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { onShelvesMakingApi, recoverMakingApi } from '@/api/project-details';
import { initStates as initDetailsStates } from '@/components/project-details/hook';
import type { DetailProvide } from '@/components/project-details/types';
import { useDetailsStore } from '@/stores/details';
import { useUserInfo } from '@/stores/user';
import moment from 'moment';
import { getReportStatusApi } from '~/api';
import { downMakingApi } from '~/api/project-details';
import { MStatus, type ItemType } from '~/types';

type Props = {
  isPreview?: boolean;
  previewDetail?: ItemType;
  previewType?: string;
};
const props = withDefaults(defineProps<Props>(), {
  isPreview: false,
  previewDetail: undefined,
  previewType: undefined,
});
const { isPreview } = toRefs(props);
const { audit } = inject('detail-common') as DetailProvide;

const detailsStore = useDetailsStore();
const userInfoStore = useUserInfo();
const { goToLogin, isLogin } = initDetailsStates();
const { isPc } = useScreen();

const detail = computed(() => detailsStore.detail);
const isPublish = computed(() => detail.value.status === 'published');
const isMyLike = computed(() => detail.value.userAction?.like > 0);
const likeCount = computed(() => detail.value.actionSum?.like ?? 0);
const isMyFavorite = computed(() => detail.value.userAction?.favorite > 0);
const favoriteCount = computed(() => detail.value.actionSum?.favorite ?? 0);
const shareTitle = computed(() => detail.value.title);
const shareDescription = computed(() => detail.value.summary);
const isForSale = computed(() => detail.value.forSale);
const isAdmin = computed(() => userInfoStore.isAdmin);
const isCreator = computed(() => detail.value.isCreator);

const handleToggleAction = (type: string) => {
  detailsStore.handleToolStatusClick(type, 'rightSide');
};

function getUpdateTime(date: number) {
  if (date) {
    return moment(date * 1000);
  } else {
    return moment(Date.now());
  }
}

// 沉贴/恢复 相关
const downBtnVisiable = ref(false);
const isOnLine = ref(detail.value.mstatus !== 'down');
const isOnLineComputed = computed({
  get() {
    return isOnLine.value;
  },
  set(val) {
    isOnLine.value = val;
  },
});
function handleVisiableChange(bool: boolean) {
  if (!bool) {
    downBtnVisiable.value = false;
    return;
  }
  if (isPreview.value) {
    return;
  } else {
    downBtnVisiable.value = true;
  }
}
function onToggleFreeMaking() {
  if (isPreview.value) {
    return;
  }
  if (isOnLineComputed.value) {
    downMaking();
  } else {
    cancelDownMaking();
  }
}
async function downMaking() {
  try {
    const id = detail.value.id;
    const { data } = await downMakingApi(id);
    if (data.value.code === 0) {
      message.success('down success!');
      isOnLine.value = false;
    } else {
      message.error(data.value?.message || 'Unknown system error!');
    }
  } catch (err: any) {
    message.error(err);
    console.log('downMaking-err :>> ', err);
  }
}
async function cancelDownMaking() {
  try {
    const id = detail.value.id;
    const res = await recoverMakingApi.fetch({ id });
    if (res.code === 0) {
      message.success('cancel down success!');
      isOnLine.value = true;
    } else {
      message.error(res?.message || 'Unknown system error!');
    }
  } catch (err) {
    console.log('cancelDownMaking-err :>> ', err);
  }
}

// 举报相关
const isReported = ref(false);
async function getReportStatus() {
  if (isPreview.value) {
    return;
  }

  if (!isLogin.value) {
    return;
  }

  const params = {
    objectId: detail.value.id,
    objectType: detail.value.type,
  };
  try {
    const { data } = await getReportStatusApi(params);
    if (!data?.value?.data) {
      isReported.value = false;
      return;
    }
    const { status } = data.value.data;
    isReported.value = ['todo', 'approved'].includes(status);
  } catch (err) {
    console.log('err :>> ', err);
  }
}
const reportModalRef = ref();
function openReportModal() {
  if (!isLogin.value) {
    goToLogin();
    return;
  }
  // 如果已举报
  if (isReported.value) {
    message.success({
      content: h('span', {
        innerHTML: `Thank you for letting us know. Your report is submitted.
        ${isPc.value ? '<br />' : ''}
        We'll address your concerns within 3 business days.`,
        style: {
          display: 'flex',
          textAlign: 'left',
        },
      }),
      key: 'report',
      class: 'content-detail-message-success',
    });
    return;
  }
  reportModalRef.value.open(detail.value);
}

// 下架/上架相关
const isCaseOn = ref(detail.value.mstatus === MStatus.OnShelves);
const isCaseOnComputed = computed({
  get: () => isCaseOn.value,
  set: (val) => {
    isCaseOn.value = val;
  },
});
const toggleShelfRef = ref();
async function onToggleDetail() {
  if (isCaseOnComputed.value) {
    toggleShelfRef.value.open(detail.value);
  } else {
    try {
      const { data } = await onShelvesMakingApi({
        id: detail.value.id,
      });
      if (data?.value?.code === 0) {
        message.success('unlist success!');
        isCaseOnComputed.value = true;
      }
    } catch (err) {
      console.log('toggle-shelf-ref-err :>> ', err);
    }
  }
}

getReportStatus();

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>

<style lang="less" scoped>
// .jump-box {
//   position: absolute;
//   right: -8px;
//   top: -8px;
//   z-index: 20;
// }
:deep(.action-btn.action-button) {
  width: 100% !important;
  border-radius: 12px;
  transition: none;
  &:hover {
    border-width: 2px;
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    .text {
      margin-top: 0;
      color: theme('colors.text.primary');
    }
  }
}

.detail-down {
  &:hover {
    color: #ff4d4f;
  }
}

@media screen and (max-width: 767px) {
  :deep(.action-button.action-btn) {
    width: 100% !important;
    max-width: unset;
    max-height: unset;
  }
}
</style>
