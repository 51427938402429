<template>
  <div class="df-user-wrap">
    <a-dropdown
      v-model:open="isAvatarPopoverVisible"
      :arrow="false"
      :disabled="!isLogin"
      :trigger="'hover'"
      :align="{ offset: [44, 4] }"
      :placement="'bottom'"
      :get-popup-container="(node: any) => node.parentNode"
      @open-change="handleVisibleChange"
    >
      <div>
        <df-btn v-if="!isLogin" type="default" @click="login">Login</df-btn>
        <a-avatar
          v-else
          :src="getOssProcessedImage(userInfoStore.myInfo.headpic.split('?')[0], 'm_fill,w_100,h_100', 'webp')"
          :size="40"
          :style="{ cursor: 'pointer' }"
          :alt="`${userInfoStore.myInfo.name}-DesignFind`"
        />
      </div>
      <template #overlay>
        <div class="user-menu" @click="onAvatarPopoverClick">
          <div class="cursor-pointer p-3" @click="onClickCredits">
            <div class="flex h-16 items-center justify-between rounded-[8px] bg-[#FF7C23] bg-opacity-10 px-3">
              <div class="flex items-center">
                <img :src="CREDIT_ICON" alt="" class="h-6 w-6 object-cover" />
                <div class="ml-1">
                  <span class="text-level-3 font-bold text-[#FF7C23]">{{ creditService.count }}</span>
                  <span class="ml-1 text-level-8 text-text-primary">Credits</span>
                </div>
              </div>
              <df-btn type="special" size="mini" class="!h-6 !text-[12px]" @click.stop="onPurchaseCredit">Buy</df-btn>
            </div>
          </div>
          <NuxtLink to="/rewards">
            <icon-font type="icon-coin-line" />
            {{ userInfoStore.shopifyUserInfo.points || 0 }}
          </NuxtLink>
          <NuxtLink to="/user-center?active=projects">
            <icon-font type="icon-planet-line" />
            My Space
          </NuxtLink>
          <NuxtLink to="/creator/center/dashboard" @click="sensorCreatorCenterEntryClick">
            <icon-font type="icon-a-CreatorCenter-line" />
            Creator Center
          </NuxtLink>
          <a v-if="showSettings" @click="handleSetAccount">
            <icon-font type="icon-settings-line" />
            Settings
          </a>
          <div class="mx-auto my-2 h-[2px] w-[248px] bg-background-3"></div>
          <a @click="logout">
            <icon-font type="icon-logout-box-line" />
            Logout
          </a>
        </div>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup lang="ts">
import CREDIT_ICON from '@/assets/img/credit/credit-icon.png';
import { useSensors } from '~/composables';
import { useCredit } from '~/stores/credit';
import { useUserInfo } from '~/stores/user';
import { isAppWebkit, isElectronXcs } from '~/utils/system';

import { userGuide } from '~/stores/userGuide';
const userGuideInfo = userGuide();
const route = useRoute();

const { isLogin, broadcastLogout } = useLoginStatus();

const creditService = useCredit();

const { $client } = useNuxtApp();
const login = () => {
  $client.openModal(() => {
    location.reload();
  });
};
const logout = async () => {
  try {
    broadcastLogout();
    await $client.logout();
  } finally {
    userInfoStore.resetUserInfo();

    const { sensors }: any = useSensors();
    sensors.logout();
    // 如果在个人中心退出登录自动跳转首页
    if (route.path === '/user-center') {
      window.location.href = '/';
    }
    window.location.reload();
  }
};

const userInfoStore = useUserInfo();

const handleSetAccount = () => {
  const isLogin = !!getUToken();
  if (!isLogin) {
    login();
    return;
  }
  const url = $client.getUrl('profile/settings');
  createAlink(url, '_blank');
};

// 捕获点击事件，隐藏popover
const isAvatarPopoverVisible = ref(false);
function onAvatarPopoverClick() {
  isAvatarPopoverVisible.value = false;
}
const handleVisibleChange = (visible: boolean) => {
  if (visible) {
    userGuideInfo.hide(); // 隐藏新手引导
  }
};
const { sensorCreatorCenterEntryClick, sensorsUserCreditClick } = useSensors();

function onClickCredits() {
  sensorsUserCreditClick({
    element_name: 'usercard',
  });

  navigateTo({
    path: '/user-center',
    query: { active: 'credits', source: 'usercard' },
  });
}

const onPurchaseCredit = () => {
  sensorsUserCreditClick({
    element_name: 'Buy',
  });

  creditService.openPurchaseModal({
    trace: {
      source: 'usercard',
    },
  });
};

const userInfo = ref();
const { sensors }: any = useSensors();
// 处理设置神策用户属性
const handleSetUserSensors = () => {
  // 登录成功设置调用神策登录接口，将用户id与神策id关联。上线后替换xtool_community_id
  sensors.loginWithKey(import.meta.env.VITE_SENSORS_ID_KEY, userInfo.value.uid);

  // 设置用户属性，存在则覆盖
  sensors.setProfile({
    name: userInfo.value.userName,
    registered_email: userInfo.value.email,
    xtool_community_id: userInfo.value.uid,
    registered_time: userInfo.value.createTime,
  });
};

const showSettings = ref(true);
onMounted(() => {
  nextTick(() => {
    showSettings.value = !(isElectronXcs() || isAppWebkit()); // 在电脑xcs、手机/iPad中不显示设置按钮
  });
});

onMounted(async () => {
  if ($client.token) {
    userInfo.value = await $client.userInfo();
    handleSetUserSensors();
  }
});
</script>

<style scoped lang="less">
.user-menu {
  display: flex;
  flex-direction: column;
  width: 276px;
  padding: 2px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.06),
    0 1px 20px 0 rgba(0, 0, 0, 0.1);
  //overflow: hidden;

  a {
    display: inline-flex;
    align-items: center;
    padding: 7px 12px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #212121;

    &:hover {
      color: #1a1a1a;
      background: #f5f7fb;
    }

    &:first-child:hover {
      border-radius: 10px 10px 0 0;
    }

    &:last-child:hover {
      border-radius: 0 0 10px 10px;
    }

    .anticon {
      margin-right: 12px;
      font-size: 20px;
    }
  }
}
</style>
