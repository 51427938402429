import * as fs from 'fs';
import * as path from 'path';

// 注意修改映射文件和目标文件夹
const iconMapping: Record<string, string> = {
  'icon-Time2222': 'icon-Time',
  'icon-a-Union1222222': 'icon-a-Union1',
};

function replaceIconFontTypeInVueFiles(directory: string, mapping: Record<string, string>): void {
  fs.readdir(directory, (err: NodeJS.ErrnoException | null, files: string[]) => {
    if (err) {
      console.error(`Error reading directory: ${err}`);
      return;
    }

    console.log(`Found ${files.length} files in directory ${directory}`);

    files.forEach((file: string) => {
      const filePath: string = path.join(directory, file);
      const fileStat = fs.statSync(filePath);
      if (fileStat.isDirectory()) {
        // 如果是文件夹，则递归调用
        replaceIconFontTypeInVueFiles(filePath, mapping);
      } else if (path.extname(file) === '.vue') {
        console.log(`Processing file ${filePath}`);
        fs.readFile(filePath, 'utf8', (err: NodeJS.ErrnoException | null, data: string) => {
          if (err) {
            console.error(`Error reading file ${filePath}: ${err}`);
            return;
          }
          console.log(`Read data from ${filePath}`);
          let updatedData: string = data;
          for (const [oldType, newType] of Object.entries(mapping)) {
            const regex = new RegExp(`<IconFont type="${oldType}"`, 'g');
            updatedData = updatedData.replace(regex, `<IconFont type="${newType}"`);
          }
          fs.writeFile(filePath, updatedData, 'utf8', (err: NodeJS.ErrnoException | null) => {
            if (err) {
              console.error(`Error writing file ${filePath}: ${err}`);
            } else {
              console.log(`Updated ${filePath}`);
            }
          });
        });
      }
    });
  });
}

async function main() {
  const projectDirectory = './';
  try {
    await replaceIconFontTypeInVueFiles(projectDirectory, iconMapping);
    console.log('替换完毕！');
  } catch (error) {
    console.error('Error occurred while replacing icon font types:', error);
  }
}

main();
