<template>
  <Exposure block-name="For You">
    <div v-if="!isMounted" class="mt-4 text-center">
      <a-spin :tip="'For You is loading...'" />
    </div>

    <div v-if="isMounted" class="for-you-column mt-10">
      <div
        class="m-auto px-10 pb-10"
        :style="{
          maxWidth: 'var(--maxBoxWidth)',
        }"
      >
        <div class="flex justify-between px-2">
          <span class="title text-level-1 font-bold">For You</span>
          <div class="flex gap-2">
            <ClientOnly>
              <df-btn size="small" class="preferences" @click="handleClickShowPreference">
                <icon-font type="icon-Preferences1" class="text-[16px]" />
                Preferences
              </df-btn>
            </ClientOnly>
          </div>
        </div>
        <div
          v-infinite-scroll="{ infiniteLoad, keepMinHeight: true }"
          :infinite-scroll-disabled="status.finished || status.loading"
          class="relative mt-4 flex w-full flex-wrap content-start pb-10"
        >
          <template v-if="items.length > 0 || status.loading">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="card-item h-fit flex-shrink-0"
              :style="{
                width: 'var(--colBoxWidth2)',
              }"
            >
              <div class="group/block-card-wrap relative w-full p-2">
                <div
                  v-if="isShowRecommendStrategy && item.recommend?.strategy"
                  :class="[
                    `absolute left-[24px] top-[24px] z-[20]`,
                    `h-6 rounded-[30px] px-3 pt-1`,
                    `bg-text-primary text-xs font-semibold text-[#FFF]`,
                    `group-hover/block-card-wrap:hidden`,
                  ]"
                >
                  {{ item.recommend?.strategy }}
                </div>
                <BlockCardItem
                  class="h-full w-full"
                  :block-id="undefined"
                  :block-title="'For You'"
                  :making-item="item"
                  :search-opts="{
                    algorithmVersion: item.recommend?.version,
                    algorithmType: item.recommend?.strategy,
                  }"
                  :action-opts="{ showFeedback: true }"
                  :is-show-user-card="true"
                />
              </div>
            </div>
          </template>
          <div v-else :class="[`absolute top-1/2 w-full -translate-y-1/2`, `flex flex-col items-center`]">
            <img :src="NO_DATA" alt="No Data" class="h-[120px] w-[120px]" />
            <div class="text-center text-level-4 text-[#999]">No Data</div>
          </div>
          <ClientOnly>
            <div v-if="items.length > 0 || status.loading" class="flex w-full justify-center">
              <a-spin v-if="!status.finished || status.loading" class="mt-10" :spinning="true" />
              <a-divider v-else dashed style="color: #999">This is the bottom line</a-divider>
            </div>
          </ClientOnly>
        </div>
      </div>
    </div>
  </Exposure>
</template>

<script setup lang="ts">
import NO_DATA from '@/assets/img/df-ip/no_data.png';
import { storeToRefs } from 'pinia';
import { useForYou } from '~/stores/for-you';
import { usePreference } from '~/stores/preference';

// 推荐算法调试用的
const isShowRecommendStrategy = import.meta.env.VITE_PASSPORT_ENV === 'dev';

const forYouService = useForYou();
const { init, infiniteLoad, updateMakingList } = forYouService;
const { items, status } = storeToRefs(forYouService);
const preference = usePreference();

const handleClickShowPreference = () => {
  preference.openPreferenceModal();
};

// 激活时重新获取数据
onActivated(() => {
  updateMakingList();
});

const isMounted = ref(false);
onMounted(async () => {
  await init(true);
  isMounted.value = true;
});

const locationHref = ref();
onMounted(() => {
  locationHref.value = window?.location?.pathname;
});
</script>

<style lang="less" scoped></style>
