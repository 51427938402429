import { storage } from './storage';

export const clone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

// 是否点击过AI制作tab
export const checkHasClickAImakeTab = (fnNot?: () => void, fnIs?: () => void) => {
  const flag = storage.get('hasClickAImakeTab') as number;

  if (flag === 1) {
    fnIs && fnIs();
    return;
  }

  fnNot && fnNot();
};

// 一个转首字母大写的函数
export const firstUpperCase = (str: string) => {
  const res = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return res;
};

// 从 [0, a] 范围内随机选择 b 个不重复的自然数
export const getRandomNumberFromRange = (a: number, b: number) => {
  if (b > a + 1) {
    throw new Error('b 不能大于范围 [0, a] 的数字个数 (a + 1)');
  }

  const result = new Set();

  // 循环直到结果集的大小达到 b
  while (result.size < b) {
    const randomNum = Math.floor(Math.random() * (a + 1)); // 生成 [0, a] 的随机整数
    result.add(randomNum); // 使用 Set 自动去重
  }

  return Array.from(result); // 将 Set 转换为数组返回
};

// AIproject做同款
export const getAimakeGenerateSamePath = (data: any) => {
  const { scene, style } = data;

  if (!scene && !style) {
    throw new Error("params 'scene' or 'style' can't be empty");
  }

  const cache = {
    curSelectScene: data.scene, // 生成方式
    curSelectStyle: data.style, // 生成样式
    curImageRatio: data.ratio, // 图片比例
    curPromptInput: data.prompt, // 提示语
    curLogoLayoutType: data.logoStyle, // logo布局方式
    curReliefType: data.reliefType, // 浮雕凹凸版
    curGroupPhotoStyle: data.sketchType, // 素描类型
  };

  storage.setGenerateCache(cache);

  return {
    path: '/aimake/generate',
    query: {
      scene,
      style,
      source: 'project',
    },
  };
};
