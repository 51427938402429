<template>
  <a-form-item label="AImake Info">
    <div class="bg-white rounded-lg border border-solid border-[#191919]/10 px-4 py-3 text-level-7">
      <div class="inline-block rounded bg-[#ffecee] px-2 py-1 text-level-4 font-semibold text-status-warning">
        {{ aiExtra.style ? `Style : ${style}` : style }}
      </div>
      <div v-if="aiExtra.prompt" class="mt-2 text-level-2 text-uni-13">
        <span>[Prompt] {{ aiExtra.prompt }}</span>
      </div>
    </div>
  </a-form-item>
</template>
<script lang="ts" setup>
import { generateMethodTextMap } from '@/config/aimake/common';
type PropsType = {
  aiExtra: any;
};
const props = defineProps<PropsType>();
const style = computed(() => {
  return props.aiExtra.style || generateMethodTextMap[props.aiExtra.scene];
});
</script>
