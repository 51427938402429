import { getPreferenceOptions, getPreferenceTags, uploadPreferenceTags } from '@/api/preference';
import { defineStore } from 'pinia';

export const usePreference = defineStore('preference', () => {
  const open = ref(false);
  const inited = ref(false);
  // 是否第一次设置
  const isFirstSet = ref(false);
  const groups = ref();
  const tagMap = new Map<number, any>();
  const selectedIds = shallowRef(new Set<number>());
  const selected = computed(() => Array.from(selectedIds.value).map((id) => tagMap.get(id)));

  const { cid } = useDeviceFlags();

  const init = async () => {
    // 获取可选喜好标签配置 + 获取该设备\用户的历史偏好设置
    await Promise.all([getUserPreferenceOptions(), getUserPreferenceSettings()]);

    inited.value = true;
  };
  const firstSet = () => {
    // 如果没有可选标签，不展示
    if (!groups?.value?.length) {
      return;
    }

    // 如果已经设置过，默认不展示
    try {
      const preference = localStorage.getItem('preference');
      if (preference) {
        const opt = JSON.parse(preference);
        if (opt.isSet) {
          return;
        }
      }
    } catch (err: any) {
      localStorage.setItem('preference', JSON.stringify({}));
    }

    // 如果已经设置过，默认不展示
    try {
      const preference = localStorage.getItem('preference');
      if (preference) {
        const opt = JSON.parse(preference);
        if (opt.isSet) {
          return;
        }
      }
    } catch (err: any) {
      localStorage.setItem('preference', JSON.stringify({}));
    }

    // 如果未设置过, 默认展示并设置展示缓存
    if (isFirstSet.value) {
      localStorage.removeItem('preference');
      saveToLocal();
      openPreferenceModal();
      return;
    }
  };
  const saveToLocal = () => {
    const opt = { isSet: true };
    localStorage.setItem('preference', JSON.stringify(opt));
  };
  const update = async (ids: number[]) => {
    const tags = [...ids];

    // 上传用户偏好
    const res = await uploadPreferenceTags.fetch({ cid: cid.value, tags });

    if (res?.code !== 0) {
      message.error('Failed to save preference settings, please try again later');
      return;
    }

    selectedIds.value = new Set(ids);
  };
  const getUserPreferenceOptions = async () => {
    const config = {
      cid: cid.value,
    };

    const res = await getPreferenceOptions.fetch();
    const data = res?.data ?? [];

    if (data && data.length) {
      groups.value = data.map((item: any) => {
        return {
          name: item.typeName,
          style: item.tagStyle,
          items: item.tagGroups.map((tag: any) => {
            tagMap.set(tag.id, tag);
            return {
              id: tag.id,
              name: tag.name,
              cover: tag.cover,
            };
          }),
        };
      });
    }

    return config;
  };
  // 获取用户偏好设置, 登录态与未登录态标签合并，请查看 getPreferenceTags 注释
  const getUserPreferenceSettings = async () => {
    const res = await getPreferenceTags.fetch({
      cid: cid.value,
    });

    const data = res?.data;

    const tags = data?.tags;
    if (tags && tags.length) {
      tags.forEach((tag: any) => {
        selectedIds.value.add(tag);
      });
    }

    isFirstSet.value = data?.isFirst;
  };

  const openPreferenceModal = () => {
    open.value = true;
  };
  const closePreferenceModal = () => {
    open.value = false;
  };

  return {
    inited,
    open,
    isFirstSet,
    groups,
    selected,
    selectedIds,
    init,
    firstSet,
    update,
    openPreferenceModal,
    closePreferenceModal,
  };
});
