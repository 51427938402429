import { getBlockExtDataListApi } from '@/api/explore';
import { getPreferenceMakings } from '@/api/preference';
import { merge } from 'lodash-es';
import { defineStore } from 'pinia';
import { usePreference } from './preference';

interface MakingExtMap {
  [key: number]: {
    id: number; // 作品id
    extra: {
      blockCover: string; // 运营配置的封面图
    };
    origPrice: number; // 原价
    sellTag: string; // 售卖标签
    hasDiscount: boolean; // 是否有折扣活动
    discount: {
      discountPercent: number; // 折扣比例
      disPrice: number; // 折扣价格
    };
  };
}

export const useForYou = defineStore('for-you', () => {
  const status = reactive({
    loading: false,
    finished: false,
  });
  const inited = ref(false);
  const refreshing = ref(false);
  const items = ref<any[]>([]);
  const makingIds = ref(new Set<number>());
  const paging: any = {
    current: 0,
    pageSize: 20,
  };
  const load = async (current: number) => {
    if (status.loading) {
      return [];
    }
    try {
      const { cid } = useDeviceFlags();

      paging.current = current;
      const res = await getPreferenceMakings.fetch({
        ...paging,
        cid: cid.value,
      });

      const data = res?.data;

      if (res.code !== 0) {
        throw new Error('unknown system error');
      }

      const list = data?.list ?? [];
      const pagination = data?.pagination ?? { current: 0, total: 0, pageSize: 20 };
      Object.assign(paging, pagination);
      status.finished = paging.current >= Math.ceil(paging.total / paging.pageSize);

      const currentList = handleDataDeduplication(unref(items), list);
      updateBlockMakingIds(currentList);

      return currentList;
    } catch (error) {
      status.finished = true;
      throw error;
    } finally {
      status.loading = false;
    }
  };
  const refresh = async () => {
    if (refreshing.value) {
      return;
    }
    try {
      refreshing.value = true;
      makingIds.value.clear();
      Object.assign(status, {
        loading: false,
        finished: false,
      });
      Object.assign(paging, {
        current: 1,
        pageSize: 20,
      });
      items.value = await load(paging.current);
    } finally {
      refreshing.value = false;
    }
  };
  const infiniteLoad = async () => {
    try {
      paging.current++;
      const arr = await load(paging.current);
      items.value.push(...arr);
    } catch (error) {
      console.warn(error);
    }
  };

  // 收集making id
  const updateBlockMakingIds = (arr: any[]) => {
    const newIds = arr.filter((item) => !makingIds.value.has(item.id));
    newIds.forEach((item) => makingIds.value.add(item.id));
  };
  const makingExtMapData = ref<MakingExtMap>({});
  const getLatestMakings = async (ids: Set<number>) => {
    try {
      const res = await getBlockExtDataListApi.fetch({ ids: [...ids] });
      makingExtMapData.value = res?.data ?? {};
    } catch (error) {
      console.error(error);
    }
  };
  // 更新案例最新数据
  const updateMakingList = async () => {
    try {
      await getLatestMakings(makingIds.value);
      items.value = remixMakingData(items.value);
    } catch (err) {
      console.error(err);
    }
  };
  // 合并案例数据, 去掉删除的数据
  const remixMakingData = (list: Array<any>) => {
    return list
      .map((item: any) => {
        const makingExt = makingExtMapData.value?.[item.id] || {};
        return merge(item, makingExt);
      })
      .filter((item) => !Boolean(item.isDeleted));
  };

  const init = async (forceRefresh = false) => {
    if (inited.value && !forceRefresh) {
      return;
    }
    items.value = [];
    await refresh();
    inited.value = true;
  };

  const preference = usePreference();
  watch(
    () => preference.selectedIds,
    async (newVal, oldValue) => {
      if (newVal.size !== oldValue.size || Array.from(newVal).some((item) => !oldValue.has(item))) {
        await refresh();
      }
    },
    { deep: true },
  );

  return {
    items,
    makingIds,
    status,
    inited,
    init,
    infiniteLoad,
    updateMakingList,
  };
});
