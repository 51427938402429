<template>
  <div v-if="!isPreview && (showEdit || showDel)" class="bar fixed bottom-0 left-0 z-50 w-full gap-4 bg-[#fff] px-5 py-3 pc:hidden not-pc:flex">
    <div v-if="showEdit" class="flex items-center gap-1" @click="onEdit">
      <icon-font class="text-[16px]" type="icon-edit-box" />
      <span class="text-level-4 text-text-primary">Edit</span>
    </div>
    <div v-if="showDel" class="flex items-center gap-1" @click="handleDeleteClick">
      <icon-font class="text-[16px]" type="icon-Delete" />
      <span class="text-level-4 text-text-primary">Delete</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useActions } from '@/components/project-details/common/action';
import type { AuditStatus } from '@/components/project-details/types';

type PropsType = {
  auditStatus?: AuditStatus;
};
const props = withDefaults(defineProps<PropsType>(), {
  auditStatus: undefined,
});

const { editable, showEdit, showDel, handleToggleAction, handleDeleteClick, isPreview } = useActions({
  auditStatus: props.auditStatus,
});

const onEdit = () => {
  if (!editable.value) {
    message.info('Your content has a version under review and cannot be edited.');
    return;
  }
  handleToggleAction();
};
</script>

<style lang="less" scoped>
.bar {
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
}
</style>
