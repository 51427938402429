import { getUserCurCredits } from '@/api/aimake';
import { styleList } from '@/config/aimake/style.js';
import { storage } from '@/utils/aimake/storage';
import { defineStore } from 'pinia';

export const useArtimindAiStore = defineStore('artimindAi', {
  state: () => {
    return {
      cacheOnOff: false,
      sucCallback: <any>null,
      curEditTaskData: <any>{
        index: 0,
        data: {},
        src: '',
        isVector: false,
      },
      isShowHistoryDestroyModal: false,
      isFirstClickDestroy: true,
      isShowDestroyTour: false,
      homeNavActiveKey: 'All',
      credits: <number>0,
      loopList: <any[]>[],
      curCreateList: <any[]>[], // 本次创建的id列表
      likeListMap: <any>{}, // 点赞列表映射
      homeStyleNavTop: <number>0,
    };
  },
  getters: {
    styleListMap() {
      const res = styleList.reduce((acc: any, cur: any) => {
        const { type } = cur;
        if (acc[type]) {
          acc[type].push(cur);
        } else {
          acc[type] = [cur];
        }
        return acc;
      }, {});

      return res;
    },
  },
  actions: {
    toggleCacheOnOff() {
      this.cacheOnOff = !this.cacheOnOff;
    },

    setIsFirstClickDestroy() {
      storage.set('isClickedDestroy', 1);
      this.isFirstClickDestroy = false;
    },

    setIsShowDestroyTour(val: boolean) {
      this.isShowDestroyTour = val;
    },

    init() {
      const flag = storage.get('isClickedDestroy') as number;
      this.isFirstClickDestroy = !flag;
    },

    setSucCallback(value: any) {
      this.sucCallback = value;
    },

    setCurEditTaskData(value: any) {
      this.curEditTaskData = value;
    },

    setIsShowHistoryDestroyModal(value: boolean) {
      this.isShowHistoryDestroyModal = value;
    },

    setHomeStyleNavActiveKey(value: any) {
      this.homeNavActiveKey = value;
    },

    setHomeStyleNavTop(value: any) {
      this.homeStyleNavTop = value;
    },

    async getCredits() {
      const res = await getUserCurCredits.fetch();
      this.credits = res?.data?.quota || 0;
    },
    // 向轮训id列表中添加id
    addLoopList(value: string) {
      this.loopList.push(value);
    },

    // 从轮训id列表中删除id
    removeLoopList(value: string) {
      // 过滤掉当前id, 返回新的数组
      this.loopList = this.loopList.filter((item) => item !== value);
    },

    updateLoopList(value: string[]) {
      this.loopList = value;
    },

    // 重置轮训id列表
    resetLoopList() {
      this.loopList = [];
    },

    // 向当前创建id列表中添加id
    addCurCreateList(value: string) {
      this.curCreateList.push(value);
    },

    // 添加点赞记录映射
    addLikeListMap(id: string, result: string) {
      this.likeListMap[id] = result;
    },
  },
});
