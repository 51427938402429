<template>
  <div>
    <div>
      <draggable class="file-list" :list="list" :animation="200" item-key="uid" :disabled="!isDrag">
        <template #item="{ element }">
          <div class="mb-2 flex h-[88px] items-center gap-3 rounded-lg bg-background-2 pl-2 pr-4">
            <icon-font type="icon-Files" class="text-[60px] mobile:text-[40px]" />
            <div class="flex-1">
              <div class="flex justify-between">
                <div>
                  <div class="mb-1 line-clamp-2 break-all text-level-2 font-semibold">{{ element.name }}</div>
                  <div v-if="element.fileSize !== 0" class="text-text-tertiary">{{ getFormatFileSize(element.fileSize) }}</div>
                </div>
                <div class="flex items-center">
                  <df-btn v-if="getFileType(element.name) === 'xcs' && element.url" icon="icon-eye-line" @click="prevXcsFile(element)" />
                  <df-btn icon="icon-Delete" class="icon-delete" @click="deleteFile(element)" />
                </div>
              </div>
              <div v-if="(element.percent && element.percent < 100) || !element.url" class="mt-[-8px]">
                <a-progress :percent="element.percent" stroke-color="#FF0035" :show-info="false" :stroke-width="5" class="mb-0" />
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div class="w-full">
      <a-upload
        :capture="undefined"
        name="file"
        multiple
        :max-count="fileLimit.maxCount"
        :support-server-render="true"
        :accept="fileLimit.format"
        :show-upload-list="false"
        :before-upload="handleBeforeUpload"
        @change="onFileChange"
      >
        <slot v-if="list.length == 0"></slot>
        <div v-else class="block">
          <div
            :class="[
              'w-full cursor-pointer rounded-lg border-[1px] border-dashed border-[#1a1a1a]/[0.18] bg-background-2 px-10 py-5 text-center hover:border-[#1a1a1a]/[0.4]',
            ]"
          >
            <div class="flex items-center justify-center gap-3">
              <icon-font type="icon-a-ShareCreationh5" class="text-[20px]" />
              <span class="text-level-2 text-text-secondary">Upload files</span>
            </div>
          </div>
        </div>
      </a-upload>
    </div>
    <a-modal v-if="showPrev" :open="showPrev" :width="1040" :footer="null" :destroy-on-close="true" @cancel="closePrev">
      <canvas-prev-list :visible="showPrev" :item="currentItem" />
    </a-modal>
  </div>
</template>
<script setup lang="ts">
import { handleSensorEditClick } from '@/components/share/utils';
import { readFileToJson } from '@/utils/canvas';
import { processDisplayData } from '@/utils/canvas/setting';
import { v4 } from 'uuid';
import draggable from 'vuedraggable';
/** @name 文件上传列表
  - 组件功能：
    - 1.上传文件
    - 2.展示文件列表，显示上传状态，进度条，可删除。
  - props：
    - fileLimit - 文件上传限制
    - list - 文件列表
*/
type FileListProps = {
  fileLimit: {
    format: string; // 支持的文件类型
    maxFileSizeText: string; // 最大文件大小文本提示
    maxFileSize: number; // 最大文件大小,单位是字节，e.g.: 15 * 1024 * 1024 = 15M
    maxCount: number; // 最大文件数
    errorTips?: string; // 错误提示
    errSizeTips?: string; // 上传文件大小错误时的提示信息
    errTypeTips?: string; // 上传文件类型错误时的提示信息
  };
  isDrag?: boolean; // 是否支持拖拽排序
};
const props = defineProps<FileListProps>();

const { fileLimit } = toRefs(props);

const list = defineModel('list', { type: Array, default: [] as any });

const { handleUploadFile, handleFileRemove } = useUpload();
const currentFileList = ref([] as any[]); // 本次实际上传的文件列表
const handleBeforeUpload = (file: any, curFileList: any) => {
  const maxCount = fileLimit.value.maxCount - list.value.length;
  currentFileList.value = curFileList.slice(0, maxCount);
  if (curFileList.length > maxCount) {
    const tips = fileLimit.value.errorTips ? fileLimit.value.errorTips : 'you can only add to ' + fileLimit.value.maxCount + ' files .';
    message.info({
      content: tips,
      key: 'upload-file',
    });
  }
  return false;
};
const addCurrentFileList = (files: any) => {
  currentFileList.value = [...currentFileList.value, ...files];
};

const onFileChange = async (info: any, format?: string) => {
  const { file } = info;
  // 过滤掉不符合条件的文件
  const fileType = '.' + getFileType(file.name);
  const accept = format ?? fileLimit.value.format;
  const regType = !accept ? true : accept.split(',').includes(fileType?.toLocaleLowerCase());
  let extra = {} as any;
  if (isXcsFile(file.name)) {
    try {
      const json = await readFileToJson(file);
      const { cover } = json;
      extra = handleXcsData(json);
      uploadCover(cover, file);
    } catch (error) {
      console.error('error :>> ', error);
      message.warn({
        content: 'File not valid, please re-upload',
        key: 'upload-file',
      });
      return false;
    }
  }
  const regSize = file.size < fileLimit.value.maxFileSize;
  if (!file.size) {
    message.warn({
      content: 'Please upload a file larger than 0 KB',
      key: 'upload-file',
    });
    return false;
  }
  if (!regType) {
    message.warn({
      content: fileLimit.value.errTypeTips || 'File format does not meet upload restrictions',
      key: 'upload-file',
    });
    return false;
  }
  if (!regSize) {
    message.warn({
      content: fileLimit.value.errSizeTips || 'File size exceeds upload limit',
      key: 'upload-file',
    });
    return false;
  }

  if (currentFileList.value.findIndex((ele: any) => ele.uid === file.uid) === -1) {
    return;
  }
  !file.uid && (file.uid = v4());
  list.value.push({
    ...file,
    name: file.name.split(/[\t\r\f\n\s]*/g).join(''), // 去掉空格
    extName: file.name.split('.').pop(),
    uid: file.uid,
    fileSize: file.size,
    percent: 0,
    lastModified: new Date().getTime(),
    extra: {
      cover: '',
      ...extra,
    },
  });
  let percentSave = 0;
  let name = encodeURIComponent(file.name);
  const contentDisposition = file.name ? `attachment;filename="${name}"` : 'attachment';
  handleUploadFile({
    file,
    contentDisposition,
    private: true,
    onProgress: (percent: number) => {
      if (percentSave !== percent) {
        percentSave = percent;
        const listItem = list.value.filter((ele: any) => ele.uid === file.uid)[0];
        listItem.percent = percent;
        Object.assign(file, { percent });
      }
    },
    onFinish: (url: any) => {
      const listItem = list.value.filter((ele: any) => ele.uid === file.uid)[0];
      listItem.url = url;
      console.log(' listItem.url : ', listItem.url);
    },
    onError: (e: any) => {
      // 手动取消上传也会触发error
      if (list.value.findIndex((ele: any) => ele.uid === file.uid) === -1) {
        return;
      }
      console.log('186-error :>> ', e);
      processErr(file);
    },
  });
};
defineExpose({
  addCurrentFileList,
  onFileChange,
});
// 上传失败删除文件
const processErr = (file: any) => {
  message.warn({
    content: 'File upload failed, please re-upload the file',
    key: 'upload-file',
  });
  list.value.splice(
    list.value.findIndex((ele: any) => ele.uid === file.uid),
    1,
  );
};
const uploadCover = (cover: any, file: any) => {
  if (cover) {
    // 上传封面
    const coverFile = base64ToFile(cover);
    let name = encodeURIComponent(file.name);
    const contentDisposition = file.name ? `attachment;filename="${name}"` : 'attachment';
    handleUploadFile({
      file: coverFile,
      contentDisposition,
      onProgress: (percent: number) => {
        Object.assign(file, { percent });
      },
      onFinish: (url: any) => {
        file.cover = url;
        const listItem = list.value.filter((ele: any) => ele.uid === file.uid)[0];
        listItem ? (listItem.extra.cover = url) : '';
      },
      onError: (err: any) => {
        console.log('err', err);
      },
    });
  }
};
const deleteFile = (file: any) => {
  Modal.confirm({
    content: 'Are you sure you want to delete?',
    onOk: () => {
      handleFileRemove(file);
      list.value = list.value.filter((ele: any) => ele.uid !== file.uid);
    },
  });
  console.log('list.value :>> ', file, list.value);
};
const handleXcsData = (json: any) => {
  let extra = {} as any;
  const allDisplay = processDisplayData(json);
  const materialList = json.device && json.device.materialList ? json.device.materialList.map((ele: any) => ele.name.en) : [];
  extra = {
    extId: json.extId,
    allDisplay,
    power: json.device ? json.device.power : 0,
    canvasIds:
      json.canvas &&
      json.canvas.map((ele: any) => {
        return { id: ele.id, title: ele.title };
      }),
    materialList: materialList,
  };
  return extra;
};
const showPrev = ref(false);
const closePrev = () => {
  showPrev.value = false;
};
const currentItem = ref();
const prevXcsFile = (item: any) => {
  showPrev.value = true;
  currentItem.value = item;
  handleSensorEditClick({ name: 'Project File Preview' });
};
</script>
<style lang="less" scoped>
:deep(.ant-upload) {
  width: 100%;
  display: block;
}
</style>
