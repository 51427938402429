<template>
  <div class="flex h-full flex-col overflow-y-hidden pt-4" @click.stop>
    <div class="pb-4 text-center text-level-7 text-text-tertiary">Try dragging images or videos into the upload area</div>
    <template v-if="empty">
      <div class="flex flex-col items-center pt-16">
        <img :src="NoDataImg" :width="130" :height="130" />
        <df-btn class="mt-5" type="highlight" @click="handleTryAimake">Try AImake</df-btn>
      </div>
    </template>
    <template v-else>
      <div class="flex-1">
        <draggable
          class="flex flex-wrap justify-start gap-2 overflow-y-auto"
          :list="aiMakeData.list"
          :group="dragGroup"
          :animation="200"
          item-key="url"
          @start="onDragStart"
          @end="onDragEnd"
          @click.stop
        >
          <template #item="{ element }">
            <div @click.stop>
              <a-image
                class="rounded-lg"
                :src="getOssProcessedImage(element.url, 'm_fill,w_96,h_96')"
                :width="96"
                :height="96"
                :preview="{ src: element.url }"
              >
                <template #previewMask>
                  <div class="flex flex-col items-center text-xs">
                    <icon-font type="icon-eye-line" />
                    <div>Preview</div>
                  </div>
                </template>
              </a-image>
            </div>
          </template>
        </draggable>
      </div>

      <a-pagination
        v-model:current="aiMakeData.pageNumber"
        class="list-paging m-auto mt-6"
        :total="aiMakeData.totalCount"
        :page-size="aiMakeData.pageSize"
        show-less-items
        :show-size-changer="false"
        @change="onPageChange"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { getUserImgList } from '@/api/aimake';
import NoDataImg from '@/assets/img/df-ip/no_data.png';
import { v4 } from 'uuid';
import draggable from 'vuedraggable';
import { useLibrary } from '../useLibrary';
defineProps<{
  dragGroup: any;
}>();
const router = useRouter();
const { onDragStart, onDragEnd } = useLibrary();
const aiMakeData = reactive({
  list: [] as any[],
  pageNumber: 1,
  pageSize: 21,
  totalCount: 0,
});

const empty = computed(() => aiMakeData.totalCount === 0);

const handleTryAimake = () => {
  router.push('/aimake/home?source=ad_01');
};

const getAiMakeList = async () => {
  try {
    const { pageNumber: page_number, pageSize: page_size } = aiMakeData;
    const { code, data } = await getUserImgList.fetch({ page_number, page_size });
    if (code === 0) {
      const list = data.list.map((item: any) => {
        return {
          ...item,
          url: item.image_url,
          type: 'image',
          uid: v4(),
        };
      });
      Object.assign(aiMakeData, {
        ...data,
        list,
      });
    }
  } catch (error) {
    console.error('获取AI生成图片列表失败:', error);
  }
};
const onPageChange = (page: number) => {
  aiMakeData.pageNumber = page;
  getAiMakeList();
};
onMounted(() => {
  nextTick(() => getAiMakeList());
});
</script>
<style lang="less" scoped>
:deep(.list-paging) {
  .ant-pagination-item {
    border-radius: 50%;
    background-color: #ffffff;
    width: 36px;
    height: 36px;
    line-height: 36px;
  }
  .ant-pagination-item-active {
    background-color: #1a1a1a;
    border: none;
    color: #ffffff;
    a {
      color: #ffffff;
    }
  }
}
</style>
