import { AbortController } from '@azure/abort-controller';
import { BlockBlobClient } from '@azure/storage-blob';
import OSS from 'ali-oss';
import { getPublicStsToken, getPublicToken, getStsToken } from './api';
import { getContentType, getFileSize, isEmptyObject } from './utils';
export class Uploader {
    baseURL;
    /**
     * Creates an instance of Uploader.
     * @param {string} baseURL API URL 如：https://api.makeblock.com
     * @memberof Uploader
     */
    constructor(baseURL) {
        this.baseURL = baseURL;
    }
    /**
     * 上传文件
     *
     * @param {string} utoken 用户登录态 token
     * @param {string} configName 存储配置名称
     * @param {File} file 上传的文件
     * @param {string} toPath 上传保存的位置
     * @param {UploadOptions} [options] options
     * @memberof Uploader
     */
    async upload(utoken, configName, file, toPath, options) {
        try {
            const config = await this.getToken(this.baseURL, utoken, configName);
            await this.uploadWithConfig(config, file, toPath, options);
        }
        catch (error) {
            options &&
                options.callback &&
                options.callback.onError &&
                options.callback.onError(error);
        }
    }
    /**
     * 上传文件到公共库
     *
     * @param {string} appKey 应用 key
     * @param {File} file 上传的文件
     * @param {string} toPath 上传保存的位置
     * @param {UploadOptions} [options] options
     * @memberof Uploader
     */
    async uploadToPublic(appKey, file, toPath, options) {
        try {
            const config = await this.getPublicToken(this.baseURL, appKey);
            await this.uploadWithConfig(config, file, toPath, options);
        }
        catch (error) {
            options &&
                options.callback &&
                options.callback.onError &&
                options.callback.onError(error);
        }
    }
    // https://github.com/ali-sdk/ali-oss#multipartuploadname-file-options
    async uploadToAliyun(config, file, toPath, options) {
        try {
            const savePath = `${config.prefixDir}${toPath}`;
            const client = new OSS(config);
            // uploadOptions
            let abortCheckpoint;
            const uploadOptions = {
                progress: function (p, cpt) {
                    abortCheckpoint = cpt;
                    const percent = Math.floor(p * 100);
                    options &&
                        options.callback &&
                        options.callback.onProgress &&
                        options.callback.onProgress(percent);
                },
            };
            // abort
            function abort() {
                if (client && abortCheckpoint) {
                    client.abortMultipartUpload(abortCheckpoint.name, abortCheckpoint.uploadId);
                }
            }
            options &&
                options.callback &&
                options.callback.AbortController &&
                options.callback.AbortController(abort);
            // timeout
            if (options && options.timeout) {
                uploadOptions.timeout = options.timeout;
            }
            // content type
            if (options && options.contentType) {
                uploadOptions.mime = options.contentType;
            }
            // headers
            const headers = {};
            if (options && options.contentDisposition) {
                headers['Content-Disposition'] = options.contentDisposition;
            }
            if (options && options.contentEncoding) {
                headers['Content-Encoding'] = options.contentEncoding;
            }
            if (!isEmptyObject(headers)) {
                uploadOptions.headers = headers;
            }
            const result = await client.multipartUpload(savePath, file, uploadOptions);
            if (result.res.status === 200) {
                const url = `https://${config.host}/${savePath}`;
                options &&
                    options.callback &&
                    options.callback.onSuccess &&
                    options.callback.onSuccess(url);
            }
            else {
                options &&
                    options.callback &&
                    options.callback.onError &&
                    options.callback.onError(new Error(`upload error status ${result.res.status}`));
            }
        }
        catch (error) {
            console.log(error);
            options &&
                options.callback &&
                options.callback.onError &&
                options.callback.onError(error);
        }
    }
    // https://github.com/Azure/azure-sdk-for-js/tree/main/sdk/storage/storage-blob
    // https://devblogs.microsoft.com/azure-sdk/how-to-use-abort-signals-to-cancel-operations-in-the-azure-sdk-for-javascript-typescript/
    async uploadToAzure(config, file, toPath, options) {
        try {
            const uploadOptions = {};
            // timeout
            let controller = new AbortController();
            if (options && options.timeout) {
                const abortSignal = controller.signal;
                const timeoutSignal = AbortController.timeout(options.timeout);
                controller = new AbortController(abortSignal, timeoutSignal);
            }
            uploadOptions.abortSignal = controller.signal;
            // abort
            function abort() {
                controller.abort();
            }
            options &&
                options.callback &&
                options.callback.AbortController &&
                options.callback.AbortController(abort);
            // 处理 contentType
            let blobContentType = '';
            if (options && options.contentType) {
                blobContentType = options.contentType;
            }
            else {
                // 用户没传，则自动获取 contentType
                blobContentType = getContentType(file);
            }
            uploadOptions.blobHTTPHeaders = {
                blobContentType,
            };
            // 处理 contentDisposition
            if (options && options.contentDisposition) {
                uploadOptions.blobHTTPHeaders.blobContentDisposition =
                    options.contentDisposition;
            }
            // 处理 contentEncoding
            if (options && options.contentEncoding) {
                uploadOptions.blobHTTPHeaders.blobContentEncoding =
                    options.contentEncoding;
            }
            // 处理上传进度
            const size = getFileSize(file);
            uploadOptions.onProgress = (progress) => {
                const percent = size > 0 ? Math.floor((progress.loadedBytes / size) * 100) : 0;
                options &&
                    options.callback &&
                    options.callback.onProgress &&
                    options.callback.onProgress(percent);
            };
            const savePath = `${config.prefixDir}${toPath}`;
            const blobUrl = `https://${config.ossHost}/${config.bucket}/${savePath}?${config.stsToken}`;
            const client = new BlockBlobClient(blobUrl);
            const resp = await client.uploadData(file, uploadOptions);
            if (resp.errorCode) {
                options &&
                    options.callback &&
                    options.callback.onError &&
                    options.callback.onError(new Error(`upload error status ${resp.errorCode}`));
            }
            else {
                const url = `https://${config.host}/${config.bucket}/${savePath}`;
                options &&
                    options.callback &&
                    options.callback.onSuccess &&
                    options.callback.onSuccess(url);
            }
        }
        catch (error) {
            console.log(error);
            options &&
                options.callback &&
                options.callback.onError &&
                options.callback.onError(error);
        }
    }
    async uploadWithConfig(config, file, toPath, options) {
        switch (config.ossType) {
            case 0:
                await this.uploadToAliyun(config, file, toPath, options);
                break;
            case 2:
                await this.uploadToAzure(config, file, toPath, options);
                break;
            default:
                break;
        }
    }
    async getToken(baseURL, utoken, configName) {
        const resp = await getStsToken(baseURL, utoken, configName);
        const data = resp.data.data;
        const ossConfig = data.ossConfig;
        const uploadConfig = {
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            stsToken: data.stsToken,
            host: data.host,
            ossType: ossConfig.ossType,
            region: ossConfig.region,
            bucket: ossConfig.bucket,
            prefixDir: ossConfig.prefixDir,
            ossHost: ossConfig.ossHost,
        };
        return uploadConfig;
    }
    async getPublicToken(baseURL, appKey) {
        const rs = await getPublicToken(baseURL, appKey);
        const authToken = rs.data.data.token;
        const resp = await getPublicStsToken(baseURL, authToken);
        const data = resp.data.data;
        const ossConfig = data.ossConfig;
        const uploadConfig = {
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            stsToken: data.stsToken,
            host: data.host,
            ossType: ossConfig.ossType,
            region: ossConfig.region,
            bucket: ossConfig.bucket,
            prefixDir: ossConfig.prefixDir,
            ossHost: ossConfig.ossHost,
        };
        return uploadConfig;
    }
}
