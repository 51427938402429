import { Uploader } from '@makeblock/upload';
import { message } from 'ant-design-vue';
import { v4 } from 'uuid';
import { ref } from 'vue';
type Fn = (url: string) => void;
interface Options {
  maxFileLenth?: number; // 文件上传数量限制
  maxFilesSize?: number; // 单文件上传大小限制
  isNeedWHvalidate?: boolean; // 是否需要宽高校验
  isNeedFileLengthValidate?: boolean; // 是否需要文件个数校验
  successCb?: any;
}

export const useUpload = (options?: Options) => {
  const { sensorFileUploadError } = useSensors();
  // 初始化upload对象
  const uploader = new Uploader(import.meta.env.VITE_REQUEST_COMMON_URL);
  const uploadLoading = ref(false);
  const attachFiles = ref<any>([]);
  const isNeedWHvalidate = options?.hasOwnProperty('isNeedWHvalidate') ? options.isNeedWHvalidate : true;
  const isNeedFileLengthValidate = options?.hasOwnProperty('isNeedFileLengthValidate') ? options.isNeedFileLengthValidate : false;
  const minWidth = 400;
  const minHeight = 400;
  let abortMap: {
    [key: string]: any;
  } = {};
  let isCancel = false;

  const handleSuccess = options?.successCb;
  // 处理文件上传
  const handleUploadFile = async (fileOptions: any) => {
    isCancel = false;
    if (options?.maxFileLenth && attachFiles.value.length >= options.maxFileLenth && isNeedFileLengthValidate) {
      return message.error(`Please upload no more than ${options.maxFileLenth} files`);
    }
    let fileType = fileOptions.file?.name ? '.' + getFileType(fileOptions.file.name) : '';
    if (fileType.toLowerCase() === '.heic') {
      // 特殊处理HEIC格式，转成jpeg格式
      fileType = '.jpeg';
    }
    const to = `attachment/xtool-community/${v4()}${fileType}`; // 因为特殊字符在服务器编码问题，如à，不用原文件名，替换成uuid
    attachFiles.value.push({
      id: fileOptions.file.uid,
      name: fileOptions.file.name,
      fileSize: fileOptions.file.size,
      status: 'uploading',
    });
    const current = attachFiles.value[attachFiles.value.length - 1];
    uploadLoading.value = true;
    const uToken = useMiddleCookie('utoken').value || '';
    const file = fileOptions.file.originFileObj ? fileOptions.file.originFileObj : fileOptions.file;

    const configName = fileOptions.private ? 'xtool-community-private' : 'xtool-community';

    await uploader.upload(uToken, configName, file, to, {
      callback: {
        AbortController: (a: any) => {
          abortMap[fileOptions.file.uid] = a;
        },
        onProgress: (percent) => {
          fileOptions.file.status = 'uploading';
          fileOptions.onProgress?.(percent);
        },
        onSuccess: (url) => {
          console.log('onSuccess', url);

          Object.assign(current, {
            url,
          });
          fileOptions.file.url = url;
          fileOptions.file.status = 'done';
          fileOptions.onFinish?.(url);
          if (handleSuccess && typeof handleSuccess === 'function') {
            handleSuccess(url);
          }
          uploadLoading.value = false;
        },
        onError: (error) => {
          Object.assign(current, {
            status: 'error',
          });
          uploadLoading.value = false;
          fileOptions.onError?.();
          console.warn(error);
          sensorFileUploadError({
            error_content: error.message || error.toString(),
          });
        },
      },
      contentDisposition: fileOptions.contentDisposition,
    });
  };
  const fileWidthHeighValidate = (options: any, resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(options.file.file);
    reader.onload = function (evt: any) {
      const replaceSrc = evt.target.result;
      const imageObj: any = new Image();
      imageObj.src = replaceSrc;
      imageObj.onload = function () {
        if (imageObj.width < minWidth || imageObj.height < minHeight) {
          message.error(`Please upload a cover image of at least ${minWidth} × ${minHeight} pixels to ensure proper display`);
          reject();
        } else {
          resolve();
        }
        // 执行上传的方法，获取外网路径，上传进度等
      };
    };
  };
  // 处理文件上传前校验
  const handleBeforeFileUpload = (options: any) => {
    return new Promise<void>(function (resolve, reject) {
      const isLimitSize = options.file.file.size > options.maxFilesSize;
      const isLimitCount = attachFiles.value.length > options.maxFileLenth;
      const reg = /\.(git|jpg|jpeg|png|GIF|JPG|PNG|webp|WEBP)$/;
      const fileStream = options.file.file;
      const fileName = fileStream.name;
      if (!reg.test(fileName)) {
        message.error(`Upload an image in the supported format`);
        reject();
        return;
      }
      if (isLimitSize) {
        message.error(`Please upload file that is smaller than ${options.maxFilesSize / (1024 * 1024)}MB`);
        reject();
        return;
      }
      if (isLimitCount && isNeedFileLengthValidate) {
        message.error(`Please upload no more than ${options.maxFileLength} files`);
        reject();
        return;
      }
      // 上传文件前获取图片宽高
      if (isNeedWHvalidate) {
        fileWidthHeighValidate(options, resolve, reject);
      } else {
        resolve();
      }
    });
  };

  // 处理移除文件
  const handleFileRemove = async (file: any) => {
    let timer: any = null;
    if (file?.percent < 100) {
      handleAbort(file.uid);
    }
    timer = setTimeout(() => {
      attachFiles.value.splice(
        attachFiles.value.findIndex((item: any) => item.uid === file.uid),
        1,
      );
      clearTimeout(timer);
      timer = null;
    }, 0);
  };

  // 处理上传公共文件
  const handleUploadPublic = async (file: any, to: string, successCb: Fn) => {
    await uploader.uploadToPublic('bk3d4i8mjinm', file, to, {
      callback: {
        onProgress: (percent: number) => {
          console.log('上传中', percent);
        },
        onSuccess: (url: string) => {
          console.log('文件保存地址：', url);
          successCb(url);
        },
        onError: (error: Error) => {
          console.log(error);
        },
      },
    });
  };

  const handleAbort = (id?: number | string) => {
    if (id) {
      abortMap[id]();
    } else {
      Object.values(abortMap)?.forEach((item) => {
        item();
      });
    }
    deleteAbortMap(id);
    isCancel = true;
    console.log(`canceled: ${isCancel}`);
  };
  const deleteAbortMap = (id?: string | number) => {
    if (id) {
      delete abortMap[id];
    } else {
      abortMap = {};
    }
  };

  return {
    handleUploadFile,
    handleFileRemove,
    handleBeforeFileUpload,
    handleUploadPublic,
    handleAbort,
    handleSuccess,
    attachFiles,
    uploadLoading,
    uploader,
  };
};
