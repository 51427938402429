<template>
  <div>
    <FeaturedHome />
  </div>
</template>
<script setup lang="ts">
import { defaultSeo, headInfo } from '~/config/seo';
import FeaturedHome from './explore/featured/index.vue';

defineOptions({
  name: 'featured',
});

useHead(defaultSeo);

onActivated(() => {
  document.title = headInfo.titleTemplate;
  nextTick(() => {
    useHead(defaultSeo);
  });
});
</script>
