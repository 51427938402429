const FILE_LIMIT = {
  size: 15 * 1024 * 1024, // 限制图片大小15M
  types: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/webp', '.heic', '.HEIC'], // 限制图片类型
};

export const useUploadFile = () => {
  const { handleUploadFile } = useUpload();
  const uploadFileList = ref<any[]>([]);

  // 是否为 heic 图片
  const isHeic = (file: any) => {
    if (file.type) {
      return false;
    }
    const suffix = file.name.split('.').pop()?.toLocaleLowerCase();
    return suffix === 'heic';
  };

  const onBeforeFileUpload = (file: any) => {
    if (!FILE_LIMIT.types.includes(file.type)) {
      if (!isHeic(file)) {
        message.warn('Supported photo formats: JPG, JPEG, PNG, GIF, WEBP, HEIC');
        return false;
      }
    }
    if (file.size > FILE_LIMIT.size) {
      message.warn('Maximum photo size: 15MB');
      return false;
    }
    if (uploadFileList.value.length >= 8) {
      message.warn({
        content: 'You can upload a maximum of 8 images',
        key: 'comment-file',
      });
      return false;
    }
    return true;
  };

  // 文件发生改变时触发
  function onFileChange(info: any) {
    const passList = info.fileList.filter((file: any) => {
      if (file.size < FILE_LIMIT.size) {
        if (FILE_LIMIT.types.includes(file.type)) {
          return true;
        } else {
          return isHeic(file);
        }
      }
    });
    uploadFileList.value = passList.slice(0, 8);
  }

  // 自定义上传逻辑
  async function onCustomRequest(info: any) {
    const file = info.file;
    const index = uploadFileList.value.findIndex((item: any) => item.uid === file.uid);
    if (index === -1) {
      return;
    }
    const isHeicFile = isHeic(file);
    let newFile: any = null;
    if (isHeicFile) {
      const fileFormats = Object.assign(
        {},
        {
          uid: file.uid,
          name: file.name,
          type: file.type,
          size: file.size,
          originFileObj: file.originFileObj ? file.originFileObj : file,
        },
      );
      newFile = await formatImgType(fileFormats);
    }
    handleUploadFile({
      file: isHeicFile ? newFile : file,
      contentDisposition: 'attachment',
      private: false,
      onProgress: (percent: number) => {
        const index = uploadFileList.value.findIndex((item: any) => item.uid === file.uid);
        if (index !== -1) {
          const originFileObj = uploadFileList.value[index].originFileObj ?? uploadFileList.value[index];
          Object.assign(uploadFileList.value[index], { ...originFileObj, percent });
        }
      },
      onFinish: (url: any) => {
        const index = uploadFileList.value.findIndex((item: any) => item.uid === file.uid);
        if (index !== -1) {
          const originFileObj = uploadFileList.value[index].originFileObj ?? uploadFileList.value[index];
          Object.assign(uploadFileList.value[index], { ...originFileObj, url });
        }
      },
    });
  }

  const deleteFile = async (item: any) => {
    const index = uploadFileList.value.findIndex((file: any) => file.uid === item.uid);
    const newFileList = uploadFileList.value.slice();
    newFileList.splice(index, 1);
    uploadFileList.value = newFileList;
  };

  return {
    FILE_LIMIT,
    deleteFile,
    onFileChange,
    onBeforeFileUpload,
    onCustomRequest,
    uploadFileList,
  };
};

export const useComment = () => {
  const handleLoginTipClick = () => {
    const { $client } = useNuxtApp();
    $client.openModal(() => {
      location.reload();
    });
  };

  const handleSensorsComment = (type: string, answer: any) => {
    const { sensorAddCommentEvent, sensorCommentFinishEvent } = useSensors();
    if (type === 'finish') {
      sensorCommentFinishEvent({
        comment_id: answer.id,
        comment_content: answer.userComment,
        comment_time: new Date().getTime(),
      });
    } else {
      sensorAddCommentEvent({
        comment_id: answer.id,
        comment_content: answer.userComment,
        comment_time: new Date().getTime(),
      });
    }
  };
  return {
    handleLoginTipClick,
    handleSensorsComment,
  };
};
