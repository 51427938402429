export function useStorage<T extends object = any>(key: string, initialValue?: T) {
  const valueRef = ref<T>(initialValue || ({} as T));
  watch(
    valueRef,
    () => {
      localStorage.setItem(key, JSON.stringify(valueRef.value));
    },
    { deep: true },
  );
  onMounted(() => {
    valueRef.value = parseJSON(localStorage.getItem(key), { defaultValue: initialValue });
  });
  return valueRef;
}
