<template>
  <section v-if="description" class="mt-10 overflow-x-auto border-0 border-t border-solid border-[#ededed] pt-10">
    <h3 class="mb-4 text-xl font-bold text-text-primary pad:text-level-2 mobile:text-level-2">Product pattern</h3>

    <EditorPreview :preview-content="description" />
  </section>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);

const description = computed(() => {
  return detail.value.productInfo?.description;
});
</script>

<style lang="less" scoped></style>
