<template>
  <a-modal
    v-if="visible"
    v-model:open="visible"
    class="report-modal"
    centered
    destroy-on-close
    :mask-closable="false"
    :width="isPc ? 480 : '90%'"
    :ok-text="'Send'"
    :cancel-text="'Cancel'"
    :confirm-loading="isConfirmLoading"
    :ok-button-props="{ disabled: !reportInfo.type }"
    @cancel="cancel"
    @ok="send"
  >
    <template #title>
      <a-space class="report-modal-title" :size="10">
        <ExclamationCircleFilled class="report-title-icon" />
        <label>Report Project</label>
      </a-space>
    </template>

    <div class="tips">
      Please review our
      <NuxtLink class="tips-guidelines" target="_blank" :to="'https://www.designfind.com/discussion/488'">Community Guidelines</NuxtLink>
      for additional information about what is and isn't permitted on DesignFind.
    </div>

    <a-radio-group v-model:value="reportInfo.type" class="report-type">
      <a-radio v-for="radio in reportOptions" :key="radio.key" :value="radio.key">
        <a-space>
          <span>{{ radio.label }}</span>
          <a-tooltip :placement="radio.placement" :title="radio.tip">
            <QuestionCircleOutlined />
          </a-tooltip>
        </a-space>
      </a-radio>
    </a-radio-group>

    <a-textarea
      v-model:value="reportInfo.content"
      placeholder="Please describe the problem. Upload up to 5 photos."
      show-count
      :auto-size="{ minRows: 3, maxRows: 12 }"
      :maxlength="1000"
    />

    <a-upload
      v-model:file-list="fileList"
      multiple
      support-server-render
      list-type="picture-card"
      accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
      :class="{
        'is-btn-hidden': fileList.length >= 5,
      }"
      :capture="undefined"
      :max-count="5"
      :disabled="fileList.length >= 5"
      :custom-request="onCustomRequest"
      :before-upload="onBeforeFileUpload"
    >
      <div class="upload-btn">
        <div class="upload-inner">
          <PictureOutlined class="upload-icon" />
          <div>Add Photo</div>
        </div>
      </div>
      <template #itemRender="{ file, actions: { remove } }">
        <div
          :id="file.uid"
          :key="file.uid"
          class="item-render"
          :style="{ marginRight: file.uid === (fileList[fileList.length - 1] as any)?.uid && fileList.length === 5 ? '0' : '8px' }"
        >
          <div v-if="!file.url" class="pre-upload">
            <span class="pre-text">Uploading...</span>
            <div class="progress-bar">
              <span class="inner-bar" :style="{ width: file.percent + '%' }"></span>
            </div>
          </div>
          <template v-if="file.url && file.thumbUrl">
            <img :alt="file.name" :src="getOssProcessedImage(file.thumbUrl, 'm_fill,w_800,h_600', 'webp')" />
            <icon-font type="icon-Delete" @click="remove" />
          </template>
        </div>
      </template>
    </a-upload>
  </a-modal>
</template>

<script setup lang="ts">
import { reportProjectApi } from '@/api/index';
import { ExclamationCircleFilled, PictureOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { ref } from 'vue';
import type { ItemType } from '~/types';
import { reportOptions } from './const';

const { sensorsSubmitReport } = useSensors();

const { handleUploadFile } = useUpload();

const emits = defineEmits(['cancel', 'send']);

const detailInfo = ref<ItemType>();
let reportInfo = ref<any>({
  objectId: 0,
  type: '',
  content: '',
  images: [],
});
const visible = ref(false);
const open = (detail: ItemType) => {
  detailInfo.value = detail;
  // console.log('detail :>> ', detail);
  visible.value = true;
};

const close = () => {
  reportInfo.value = {
    objectId: 0,
    type: '',
    content: '',
    images: [],
  };
  fileList.value = [];
  visible.value = false;
};

const cancel = () => {
  emits('cancel');
  close();
};

const fileList = ref([]);
const isConfirmLoading = ref(false);
const send = async () => {
  reportInfo.value.objectId = detailInfo?.value?.id;
  reportInfo.value.images = fileList.value.map((item: any) => item.url);

  const isUploaded = reportInfo.value.images.every((img: any) => Boolean(img));
  if (!isUploaded) {
    message.error('Please wait for the image to finish uploading');
    return;
  }

  const params = {
    objectId: reportInfo.value.objectId,
    type: reportInfo.value.type,
    content: reportInfo.value.content,
    images: reportInfo.value.images,
  };

  let sensorsParams = {
    is_success: false,
    is_picture: params.images.length > 0,
    reportContent: params.content,
    reportTags: params.type,
  };

  try {
    isConfirmLoading.value = true;
    await reportProjectApi(params);

    message.success({
      content: h('span', {
        innerHTML: `Thank you for letting us know. Your report is submitted.
        ${isPc.value ? '<br />' : ''}
        We'll address your concerns within 3 business days.`,
        style: {
          display: 'flex',
          textAlign: 'left',
        },
      }),
      key: 'report',
      class: 'content-detail-message-success',
    });

    sensorsParams.is_success = true;
    emits('send');
    close();
  } catch (err) {
    console.log('err :>> ', err);
    sensorsParams.is_success = false;
  } finally {
    isConfirmLoading.value = false;
  }

  sensorsSubmitReport(sensorsParams);
};

const onBeforeFileUpload = (file: any, curFileList: any) => {
  if (fileList.value.length + curFileList.length > 5) {
    message.warn({
      content: 'Up to 5 pictures can be uploaded',
      key: 'report-file',
    });
  }
  return true;
};

const onCustomRequest = (info: any) => {
  const { file } = info;
  // console.log('info :>> ', info);
  // console.log('file :>> ', file);
  handleUploadFile({
    file,
    contentDisposition: '',
    onProgress: (percent: number) => {
      info.onProgress({ percent }, file);
    },
    onFinish: (url: any) => {
      console.log('onFinish');
      info.onSuccess();
      nextTick(() => {
        file.preview = file.thumbUrl || url;
      });
    },
    onError: () => {},
  });
};

const isPc = ref(true);
onMounted(() => {
  isPc.value = window.innerWidth > 768;
});

defineExpose({ open });
</script>

<style scoped lang="less">
.report-modal-title {
  font-size: 30px;
}

.report-title-icon {
  color: #faad14;
  font-size: 30px;
}

.tips {
  font-size: 16px;
  .tips-guidelines {
    //color: #00755f;
    text-decoration: underline;
  }
}

.report-type {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px 16px 0;
  background-color: #f8f8f8;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  :deep(.ant-radio-wrapper) {
    flex: calc(50% - 10px);
    margin: 0;
    margin-bottom: 16px;
    display: flex;
    font-size: 16px;
  }
  .ant-radio-wrapper:nth-child(even) {
    margin-left: 20px;
  }
}

// 图片上传
.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  :deep(.ant-upload-list) {
    // 上传按钮
    .ant-upload-select {
      width: 80px;
      height: 80px;
      margin: 0;
      .ant-upload {
        color: #999;
        .upload-btn {
          border-radius: 8px;
          transition: none;
          background-color: #f8f8f8;
          .upload-inner {
            font-size: 12px;
            .upload-icon {
              font-size: 24px;
            }
          }
        }
      }
    }
    // 已上传的图像卡片
    .ant-upload-list-item-container {
      width: auto;
      height: auto;
      display: inline-flex;
      margin: 0;
      .item-render {
        position: relative;
        display: flex;
        width: 80px;
        height: 80px;
        margin-bottom: 8px;
        margin-right: 8px;
        margin-left: 0;
        padding: 4px;
        border: 1px dashed #d9d9d9;
        border-radius: 8px;
        transition: all 0.5s;
        .anticon {
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: #ff4d4f;
          padding: 2px;
          border-radius: 4px;
          color: #fff;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
        .pre-upload {
          width: 100%;
          height: 100%;
          background-color: #f8f8f8;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .pre-text {
            font-size: 12px;
            color: #999;
          }
          .progress-bar {
            width: 100%;
            height: 6px;
            background-color: #ccc;
            border-radius: 5px;
            display: flex;
            overflow: hidden;
            .inner-bar {
              height: 100%;
              background-color: #28be44;
            }
          }
        }
      }
    }
  }
  :deep(.ant-upload-list-picture-card) {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}
// 数量等于5时隐藏上传按钮
.ant-upload-wrapper.is-btn-hidden {
  :deep(.ant-upload-list) {
    .ant-upload-select {
      display: none;
    }
  }
}

// 移动端
@media screen and (max-width: 768px) {
  .report-modal-title {
    font-size: 18px;
  }
  .report-title-icon {
    font-size: 18px;
  }
  .report-type {
    .ant-radio-wrapper {
      flex: 100%;
    }
    .ant-radio-wrapper:nth-child(even) {
      margin-left: 0;
    }
  }
  .ant-upload-wrapper.ant-upload-picture-card-wrapper {
    :deep(.ant-upload-list-picture-card) {
      flex-wrap: wrap;
    }
  }
  .item-render {
    margin-left: 0 !important;
    margin-right: 8px;
  }
  .upload-btn {
    margin-left: 0 !important;
  }
}
</style>

<style lang="less">
.report-modal {
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 16px;
  }
  .ant-modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-modal-content {
    border-radius: 16px !important;
    overflow: hidden;
  }
  .ant-modal-footer {
    border-top: none;
    margin-top: 0;
    padding-top: 14px;
  }
}

// 移动端 ant-modal-body
@media screen and (max-width: 768px) {
  .report-modal {
    .ant-modal-content {
      border-radius: 16px !important;
      overflow: hidden;
      .ant-modal-body {
        max-height: 440px;
        overflow: auto;
      }
    }
  }
}
</style>
