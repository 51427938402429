<template>
  <a-drawer
    width="100%"
    height="100%"
    placement="bottom"
    :open="layoutStore.isShowMbDraft"
    :closable="false"
    class="draft-mb-modal"
    :body-style="modalStyle"
    @close="onClose"
  >
    <header class="draft-modal-hader">
      <icon-font class="icon" type="icon-arrow-left-line" @click="onClose" />
      My Draft
      <span class="draft-num">{{ total }}</span>
    </header>
    <div class="draft-modal-content">
      <div v-if="!isEmptyResult">
        <DraftMbItem v-for="item of draftList" :key="item" :draft-item="item" @delete-draft="handleDelDraft" />
      </div>
      <a-empty v-if="isEmptyResult" :image="emptyImg" class="empty-img">
        <template #description>
          <span>No Data</span>
        </template>
      </a-empty>
      <bottom-loading v-if="draftList.length > 0" :loading="isLoading" :finished="isFinished" @infinite="handleInfiniteLoading" />
    </div>
  </a-drawer>
</template>

<script setup lang="ts">
import { deleteDraftApi, getDraftListApi } from '@/api/explore';
import emptyImg from '@/assets/img/no-data.png';
import DraftMbItem from '@/components/share/create/DraftMbItem.vue';
import { useLayoutStore } from '@/stores/layout';
import { message } from 'ant-design-vue';
import { RESPONSE_CODE } from '~/stores/types';
const layoutStore = useLayoutStore();

const modalStyle = {
  backgroundColor: '#F4F4F4',
  padding: 0,
};

const onClose = () => {
  layoutStore.setIsShowMbDraft(false);
};

watch(
  () => layoutStore.isShowMbDraft,
  () => {
    console.log(222);
    if (layoutStore.isShowMbDraft) {
      getDraftList();
    }
  },
);
const total = ref(0);
const draftList = ref([] as any[]);
const isEmptyResult = ref(false);
const firstLoading = ref(false);
const isLoading = ref(false);
const isFinished = ref(false);
const pagination = reactive({
  current: 1,
  pageSize: 21,
  total: 0,
});

async function getDraftList() {
  try {
    const { data: getDraftListResp, error: getDraftListError } = await getDraftListApi({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    if (!getDraftListError.value) {
      const { data, code } = getDraftListResp.value;
      if (code === RESPONSE_CODE.SUCCESS) {
        const { list, pagination } = data;

        pagination.total = pagination?.total;
        total.value = pagination.total;
        pagination.current = pagination?.current;
        isFinished.value = pagination.current >= Math.ceil(pagination.total / pagination.pageSize);
        if (list && list.length > 0) {
          const arr = handleDataDeduplication(draftList.value, list);
          draftList.value = draftList.value.concat(arr);
        } else {
          draftList.value = [];
        }
        isEmptyResult.value = !draftList.value.length;
      }
    }
  } finally {
    firstLoading.value = false;
    isLoading.value = false;
  }
}

const handleInfiniteLoading = async () => {
  if (isLoading.value || isFinished.value) {
    return;
  }
  isLoading.value = true;
  pagination.current = pagination.current + 1;
  getDraftList();
};

async function handleDelDraft(id: number) {
  const { data } = await deleteDraftApi(id);

  if (data.value.code === 0) {
    const index = draftList.value.findIndex((item: { id: number }) => item.id === id);

    if (index >= 0) {
      draftList.value.splice(index, 1);
      total.value--;
      isEmptyResult.value = !draftList.value.length;
    }

    message.success('Deleted successfully');
  }
}
</script>

<style lang="less" scoped>
.draft-mb-modal {
  :deep(.ant-drawer-wrapper-body) {
    background-color: aqua;
  }
  .draft-modal-hader {
    height: 64px;
    display: flex;
    justify-content: center;
    line-height: 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    background: #fff;
    font-weight: theme('fontWeight.semibold');
    font-size: 18px;
    font-style: normal;
    font-weight: theme('fontWeight.semibold');
    color: #212121;
    position: fixed;
    width: 100%;
    z-index: 10;
    .draft-num {
      margin-left: 8px;
      color: #999;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 20px;
      font-size: 24px;
      transform: translateY(-50%);
    }
  }
  .draft-modal-content {
    padding: 80px 16px 20px;
    min-height: 100%;
    background: theme('colors.background.4');
    .empty-img {
      margin-top: 20px;
      :deep(.ant-empty-description) {
        color: #999;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
</style>
<style lang="less">
.draft-mb-modal {
  z-index: 1002;
}
</style>
