<template>
  <div v-if="printTags.length > 0" class="mb-8">
    <h3 class="mb-0 text-xl font-bold text-text-primary pad:text-level-2 mobile:text-level-2">Print on demand tags</h3>
    <a-row class="mt-4">
      <a-col :span="24">
        <a-row
          class="tags"
          :gutter="[
            { xs: 8, sm: 8, md: 8, lg: 8 },
            { xs: 8, sm: 8, md: 8, lg: 8 },
          ]"
        >
          <a-col v-for="(tag, index) in printTags" :key="index">
            <TagItem :tag="tag" />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import TagItem from '@/components/project-details/common/TagItem.vue';
import { useDetailsStore } from '@/stores/details';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const printTags = computed(() => detail.value.printTags ?? []);
</script>

<style lang="less" scoped></style>
