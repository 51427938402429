/**
 * 触发点击
 *
 * @date 13/09/2021
 * @param {HTMLElement} ele
 */
export const dispatchClick = (ele: HTMLElement) => {
  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  ele.dispatchEvent(event);
};
