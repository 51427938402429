<template>
  <div>
    <draggable class="file-list" :list="list" :animation="200" item-key="uid">
      <template #item="{ element, index }">
        <div class="mb-2 flex cursor-pointer items-center gap-2 rounded-sm bg-[rgb(248,248,248)] p-1">
          <div class="flex gap-2">
            <icon-font type="icon-draggable" class="text-xl" />
            <img :src="element.image" alt="" class="h-[100px] w-[100px]" />
          </div>
          <div class="flex w-[calc(100%-112px)] justify-between mobile:flex-col mobile:gap-1 mobile:pr-3">
            <span
              :class="[
                'title mr-2 line-clamp-2 max-w-[calc(100%-174px)] break-all text-base text-[#1A1A1A] hover:text-status-link mobile:w-full mobile:max-w-full mobile:text-sm',
              ]"
              @click="goLink(element.url)"
            >
              {{ element.productTitle }}/ {{ element.variantTitle }}
            </span>
            <div class="flex items-center">
              <a-input-number v-model:value="element.quantity" :min="1" class="mr-2 h-[32px] w-[100px] mobile:w-[calc(100%-24px)]" />
              <icon-font type="icon-Delete" class="mr-2 text-xl leading-4 hover:text-error" @click.stop="deleteEle(index)" />
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script setup lang="ts">
import draggable from 'vuedraggable';
type PropsType = {
  list: any;
};
const props = defineProps<PropsType>();
const { list } = toRefs(props) as any;
const emits = defineEmits(['deleteItem']);
const deleteEle = (idx: number) => {
  Modal.confirm({
    content: 'Are you sure you want to delete this part?',
    okText: 'Delete',
    zIndex: 9999,
    onOk: () => {
      emits('deleteItem', idx);
    },
  });
};
const goLink = (url: string) => {
  window.open(url, '_blank');
};
</script>
