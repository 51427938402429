import { isRef, unref, watch, getCurrentInstance, type MaybeRef } from 'vue';
import type { Fn } from '~/types/fns';
import { isNil } from 'lodash-es';

export type EventSource = Window | Document | HTMLElement;
export type EventMap<S extends EventSource> = S extends Window
  ? WindowEventMap
  : S extends Document
    ? DocumentEventMap
    : S extends HTMLElement
      ? HTMLElementEventMap
      : Record<string, Event>;

export function useEventListener<S extends EventSource, T extends keyof EventMap<S>, L extends (ev: EventMap<S>[T]) => any>(
  source: MaybeRef<S | undefined>,
  type: T,
  listener: L,
  options: AddEventListenerOptions | boolean = true,
) {
  let es: EventSource | undefined;
  const _listener = listener as unknown as EventListenerOrEventListenerObject;
  let _cleanup: Fn<[], void>;
  const cleanup = () => {
    if (isRef(source)) {
      _cleanup();
    }
    if (isNil(es)) {
      return;
    }
    es.removeEventListener(type as string, _listener);
  };

  if (!isInClient()) {
    return cleanup;
  }

  if (isRef(source)) {
    _cleanup = watch(
      () => {
        const _s = unref(source);
        return ![window, document].includes(_s as any) ? getComponentRootEl(_s) : _s;
      },
      (newEs, oldEs) => {
        if (!isNil(oldEs)) {
          oldEs.removeEventListener(type as string, _listener);
        }
        if (!isNil(newEs)) {
          newEs.addEventListener(type as string, _listener, options);
        }

        es = newEs;
      },
    );
  } else if (!isNil(source)) {
    es = source;
    es.addEventListener(type as string, _listener, options);
  }

  if (!isNil(getCurrentInstance())) {
    onActivated(() => {
      es?.addEventListener(type as string, _listener, options);
    });
    onDeactivated(() => {
      es?.removeEventListener(type as string, _listener, options);
    });
  }

  tryOnUnMounted(cleanup);

  return cleanup;
}
