import { h } from 'vue';
import { replaceable } from "./replaceable.mjs";
export default replaceable('retry', h("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512"
}, h("path", {
  d: "M320,146s24.36-12-64-12A160,160,0,1,0,416,294",
  style: "fill: none; stroke: currentcolor; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 32px;"
}), h("polyline", {
  points: "256 58 336 138 256 218",
  style: "fill: none; stroke: currentcolor; stroke-linecap: round; stroke-linejoin: round; stroke-width: 32px;"
})));