<template>
  <a-form ref="formRef" :rules="rules" layout="vertical" :model="formState" class="form-content new-ant-style">
    <section :id="FormIds.BasicInfo">
      <CollapsePanel v-model:active-key="activeKey" :col-key="CollapseKey.BasicInfo" class="section-wrap" title="Basic Infomation">
        <BasicImgs v-model:coverList="formState.coverList" v-model:projectFileList="formState.projectFileList" />
        <ProjectTitle v-model:title="formState.title" :cover-auto-tag="coverAutoTag" label="Title" @handle-validate="validateTitle" />
        <Summary v-model:summary="formState.summary" />
        <AimakeInfo v-if="formState.aiExtra?.scene || formState.aiExtra?.style" :ai-extra="formState.aiExtra" />
      </CollapsePanel>
    </section>
    <section :id="FormIds.ProjectFiles">
      <CollapsePanel v-model:active-key="activeKey" :col-key="CollapseKey.ProjectFiles" class="section-wrap" title="Project Files">
        <DesignFiles v-model:attachFiles="formState.attachFiles" :mode="mode" :info="ProjectFilesInfo" />
        <ProjectOrigin
          v-model:source="formState.source"
          v-model:originals="formState.originals"
          :allow-license-ids="allowLicenseIds"
          :mode="mode"
          label="Project origin"
        />
        <License
          v-model:licenseId="formState.licenseId"
          v-model:forSale="formState.forSale"
          :is-published="isPublished"
          :source="formState.source"
          :default-license-id="defaultLicenseId"
          :license-list="licenseList"
          @handle-allow-license="handleAllowLicenseChange"
        />
        <Price v-if="mode === ModeType.Sale && isPrimeCreator" v-model:origPriceCent="formState.origPriceCent" type="project" class="mb-8" />
      </CollapsePanel>
    </section>
  </a-form>
</template>
<script setup lang="ts">
import { CollapseKey, FileEventType, FormIds } from '@/components/share/project/type';

import Price from '@/components/share/common/Price.vue';
import { ProjectFilesInfo } from '@/components/share/const';
import { useAiAutoTag } from '@/pages/share/useShare';
import { useShareProject } from '@/stores/shareProject';
import { useUserInfo } from '@/stores/user';
import type { Rule } from 'ant-design-vue/es/form';
import { storeToRefs } from 'pinia';
import { v4 } from 'uuid';
import { DETAIL_TYPE } from '~/types';
import { ModeType } from '~/types/share';
import AimakeInfo from '../../common/basic-info/AimakeInfo.vue';
import BasicImgs from '../../common/basic-info/BasicImgs.vue';
import DesignFiles from '../../common/basic-info/DesignFiles.vue';
import License from '../../common/basic-info/License.vue';
import ProjectOrigin from '../../common/basic-info/ProjectOrigin.vue';
import ProjectTitle from '../../common/basic-info/ProjectTitle.vue';
import Summary from '../../common/basic-info/Summary.vue';
import CollapsePanel from '../../common/collapse-panel.vue';
import { SourceType } from '../../interface';

const useShareProjectInfo = useShareProject();
const userInfoStore = useUserInfo();
const editCover = ref('');
const isPrimeCreator = computed(() => userInfoStore.isSaleMaker);
const { form: storeForm, requestForm, mode, activeKey, defaultLicenseId, licenseList, allFileExtra } = storeToRefs(useShareProjectInfo);
const formRef = ref();
const formState = ref({
  title: '', // 标题
  summary: '', // 概要
  cover: '', // 封面图
  coverList: [] as any, // 封面图文件
  images: [] as any, // 图片
  aiImages: [] as any, // AI生成的图片
  videos: [] as any, // 视频
  attachFiles: [], // 附件
  source: SourceType.Original as SourceType, // 来源
  licenseId: 0, // 授权协议id
  originals: [], // 原创信息
  projectFileList: [] as any, // 图片和视频
  isAudit: false, // 是否审核
  aiExtra: {} as any,
  forSale: true, // 是否付费用户
  origPriceCent: 0, // 价格/美分
});
const isPublished = computed(() => requestForm.value.status === DETAIL_TYPE.PUBLISHED);
const { coverAutoTag } = useAiAutoTag(formState, editCover);
watch(coverAutoTag, () => {
  useShareProjectInfo.setCoverAutoTag(coverAutoTag);
});
const allowLicenseIds = ref([]);
const handleAllowLicenseChange = (ids: any) => {
  allowLicenseIds.value = ids;
};
watch(
  () => formState.value,
  () => {
    storeForm.value.basicForm = Object.assign({}, formState.value, {
      cover: formState.value.coverList[0]?.url || '',
      images: formState.value.projectFileList.filter((item: any) => item.type !== 'video' && item.type !== 'aiImage').map((item: any) => item.url),
      videos: formState.value.projectFileList.filter((item: any) => item.type === 'video'),
      aiImages: formState.value.projectFileList.filter((item: any) => item.type === 'aiImage').map((item: any) => item.url),
    });
  },
  { deep: true },
);
watch(
  () => formState.value.coverList,
  () => {
    formRef.value?.validateFields(['coverList']);
  },
);
watch(
  () => formState.value.licenseId,
  () => {
    if (formState.value.licenseId !== 0) {
      formRef.value?.validateFields(['licenseId']);
    }
  },
);
let lastXcsLen = allFileExtra.value?.length ?? 0;
watch(
  () => formState.value.attachFiles.length,
  (newLength, oldLength) => {
    // 如果新旧长度都为0，无需校验
    if (newLength === 0 && oldLength === 0) {
      return;
    }

    // 校验附件
    formRef.value?.validateFields(['attachFiles']);

    const currentXcsLen = allFileExtra.value.length;
    // 如果文件数量没有变化，直接返回
    if (currentXcsLen === lastXcsLen) {
      return;
    }
    // 触发文件变更事件
    const eventType = currentXcsLen > lastXcsLen ? FileEventType.Add : FileEventType.Remove;
    useShareProjectInfo.triggerFileEvent(eventType);
    lastXcsLen = currentXcsLen;
  },
);

const validateTitle = () => {
  formRef.value?.validateFields(['title']);
};

const handleFormat = async () => {
  try {
    await formRef.value.validateFields();
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
defineExpose({
  handleFormat,
});

// 编辑模式更新数据
watch(requestForm, () => {
  formState.value.projectFileList = [];
  Object.keys(formState.value).forEach((key) => {
    if (requestForm.value.hasOwnProperty(key) && (requestForm.value as any)[key] !== undefined) {
      (formState.value as any)[key] = (requestForm.value as any)[key];
    }
  });
  if (requestForm.value.cover) {
    editCover.value = requestForm.value.cover;
    formState.value.coverList = [
      {
        uid: v4(),
        url: requestForm.value.cover,
        thumbnailUrl: requestForm.value.cover,
        name: requestForm.value.cover,
        type: 'image',
      },
    ];
  }

  if (requestForm.value.images?.length) {
    formState.value.projectFileList = formState.value.images.map((image: any) => {
      return {
        uid: v4(),
        url: image,
        name: image,
        type: 'image',
      };
    });
  }
  if (requestForm.value.videos?.length) {
    formState.value.projectFileList.unshift(...requestForm.value.videos);
  }
  if (requestForm.value.aiImages?.length) {
    const aiImages = formState.value.aiImages.map((image: any) => {
      return {
        uid: v4(),
        url: image,
        name: image,
        type: 'aiImage',
      };
    });
    formState.value.projectFileList.unshift(...aiImages);
  }
});
const checkOriginals = async () => {
  if (formState.value.originals.length === 0) {
    return Promise.reject('Please enter the original project');
  }
  return Promise.resolve();
};
// 校验规则
const rules: Ref<Record<string, Rule[]>> = computed(() => {
  return {
    title: [{ required: true, message: 'Please enter a title', trigger: ['change', 'blur'] }],
    coverList: [{ required: true, message: 'Please add image' }],
    attachFiles: [{ required: true, message: 'Please upload file', trigger: 'blur' }],
    source: [{ required: true, message: 'Please choose a source' }],
    originals: [{ required: true, validator: checkOriginals, message: 'Please enter the original project' }],
    licenseId: [
      { required: true, message: 'Please choose a license', trigger: 'blur' },
      { validator: (rule: any, value: any) => (value !== 0 ? Promise.resolve() : Promise.reject('Please choose a license')), trigger: 'blur' },
    ],
    origPriceCent: [
      {
        required: true,
        validator: async (_rule: any, value: any) => {
          if (value > 60 && value < 50000) {
            return true;
          }
          if (value === '' || value === 0) {
            return Promise.reject('Please enter the selling price');
          }
          if (Number(value) <= 60 || Number(value) >= 50000) {
            return Promise.reject('Price must be between USD 0.60 and USD 500');
          }
          return Promise.reject('Please enter the selling price');
        },
        trigger: 'change',
      },
    ],
  };
}) as any;
</script>
<style scoped lang="less">
.section-wrap {
  margin-top: 16px;
}
.form-content {
  :deep(.ant-form-item) {
    margin-bottom: 32px;
    .ant-form-item-label > label {
      font-size: 16px;
      font-weight: theme('fontWeight.semibold');
      line-height: 22px;
      color: #1a1a1a;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 48px;
    }
  }
}
</style>
