<template>
  <div class="aimake-header-user-icon">
    <!-- <div class="user-icon-img" :style="{ width: `${size}px`, height: `${size}px` }">
      <a-avatar
        v-if="isLogin"
        :size="size"
        :src="getOssProcessedImage(userInfoStore.myInfo.headpic.split('?')[0], 'm_fill,w_100,h_100', 'webp')"
        :draggable="false"
      />

      <img v-else class="img" :src="noLoginImg" @click="handleLogin" />
    </div> -->

    <a-dropdown :trigger="['click']" overlay-class-name="aimake-login-dropdown">
      <div class="aimake-user-icon" :style="{ width: `${size}px`, height: `${size}px` }">
        <a-avatar
          v-if="isLogin"
          :size="size"
          :src="getOssProcessedImage(userInfoStore.myInfo.headpic.split('?')[0], 'm_fill,w_100,h_100', 'webp')"
          :draggable="false"
        />
        <img v-else class="img" :src="noLoginImg" @click="handleLogin" />
      </div>

      <template #overlay>
        <div v-if="isLogin" class="aimake-user-pop">
          <a @click="handleLogout">
            <icon-font type="icon-logout-box-line" />
            Logout
          </a>
        </div>
      </template>
    </a-dropdown>
  </div>
</template>
<script setup lang="ts">
import noLoginImg from '@/assets/img/aimake/nologin.png';
import { useSensors } from '~/composables/sensors/aimake';
import { useUserInfo } from '~/stores/user';

const { $client } = useNuxtApp();

const { isLogin, broadcastLogout } = useLoginStatus();
const userInfoStore = useUserInfo();
const { sensors }: any = useSensors();

type PropsType = {
  size: number;
};
defineProps<PropsType>();

// 声明事件，
type emitsType = {
  (event: 'logout'): void;
  (event: 'login'): void;
};

const emit = defineEmits<emitsType>();

const login = () => {
  $client.openModal(() => {
    location.reload();
  });
};

const logout = async () => {
  try {
    broadcastLogout();
    await $client.logout();
  } finally {
    userInfoStore.resetUserInfo();
    window.location.reload();
  }
};

const handleLogout = async () => {
  emit('logout');
  logout();
};

const handleLogin = async () => {
  emit('login');
  login();
};

const userInfo = ref<any>();

// 处理设置神策用户属性
const handleSetUserSensors = () => {
  // 登录成功设置调用神策登录接口，将用户id与神策id关联。上线后替换xtool_community_id
  sensors?.loginWithKey(import.meta.env.VITE_SENSORS_ID_KEY, userInfo.value.uid);

  // 设置用户属性，存在则覆盖
  sensors?.setProfile({
    name: userInfo.value.userName,
    registered_email: userInfo.value.email,
    xtool_community_id: userInfo.value.uid,
    registered_time: userInfo.value.createTime,
  });
};

onMounted(async () => {
  if ($client.token) {
    userInfo.value = await $client.userInfo();
    handleSetUserSensors();
  }
});
</script>

<style lang="less" scoped>
.aimake-header-user-icon {
  user-select: none;
  margin-left: 16px;

  .aimake-user-icon {
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

    .img {
      height: 100%;
      width: 100%;
    }
  }
}

.aimake-user-pop {
  display: flex;
  flex-direction: column;
  width: 186px;
  padding: 2px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.06),
    0 1px 20px 0 rgba(0, 0, 0, 0.1);

  a {
    display: inline-flex;
    align-items: center;
    padding: 7px 12px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #212121;

    &:hover {
      color: #1a1a1a;
      background: #f5f7fb;
    }

    &:first-child:hover {
      border-radius: 10px 10px 0 0;
    }

    &:last-child:hover {
      border-radius: 0 0 10px 10px;
    }

    .anticon {
      margin-right: 12px;
      font-size: 20px;
    }
  }
}
</style>

<style lang="less">
.aimake-login-dropdown {
  padding-top: 20px;
  .aimake-user-pop {
    display: flex;
    flex-direction: column;
    width: 186px;
    padding: 2px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.06),
      0 1px 20px 0 rgba(0, 0, 0, 0.1);

    .item {
      display: inline-flex;
      align-items: center;
      padding: 7px 12px;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #212121;
      cursor: pointer;

      &:hover {
        color: #1a1a1a;
        background: #f5f7fb;
      }

      &:first-child:hover {
        border-radius: 10px 10px 0 0;
      }

      &:last-child:hover {
        border-radius: 0 0 10px 10px;
      }

      .anticon {
        margin-right: 12px;
        font-size: 20px;
      }
    }
  }
}
</style>
