import { Methods, request, REQUEST_SEVER } from '@/utils/request';
import { defineFindApi } from '~/utils/fetch/define-api';

// 修改回答
export const editAnswerApi = (id: number, data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/answer/${id}`,
    method: Methods.PUT,
    data,
  });
};

// 删除回答
export const deleteAnswerApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/answer/${id}`,
    method: Methods.DELETE,
  });
};

// 获取回答列表
export const getAnswerListApi = defineFindApi({
  url: '/community/v1/web/answers',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    objectType: string;
    objectId: number;
    current: number;
    pageSize: number;
  }>(),
  respTy: RespTy.Json<any>(),
});

// 回答问题
export const doAnswerApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/answer',
    method: Methods.POST,
    data,
  });
};

// 获取回答列表
export const getAnswerInfoApi = defineFindApi({
  url: `/community/v1/web/answer/:id`,
  paramTy: ParamTy.Query<{
    id: number;
  }>(),
  respTy: RespTy.Json<any>(),
});
