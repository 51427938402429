<template>
  <a-collapse v-model:activeKey="activeKey" ghost class="xt-collapse-panel">
    <template #expandIcon="props">
      <icon-font type="icon-arrow-right-m" :class="{ 'rotate-90': props?.isActive }" />
    </template>
    <a-collapse-panel :key="colKey" :header="title" class="bg-white">
      <template #extra><slot name="extra"></slot></template>
      <slot></slot>
    </a-collapse-panel>
  </a-collapse>
</template>

<script setup lang="ts">
defineProps({
  title: { type: String, required: true },
  colKey: { type: String, default: '1' },
  activeKey: { type: Array, default: () => [] as string[] },
});

const activeKey = defineModel('activeKey', {
  type: Array as PropType<string[]>,
  default: [],
});
</script>

<style scoped lang="less">
.xt-collapse-panel {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 24px 20px;

  :deep(.ant-collapse-header) {
    align-items: center;
    font-size: 20px;
    font-weight: theme('fontWeight.bold');
    padding: 0 16px;
    .ant-collapse-arrow {
      font-size: 24px;
    }
  }

  :deep(.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
    padding-top: 0;
  }
  :deep(.ant-collapse-content > .ant-collapse-content-box) {
    padding-top: 12px;
    padding-bottom: 0;
  }
}
@media screen and(max-width: 767px ) {
  .xt-collapse-panel {
    border-radius: 0px;
    padding: 4px;
    :deep(.ant-collapse-header) {
      padding: 12px 16px;
    }
  }
}
</style>
