<template>
  <a-button :shape="shape" class="action-button" :disabled="disabled" :class="active ? 'done' : ''" :style="btnStyle">
    <div class="content">
      <icon-font v-if="icon" class="icon" :type="iconDisplay" />
      <span v-if="textDisplay" class="text">{{ textDisplay }}</span>
    </div>
  </a-button>
</template>
<script setup lang="ts">
import { type ButtonShape } from 'ant-design-vue/es/button';

interface HoverStyle {
  borderColor: string;
  color: string;
}
type Props = {
  size?: number;
  shape?: ButtonShape | undefined;
  active?: boolean;
  icon?: string;
  activeIcon?: string;
  text?: string | number;
  hoverSyle?: HoverStyle;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  shape: 'circle',
  size: undefined,
  icon: undefined,
  text: undefined,
  active: false,
  disabled: false,
  activeIcon: undefined,
  hoverSyle: undefined,
});

const btnStyle = computed(() => {
  const style = {
    width: `${props.size}px`,
    height: `${props.size}px`,
    '--h-border-color': '#1a1a1a',
    '--h-color': '#1a1a1a',
  };

  if (props.hoverSyle) {
    props.hoverSyle.borderColor && (style['--h-border-color'] = props.hoverSyle.borderColor);
    props.hoverSyle.color && (style['--h-color'] = props.hoverSyle.color);
  }

  return style;
});

const textDisplay = computed(() => props.text?.toString());

const iconDisplay = computed(() => {
  return props.active ? props.activeIcon || props.icon : props.icon;
});
</script>
<style lang="less" scoped>
.action-button {
  box-shadow: none;
  &:hover {
    border-color: var(--h-border-color);
    color: var(--h-color);
    .content {
      .icon,
      .text {
        color: var(--h-color);
      }
    }
  }
  &:disabled {
    border-color: rgba(0, 0, 0, 0.16);
    .content {
      .icon,
      .text {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .content {
    color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    .icon {
      transition: all ease 0.3s;
      font-size: 24px;
    }
    .text {
      font-size: 14px;
      line-height: 1;
      margin-top: 4px;
    }
  }

  &.done {
    background-color: theme('colors.red.10');
    border-color: transparent;
    .content {
      .icon {
        color: theme('colors.red.default');
      }
      .text {
        color: theme('colors.text.title');
      }
    }
  }
  &.done:hover {
    background-color: theme('colors.red.5');
  }
}

// 移动端适配 start
@media screen and (max-width: 767px) {
  .action-button {
    max-width: 56px;
    max-height: 56px;
  }
}
</style>
