<template>
  <a-form-item name="scenarios" label="Application scenarios">
    <a-checkbox-group v-model:value="scenarios" class="flex gap-3 text-[#1A1A1A]">
      <a-checkbox v-for="(item, index) in scenarioMapData.scenariosList" :key="index" class="mr-2" :value="item.name">{{ item.name }}</a-checkbox>
    </a-checkbox-group>
  </a-form-item>
</template>
<script setup lang="ts">
import { type PropType } from 'vue';
import { useScenariosMachine } from '../../project/production-info/useScenariosMachine';
const { scenarioMapData } = useScenariosMachine();
const scenarios = defineModel('scenarios', { type: Array as PropType<any> });
</script>
