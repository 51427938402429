"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QUERY = exports.googleClientId = void 0;
/**
 * Passport 域名
 */
const DOMAINS = {
    // local: {
    // 	page: "http://ppl.makeblock.com:8082/xtool-passport/index.html#/",
    // 	api: "https://passport-api-dev.makeblock.com/v1",
    // },
    dev: {
        xtool: {
            us: {
                page: "https://dev-web.makeblock.com/xtool-passport/index.html#/",
                prefetch: "https://dev-web.makeblock.com/xtool-passport/prefetch.html",
                api: "https://xtool-passport-api-dev.makeblock.com/v1",
            },
            eu: {
                page: "https://dev-web.makeblock.com/xtool-passport/index.html#/",
                prefetch: "https://dev-web.makeblock.com/xtool-passport/prefetch.html",
                api: "https://xtool-passport-api-dev.makeblock.com/v1",
            },
            cn: {
                page: "https://dev-web.makeblock.com/xtool-passport/index.html#/",
                prefetch: "https://dev-web.makeblock.com/xtool-passport/prefetch.html",
                api: "https://xtool-passport-api-dev.makeblock.com/v1",
            },
        },
        makeblock: {
            us: {
                page: "https://dev-web.makeblock.com/passport/index.html#/",
                api: "https://passport-api-dev.makeblock.com/v1",
            },
            eu: {
                page: "https://dev-web.makeblock.com/passport/index.html#/",
                api: "https://passport-api-dev.makeblock.com/v1",
            },
            cn: {
                page: "https://dev-web.makeblock.com/passport/index.html#/",
                api: "https://passport-api-dev.makeblock.com/v1",
            },
        },
    },
    test: {
        xtool: {
            us: {
                page: "https://test-web.xtool.com/xtool-passport/index.html#/",
                prefetch: "https://test-web.xtool.com/xtool-passport/prefetch.html",
                api: "https://passport-api-test.xtool.com/v1",
            },
            eu: {
                page: "https://test-web.mblock.cc/xtool-passport/index.html#/",
                prefetch: "https://test-web.mblock.cc/xtool-passport/prefetch.html",
                api: "https://passport-api-test.mblock.cc/v1",
            },
            cn: {
                page: "https://test-web.makeblock.com/xtool-passport/index.html#/",
                prefetch: "https://test-web.makeblock.com/xtool-passport/prefetch.html",
                api: "https://passport-api-test.makeblock.com/v1",
            },
        },
        makeblock: {
            us: {
                page: "https://test-web.makeblock.com/passport/index.html#/",
                api: "https://passport-api-test.mblock.cc/v1",
            },
            eu: {
                page: "https://test-web.mblock.cc/passport/index.html#/",
                api: "https://passport-api-test.mblock.cc/v1",
            },
            cn: {
                page: "https://test-web.makeblock.com/passport/index.html#/",
                api: "https://passport-api-test.makeblock.com/v1",
            },
        },
    },
    prod: {
        xtool: {
            us: {
                page: "https://passport.xtool.com/#/",
                prefetch: "https://passport.xtool.com/prefetch.html",
                api: "https://passport-api.xtool.com/v1",
            },
            eu: {
                page: "https://passport.xtool.com/#/",
                prefetch: "https://passport.xtool.com/prefetch.html",
                api: "https://passport-api.xtool.com/v1",
            },
            cn: {
                page: "https://passport.xtool.com/#/",
                prefetch: "https://passport.xtool.com/prefetch.html",
                api: "https://passport-api.xtool.com/v1",
            },
        },
        makeblock: {
            us: {
                page: "https://passport.mblock.cc/#/",
                api: "https://passport-api.mblock.cc/v1",
            },
            eu: {
                page: "https://passport.mblock.cc/#/",
                api: "https://passport-api.mblock.cc/v1",
            },
            cn: {
                page: "https://passport.makeblock.com/#/",
                api: "https://passport-api.makeblock.com/v1",
            },
        },
    },
};
exports.googleClientId = "741723023720-rvn5ckm07n0k2m2iits9t9fike24b0d8.apps.googleusercontent.com";
exports.QUERY = {
    PLATFORM: "p",
    REDIRECT: "r",
    MODE: "md",
    SHOW_SWITCH_HOST: "sh",
    SHOW_SOCIAL_LOGIN: "sl",
    SHOW_SWITCH_LOGIN_REGISTER: "lr",
    ONLY_PHONE_LOGIN: "op",
    USE_LOCALSTORAGE: "uls",
    USE_RELOGIN: "rl",
    HIDE_LOGO: "hl",
    LOGO: "lo",
    TEXT_LOGO: "tlo",
    SUB_TEXT_LOGO: "stlo",
    COPYRIGHT: "cr",
    ORG_CODE: "oc",
    TOKEN: "ut",
    CLEAR_TOKEN: "cut",
    COOKIES_SAME_SITE: "sameSite",
    COOKIES_SECURE: "secure",
    APP_VERSION: "av",
    SYSTEM: "s",
    ORIGIN: "o",
    CUSTOM_IMAGE: "img",
};
exports.default = DOMAINS;
