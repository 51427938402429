<template>
  <div id="anchor-content-box" class="content-main border-0 border-b border-solid border-[rgba(0,0,0,0.1)]">
    <Information v-show="activeKey === 'Information'" />
    <Instruction v-if="activeKey === 'Instruction'" />
    <template v-if="!isPreview && !audit && isFirstLoadComments">
      <CommentsInfo v-show="activeKey === 'Comments'" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { DetailProvide } from '@/components/project-details/types';
import CommentsInfo from './CommentsInfo.vue';
import Information from './Information.vue';
import Instruction from './Instruction.vue';

const { isPreview, audit } = inject('detail-common') as DetailProvide;

type PropsType = {
  activeKey?: string;
};
const props = withDefaults(defineProps<PropsType>(), {
  activeKey: '',
});
const { activeKey } = toRefs(props);

const isFirstLoadComments = ref(false);
onMounted(() => {
  isFirstLoadComments.value = true;
});
</script>

<style lang="less" scoped></style>
