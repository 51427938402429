import type { Fn } from '~/types/fns';

export function raf(callback: Fn<[], void>) {
  let rafId: number;
  let stop = false;
  const done = () => {
    stop = true;
    cancelAnimationFrame(rafId);
  };
  call(function runner() {
    rafId = requestAnimationFrame(() => {
      if (stop) {
        return;
      }
      callback();
      runner();
    });
  });
  return done;
}
