<template>
  <div>
    <div class="step-wrap flex flex-col gap-4 font-sans mobile:hidden">
      <ClientOnly>
        <span class="highlight" :style="`top:${currentIdx * 39}px`"></span>
        <template v-for="item in currentStepList" :key="item">
          <div
            class="step editor-step-menu cursor-pointer pl-4"
            :class="{ 'current-step': currentStepId === item.id }"
            :data-target="item.id"
            @click="changeStep(item)"
          >
            <span class="text text-level-2 font-semibold mobile:text-[12px]">{{ item.title }}</span>
          </div>
        </template>
      </ClientOnly>
    </div>
    <div class="hidden mobile:block">
      <div class="step-icon flex h-[44px] w-[44px] items-center justify-center rounded-full bg-[#fff]" @click="showMbDrawer = true">
        <icon-font type="icon-list" class="text-[24px]" />
      </div>
      <a-drawer height="auto" placement="bottom" :visible="showMbDrawer" :closable="false" class="create-mb-step" @close="onClose">
        <div class="my-3 flex justify-between">
          <icon-font type="icon-list" class="text-[24px]" />
          <icon-font type="icon-close-line" class="cursor-pointer text-[20px]" @click="onClose" />
        </div>
        <div class="step-wrap mb-4 mt-2 flex flex-col gap-4 font-sans">
          <ClientOnly>
            <span class="highlight" :style="`top:${currentIdx * 39}px`"></span>
            <template v-for="item in currentStepList" :key="item">
              <div class="step cursor-pointer pl-4" :class="{ 'current-step': currentStepId === item.id }" @click="changeStep(item)">
                <span class="text text-level-2 font-semibold">{{ item.title }}</span>
              </div>
            </template>
          </ClientOnly>
        </div>
      </a-drawer>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useShareProject } from '@/stores/shareProject';
const route = useRoute();
const { sensorEditorClick } = useSensors();
const useShareProjectStore = useShareProject();
const currentStepList = computed(() => useShareProjectStore.currentStepList); // 当前可显示的步骤列表
const currentStepId = computed(() => useShareProjectStore.currentStepId); // 当前选中的步骤id
const currentIdx = computed(() => currentStepList.value.findIndex((item: any) => item.id === currentStepId.value)); // 当前选中的步骤索引
const changeStep = (item: any) => {
  const { title } = item;
  const { mode, id } = route.query;
  sensorEditorClick({
    element_name: title,
    sevice_type: 'project',
    content_type: String(mode),
    content_id: Number(id),
  });

  onClose();
  nextTick().then(() => {
    const layout = document.querySelector('#project-layout') as HTMLElement;
    const currentId = document.getElementById(item.id) as HTMLElement;

    window.scrollTo({
      top: currentId.offsetTop + layout.offsetTop - 32,
      behavior: 'smooth',
    });
    setTimeout(() => {
      useShareProjectStore.changeStep(item.id);
    }, 1000); // 1000ms后再改变状态，防止高亮被滚动影响
  });
};
const showMbDrawer = ref(false);
const onClose = () => {
  showMbDrawer.value = false;
};
</script>

<style scoped lang="less">
.step-wrap {
  position: relative;
  width: 170px;
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: rgba(26, 26, 26, 0.1);
    border-radius: 4px;
    overflow: hidden;
  }
  .highlight {
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 23px;
    background-color: #1a1a1a;
    transition: all 0.3s;
  }
}
.step {
  .text {
    color: theme('colors.text.tertiary');
    &:hover {
      color: theme('colors.text.primary');
      font-weight: theme('fontWeight.semibold');
    }
  }
}

.current-step {
  .text {
    color: theme('colors.text.primary');
    font-weight: theme('fontWeight.semibold');
  }
}
.step-icon {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}
</style>
<style lang="less">
.create-mb-step {
  z-index: 1005;
  &.ant-drawer-content {
    border-radius: 16px 16px 0 0;
    .ant-drawer-body {
      padding: 0 16px;
    }
  }
}
</style>
