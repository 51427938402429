import { useReportData } from '~/stores/reportData';

// 所有页面离开时的情况
export const usePageLeave = () => {
  if (import.meta.server) {
    return;
  }
  const router = useRouter();
  const reportDataStore = useReportData();
  // 页面不可见时
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      // console.log('page hidden');
      reportDataStore.report();
    }
  };

  // 页面卸载时
  const handleBeforeUnload = () => {
    reportDataStore.report({
      isReportAll: true,
      immediate: true,
    });
    // console.log('page unload');
  };

  // 地址改变时
  const handlePopState = () => {
    // console.log('url change');
    reportDataStore.report(true);
  };

  // 路由改变时
  const handleRouterChange = () => {
    // console.log('router change');
    reportDataStore.report(true);
  };

  // 在插件卸载时移除事件监听器，防止内存泄漏
  onMounted(() => {
    router.afterEach(handleRouterChange);
    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('popstate', handlePopState); // 确保 popstate 事件也被注册
  });

  onBeforeUnmount(() => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('popstate', handlePopState); // 确保 popstate 事件也被移除
  });
};
