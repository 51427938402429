<template>
  <a-form-item name="licenseId">
    <template #label>
      <div class="flex items-center gap-2 text-level-2 font-semibold">
        <span>License</span>
        <a-tooltip v-if="!forSale" arrow-point-at-center>
          <template #title>Set your default license in【Creator Center - Copyright】</template>
          <icon-font type="icon-question-line" class="text-text-tertiary hover:text-black-hover" />
        </a-tooltip>
      </div>
    </template>
    <div v-if="isPrimeCreator" class="mb-4">
      <a-radio-group :value="forSale" :disabled="saleTypeDisabled" @change="changePaymentType">
        <a-radio :value="false">
          <span class="text-level-2">Community Projects</span>
        </a-radio>
        <a-radio :value="true"><span class="text-level-2">Premium Projects</span></a-radio>
      </a-radio-group>
    </div>
    <div
      v-if="!licenseId"
      class="flex cursor-pointer items-center justify-between rounded-lg bg-background-2 px-4 py-3 text-level-2 text-text-tertiary"
      @click="handleOpenChooseModal"
    >
      <span>Please choose a license</span>
      <icon-font type="icon-a-30-arrow-right-s-line" />
    </div>
    <div v-else>
      <div
        :class="forSale ? 'cursor-not-allowed' : 'cursor-pointer'"
        class="flex items-center justify-between rounded-md bg-background-3 px-4 py-3"
        @click="handleOpenChooseModal"
      >
        <div class="flex flex-col">
          <div class="mt-2 flex items-center">
            <img :src="getOssProcessedImage(selectLicense.extra?.icon, 'm_fill,w_90,h_32', 'webp')" width="90" height="32" />
            <span class="ml-3 text-level-2">{{ selectLicense.code }}</span>
          </div>
          <span class="mt-2 text-level-7 text-uni-3">{{ selectLicense?.extra?.copywriting }}</span>
        </div>
        <icon-font type="icon-a-30-arrow-right-s-line" />
      </div>
      <div v-if="selectLicense?.extra?.link" class="mt-2 flex items-center justify-start text-level-4 text-uni-3">
        <icon-font type="icon-License" class="text-xl leading-3 mobile:text-base" />
        <span :class="'ml-1 cursor-pointer underline hover:text-uni-3 hover:underline'" @click="handleGoToLicense(selectLicense?.extra?.link)">
          Read about license
        </span>
      </div>
    </div>
  </a-form-item>
  <a-modal v-model:open="licenseModal" title="Please choose a license" width="800px" :body-style="{ paddingTop: 0 }" :footer="null">
    <div class="flex h-[350px] flex-col overflow-y-scroll">
      <div
        v-for="item in freeLicenseList"
        :key="item.id"
        class="license-item"
        :class="item.id === licenseId ? 'license-item__select' : ''"
        @click="handleSelect(item.id)"
      >
        <span v-if="!isMobile" class="text-level-2 font-bold text-uni-7 underline" @click.stop="openLink(item?.extra?.link)">
          {{ item.code }}
        </span>
        <div class="mt-4 flex items-center">
          <img :src="getOssProcessedImage(item.extra?.icon, 'm_fill,w_180,h_64', 'webp')" width="180" height="64" />
          <span v-if="!isMobile" class="ml-4 text-level-7 text-uni-3">{{ item?.extra?.copywriting }}</span>
          <span v-else class="ml-4 text-level-2 font-bold text-uni-7 underline" @click.stop="openLink(item?.extra?.link)">{{ item.code }}</span>
        </div>
      </div>
    </div>

    <div class="relative mt-5 flex items-center justify-center mobile:flex-col">
      <df-btn type="text" class="absolute left-0 text-level-2 text-uni-2 mobile:relative" @click="handleAssistOpen">How to choose a license?</df-btn>
      <df-btn :disabled="!licenseId" type="primary" class="w-[130px]" @click="handleOk">ok</df-btn>
    </div>
  </a-modal>
  <a-modal v-model:open="assistModal" title="How to choose a license?" :footer="null" :body-style="{ paddingTop: 0 }">
    <div v-for="(item, idx) in questions" :key="idx" class="mb-8">
      <div class="mb-4 text-level-2 font-semibold">{{ item.que }}</div>
      <a-radio-group v-model:value="results[idx]">
        <a-radio v-for="ans in item.answer" :key="ans" :value="ans">
          {{ ans }}
        </a-radio>
      </a-radio-group>
    </div>
    <div class="flex items-center justify-center gap-4 mobile:flex-col mobile:gap-2">
      <df-btn class="w-[130px]" @click="handleAssistCancel">Cancel</df-btn>
      <df-btn type="primary" class="w-[130px]" :disabled="!assistLicenseId" @click="handleAssistDone">Done</df-btn>
    </div>
  </a-modal>
</template>
<script setup lang="ts">
import { useUserInfo } from '@/stores/user';
import { LicenseCodeMap, LicenseExtraQuestionMap, LicenseQuestionsMap } from '../../const';
import { SourceType } from '../../interface';

const route = useRoute();
const { id } = route.query;
const isEdit = !!id;

type EmitType = {
  (event: 'handleAllowLicense', ids: number[]): void;
};
const emits = defineEmits<EmitType>();

const props = defineProps<{
  isPublished: boolean;
  source: SourceType;
  defaultLicenseId: number;
  licenseList: any[];
}>();
const { licenseList } = toRefs(props);
const saleTypeDisabled = computed(() => props.isPublished || props.source === SourceType.Remix);
watchEffect(() => {
  if (props.source === SourceType.Remix && forSale.value) {
    forSale.value = false;
    licenseId.value = props.defaultLicenseId;
  }
});

const { isMobile } = useScreen();
const userInfoStore = useUserInfo();
const isPrimeCreator = computed(() => userInfoStore.isSaleMaker);
const forSale = defineModel('forSale', { type: Boolean });
const licenseId = defineModel('licenseId', { type: Number });
watch(
  isPrimeCreator,
  () => {
    if (!isPrimeCreator.value && !isEdit) {
      // 非付费用户，改为免费案例
      forSale.value = false;
    }
  },
  { immediate: true },
);
const assistLicenseId = ref<number>(0);
const licenseModal = ref(false); // 选择弹窗
const assistModal = ref(false); // 版权选择帮助弹窗
let saleLicenseId = ref(0);
const selectLicense = computed(() => {
  // 根据付费状态自动调整 licenseId
  if (forSale.value) {
    // 付费项目必须使用付费 license
    licenseId.value = saleLicenseId.value;
  } else if (saleLicenseId.value === licenseId.value) {
    // 免费项目不能使用付费 license
    licenseId.value = 0;
  }
  const licenseItem = licenseList.value.find((item: any) => item.id === licenseId.value);
  // 返回当前选中的 license 信息
  return licenseItem ?? {};
});
const freeLicenseList = computed(() => licenseList.value.filter((item: any) => !item.extra.forSale));
const results = ref([null, null, null]);
const questions = ref([...LicenseQuestionsMap]);

watch(
  () => results.value,
  () => {
    // 更改选项后，根据答案过滤问题，获取licenseId
    if (results.value.filter((item) => !!item).length < 2) {
      return;
    }
    assistLicenseId.value = 0;
    // 目前问卷由前面两个问题影响第三个问题，通过前两个问题映射是否需要显示第三个问题，如果切换要隐藏第三个问题，需要把之前的第三个数据清调
    const resTwoKey = results.value.slice(0, 2).join('-');
    const extraFind = LicenseExtraQuestionMap.find((item) => item.logic.join('-') === resTwoKey);
    if (extraFind) {
      questions.value = [...LicenseQuestionsMap, ...[extraFind]];
    } else {
      questions.value = [...LicenseQuestionsMap];
      results.value[2] = null;
    }
    // 根据选择的结果获取licenseId, 通过codeMap映射
    const resKey = results.value.filter((item) => !!item).join('-');
    const codeFind = LicenseCodeMap.find((item) => item.logic.join('-') === resKey);
    if (codeFind) {
      assistLicenseId.value = licenseList.value.find((item: any) => item?.code === codeFind.code)?.id ?? 0;
    }
  },
  { deep: true },
);
const handleAssistDone = () => {
  licenseId.value = assistLicenseId.value;
  assistModal.value = false;
};

const handleSelect = (id: number) => {
  licenseId.value = id;
};
const getSaleLicense = () => {
  saleLicenseId.value = licenseList.value?.filter((item: any) => item.extra.forSale)?.[0]?.id ?? 0;
};
const handleGoToLicense = (link: string) => {
  if (!link) {
    return;
  }
  window.open(link, '_blank');
};
watch(licenseList, () => {
  const allowLicenseIds = licenseList.value.filter((item: any) => item.extra.supportRemix === true).map((item: any) => item.id);
  getSaleLicense();
  initLicenseId();
  emits('handleAllowLicense', allowLicenseIds);
});
const initLicenseId = () => {
  if (isEdit) {
    // 编辑模式不用初始化
    return;
  }
  resetLicense();
};
const handleOk = () => {
  licenseModal.value = false;
};

const changePaymentType = () => {
  const content = forSale.value ? 'Switch to a Community Project?' : 'Switch to a Premium Project?';
  Modal.confirm({
    content,
    okText: 'OK',
    onOk: () => {
      forSale.value = !forSale.value;
      resetLicense();
    },
  });
};
const resetLicense = () => {
  nextTick(() => {
    licenseId.value = forSale.value ? saleLicenseId.value : props.defaultLicenseId;
  });
};
watch(
  () => props.defaultLicenseId,
  () => {
    !licenseId.value && resetLicense();
  },
);

const handleOpenChooseModal = () => {
  if (forSale.value) {
    return;
  }
  licenseModal.value = true;
};

const handleAssistOpen = () => {
  licenseModal.value = false;
  assistModal.value = true;
};
const handleAssistCancel = () => {
  assistModal.value = false;
  licenseModal.value = true;
};
const openLink = (link: string) => {
  if (!link) {
    return;
  }
  window.open(link, '_blank');
};
</script>
<style scoped lang="less">
.license-item {
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 16px;
  background-color: #f5f7fb;
  cursor: pointer;
  &__select {
    border: 2px solid #000000;
  }
}
</style>
