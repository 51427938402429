// 生成图片方式对应的方法名字典配置，用于前端和后台进行映射逻辑
export const generateMethodMap = {
  laserart: 'LaserArt', // 激光艺术
  vectorgraphics: 'VectorArt', // 矢量
  imageTextMix: 'ImageTextMix', // 图文混排
  artword: 'CreativeText', // 艺术字
  handpainted: 'FaceSketch', // 肖像线稿
  relief: 'Relief', // 浮雕
  artimage: 'Artistic', // 艺术图像 MJ使用，暂不支持
  screenart: 'ScreenPrintArt', // 丝网
  groupphoto: 'GroupPortraitToSketch', // 合影
};

// 生成图片方式对应页面文案的字典配置, 用于页面展示
export const generateMethodTextMap = {
  [generateMethodMap.relief]: 'Embossment',
  [generateMethodMap.handpainted]: 'Single portrait to sketch',
  [generateMethodMap.groupphoto]: 'Group portrait to sketch',
};

// 首页生成工具列表
export const homeMethodList = [
  {
    icon: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/common/faceMethod.png',
    name: generateMethodTextMap[generateMethodMap.handpainted],
    key: generateMethodMap.handpainted,
    desc: 'Transform single portraits into sketches for laser processing',
  },
  {
    icon: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/common/groupPhotoMethod.jpg',
    name: generateMethodTextMap[generateMethodMap.groupphoto],
    key: generateMethodMap.groupphoto,
    desc: 'Transform group portraits into sketches for laser processing',
  },
  {
    icon: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/common/reliefMethod.png',
    name: generateMethodTextMap[generateMethodMap.relief],
    key: generateMethodMap.relief,
    desc: 'Generating a depth map for Embossment processing',
    label: 'For F1 Ultra',
  },
];

// 热门列表
export const homePopularList = [
  {
    name: 'No idea? Try random prompts!',
    name_zh: '',
    style: '2D graphics',
    scene: generateMethodMap.vectorgraphics,
    img_a: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular6_a.jpg',
    type: '',
  },
  {
    name: 'Make fall quotes designs',
    name_zh: '',
    style: 'Classic quote',
    scene: generateMethodMap.imageTextMix,
    img_a: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular7_a.jpg',
    type: 'Classic quote',
  },
  {
    name: 'Customize your own logo',
    name_zh: '创意logo',
    style: 'Creative logo',
    scene: generateMethodMap.imageTextMix,
    img_a: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular1_a.jpg',
    img_b: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular1_b.jpg',
    type: '',
    pos: { 'scale': 2.34, 'y': -0.4, 'x': -1 },
  },
  {
    name: 'Create 3D effect laser work',
    name_zh: '浅浮雕',
    style: 'Low relief',
    scene: generateMethodMap.laserart,
    img_a: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular2_a.jpg',
    img_b: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular2_b.jpg',
    type: '3D effect',
    pos: { 'scale': 2.1, 'y': 1.5, 'x': 0 },
  },
  {
    name: 'Convert image to depth map',
    name_zh: '深浮雕',
    style: '',
    scene: generateMethodMap.relief,
    img_a: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular4_a.jpg',
    img_b: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular4_b.jpg',
    type: 'Embossment',
    pos: { 'scale': 2.2, 'y': -5.5, 'x': 4 },
  },
  {
    name: 'Make laser art',
    name_zh: '几何线条',
    style: 'Geometric line',
    scene: generateMethodMap.laserart,
    img_a: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular3_a.jpg',
    img_b: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular3_b.jpg',
    type: 'Monochrome',
    pos: { 'scale': 2.05, 'y': 14, 'x': 1 },
  },
];

// 热门列表icon
export const homePopularIcon = 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/common/popular_icon.png';

// 历史记录失败状态文案映射
export const historyErrorTipsMap = {
  '1000':
    'Sorry, image generation failed because no face is recognized. The credits have been refunded. Please upload a portrait photo and try again.',
  'server': 'Sorry, image generation failed. The credits have been refunded. Please try again.',
} as any;

// 异常页面的场景配置映射
export const errorPageSceneConfigMap = {
  // 新人引导
  guide: {
    title: 'How it works ?',
    icon: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/generate/guide_icon.png',
    list: [
      {
        title: '1. Select a style',
        desc: 'Select a style according to your machine, such as a style about blade-cutting or that about screen printing.',
        img: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/generate/guide_1.png',
      },
      {
        title: '2. Enter a prompt',
        desc: 'Enter description for your desired image. You can click the dice icon for more inspiration.',
        img: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/generate/guide_2.png',
      },
      {
        title: '3. Generate an image',
        desc: 'Go ahead and generate images! If you dislike the image, dispose of it and get a credit refund.',
        img: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/generate/guide_3.png',
      },
    ],
  },
  // 网络异常
  network: {
    img: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/common/network_error.png',
    imgSize: { w: 100, h: 100 },
    desc: ['Network abnormality, please check the network connection'],
    button: 'Refresh',
  },
  // 服务异常
  server: {
    img: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/common/server_error.png',
    imgSize: { w: 150, h: 100 },
    desc: ['The server is out of service, please try again later.'],
    button: 'Reload',
  },
  // 未登录
  login: {
    img: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/common/login_error.png',
    imgSize: { w: 150, h: 100 },
    desc: ['You can view your image generation history after logging in.'],
  },
  // 历史记录为空
  historyEmpry: {
    img: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/common/empty_error.png',
    imgSize: { w: 100, h: 100 },
    desc: ["You haven't used the image generation function before.", "Click 'Generate' to have a try."],
  },
} as any;

// 历史记录状态映射
export const historyStatusMap = {
  line: 1, // 排队中
  pending: 2, // 生成中
  success: 3, // 成功
  fail: 4, // 失败
};

// 教程文档映射
export const helpIconUrlMap = {
  [generateMethodMap.laserart]: 'https://support.xtool.com/article/869',
  [generateMethodMap.artimage]: 'https://support.xtool.com/article/869',
  [generateMethodMap.artword]: 'https://support.xtool.com/article/1678',
  [generateMethodMap.imageTextMix]: 'https://support.xtool.com/article/1679',
  [generateMethodMap.handpainted]: 'https://support.xtool.com/article/1309?from=search&q=AImake%20face',
  'def': 'https://support.xtool.com/article/1679',
};
