<template>
  <div class="comment-user-info flex gap-2">
    <div class="uname cursor-pointer text-level-2 font-bold text-uni-1 mobile:text-level-4" :title="answer.createdByName" @click="handleClick">
      {{ answer.createdByName }}
    </div>
    <span
      v-if="isAuthor"
      title="Author"
      :class="'inline-flex h-6 rounded-sm bg-[rgba(255,124,35,0.10)] px-2 text-[14px] font-semibold leading-6 text-status-alert mobile:text-[12px]'"
    >
      Author
    </span>
    <a-tooltip :title="timeTitle">
      <span class="comment-date text-level-4 text-text-tertiary mobile:whitespace-nowrap mobile:text-[12px]">{{ timeText }}</span>
    </a-tooltip>
  </div>
</template>

<script lang="ts" setup>
import { useUserInfo } from '@/stores/user';
import type { ItemType } from '@/types/common';
import moment from 'moment';

type PropsType = {
  objectDetail: ItemType;
  answer: any;
};
const props = defineProps<PropsType>();

const useUserInfoStore = useUserInfo();
const router = useRouter();

const isAuthor = computed(() => props.objectDetail?.createdBy === props.answer.createdBy);
const time = computed(() => props.answer.createdAt * 1000);
const timeTitle = computed(() => moment(time.value).format('YYYY-MM-DD HH:mm'));
const timeText = computed(() => moment(time.value).startOf('minute').fromNow());

const handleClick = () => {
  if (props.answer.createdBy === useUserInfoStore.myInfo.id) {
    router.push({
      path: '/user-center',
      query: {
        active: 'projects',
      },
    });
  } else {
    router.push(`/profile/${props.answer.createdBy}`);
  }
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 425px) {
  .uname {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
