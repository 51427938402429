const prefix = 'AImake_';
const common_data = {
  item_type: 'AImake',
};

export const useSensors = () => {
  const { $sensors: sensors } = useNuxtApp();

  // 落地页 -> 进入首页点击
  const sensorClickIndexGenerate = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'homeEnter',
      ...data,
    });
  };

  // 落地页 -> 卡片点击
  const sensorClickMarketingCardItem = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'homeCardEnter',
      ...data,
    });
  };

  // 首页 -> 生成方法Tab点击
  const sensorClickHomeStyle = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'styleEnter',
      ...data,
    });
  };

  // 热门project点击
  const sensorClickPopularProject = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'popularProjectEnter',
      ...data,
    });
  };

  // 首页 -> 热门推荐点击
  const sensorClickPopularItem = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'popularEnter',
      ...data,
    });
  };

  // 首页 -> 菜单点击
  const sensorClickNavItem = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'homeNav',
      ...data,
    });
  };

  // 首页 -> 查看全部style
  const sensorClickHomeShowAll = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'showAll',
      ...data,
    });
  };

  // 生成页 -> 切换style
  const sensorClickGenerateStyleChange = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'styleChange',
      ...data,
    });
  };

  // 点击历史记录-图片预览
  const sensorClickHistoryImage = (data: any) => {
    sensors.track(`${prefix}pictureClick`, {
      ...common_data,
      source: 'pictureGenerated',
      ...data,
    });
  };

  // 历史记录 -> 点击下载至本地
  const sensorClickHistoryDownload = (data: any) => {
    sensors.track(`${prefix}saveComputer`, {
      ...common_data,
      ...data,
    });
  };

  // 历史记录 -> 点击导出画布
  const sensorClickHistoryExport = (data: any) => {
    sensors.track(`${prefix}importCanvas`, {
      ...common_data,
      ...data,
    });
  };

  // 历史记录 -> 点击去背景
  const sensorClickHistoryRemoveBack = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      elementId: 'historyRemoveBack',
      ...common_data,
      ...data,
    });
  };

  // 历史记录 -> 点击预览3d
  const sensorClickHistory3dView = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: '3dView',
      ...data,
    });
  };

  // 历史记录 -> 加载更多
  const sensorGetHistoryMore = (data: any) => {
    sensors.track(`${prefix}pageFlip`, {
      ...common_data,
      source: 'history',
      ...data,
    });
  };

  // 历史记录 -> 点赞
  const sensorClickHistoryLike = (data: any) => {
    sensors.track(`${prefix}postLike`, {
      ...common_data,
      ...data,
    });
  };

  // 历史记录 -> 删除
  const sensorClickHistoryDelete = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'historyDelete',
      ...data,
    });
  };

  // 历史记录 -> 销毁图片
  const sensorClickHistoryDestroy = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'destruction',
      ...data,
    });
  };

  // 历史记录 -> 参加活动
  const sensorClickHistoryContest = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'shareToDesignFind',
      ...data,
    });
  };

  // 历史记录 -> 重新生成
  const sensorClickHistoryAgain = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'generateAgain',
      ...data,
    });
  };

  // 历史记录 -> 去背景
  const sensorClickRemoveBack = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'historyRemoveBack',
      ...data,
    });
  };

  const sensorClickContestTips = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'aicontestTips',
      ...data,
    });
  };

  // 点击头部购买积分按钮
  const sensorClickHeaderBuy = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'creditsBuy',
      ...data,
    });
  };

  // 生成图片
  const sensorClickGenerate = (data: any) => {
    sensors.track(`${prefix}generate`, {
      ...common_data,
      ...data,
    });
  };

  // 生成图片结果
  const sensorClickGenerateResult = (data: any) => {
    sensors.track(`${prefix}generateResult`, {
      ...common_data,
      ...data,
    });
  };

  // 点击随机提示词
  const sensorClickRandomPrompt = (data: any) => {
    sensors.track(`${prefix}shufflePromptClick`, {
      ...common_data,
      ...data,
    });
  };

  // 点击help图标
  const sensorClickHelp = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'help',
      ...data,
    });
  };

  // 点击积分规则
  const sensorClickPointsRules = () => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'pointRules',
    });
  };

  // 点击获取更多积分
  const sensorClickMoreCredits = (data: any) => {
    sensors.track(`${prefix}buttonClick`, {
      ...common_data,
      elementId: 'moreCredits',
      ...data,
    });
  };

  // 订阅弹窗曝光
  const sensorExposeSubsModal = (data: any) => {
    sensors.track(`${prefix}dialogShow`, {
      ...common_data,
      ...data,
    });
  };

  // 订阅弹窗曝光
  const sensorDialogOperation = (data: any) => {
    sensors.track(`${prefix}dialogOperation`, {
      ...common_data,
      ...data,
    });
  };

  // 页面曝光
  const sensorPageView = (data: any) => {
    sensors.track(`${prefix}pageView`, {
      ...common_data,
      ...data,
    });
  };

  return {
    sensors,

    sensorPageView,
    sensorClickContestTips,
    sensorClickHistoryAgain,
    sensorClickHeaderBuy,
    sensorClickHistoryContest,
    sensorClickHistoryDestroy,
    sensorClickIndexGenerate,
    sensorClickNavItem,
    sensorDialogOperation,
    sensorClickHomeStyle,
    sensorClickPopularItem,
    sensorClickPopularProject,
    sensorClickMarketingCardItem,
    sensorClickHomeShowAll,
    sensorClickGenerateStyleChange,
    sensorClickHistoryImage,
    sensorClickHistoryDownload,
    sensorClickHistoryRemoveBack,
    sensorClickHistoryExport,
    sensorClickHistory3dView,
    sensorGetHistoryMore,
    sensorClickHistoryLike,
    sensorClickHistoryDelete,
    sensorClickGenerate,
    sensorClickGenerateResult,
    sensorClickRandomPrompt,
    sensorClickHelp,
    sensorClickPointsRules,
    sensorExposeSubsModal,
    sensorClickMoreCredits,
    sensorClickRemoveBack,
  };
};
