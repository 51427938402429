<template>
  <div class="comment m-auto max-w-[900px] pt-8 pad:px-5 mobile:px-5">
    <div class="anchor-page-comments">
      <div v-if="isPublished" class="comment-wrapper">
        <div class="comment-totals text-[20px] font-bold mobile:text-[16px]">
          <span>Comments</span>
          <span v-if="commentCount > 0">({{ commentCount }})</span>
        </div>

        <div class="comment mt-4">
          <div class="comment-input">
            <a-button v-if="commentId" :size="'large'" :style="{ width: '100%', marginBottom: '20px' }" @click="handleDisplayAll">
              Display all comments
            </a-button>

            <CommentTextarea
              v-else
              placeholder="Add a comment to this project"
              is-reset
              :filter-image-object="filterImageObject"
              :comment-object="commentObject"
              :object-type="OBJECT_TYPE.MAKING"
              :commont-type="OBJECT_TYPE.MAKING"
              @change="handleCommentChange"
              @change-filter="handleFilterStatus"
            />

            <CommentImageFilter v-if="!commentId && hasComment && hasAtLeastOneImage" ref="filterImageRef" @handle-change="filterImage" />

            <CommentItem
              v-if="hasComment"
              :is-reset="false"
              :filter-image-object="filterImageObject"
              class="detail-comment"
              :object-detail="detail"
              :answer="commentObject"
              :comment-list="commentObject.commentList"
              @change-filter="handleFilterStatus"
              @update-comment-list="hanldeGetCommentList"
            />
          </div>
        </div>
      </div>

      <!-- 空白占位 -->
      <div v-if="commentListLen <= 0" class="mt-12 flex flex-col items-center gap-4 pb-12 mobile:mt-8 mobile:gap-2 mobile:pb-6">
        <img class="w-[140px] mobile:w-[80px]" :src="EmptyImg" alt="" />
        <span class="text-[14px] text-[#999] mobile:text-[12px]">No comments yet.</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';
import { getCommentListApi, getTagCommentApi } from '~/api/common';
import EmptyImg from '~/assets/img/df-ip/comments.png';
import { OBJECT_TYPE } from '~/types';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const isPublished = computed(() => detail.value.status === 'published');
const contentType = computed(() => detail.value.type);
const commentCount = computed(() => detailsStore.commentCount);
// 判断是否显示空白占位
const commentListLen = computed(() => commentObject.value.commentList.length);
const hasComment = computed(() => commentObject.value.commentList.length > 0);
const hasAtLeastOneImage = computed(() => {
  return commentObject.value.commentList.some((item) => {
    if (item.hasImage) {
      return true;
    }
    if (item.subComments && item.subComments.length > 0) {
      return item.subComments.some((child: any) => child.hasImage);
    }
    return false;
  });
});

// 如果有评论 id，会筛选对应的评论，点击跳转到完整的详情页
const route = useRoute();
const commentId = Number(route.params.commentId);
function handleDisplayAll() {
  navigateTo(`/${contentType.value}/${detail.value.id}`);
}

const commentObject = ref({
  objectType: OBJECT_TYPE.MAKING,
  id: detail.value.id,
  userComment: '',
  commentList: [] as any[],
});

function handleCommentChange(value: string) {
  Object.assign(commentObject.value, {
    userComment: value,
  });
}

const filterImageRef = ref<any>(null);
function handleFilterStatus(hasImages: boolean) {
  setTimeout(() => {
    if (filterImageRef.value) {
      filterImageObject.value.filterHasImage = hasImages;
      filterImageRef.value.setActive(hasImages ? 'images' : 'all');
    }
  }, 500);
}

const filterImageObject = ref({
  hasImages: false, // 查找所有评论，是否有图片
  filterHasImage: false,
});

const filterImage = (type: boolean) => {
  filterImageObject.value.filterHasImage = type;
  handleFilterStatus(type);
  getCommentList();
};
async function getCommentList() {
  if (detail.value.id) {
    if (commentId && !isNaN(commentId)) {
      await nextTick();
      const { data: resp } = await getTagCommentApi(commentId);
      const { code, data } = resp.value;
      if (code === 0) {
        commentObject.value.commentList = data ? [data] : [];
      }
    } else {
      await nextTick();
      const { data: resp } = await getCommentListApi({
        objectType: OBJECT_TYPE.MAKING,
        objectId: detail.value.id,
        filterHasImage: filterImageObject.value.filterHasImage,
        pageSize: -1,
        current: 1,
      });
      const { data } = resp.value;
      commentObject.value.commentList = data.list || [];
      detailsStore.updateCommentCount(data.countCommentDetails);
      if (commentObject.value.commentList.length <= 0 && filterImageObject.value.filterHasImage) {
        filterImageObject.value.filterHasImage = false;
        getCommentList();
      }
    }
  }
}
function hanldeGetCommentList() {
  getCommentList();
}

onMounted(() => {
  getCommentList();
});
</script>

<style lang="less" scoped></style>
