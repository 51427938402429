<template>
  <div class="h-full">
    <template v-if="hasLibraryFile">
      <div class="flex h-full flex-col">
        <div class="mt-4 flex gap-3" @click.stop>
          <a-upload
            v-model:file-list="uploadFileList"
            name="file"
            :show-upload-list="false"
            :accept="accept"
            :multiple="true"
            :custom-request="() => {}"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <df-btn class="flex-1" type="primary">Upload Media</df-btn>
          </a-upload>

          <df-btn class="flex-1" @click="uploadVideo">URL</df-btn>
        </div>
        <div class="pt-4 text-center text-level-7 text-text-tertiary">Try dragging images or videos into the upload area</div>
        <div class="flex-1 overflow-y-scroll" @click.stop>
          <draggable
            class="mt-4 flex flex-wrap justify-start gap-2"
            :list="allFileList"
            :group="dragGroup"
            :animation="200"
            item-key="uid"
            @click.stop
            @start="onDragStart"
            @end="onDragEnd"
          >
            <template #item="{ element }">
              <div @click.stop>
                <ImgInfo
                  :data="element"
                  :width="96"
                  :height="96"
                  @delete="libraryDeleteImg(element)"
                  @preview="handlePreview(element)"
                  @preview-video="handlePreviewVideo(element)"
                />
              </div>
            </template>
          </draggable>
        </div>
        <div class="mt-3 h-6 text-level-7 text-text-tertiary">Try dragging images or videos into the upload area</div>
      </div>
    </template>
    <template v-else>
      <Empty :accept="accept" />
    </template>

    <client-only>
      <CropCover ref="cropRef" @finish="handleFinishCrop" />
    </client-only>
    <client-only>
      <VideoPlayer ref="videoRef" />
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { beforeUpload, handleSensorEditClick } from '@/components/share/utils';
import { type UploadChangeParam, type UploadFile } from 'ant-design-vue';
import draggable from 'vuedraggable';
import CropCover from '../../CropCover.vue';
import ImgInfo from '../../ImgInfo.vue';
import VideoPlayer from '../../VideoPlayer.vue';
import { useLibrary } from '../useLibrary';
import Empty from './Empty.vue';
defineProps<{
  dragGroup: any;
  accept: string | undefined;
}>();
const { handleUploadFile } = useUpload();
const { onDragStart, onDragEnd, libraryDeleteImg, allFileList, uploadFileList, libraryUploadVideo, libraryUploadMedia } = useLibrary();
const hasLibraryFile = computed(() => !!allFileList.value.length);
const cropRef = ref();
const videoRef = ref();
const handlePreview = (element: any) => {
  cropRef.value?.open(element);
};
const asyncPool = new AsyncPool(6);
const handleFinishCrop = (blobData: Blob, file: UploadFile) => {
  let cropFile: any = {};
  cropFile = allFileList.value.find((item: any) => item.uid === file.uid);
  Object.assign(cropFile, { url: fileToBlob(blobData), percent: 0 });
  asyncPool.run(async () => {
    let contentDisposition = '';
    await handleUploadFile({
      file: blobData,
      contentDisposition,
      onProgress: (percent: number) => {
        Object.assign(cropFile, { percent });
      },
      onFinish: (url: string) => {
        Object.assign(cropFile, { url });
      },
      onError: () => {},
    });
  });
};
const handlePreviewVideo = (element: any) => {
  if (element?.videoUrl) {
    videoRef.value?.open(element.videoUrl, element.name);
  }
};
const handleChange = (info: UploadChangeParam<UploadFile>) => {
  if (!info.file.status) {
    return false;
  }
  info.fileList.length && libraryUploadMedia(info);
  handleSensorEditClick({ name: 'All Media Upload URL' });
};
const uploadVideo = () => {
  libraryUploadVideo();
  handleSensorEditClick({ name: 'All Media Upload Media' });
};
</script>
