<template>
  <div class="block-card-item-wrapper h-full w-full">
    <NuxtLink :to="nuxtLinkUrl">
      <div class="block-card-item group/block-card flex h-full w-full rounded-xl bg-[#fff] px-2 pb-4 pt-2">
        <div
          :id="`blockSingle-${makingItem.id}`"
          :key="makingItem.id"
          class="group/single-item block-single-item relative h-full w-full"
          :data-sensors-exposure-property-content_id="makingItem.id"
          :data-sensors-exposure-property-content_name="makingItem.title"
          :data-sensors-exposure-property-content_show_type="makingItem.type"
          :data-sensors-exposure-property-content_tag="(makingItem as any)?.[tagsField] || []"
          :data-sensors-exposure-property-publish_time="makingItem.createdAt"
          :data-sensors-exposure-property-author_name="makingItem.createdByName"
          :data-sensors-exposure-property-show_source="locationHref"
          :data-sensors-exposure-property-block_id="blockId"
          :data-sensors-exposure-property-block_name="blockTitle"
          :data-sensors-exposure-property-service_type="makingItem.type"
          :data-sensors-exposure-property-content_type="makingItem.forSale ? 'paid' : 'free'"
          :data-sensors-exposure-property-algorithm_type="makingItem.recommend?.strategy ?? null"
          :data-sensors-exposure-property-algorithm_version="makingItem.recommend?.version ?? null"
          @click="handleCheckMakings(makingItem)"
        >
          <!-- 活动投稿页-右上角勾勾 -->
          <RadioCheck v-if="postCheck" class="absolute right-0.5 top-0.5 z-[11] inline-flex" :making-item="makingItem" />

          <div class="block-top-wrap group/cover relative w-full overflow-hidden rounded-lg" :title="makingItem.title">
            <!-- 图片和蒙层 -->
            <RatioBox :padding-top="'75%'">
              <CardImg class="h-full w-full overflow-hidden rounded-[8px]" :making-item="makingItem" :disabled-lazy-load="disabledLazyLoad" />
            </RatioBox>

            <!-- 右上角操作项 -->
            <div v-if="!postCheck" class="block-card-right-extra absolute right-2 top-2 z-10 hidden justify-end group-hover/single-item:flex">
              <slot name="rightExtra" :card-info="makingItem">
                <CommonMoreActions :action-list="actionList" @command="postCommonActions" />
              </slot>
            </div>

            <!-- 价格 -->
            <div v-if="isForSale" class="absolute bottom-0 left-0 z-20 flex h-6 w-full overflow-hidden">
              <div class="price-wrap flex h-12 w-full flex-col items-end">
                <div class="inline-flex h-6 w-fit items-center gap-1 rounded-tl-lg bg-uni-0/90 px-2 text-[#fff]">
                  <div class="text-level-7 font-bold">
                    {{ isDiscount ? priceInfo.disCountPrice : priceInfo.origPrice }}
                  </div>
                </div>
                <div :class="'flex h-6 w-full items-center justify-between rounded-b-lg bg-uni-0/90 px-2'">
                  <div class="inline-flex items-center gap-1 text-[#fff]">
                    <div class="text-level-7 font-bold">
                      {{ isDiscount ? priceInfo.disCountPrice : priceInfo.origPrice }}
                    </div>
                    <div v-if="isDiscount" class="text-level-7 text-[#fff] line-through opacity-40">
                      {{ priceInfo.origPrice }}
                    </div>
                  </div>
                  <div v-if="!isXThings" class="ml-1 flex gap-1 text-level-7 font-bold text-[#FFF]">
                    <span class="text-status-alert">Credits</span>
                    <span>{{ makingItem.credits ?? 0 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 付费tag 和 title -->
          <div class="mt-2 flex items-center gap-1 px-2">
            <div v-if="isForSale && sellTagInfo?.tagName && feedbackStatus === 'deactive'">
              <img class="flex h-[22px]" :src="sellTagInfo.tagIcon" :alt="sellTagInfo.tagName" />
            </div>
            <h2 class="one-line-ellipsis mb-0 text-level-3 font-semibold text-text-primary" :title="makingItem.title">
              {{ makingItem.title }}
            </h2>
          </div>

          <div class="user-wrap block-bottom-wrap mt-2 flex h-6 items-center justify-between px-2" @click.prevent="">
            <!-- user -->
            <author-user-img
              v-if="isShowAuthor"
              class="max-w-[60%]"
              size="24px"
              img-size="24px"
              :user-id="makingItem.createdBy"
              :user-img="makingItem.createdByHeadpic"
              :user-name="makingItem.createdByName"
              :block-id="blockId"
              :block-name="blockTitle"
              :is-show-user-card="isShowUserCard"
              @click.prevent=""
            />
            <!-- 时间、审核tag -->
            <div v-else class="flex flex-1 justify-between gap-[8px]">
              <ClientOnly>
                <div class="one-line-ellipsis h-[22px] text-sm text-[#9E9E9E]">
                  {{ moment(makingItem?.publishedAt * 1000).format('YYYY/MM/DD') }}
                </div>
              </ClientOnly>
              <span
                v-if="isAudit"
                :class="[
                  'flex items-center rounded px-2 text-[12px] font-semibold pad:px-0',
                  {
                    'bg-[#FF7C23]/10 text-[#FF7C23]': BlockCardStatus.Todo === auditState,
                    'bg-[#FF4159]/10 text-[#FF4159]': BlockCardStatus.Rejected === auditState,
                    'bg-[#9E9E9E]/10 text-[#9E9E9E]': BlockCardStatus.Removed === auditState,
                  },
                ]"
              >
                {{ auditState }}
              </span>
            </div>

            <!-- 点赞和下载 -->
            <div v-if="!isAudit" class="flex gap-2">
              <div
                v-for="(item, index) in iconNumList"
                :key="index"
                class="flex items-center gap-0.5 text-text-primary"
                :title="item.type"
                @click.prevent.stop="postCommonActions(item.type)"
              >
                <ClientOnly>
                  <template v-if="item.type === 'like'">
                    <CommonLikeButton :is-liked="item.isTriggled" />
                  </template>
                  <template v-else>
                    <icon-font
                      :type="item.icon"
                      class="text-base"
                      :class="[
                        {
                          'text-text-primary hover:text-[#ff0035]': item.type === 'like' && !item.isTriggled,
                          'text-[#ff0035]': item.type === 'like' && item.isTriggled,
                        },
                      ]"
                    />
                  </template>
                </ClientOnly>
                <ClientOnly>
                  <span class="whitespace-nowrap pt-[1px] text-level-7 font-semibold">{{ item.count }}</span>
                </ClientOnly>
              </div>
            </div>
          </div>

          <Feedback v-model:status="feedbackStatus" :item="makingItem" />
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import { BlockCardStatus, type ItemType, OBJECT_TYPE, SENSORS_EVENT } from '@/types';
import moment from 'moment';
import { useReportData } from '~/stores/reportData';
import { useUserInfo } from '~/stores/user';
import { downloadZip } from '~/utils/fileZip';
import CardImg from './CardImg.vue';
import Feedback from './Feedback.vue';
import {
  actionListComputed,
  auditComputed,
  auditStateComputed,
  // deviceTagComputed,
  // materialsTagComputed,
  iconNumListComputed,
  priceComputed,
  tagComputed,
  targetLinkComputed,
} from './hooks';
import RadioCheck from './RadioCheck.vue';

type SearchOptsType = {
  keyword?: string;
  algorithmVersion?: string;
  algorithmType?: string;
  searchResultRanking?: number;
};
type PropsType = {
  makingItem: ItemType;
  actionOpts?: { showFavorites?: boolean; showFeedback?: boolean; showRemove?: boolean };
  actions?: string[]; // 右上角操作项
  postCheck?: boolean; // 是否展示复选框，层级最高
  isShowAuthor?: boolean; // 是否展示作者
  isDownload?: boolean; // 是否可以下载
  isSearch?: boolean; // 是否为搜索结果
  searchOpts?: SearchOptsType; // 搜索参数
  disabledLazyLoad?: boolean; // 禁用封面的懒加载，活动页运营要求
  blockTitle?: string; // 栏目名称
  blockId?: number; // 栏目id
  linkTarget?: '_self' | '_blank'; // 链接打开方式
  isUserCenter?: boolean; // 是否在用户中心
  isShowUserCard?: boolean; // 是否展示用户卡片
  tagsField?: string; // 标签字段
};
type EmitsType = {
  (e: 'toggleActions', type: string): void;
  (e: 'radioCheck', item: ItemType): void;
  (e: 'clickItem'): void; // for search result page
  (e: 'emitSensors', type: string): void;
};

const emits = defineEmits<EmitsType>();
const props = withDefaults(defineProps<PropsType>(), {
  isShowAuthor: true,
  onlyImg: false,
  isSearch: false,
  searchOpts: undefined,
  actionOpts: () => ({}),
  actions: () => [],
  blockTitle: '',
  blockId: undefined,
  linkTarget: '_self',
  isUserCenter: false,
  isShowUserCard: false,
  tagsField: 'contentTags',
});

const { sensorContentClickEvent, sensorForYouSeeFewerClick, sensors } = useSensors();
const reportDataSrore = useReportData();
const userInfoStore = useUserInfo();
const router = useRouter();
const route = useRoute();
const { $client } = useNuxtApp();
const { cid } = useDeviceFlags();
const { isLogin } = useLoginStatus();

const ITEM_TYPE = ref(props.makingItem.type);
const feedbackStatus = ref<any>('deactive');

// 数据统计
const iconNumList = computed(() => iconNumListComputed(props.makingItem));
// 右上角操作项
const actionList = computed(() => actionListComputed(props.makingItem, props.actionOpts));
// 是否付费
const isForSale = computed(() => props.makingItem.forSale);
// 是否 xthings
const isXThings = computed(() => props.makingItem.type === 'xthings');
// 是否有折扣
const isDiscount = computed(() => props.makingItem.hasDiscount);
// 折扣信息
const priceInfo = computed(() => priceComputed(props.makingItem));
// 付费标签
const sellTagInfo = computed(() => tagComputed(props.makingItem, props.isUserCenter));
// 跳转地址
const targetLink = computed(() => targetLinkComputed(props.makingItem, route));
// NuxtLink的to属性(用于预加载)
const nuxtLinkUrl = computed(() => {
  if (isAudit.value || props.linkTarget === '_blank' || props.postCheck) {
    return ''; // 阻止跳转
  }
  return targetLink.value;
});
// 是否处于审核状态
const isAudit = computed(() => auditComputed(props.makingItem));
// 审核状态
const auditState = computed(() => auditStateComputed(props.makingItem));

// 调用公共的操作方法
const postCommonActions = async (actionType: string) => {
  const cookie = useMiddleCookie('utoken');
  console.log(['actionType', actionType]);
  const hasCookie = Boolean(cookie.value);
  switch (actionType) {
    case 'favorite':
      if (!hasCookie) {
        goToLogin();
        return;
      }
      if (props.makingItem.userAction?.favorite > 0) {
        Modal.confirm({
          title: 'Are you sure you want to remove this project?',
          cancelText: 'Cancel',
          okText: 'Ok',
          closable: true,
          width: 550,
          onOk: async () => {
            await handleToggleAction(OBJECT_TYPE.MAKING, props.makingItem, actionType, 'block', hasCookie);
            message.success('Removed successfully.', 1);
            emits('toggleActions', actionType);
          },
        });
      } else {
        await handleToggleAction(OBJECT_TYPE.MAKING, props.makingItem, actionType, 'block', hasCookie);
        message.success('Successfully added to Favorites.', 1);
        emits('toggleActions', actionType);
      }

      break;
    case 'like':
      if (!hasCookie) {
        goToLogin();
        return;
      }
      await handleToggleAction(OBJECT_TYPE.MAKING, props.makingItem, actionType, 'block', hasCookie);
      emits('toggleActions', actionType);
      break;
    case 'download':
      if (!hasCookie) {
        goToLogin();
        return;
      }
      if (props.isDownload && props.makingItem.attachFiles.length > 0) {
        emits('emitSensors', ITEM_TYPE.value);
        const fileList = props.makingItem.attachFiles;
        downloadZip(fileList, {
          fileName: 'files',
        });
      }
      break;
    case 'feedback':
      feedbackStatus.value = 'selecting';
      const item = props.makingItem;
      sensorForYouSeeFewerClick({
        content_id: item.id + '',
        content_type: item.forSale ? 'paid' : 'free',
        content_name: item.title,
        service_type: item.type,
        element_name: 'See fewer projects like this',
      });
    case 'remove':
      if (!hasCookie) {
        goToLogin();
        return;
      }
      emits('toggleActions', actionType);
      break;
    default:
      break;
  }
};

// 登录
function goToLogin() {
  $client.openModal(() => {
    location.reload();
  });
}

// 点击卡片
const handleCheckMakings = (item: ItemType) => {
  // 有复选框的时候不跳转不弹窗, 优先级最高
  if (props.postCheck) {
    emits('radioCheck', item);
    return;
  }

  const { makingItem } = props;
  // 内容点击上报
  sensorContentClickEvent({
    content_id: makingItem.id,
    content_name: makingItem.title,
    service_type: ITEM_TYPE.value,
    publish_time: makingItem.createdAt,
    content_tag: makingItem?.contentTags || [],
    author_name: makingItem.createdByName,
    author_id: makingItem.createdBy,
    link_url: `${window.location.origin}/${ITEM_TYPE.value}/${makingItem.id}`,
    block_name: props.blockTitle,
    block_id: props.blockId,
    content_type: makingItem.forSale ? 'paid' : 'free',
    algorithm_type: makingItem.recommend?.strategy,
    algorithm_version: makingItem.recommend?.version,
  });

  // 搜索的结果点击上报
  if (props.isSearch) {
    emits('clickItem');
  }

  if (isAudit.value || props.linkTarget === '_blank') {
    window.open(targetLink.value, '_blank');
  } else {
    // 设置神策属性
    (window as any)?.df_shen?.setParams({
      latest_page_source: locationHref.value,
      latest_blockname_source: props.blockTitle,
      latest_keyword_source: props.searchOpts?.keyword,
      latest_contentid_source: makingItem.id,
      latest_contentid_algorithm_version: props.searchOpts?.algorithmVersion,
      latest_contentid_algorithm_type: props.searchOpts?.algorithmType,
    });

    router.push({
      path: targetLink.value,
      query: route.query.openFrom === 'xcs' ? { openFrom: 'xcs' } : {},
    });
  }
};

function exposeHandler() {
  const exposure = (sensors as any)?.modules?.Exposure;
  exposure.addExposureView(document.getElementById(`blockSingle-${props.makingItem.id}`), {
    eventName: SENSORS_EVENT.CASE_EXPOSURE,
    listener: {
      /**
       * 是否触发曝光事件
       * @param ele 为当前曝光的元素
       * @param properties 为曝光元素的元素信息及该元素的曝光自定义属性
       * @return boolean 触发曝光事件则返回 true / 不触发曝光事件则返回 false
       */
      shouldExpose: function () {
        // console.log('ele :>> ', ele);
        // console.log('properties :>> ', properties);
        (window as any)?.df_shen.addNewBlock(
          {
            content_id: props.makingItem.id,
            content_name: props.makingItem.title,
            content_show_type: props.makingItem.type,
            content_tag: (props.makingItem as any)?.[props.tagsField] || [],
            publish_time: props.makingItem.createdAt,
            author_name: props.makingItem.createdByName,
            show_source: locationHref.value,
            block_id: props.blockId,
            block_name: props.blockTitle,
            service_type: props.makingItem.type,
            content_type: props.makingItem.forSale ? 'paid' : 'free',
            algorithm_type: props.makingItem.recommend?.strategy || props.searchOpts?.algorithmType || undefined,
            algorithm_version: props.makingItem.recommend?.version || props.searchOpts?.algorithmVersion || undefined,
            search_result_ranking: props.searchOpts?.searchResultRanking || undefined,
            anonymous_id: isLogin.value ? userInfoStore.myInfo.id : cid.value,
            isSend: false,
            id: props.makingItem.id,
          },
          () => {
            reportDataSrore.report();
          },
        );
        return false;
      },
    },
  });
}

const locationHref = ref();
onMounted(() => {
  locationHref.value = window?.location?.pathname?.split('-')[0];
  exposeHandler();
});

onActivated(() => {
  exposeHandler();
});
</script>

<style lang="less" scoped>
.block-card-item {
  transition-property: box-shadow, transform;
  transition-duration: 0.2s;
  :deep(&:hover) {
    .img-wrapper::before {
      display: block;
    }
  }
}
.price-wrap {
  transition-property: transform;
  transition-duration: 0.2s;
}
.block-card-item-wrapper:hover {
  .block-card-item {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    transform: translateY(-4px);
  }
  .price-wrap {
    transform: translateY(-24px);
  }
}

.block-card-left-extra {
  flex: 1;
  position: relative;
  .left-extra {
    transition-property: opacity;
    transition-duration: 0.5s;
    .device-tag {
      white-space: nowrap;
    }
    .materials-tag {
      white-space: nowrap;
    }
  }
}
</style>
