import { v4 } from 'uuid';

const MAX_AGE = 60 * 60 * 24 * 365 * 5;

export function useDeviceFlags() {
  // 标记用户设备
  const cid = useCookie('cid', { maxAge: MAX_AGE });
  // cid 是否注册过
  const isRegistered = useCookie<boolean>('isRegistered', { maxAge: MAX_AGE });
  // 标记已注册
  const markRegistered = () => {
    isRegistered.value = true;
  };

  if (!cid.value) {
    // 新设备，生成新的 cid，并标记未注册
    cid.value = v4();
    isRegistered.value = false;
  } else if (getUToken()) {
    // 存在 utoken，标记已注册
    isRegistered.value = true;
  } else {
    // 不处理
    // 有 cid 但是 utoken 为空，存在两种情况：1. 未注册，2. utoken 过期
  }

  /*   console.log(`--------- Device Flags ---------
  cid:\t ${cid.value}
  isRegistered: ${isRegistered.value}
  `); */

  return {
    cid: computed(() => cid.value ?? ''),
    isRegistered: computed(() => isRegistered.value),
    markRegistered,
  };
}
