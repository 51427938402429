import { isEmpty, isNumber, isString } from 'lodash-es';

// 将数字转为千分位显示
export function formatNumberWithCommas(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function parseNum(target?: any) {
  if ((isString(target) && !isEmpty(target)) || (isNumber(target) && Number.isNaN(target))) {
    const res = Number(target);
    return Number.isNaN(res) ? undefined : res;
  }
}

export function formatNumberWithUnit(num: number): string {
  const units = [
    { value: 1e9, symbol: 'B' }, // 十亿
    { value: 1e6, symbol: 'M' }, // 百万
    { value: 1e3, symbol: 'K' }, // 千
  ];
  if (num === 0) {
    return '0';
  }
  if (num < 0) {
    return '-' + formatNumberWithUnit(Math.abs(num));
  }
  for (const { value, symbol } of units) {
    if (num >= value) {
      // 保留一位小数并去除末尾的0
      const formatted = (num / value).toFixed(1).replace(/\.0$/, '');
      return formatted + symbol;
    }
  }
  // 小于1000的数字直接返回
  return num.toString();
}
