<template>
  <div ref="$photoList" class="photo-list-wrapper">
    <!-- <div class="bg-[pink]">{{ images?.length }}张： {{ images }}</div> -->
    <a-space v-if="images?.length" direction="vertical" :size="0" style="width: 100%">
      <!-- 1张图 -->
      <a-row v-if="images.length === 1">
        <a-col id="fed" :span="24" :style="varsWhen1Items">
          <div class="relative h-full w-full">
            <img
              v-lazy="isPreview ? images[0] : getOssProcessedImage(images[0], 'm_fill,w_1500,h_1125', 'webp')"
              class="h-full w-full object-cover"
            />
            <PhotoMask @click="handlePreview(0)" />
          </div>
        </a-col>
      </a-row>

      <!-- 2张图 -->
      <a-row v-if="images.length === 2" :gutter="[1, 1]">
        <a-col ref="cols" :span="12" :style="varsWhen2Items">
          <div class="relative h-full w-full">
            <img v-lazy="isPreview ? images[0] : getOssProcessedImage(images[0], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
            <PhotoMask @click="handlePreview(0)" />
          </div>
        </a-col>
        <a-col ref="cols" :span="12" :style="varsWhen2Items">
          <div class="relative h-full w-full">
            <img v-lazy="isPreview ? images[1] : getOssProcessedImage(images[1], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
            <PhotoMask @click="handlePreview(1)" />
          </div>
        </a-col>
      </a-row>

      <!-- 3张图 -->
      <a-row v-if="images.length === 3" :gutter="[1, 1]">
        <a-col :span="16" :style="varsWhen3Items.left">
          <div class="relative h-full w-full">
            <img v-lazy="isPreview ? images[0] : getOssProcessedImage(images[0], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
            <PhotoMask @click="handlePreview(0)" />
          </div>
        </a-col>
        <a-col :span="8" :style="varsWhen3Items.right">
          <a-row :gutter="[1, 1]">
            <a-col :span="24">
              <div class="relative h-full w-full">
                <img
                  v-lazy="isPreview ? images[1] : getOssProcessedImage(images[1], 'm_fill,w_800,h_600', 'webp')"
                  class="h-full w-full object-cover"
                />
                <PhotoMask @click="handlePreview(1)" />
              </div>
            </a-col>
            <a-col :span="24">
              <div class="relative h-full w-full">
                <img
                  v-lazy="isPreview ? images[2] : getOssProcessedImage(images[2], 'm_fill,w_800,h_600', 'webp')"
                  class="h-full w-full object-cover"
                />
                <PhotoMask @click="handlePreview(2)" />
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <!-- 4张图 -->
      <a-row v-if="images.length === 4" :gutter="[1, 1]">
        <a-col :span="18" :style="varsWhenLte3Items.left">
          <div class="relative h-full w-full">
            <img v-lazy="getOssProcessedImage(images[0], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
            <PhotoMask @click="handlePreview(0)" />
          </div>
        </a-col>
        <a-col :span="6" :style="varsWhenLte3Items.right">
          <a-row :gutter="[1, 1]">
            <a-col :span="24">
              <div class="relative h-full w-full">
                <img v-lazy="getOssProcessedImage(images[1], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
                <PhotoMask @click="handlePreview(1)" />
              </div>
            </a-col>
            <a-col :span="24">
              <div class="relative h-full w-full">
                <img v-lazy="getOssProcessedImage(images[2], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
                <PhotoMask @click="handlePreview(2)" />
              </div>
            </a-col>
            <a-col :span="24">
              <div class="relative h-full w-full">
                <img v-lazy="getOssProcessedImage(images[3], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
                <PhotoMask @click="handlePreview(3)" />
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <!-- 5张图及以上 -->
      <a-space v-if="images.length >= 5" direction="vertical" style="width: 100%" :size="0">
        <a-row style="margin-bottom: 1px" :gutter="[1, 2]">
          <a-col :span="16" :style="varsWhen3Items.left">
            <div class="relative h-full w-full">
              <img v-lazy="getOssProcessedImage(images[0], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
              <PhotoMask @click="handlePreview(0)" />
            </div>
          </a-col>
          <a-col :span="8" :style="varsWhen3Items.right">
            <a-row :gutter="[1, 1]">
              <a-col :span="24">
                <div class="relative h-full w-full">
                  <img v-lazy="getOssProcessedImage(images[1], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
                  <PhotoMask @click="handlePreview(1)" />
                </div>
              </a-col>
              <a-col :span="24">
                <div class="relative h-full w-full">
                  <img v-lazy="getOssProcessedImage(images[2], 'm_fill,w_800,h_600', 'webp')" class="h-full w-full object-cover" />
                  <PhotoMask @click="handlePreview(2)" />
                </div>
                <div v-if="!moreImagesVisible" class="collapse-btn" @click="handleShowMoreImagesClick">{{ images.length - 3 }} More images</div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <div v-if="moreImagesVisible">
          <a-row v-for="(rowItem, rowIndex) in moreImages" :key="rowIndex" style="margin-bottom: 1px" :gutter="[1, 1]">
            <a-col v-if="rowItem === 2" :span="12" :style="varsMoreImageCol12">
              <div class="relative h-full w-full">
                <img
                  v-lazy="getOssProcessedImage(images[getCurrentSum(rowIndex) - 2], 'm_fill,w_800,h_600', 'webp')"
                  class="h-full w-full object-cover"
                />
                <PhotoMask @click="handlePreview(getCurrentSum(rowIndex) - 2)" />
              </div>
            </a-col>
            <a-col v-if="rowItem === 2" :span="12" :style="varsMoreImageCol12">
              <div class="relative h-full w-full">
                <img
                  v-lazy="getOssProcessedImage(images[getCurrentSum(rowIndex) - 1], 'm_fill,w_800,h_600', 'webp')"
                  class="h-full w-full object-cover"
                />
                <PhotoMask @click="handlePreview(getCurrentSum(rowIndex) - 1)" />
              </div>
            </a-col>
            <a-col v-if="rowItem === 3" :span="8" :style="varsMoreImageCol8">
              <div class="relative h-full w-full">
                <img
                  v-lazy="getOssProcessedImage(images[getCurrentSum(rowIndex) - 3], 'm_fill,w_800,h_600', 'webp')"
                  class="h-full w-full object-cover"
                />
                <PhotoMask @click="handlePreview(getCurrentSum(rowIndex) - 3)" />
              </div>
            </a-col>
            <a-col v-if="rowItem === 3" :span="8" :style="varsMoreImageCol8">
              <div class="relative h-full w-full">
                <img
                  v-lazy="getOssProcessedImage(images[getCurrentSum(rowIndex) - 2], 'm_fill,w_800,h_600', 'webp')"
                  class="h-full w-full object-cover"
                />
                <PhotoMask @click="handlePreview(getCurrentSum(rowIndex) - 2)" />
              </div>
            </a-col>
            <a-col v-if="rowItem === 3" :span="8" :style="varsMoreImageCol8">
              <div class="relative h-full w-full">
                <img
                  v-lazy="getOssProcessedImage(images[getCurrentSum(rowIndex) - 1], 'm_fill,w_800,h_600', 'webp')"
                  class="h-full w-full object-cover"
                />
                <PhotoMask @click="handlePreview(getCurrentSum(rowIndex) - 1)" />
              </div>
            </a-col>
          </a-row>
        </div>
      </a-space>

      <!-- 图片预览 -->
      <div style="display: none">
        <a-image-preview-group
          :preview="{
            visible: previewVisible,
            current: currentIndex,
            onVisibleChange: (vis: boolean) => (previewVisible = vis),
          }"
        >
          <template v-if="isPreview">
            <a-image v-for="(image, idx) in images" :key="idx" class="preview-img" :src="image" />
          </template>
          <template v-else>
            <a-image v-for="(image, idx) in images" :key="idx" class="preview-img" :src="getOssProcessedImage(image, '', 'webp')" />
          </template>
        </a-image-preview-group>
      </div>
    </a-space>
  </div>
</template>

<script setup lang="ts">
type PropsType = {
  images: null | string[];
  isPreview?: boolean | undefined;
};

const props = defineProps<PropsType>();
const moreImages = ref<any[]>([]);
const moreImagesVisible = ref(false);
const $photoList = ref<HTMLElement>();
const elementWidth1 = ref();
const elementHeight1 = ref();
const elementWidth2 = ref();
const elementHeight2 = ref();
const elementWidth3 = ref();
const elementHeight3 = ref();
const elementWidth4 = ref();
const elementHeight4 = ref();
const elementWidth8 = ref();
const elementWidth12 = ref();

// 使用 computed 计算属性
const varsWhen1Items = computed(() => {
  return {
    '--w': elementWidth1.value + 'px',
    '--h': elementHeight1.value + 'px',
  };
});
const varsWhen2Items = computed(() => {
  return {
    '--w': elementWidth2.value + 'px',
    '--h': elementHeight2.value + 'px',
  };
});

const varsWhen3Items = computed(() => {
  return {
    left: {
      '--w': `${elementWidth3.value - 1}px`,
      '--h': `${calcHeight(elementWidth3.value) + 1}px`,
    },
    right: {
      '--w': `${elementHeight3.value - 1}px`,
      '--h': `${calcHeight(elementWidth3.value) / 2}px`,
    },
  };
});

const varsWhenLte3Items = computed(() => {
  return {
    left: {
      '--w': `${elementWidth4.value}px`,
      '--h': `${calcHeight(elementWidth4.value) + 2}px`,
    },
    right: {
      '--w': `${elementHeight4.value}px`,
      '--h': `${calcHeight(elementWidth4.value) / 3}px`,
    },
  };
});

const varsMoreImageCol12 = computed(() => {
  return {
    '--w': `${elementWidth12.value - 1}px`,
    '--h': `${calcHeight(elementWidth12.value)}px`,
  };
});

const varsMoreImageCol8 = computed(() => {
  return {
    '--w': `${elementWidth8.value - 1}px`,
    '--h': `${calcHeight(elementWidth8.value)}px`,
  };
});
// 更新元素尺寸
const updateSize = (val: number) => {
  const half = val / 2;
  elementWidth1.value = val;
  elementHeight1.value = calcHeight(val);
  elementWidth2.value = half - 1;
  elementHeight2.value = calcHeight(half);
  elementWidth3.value = val * (2 / 3);
  elementHeight3.value = val * (1 / 3);
  elementWidth4.value = val * (18 / 24);
  elementHeight4.value = val * (6 / 24);
  elementWidth8.value = val / 3;
  elementWidth12.value = val / 2;
};
function calcHeight(w: number) {
  return w * 0.75;
}

const reducer = (prev: number, cur: number) => {
  return prev + cur;
};

const getCurrentSum = (rowIndex: number) => {
  let newArr: any = [].concat(...moreImages.value);
  newArr.length = rowIndex + 1;

  return newArr.reduce(reducer) + 3;
};

const previewVisible = ref(false);
const currentIndex = ref(0);
const handlePreview = (index: number) => {
  previewVisible.value = true;
  currentIndex.value = index;
};
const getImageOrder = (count: number) => {
  let order: number[] = [];
  if (count < 2) {
    order = [];
  } else if (count === 2) {
    order = [2];
  } else if (count === 3) {
    order = [3];
  } else if (count === 4) {
    order = [2, 2];
  } else if (count === 5) {
    order = [2, 3];
  } else if (count > 5) {
    let time = Math.floor(count / 5);
    let remainder = count % 5;
    if (remainder === 0) {
      for (let i = 0; i < time; i++) {
        order.push(2, 3);
      }
    } else if (remainder === 1) {
      for (let i = 0; i < time - 1; i++) {
        order.push(3, 2);
      }
      order.push(3, 3);
    } else if (remainder === 2) {
      for (let i = 0; i < time; i++) {
        order.push(3, 2);
      }
      order.push(2);
    } else if (remainder === 3) {
      for (let i = 0; i < time; i++) {
        order.push(3, 2);
      }
      order.push(3);
    } else if (remainder === 4) {
      for (let i = 0; i < time; i++) {
        order.push(2, 3);
      }
      order.push(2, 2);
    }
  }
  return order;
};
if ((props.images?.length as number) >= 5) {
  moreImages.value = getImageOrder((props.images?.length as number) - 3);
}
const handleShowMoreImagesClick = () => {
  moreImagesVisible.value = true;
};

// 监听窗口大小变化
const handleResize = () => {
  if ($photoList.value) {
    updateSize($photoList.value.clientWidth);
  }
};
watch(
  () => props,
  () => {
    setTimeout(() => {
      handleResize();
    });
  },
  {
    immediate: true,
  },
);
onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="less" scoped>
.collapse-btn {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  transition: all 0.2s;
  z-index: 12;
  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
}

:deep(.ant-image) {
  height: 100%;
  .ant-image-img {
    max-width: 100%;
    max-height: 100%;
    width: var(--w);
    height: var(--h);
    object-fit: cover;
    object-position: center;
  }
}
</style>

<style lang="less">
// 原ant-d字体图标会有加载不出来的bug，将左右箭头替换为图片
.ant-image-preview-switch-right,
.ant-image-preview-switch-right:hover {
  background-image: url(~'/assets/img/icon-right.svg');
  background-size: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  svg {
    display: none;
  }
  &.ant-image-preview-switch-right-disabled {
    background-image: url(~'/assets/img/icon-right-dis.svg');
  }
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-left:hover {
  background-image: url(~'/assets/img/icon-left.svg');
  background-size: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  svg {
    display: none;
  }
  &.ant-image-preview-switch-left-disabled {
    background-image: url(~'/assets/img/icon-left-dis.svg');
  }
}
</style>
