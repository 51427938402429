<template>
  <div class="my-8 text-center">
    <!-- <icon-font type="icon-upload" class="text-6xl text-[#000000]" /> -->
    <icon-font class="py-6 text-6xl" type="icon-Upload" />
    <div class="text-level-2 text-text-primary">Drag local files here</div>
  </div>
  <div v-for="(item, index) in SuportRules" :key="index" class="mt-4 text-level-4 text-text-tertiary">
    <div>{{ item.desc }}</div>
    <div>{{ item.type }}</div>
    <div>
      Max. size:
      <span class="text-status-alert">{{ item.size }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const SuportRules = [
  {
    desc: 'Video format supported: ',
    type: 'MP4/MOV/WebM',
    size: '70 MB',
  },
  {
    desc: 'Image format supported: ',
    type: 'JPG/JPEG/PNG/GIF',
    size: '15 MB',
  },
];
</script>
