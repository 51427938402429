<template>
  <a-modal v-model:open="layoutStore.isShowDownloadXcs" class="download-xcs-modal" :footer="null" width="580px" @ok="handleDownloadXcs">
    <div class="modal-section">
      <exclamation-circle-filled />
      <span>
        {{ Text[layoutStore.downloadXcsType as any] }}
      </span>
    </div>
    <div class="modal-footer">
      <a-button @click="onClose">Cancel</a-button>
      <a-button type="primary" @click="handleDownloadXcs">Download</a-button>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import { useSensors } from '~/composables/sensors';

const { sensorsInstallPop } = useSensors();
const layoutStore = useLayoutStore();
const Text: any = {
  'detail':
    'To utilize this function, please ensure you have the latest version of xTool Creative Space installed. If you have not yet installed it, you can download the software from the website.',
  'xArt':
    "To use xArt, please make sure you have xTool Creative Space version V1.5 or above installed. If you haven't installed it yet, please download the software from the website.",
};
const onClose = () => {
  layoutStore.setIsShowDownloadXcs(false, '');
};

const handleDownloadXcs = () => {
  sensorsInstallPop();
  window.open('https://www.xtool.com/pages/software', '_blank');
};
</script>

<style lang="less" scoped>
.download-xcs-modal {
  .modal-section {
    padding: 16px;
    color: #212121;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    .anticon {
      font-size: 40px;
      color: rgba(250, 173, 20, 1);
      margin-right: 16px;
    }
    > span {
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
      line-height: 24px; /* 150% */
    }
  }
  .modal-footer {
    display: flex;
    padding: 16px;
    justify-content: flex-end;
    :deep(.ant-btn) {
      // width: 160px;
      height: 40px;
      margin-left: 16px;
      font-size: 16px;
    }
  }
}
</style>
