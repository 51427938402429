// import { useAllStore } from '@/stores/all';
import { isEmpty, isNil } from 'lodash-es';
// import { parse } from 'qs';
import type { InjectionKey, Ref } from 'vue';
import { getNavCatalogsApi, getNavImgApi } from '~/api';
import { useSensors } from '~/composables';
import type { CatalogTy, NavConfig } from './types';

export interface MenuCtx {
  close(): void;
}

export const MENU_CTX_KEY: InjectionKey<MenuCtx> = Symbol('MENU_CTX_KEY');

export const useMenuCtx = () => {
  const openKey = ref('');
  const close = () => {
    openKey.value = '';
  };
  provide(MENU_CTX_KEY, {
    close,
  });
  return { openKey, close };
};

export function useNav() {
  const router = useRouter();
  const route = useRoute();
  // const allStore = useAllStore();
  const { sensorButtonClick } = useSensors();
  const myNavigateTo = async (path: string, name?: string) => {
    if (!isEmpty(name) && !isNil(name)) {
      sensorButtonClick({
        element_name: name,
      });
    }
    const url = new URL(path, import.meta.url);

    // console.log('route :>> ', route);
    // console.log('route.path :>> ', route.path);
    // console.log('url.pathname :>> ', url.pathname);
    // console.log('route.query.fullPath :>> ', route.query.fullPath);
    // console.log('path :>> ', path);
    // console.log(path.split('?')[1]);

    // const pathQuery = path.split('?')[1];
    if (route.path === url.pathname && route.fullPath !== path) {
      // if (route.path === '/all' && pathQuery) {
      //   const obj = parse(pathQuery);
      //   const type = obj.types as string;
      //   allStore.updateNavigations(type);
      // }
      router.$refresh(path);
    } else {
      // if (url.pathname === '/all' && pathQuery) {
      //   const obj = parse(pathQuery);
      //   const type = obj.types as string;
      //   allStore.updateNavigations(type);
      // }
      await router.push(path);
    }
  };
  return {
    myNavigateTo,
  };
}

export async function useNavConfig() {
  const parsePath = (item: any) => {
    let res = '';
    if (item.type === 'tag') {
      res = `/all?tagGroups=${item.tagGroupId}`;
    } else if (item.type === 'collection') {
      res = `/collection/${item.collectionId}`;
    }
    return res;
  };
  const { data: config } = await useAsyncData(async () => {
    const config: Record<string, any> = {};
    await Promise.all([
      call(async () => {
        const res = await getNavImgApi.fetch();
        if (res?.code !== 0 || isNil(res?.data)) {
          return;
        }
        res.data.forEach((item: any) => {
          config[item.catalogType] = {
            ...config[item.catalogType],
            catalogType: item.catalogType,
            imgInfo: item,
          };
        });
      }),
      call(async () => {
        const res = await getNavCatalogsApi.fetch();
        if (res?.code !== 0 || isNil(res?.data)) {
          return;
        }
        const list = res.data;
        list.forEach((catalog: any) => {
          catalog.items = catalog.items.map((item: any) => ({
            // id: item.type === 'tag' ? item.tagGroupId : item.collectionId,
            ...item,
            name: item.type === 'tag' ? item.tagGroupName : item.collectionName,
            path: parsePath(item),
            // type: item.type,
          }));
          const catalogType = catalog.catalogType as string;
          config[catalogType] = config[catalogType] ?? {};
          const group = config[catalogType];
          group.catalogType = catalogType;
          group.catalogs = group.catalogs ?? [];
          group.catalogs.push(catalog);
        });
      }),
    ]);
    return config;
  });
  // console.log(config.value);
  return config as Ref<Partial<Record<Exclude<CatalogTy, ''>, NavConfig>>>;
}
