export const LicenseQuestionsMap = [
  {
    que: 'Allow adaptations of your project to be shared?',
    answer: ['Yes', 'No', 'Yes, as long as others share in the same way'],
  },
  {
    que: 'Allow commercial uses of your work?',
    answer: ['Yes', 'No'],
  },
];
export const LicenseExtraQuestionMap = [
  {
    que: 'Allow sharing without attribution?',
    answer: ['Yes', 'No'],
    logic: ['Yes', 'Yes'],
  },
  {
    que: 'Allow sharing or redistributing of your work or its derivatives?',
    answer: ['Yes', 'No'],
    logic: ['No', 'No'],
  },
];
export const LicenseCodeMap = [
  {
    code: 'CC0',
    logic: ['Yes', 'Yes', 'Yes'],
  },
  {
    code: 'BY',
    logic: ['Yes', 'Yes', 'No'],
  },
  {
    code: 'BY-NC',
    logic: ['Yes', 'No'],
  },
  {
    code: 'BY-ND',
    logic: ['No', 'Yes'],
  },
  {
    code: 'BY-NC-ND',
    logic: ['No', 'No', 'Yes'],
  },
  {
    code: 'S-D-F-L',
    logic: ['No', 'No', 'No'],
  },
  {
    code: 'BY-SA',
    logic: ['Yes, as long as others share in the same way', 'Yes'],
  },
  {
    code: 'BY-NC-SA',
    logic: ['Yes, as long as others share in the same way', 'No'],
  },
];
export const ProjectFilesInfo = {
  // format: '.xcs,.lbrn,.lbrn2,.dwg,.dxf,.svg,.ai,.pdf,.plt,.jpg,.png,.rar,.7z,.GZIP,.EPS',
  format: '.xcs,.svg,.png,.jpg,.dxf,.pdf,.jpeg,.lbrn2,.cdr,.ai,.stl,.eps,.gif,.dwg,.ttf,.cdr,.lbrn,.webp,.bmp,.tiff',
  zipFormat: '.zip,.rar',
  hint: `Supported file formats: .xcs(Settings will automatic parsing)/.svg/.png/.jpg/.dxf and more.</br>Max. size: 200 MB`,
  maxFileSizeText: '200M',
  maxFileSize: 200 * 1024 * 1024,
  maxCount: 50,
  zipMaxCount: 1,
  errSizeTips: 'The file exceeds the size limit of 200MB.',
  errTypeTips: 'The file format is not supported for upload.',
};
export const DesignFilesInfo = {
  // format: '.xcs,.dxf,.svg,.bmp,.zip,.txt,.pdf,.ai,.eps,.jpg,.png,.crd,.zip,.rar',
  format: '',
  hint: `Supported file formats: XCS ,DXF, SVG, JPG, PNG, BMP, ZIP, TXT, PDF, and more</br> Max. size: 200 MB , Max. quantity: 50`,
  maxFileSizeText: '200M',
  maxFileSize: 200 * 1024 * 1024,
  maxCount: 50,
};
export const ImgLimitSize = 15 * 1024 * 1024;
export const VideoLimitWidth = 70 * 1024 * 1024;
export const VideoType = '.mp4,.mov,.webm';
export const ImageType = '.jpg,.jpeg,.png,.gif,.heic';
export const DefaultScenarios = 'Laser processing';
export const XCS_CODE = 'xTool Creative Space';
