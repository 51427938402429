export type ScreenSize = 'sm' | 'md' | 'lg';
export type DeviceSize = 'mobile' | 'pad' | 'pc';
const ScreenConfig: {
  [key in ScreenSize]: number;
} = {
  sm: 767,
  md: 992,
  lg: 1200,
};
const DeviceConfig: {
  [key in DeviceSize]: number;
} = {
  mobile: 767,
  pad: 992,
  pc: 1200,
};

export const useScreen = () => {
  const screenSize = reactive({
    width: 0,
    height: 0,
  });

  const onWindowResize = () => {
    const { innerWidth, innerHeight } = window;
    screenSize.width = innerWidth;
    screenSize.height = innerHeight;
  };

  const checkSize = (size: ScreenSize, operator: '>' | '<=') => {
    if (!size) {
      return false;
    }
    return operator === '>' ? screenSize.width > ScreenConfig[size] : screenSize.width <= ScreenConfig[size];
  };

  const checkDevice = (device: DeviceSize) => {
    const { mobile, pad } = DeviceConfig;
    switch (device) {
      case 'mobile':
        return screenSize.width <= mobile;
      case 'pad':
        return screenSize.width <= pad && screenSize.width > mobile;
      case 'pc':
        return screenSize.width > pad;
      default:
        return false;
    }
  };

  const isMobile = computed(() => (screenSize.width ? screenSize.width <= DeviceConfig.mobile : undefined));
  const isPad = computed(() => (screenSize.width ? screenSize.width <= DeviceConfig.pad && screenSize.width > DeviceConfig.mobile : undefined));
  const isPc = computed(() => (screenSize.width ? screenSize.width > DeviceConfig.pad : undefined));

  // 使用 onMounted 和 onUnmounted 钩子处理事件监听器
  onMounted(() => {
    // 确保代码只在客户端执行
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onWindowResize);
      onWindowResize(); // 初次调用以设置初始大小
    }
  });

  onUnmounted(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', onWindowResize);
    }
  });

  return {
    screenSize,
    higherSize: (size: ScreenSize) => checkSize(size, '>'),
    lowerSize: (size: ScreenSize) => checkSize(size, '<='),
    checkDevice,
    isMobile,
    isPad,
    isPc,
  };
};
