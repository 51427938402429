export const useFont = () => {
  if (import.meta.client) {
    setTimeout(async () => {
      const { isPc } = useScreen();
      if (isPc) {
        const { useDisplayStore } = await import('@/stores/display');
        const displayStore = useDisplayStore();
        await displayStore.listDisplayFonts();
      }
    }, 1000);
  }
};
