<template>
  <div class="m-auto max-w-[980px] px-[40px] pb-12 pt-8 pad:px-5 mobile:px-5">
    <SaleInfo v-if="isForSale" />
    <FreeInfo v-else />
  </div>
</template>

<script setup lang="ts">
import FreeInfo from './information/FreeInfo.vue';
import SaleInfo from './information/SaleInfo.vue';

import { useDetailsStore } from '@/stores/details';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const isForSale = computed(() => detail.value.forSale);
</script>

<style lang="less" scoped></style>
