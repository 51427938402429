<template>
  <template v-if="isAnimating">
    <Vue3Lottie :animation-data="LikeAmination" :loop="false" :auto-play="true" :height="width" :width="height" @on-complete="animationComplete" />
  </template>
  <icon-font v-else-if="isLiked" type="icon-filled-thumb-up" class="h-4 w-4 text-[#ff0035]" @click="handleLikeClick(false)" />
  <icon-font v-else type="icon-thumb-up" class="h-4 w-4 hover:text-[#ff0035]" @click="handleLikeClick(true)" />
</template>
<script setup lang="ts">
import LikeAmination from '@/assets/animate/like.json';
const isAnimating = ref(false);
interface Props {
  isLiked: boolean;
  width?: number;
  height?: number;
}
interface Emits {
  (e: 'update:liked', value: boolean): void;
}
const props = withDefaults(defineProps<Props>(), {
  width: 20,
  height: 20,
});
const emit = defineEmits<Emits>();
watch(
  () => props.isLiked,
  (newVal) => {
    if (newVal) {
      isAnimating.value = true;
    }
  },
);
const animationComplete = () => {
  isAnimating.value = false;
};
const handleLikeClick = (type: boolean) => {
  emit('update:liked', type);
};
</script>
