<template>
  <div id="m-canvas-container" ref="container" class="m-canvas-container"></div>
</template>

<script lang="ts" setup>
import type { MCanvas } from '@makeblock/m-canvas-core';
type MCanvasProps = {
  id: string | undefined;
  canvas: MCanvas | undefined;
  unitData: {
    unit: string;
    pixelPerUnit: number;
    fractionDigits: number;
    enableSnapping?: boolean;
  };
  isCustomEditor?: boolean;
};
const props = withDefaults(defineProps<MCanvasProps>(), {
  isCustomEditor: false,
});
type MCanvasEmits = {
  (event: 'created', data: { id: string; canvas: MCanvas }): void;
  (event: 'zoomed', ...args: any[]): void;
  (event: 'selected', ...args: any[]): void;
};

const emits = defineEmits<MCanvasEmits>();

const container = ref<HTMLSelectElement>();
const emitZoomed = (scaled: number) => {
  emits('zoomed', scaled);
};
const emitSelect = (res: any) => {
  emits('selected', res);
};
const init = async () => {
  const { canvas, id } = props;
  const { CANVAS_EVENT } = await import('@makeblock/m-canvas-core');

  if (id && canvas && container.value) {
    // emits('created', { id, canvas });
    container.value.innerHTML = '';
    container.value.appendChild(canvas.view);
    canvas.resizeCanvasTo(container.value);
    // canvas默认不启动ticker，这里针对多画布，未激活的画布提前渲染一次，改善用户体验
    canvas.render();
    // 防止重复监听
    if (saveIds.value.indexOf(id) === -1) {
      canvas.on(CANVAS_EVENT.ZOOMED, emitZoomed);
      canvas.on(CANVAS_EVENT.SELECT_OBJECT, emitSelect);
      saveIds.value.push(id);
    }
  }
};
const saveIds = ref<string[]>([]);
watch(
  () => props.id,
  () => {
    init();
  },
);
onMounted(() => {
  nextTick(() => {
    init();
  });
});
</script>

<style lang="less">
.m-canvas-container {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: calc(~'100% + 5px');
  height: calc(~'100% - 26px');
  position: relative;
  left: -5px;
  top: 0;

  canvas ~ div {
    display: none;
  }
}
</style>
