import mime from 'mime';
export const isFile = (obj) => {
    return typeof File !== 'undefined' && obj instanceof File;
};
export const isBlob = (obj) => {
    return typeof Blob !== 'undefined' && obj instanceof Blob;
};
export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
};
export const getFileSize = (file) => {
    return file.size;
};
export const getContentType = (file) => {
    let blobContentType = '';
    if (isFile(file)) {
        blobContentType = mime.getType(file.name);
    }
    else if (isBlob(file)) {
        blobContentType = file.type;
    }
    else {
        blobContentType = 'application/octet-stream';
    }
    return blobContentType;
};
