import { defineStore } from 'pinia';

export enum Layouts {
  Default = 'default',
  Common = 'common',
}

const setup = () => {
  const layout = ref(Layouts.Default);
  const isHeaderHide = ref(false);
  const isShowNewProject = ref(false); // 显示创建入口弹窗
  const disableTab = ref(false); // 是否禁选tab
  const isChooseSale = ref(false); // 是否选择付费tab
  const isShowDraftEntry = ref(true); // 显示草稿入口
  const isActModalShow = ref(false); // 是否显示活动弹窗
  const isActMbModalShow = ref(false); // 是否显示活动弹窗(移动端)
  const actModalOpts = ref<Record<string, any>>(); // 活动弹窗的配置

  const isShowMbDraftEntry = ref(true);
  const isShowMbEditorEntry = ref(false);

  const isShowMbDraft = ref(false);
  const isShowDownloadXcs = ref(false);
  const downloadXcsType = ref('');
  const remixBaseOnLinks = ref<string[]>([]);
  function set(ly: Layouts) {
    layout.value = ly;
  }
  function setIsHeaderHide(val: boolean) {
    isHeaderHide.value = val;
  }
  // 打开/关闭 PC端/移动端 新建弹窗
  function setIsShowNewProject(val: boolean) {
    isShowNewProject.value = val;
  }

  // 打开/关闭  PC活动弹窗
  function setActModalShow(val: boolean, opts?: { title?: string; items: any[]; activityId: number }) {
    isActModalShow.value = val;
    actModalOpts.value = isActModalShow.value ? opts : undefined;
  }
  // 打开/关闭活动弹窗(移动端)
  function setActMbModalShow(val: boolean, opts?: { title?: string; items?: any[]; activityId: number }) {
    isActMbModalShow.value = val;
    actModalOpts.value = isActMbModalShow.value ? opts : undefined;
  }
  function setIsShowMbDraft(val: boolean) {
    isShowMbDraft.value = val;
  }
  function setIsShowDownloadXcs(val: boolean, type: string) {
    isShowDownloadXcs.value = val;
    downloadXcsType.value = type;
  }
  function setRemixBaseOnLink(links: string[]) {
    remixBaseOnLinks.value = links;
  }
  function setIsChooseSale(val: boolean) {
    isChooseSale.value = val;
  }
  // 打开/关闭  移动端编辑器&草稿箱 抽屉
  function openMbEditorEntryModal(opts?: { isShowMbDraft?: boolean; isChooseSale?: boolean }) {
    isChooseSale.value = opts?.isChooseSale ?? isChooseSale.value;
    isShowMbDraftEntry.value = opts?.isShowMbDraft ?? isShowMbDraftEntry.value;
    if (!isChooseSale.value) {
      isShowMbEditorEntry.value = true;
    } else {
      isShowNewProject.value = true;
    }
  }
  function closeMbEditorEntryModal() {
    isShowMbEditorEntry.value = false;
  }
  const setDraftEntry = (val: boolean) => {
    isShowDraftEntry.value = val;
  };
  const initStatus = () => {
    isShowDraftEntry.value = true;
    isChooseSale.value = false;
    disableTab.value = false;
    setRemixBaseOnLink([]);
  };
  return {
    layout,
    set,
    isHeaderHide,
    isShowNewProject,
    setActModalShow,
    isActModalShow,
    setActMbModalShow,
    isActMbModalShow,
    actModalOpts,
    disableTab,
    isShowDraftEntry,
    isShowMbDraftEntry,
    isShowMbEditorEntry,
    isShowMbDraft,
    isShowDownloadXcs,
    isChooseSale,
    downloadXcsType,
    remixBaseOnLinks,
    setRemixBaseOnLink,
    setIsHeaderHide,
    setIsShowNewProject,
    setIsShowMbDraft,
    setIsShowDownloadXcs,
    setIsChooseSale,
    openMbEditorEntryModal,
    closeMbEditorEntryModal,
    setDraftEntry,
    initStatus,
  };
};

export const useLayoutStore = defineStore('layoutStore', setup);
