<template>
  <div class="error-page h-[100vh] bg-uni-5 pt-[160px]">
    <a-row justify="center">
      <a-col flex="675px">
        <div class="error-img text-center">
          <img class="w-[200px] object-cover mobile:w-[160px]" :src="ErrorPng" alt="" />
        </div>

        <div v-if="message" class="error-message mt-4 text-center text-level-2 font-bold text-text-primary">
          {{ message }}
        </div>

        <div class="error-description mt-1 text-center text-level-3 text-uni-3 mobile:px-4 mobile:text-[12px]" v-html="description"></div>

        <div class="go-back mt-4 text-center">
          <df-btn
            class="go-back-btn inline-flex h-[40px] items-center justify-center !rounded-full"
            type="primary"
            shape="default"
            :href="redirectUrl"
          >
            <span>{{ redirectText }}</span>
          </df-btn>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import ErrorPng from '~/assets/img/df-ip/error.png';

const error = useError();

let timerInfo = reactive<any>({
  timeNum: 5,
  timer: null,
});
const notFindTitle = "Oops! We couldn't find the page you were looking for.";
const btnText = 'Go to Homepage';
const btnUrl = '/';
const notFindDesc = computed(() => {
  return `Please double-check the URL and try again, or return to our homepage to continue browsing. You'll be redirected to the homepage in <span class="text-status-alert text-[16px]">${timerInfo.timeNum}</span> seconds.`;
});

const message = ref(notFindTitle);
const description = ref(notFindDesc);
const redirectUrl = ref(btnUrl);
const redirectText = ref(btnText);

if (error.value) {
  const err = error.value;
  message.value = err.statusMessage?.startsWith('Page not found') ? notFindTitle : err.statusMessage;
  if (err.data) {
    let parsedData = typeof err.data === 'string' ? JSON.parse(err.data) : err.data;
    description.value = parsedData.description || notFindDesc;
    redirectUrl.value = parsedData.redirectUrl || btnUrl;
    redirectText.value = parsedData.redirectText || btnText;
  }
}

function goToHomePage() {
  timerInfo.timer = setInterval(() => {
    timerInfo.timeNum--;
    if (timerInfo.timeNum <= 0) {
      clearInterval(timerInfo.timer);
      navigateTo({ path: btnUrl }, { replace: true });
    }
  }, 1000);
}
goToHomePage();

onMounted(() => {
  console.log(error.value);
});

onUnmounted(() => {
  clearInterval(timerInfo.timer);
});
</script>
