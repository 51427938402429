<template>
  <div v-if="creatorList.length > 0" class="mt-10">
    <div class="creators-column">
      <div class="m-auto px-10 pb-6 pt-10" :style="{ maxWidth: 'var(--maxBoxWidth)' }">
        <h5 class="mb-4 px-2 text-level-1 font-bold">Featured Creators</h5>
        <Lane :items="creatorList" :gap="16" :column="6">
          <template #item="{ item }">
            <BlockCreatorItem source="homepage" :user-item="item" id-key="uid" @follow-change="handleFollowChange($event, item)" />
          </template>
        </Lane>
      </div>
    </div>
    <!-- 创作者广告 -->
    <CreatorAd ad-key="HOME" type="homeBottom" source="home" />
  </div>
</template>

<script setup lang="ts">
import { useCreators } from './hooks';
import Lane from '~/components/common/lane/index.vue';

const { getCreatorList, creatorList, handleFollowChange } = useCreators();

await getCreatorList();

// 激活时重新获取数据
onActivated(() => {
  getCreatorList();
});
</script>

<style lang="less" scoped>
.creators-column {
  background-image: url('~/assets/img/home/user-box-bg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

:deep(.df-lane-wrap) {
  .df-lane {
    @media (max-width: 575px) {
      --c: 1;
    }
    @media (min-width: 576px) {
      --c: 3;
    }
    @media (min-width: 768px) {
      --c: 4;
    }
    @media (min-width: 992px) {
      --c: 5;
    }
    @media (min-width: 1200px) {
      --c: 6;
    }
    @media (min-width: 1600px) {
      --c: 7;
    }

    .df-lane-track {
      padding-bottom: 16px;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    .df-lane-item {
      @media (min-width: 768px) {
        padding-top: 4px;
      }
      @media (max-width: 767px) {
        display: none;
        width: 100%;
        margin: 12px 0 0;
        &:first-child {
          margin: 0;
        }
        &:nth-of-type(-n + 5) {
          display: block;
        }
      }
    }
  }
  .df-lane-arrow {
    top: calc(50% - 16px);
    @media (max-width: 767px) {
      display: none;
    }
  }
}
</style>
