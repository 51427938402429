<template>
  <div ref="collapsedBtnRef" class="design-files mt-6 rounded-xl border border-solid border-[#000]/10 px-4 pb-6 pt-4">
    <header class="text-level-2 font-bold text-text-primary">Design Files ({{ newAttachFiles.length }})</header>

    <a-config-provider>
      <!-- 空状态 -->
      <template #renderEmpty>
        <div class="flex items-center justify-center gap-2.5 py-2.5">
          <div class="flex flex-col items-center">
            <img class="w-[140px] mobile:w-[80px]" :src="emptyImg" alt="" />
            <span class="text-[14px] text-[#999] mobile:text-[12px]">No data</span>
          </div>
        </div>
      </template>

      <!-- 文件列表 -->
      <a-list class="file-list mt-4">
        <a-list-item v-for="file in displayFiles" :key="file.uid" class="file-item group rounded-lg bg-background-2 py-2 !pl-2 !pr-4">
          <div class="flex items-center gap-3">
            <div class="file-cover flex h-[72px] w-[96px] flex-shrink-0 items-center justify-center rounded bg-[#fff]">
              <img
                class="file-img h-8 w-8 object-contain"
                :class="[file.xcsFileNoCover && 'h-8 w-full', file.isXcsFile && 'w-full']"
                :src="file.fileCover"
                alt=""
              />
            </div>
            <div class="file-info">
              <div :title="file.name" class="file-name one-line-ellipsis text-level-4 font-semibold">{{ file.name }}</div>
              <span v-if="file.xcsTags" class="mt-2 inline-block rounded bg-uni-6/70 px-1.5 py-0.5 font-sans text-level-7 text-text-tertiary">
                {{ file.xcsTags }}
              </span>
            </div>
          </div>
          <div v-if="moreThanOneFile && !isPreview" class="hidden gap-2 group-hover:flex pad:flex mobile:flex">
            <icon-font
              v-if="!isDownloadLoading && !isDownLoadAllLoading"
              class="cursor-pointer text-[20px]"
              type="icon-a-30-Download"
              title="download this file"
              @click="downloadOne(file)"
            />
            <df-spin v-if="isDownloadLoading && file.uid === currentFileUid" />

            <icon-font
              v-if="file.isXcsFile && isOpenIconShow"
              class="cursor-pointer text-[20px] mobile:hidden"
              type="icon-XCS"
              title="open this file in xcs"
              @click="openOne(file)"
            />
            <icon-font
              v-if="(file.isImgFile || file.isDxfFile) && isInPcXcs"
              class="cursor-pointer text-[20px] mobile:hidden"
              type="icon-Export-line"
              title="Import to Canvas"
              @click="importOneToCanvas(file)"
            />
          </div>
        </a-list-item>
        <div
          v-if="newAttachFiles.length > 2"
          class="collapse-btn mt-2 flex cursor-pointer justify-center gap-1 rounded-lg bg-background-2 px-4 py-1.5"
          @click="onClickCollapse"
        >
          <span class="text-level-7 font-semibold text-text-primary">{{ isCollapsed ? 'View All' : 'Collapse' }}</span>
          <icon-font class="text-[12px]" type="icon-arrow-down" :style="{ transform: `rotate(${isCollapsed ? 0 : -180}deg)` }" />
        </div>
      </a-list>
    </a-config-provider>

    <div v-if="newAttachFiles.length > 0 || isOpenBtnShow" class="button-wrap mt-6 flex flex-wrap justify-center gap-3">
      <!-- 下载 按钮 -->
      <div v-if="newAttachFiles.length > 0" class="flex-[1_0_0%]">
        <df-btn
          :title="downloadBtnTitle"
          class="flex w-full items-center justify-center !px-3"
          type="highlight"
          size="large"
          :disabled="isPreview || isDownLoadAllLoading || isDownloadLoading"
          @click="onDownloadClick"
        >
          <div v-if="isDownloadLoading || isDownLoadAllLoading" class="flex items-center gap-1">
            <df-spin :stroke-color="'#fff'" :width="'14px'" />
            <span>{{ p }}%</span>
            <span class="text-level-2 font-bold">Downloading</span>
          </div>
          <template v-if="!(isDownloadLoading || isDownLoadAllLoading)">
            <icon-font type="icon-a-30-Download" />
            <span class="text-level-2 font-bold">{{ downloadBtnTitle }}</span>
          </template>
        </df-btn>
      </div>
      <!-- 打开xcs文件按钮 -->
      <div v-if="isOpenBtnShow" class="flex-[1_0_0%] mobile:hidden">
        <ClientOnly>
          <df-btn
            class="flex w-full items-center justify-center !px-3"
            type="default"
            size="large"
            :loading="isCallingXcs"
            :disabled="isPreview"
            @click="onOpenClick"
          >
            <icon-font v-if="!isCallingXcs" class="h-6 text-[24px]" type="icon-XCS" />
            <span class="text-level-2 font-bold">{{ isXcsFileAll ? 'Open All in XCS' : 'Open in XCS' }}</span>
          </df-btn>
        </ClientOnly>
      </div>
      <!-- Import to Canvas 按钮 -->
      <div v-if="isImportToCanvasBtnShow" class="flex-[1_0_0%] mobile:hidden">
        <ClientOnly>
          <df-btn
            class="flex w-full items-center justify-center !px-3"
            type="default"
            size="large"
            :loading="importToCanvasLoading"
            :disabled="isPreview"
            @click="onImportToCanvas"
          >
            <icon-font v-if="!importToCanvasLoading" class="h-6 text-[24px]" type="icon-Export-line" />
            <span class="text-level-2 font-bold">Import to Canvas</span>
          </df-btn>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import emptyImg from '@/assets/img/df-ip/no_data.png';
import { useFile } from './hooks/designFiles';

type PropsType = {
  isPreview?: boolean;
};
withDefaults(defineProps<PropsType>(), {
  isPreview: false,
});

const {
  isCollapsed,
  collapsedBtnRef,
  onClickCollapse,
  newAttachFiles,
  displayFiles,
  moreThanOneFile,
  downloadBtnTitle,
  isDownloadLoading,
  isDownLoadAllLoading,
  currentFileUid,
  isOpenIconShow,
  openOne,
  isInPcXcs,
  importOneToCanvas,
  isOpenBtnShow,
  onDownloadClick,
  downloadOne,
  p,
  isCallingXcs,
  onOpenClick,
  isXcsFileAll,
  isImportToCanvasBtnShow,
  importToCanvasLoading,
  onImportToCanvas,
} = useFile();
</script>

<style lang="less" scoped>
.design-files {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}
.file-item {
  border: none;
  .file-name {
    word-break: break-all;
  }
}
.file-item + .file-item {
  margin-top: 8px;
}

:deep(.ant-list-empty-text) {
  background: #f5f7fb;
  border-radius: 8px;
}

.button-wrap {
  @media screen and (max-width: 1260px) {
    flex-direction: column;
  }
}
</style>
