<template>
  <div class="share-pot-wrapper">
    <a-dropdown :arrow="false" :trigger="trigger" :placement="placement" :get-popup-container="(node: any) => node.parentNode">
      <slot>Share</slot>

      <template #overlay>
        <div class="pop">
          <a v-for="item in items" :key="item.title" :href="item.link" target="_blank" @click.prevent="onClickItem(item)">
            <img :src="item.icon" alt="" />
            <span>{{ item.title }}</span>
          </a>
          <a class="copy-link" @click="onClickCopy">
            <img :src="COPY_ICON" alt="" />
            <span>Copy Link</span>
          </a>
        </div>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup lang="ts">
import { useUserInfo } from '@/stores/user';
import { queryActivityOnlineApi } from '~/api/project-details';
import COPY_ICON from '~/assets/img/copy-link-fill.svg';
import FACEBOOK_ICON from '~/assets/img/facebook-circle-fill.svg';
import PINTEREST_ICON from '~/assets/img/pinterest-fill.svg';
import REDDIT_ICON from '~/assets/img/reddit-fill.svg';
import TWITTER_ICON from '~/assets/img/x-fill.svg';

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  jump: {
    type: Boolean,
    default: false,
  },
  jumpEl: {
    type: Object as PropType<HTMLElement | null | undefined>,
    default: null,
  },
  url: {
    type: String,
    default: '',
  },
  placement: {
    type: String as PropType<'bottom' | 'top'>,
    default: 'bottom',
  },
  trigger: {
    type: String as PropType<any>,
    default: 'hover',
  },
  // 回调,可用于埋点
  cb: {
    type: Function as PropType<(ev?: any) => void>,
    default: () => {},
  },
  copyLinkProp: {
    type: String,
    default: '',
  },
});

const options = {
  // 四周年自定义分享文案
  customStr: `Check out my entry for the xTool 4th Anniversary Creative Contest! Support me in winning an xTool machine by liking my entry on the DesignFind website. #xtool #xtoolmade`,
  // 分享窗口配置
  windowOpts: 'left=300,top=230,width=800,height=600',
  // 埋点参数
  sensorPara:
    '?utm_source=Clipboard_Shot&utm_campaign=extej&utm_content=Crypto%20AI%20Staking%20DeFi%20Platform%20UI%20UX%20Performance%20Dashboard%20App&utm_medium=Social_Share',
  message1: 'Sharing text copied, paste to share. Redirecting in 2 seconds',
  message2: 'link copied, paste to share.',
};

const useUserInfoStore = useUserInfo();
const { isPc } = useScreen();
const { $client } = useNuxtApp();

const items = computed(() => {
  const targetUrl = props.url || window.location.href;
  const url = encodeURIComponent(targetUrl);
  const title = encodeURIComponent(props.title);
  const description = encodeURIComponent(props.description);
  // const media = encodeURIComponent('https://res-us.makeblock.com/xtool/community/share_default.jpeg');
  return [
    {
      title: 'Facebook',
      icon: FACEBOOK_ICON,
      link: `https://www.facebook.com/sharer.php?u=${url}&t=${title}`,
    },
    {
      title: 'Pinterest',
      icon: PINTEREST_ICON,
      link: `https://www.pinterest.com/pin/create/button?url=${url}&description=${description}`,
      // link: `https://www.pinterest.com/pin/create/button?url=${url}&media=${media}&description=${description}`,
    },
    {
      title: 'Reddit',
      icon: REDDIT_ICON,
      link: `https://www.reddit.com/submit?type=LINK&title=${title}&url=${url}`,
    },
    {
      title: 'X',
      icon: TWITTER_ICON,
      link: `https://twitter.com/intent/tweet?text=${description}&url=${url}&hashtags=${title},DesignFind`,
    },
  ];
});

// 查询活动状态
const isOnActivity = ref(false);
async function getActivityStatus() {
  try {
    const res = await queryActivityOnlineApi.fetch({ key: 'activity-4th' });
    // console.log('res :>> ', res);
    if (res.code === 0) {
      isOnActivity.value = res.data?.['activity-4th'] || false;
    }
  } catch (err) {
    console.log('getActivityStatus-err :>> ', err);
  }
}
getActivityStatus();

const onClickItem = (item: any) => {
  if (isOnActivity.value) {
    // 四周年活动时，点击分享按钮，提示复制分享文案，3s 后跳转到真正的地址
    writeClipboard(options.customStr);
    message.success(options.message1);
    const timer = setTimeout(() => {
      onClickJump();
      window.open(item.link, 'shareTab', options.windowOpts);
      clearTimeout(timer);
    }, 2000);
  } else {
    onClickJump();
    // 非活动时，打开内置窗口
    window.open(item.link, 'shareTab', options.windowOpts);
  }
  if (typeof props.cb === 'function') {
    props.cb(item);
  }
};

/**
 * 跳到导航头像位置
 */
const onClickJump = () => {
  if (!props.jump) {
    return;
  }
  if (!$client.token) {
    return;
  }

  // 计算跳跃元素的起始位置
  const jumpEl = props.jumpEl || document.getElementById('jump-element');
  if (!jumpEl) {
    return;
  }
  const startRect = jumpEl.getBoundingClientRect();
  const start = {
    x: startRect.left,
    y: startRect.top + window.scrollY,
  };
  // console.log('start :>> ', start);
  // 计算跳跃元素的结束位置
  const targetEl = isPc.value ? document.getElementById('df-user-wrap') : document.getElementById('mb-target-jump');
  if (!targetEl) {
    return;
  }
  const targetRect = targetEl.getBoundingClientRect();
  const target = {
    x: targetRect.left,
    y: targetRect.top + window.scrollY,
  };
  // console.log('target', target);
  // 克隆跳跃元素
  const cloneEl = jumpEl.cloneNode(true) as HTMLElement;
  if (!cloneEl) {
    return;
  }
  cloneEl.id = 'clone-jump-element';
  cloneEl.style.top = `${start.y}px`;
  cloneEl.style.left = `${start.x}px`;
  cloneEl.style.zIndex = '1002'; // 置于导航条之上
  document.body.appendChild(cloneEl);
  cloneEl.clientWidth;
  const onTransitionEnd = () => {
    // console.log('onTransitionEnd');
    cloneEl.removeEventListener('transitionend', onTransitionEnd);
    cloneEl.remove();
  };
  cloneEl.addEventListener('transitionend', onTransitionEnd, { once: true });

  cloneEl.style.transition = 'transform 0.4s cubic-bezier(.45,1.14,1,1.1)';
  cloneEl.style.transform = `translate(${target.x - start.x}px, ${target.y - start.y}px)`;
};

const onClickCopy = () => {
  copyLink();
  message.success(options.message2);
  if (typeof props.cb === 'function') {
    props.cb({ title: 'CopyLink' });
  }
};

const copyLink = () => {
  const uidString = useUserInfoStore.myInfo.id ? `&uid=${useUserInfoStore.myInfo.id}` : '';
  const href = props.copyLinkProp ? props.copyLinkProp : window.location.href;
  const url = href.includes('?') ? options.sensorPara.replace('?', '&') : options.sensorPara;
  let shareUrl = `${href + url + uidString}`;

  // 活动时，分享文案加上自定义文案
  if (isOnActivity.value) {
    shareUrl = `${shareUrl} \n ${options.customStr}`;
  }

  writeClipboard(shareUrl);
};
</script>

<style lang="less" scoped>
.pop {
  display: flex;
  flex-direction: column;
  min-width: 130px;
  padding: 2px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.06),
    0 1px 20px 0 rgba(0, 0, 0, 0.1);

  a {
    display: inline-flex;
    align-items: center;
    padding: 7px 12px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #212121;

    &:hover {
      color: #1a1a1a;
      background: #f5f7fb;
    }

    &:first-child:hover {
      border-radius: 10px 10px 0 0;
    }

    &:last-child:hover {
      border-radius: 0 0 10px 10px;
    }

    img {
      margin-right: 12px;
    }
  }
  .copy-link {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
  }
}
</style>
