<template>
  <div v-if="sellTagInfo.tagName" class="absolute -left-2 top-3 z-10">
    <div
      class="seller-tag flex h-10 items-center justify-center px-4 text-[18px] font-bold"
      :style="{
        'background': sellTagInfo.tagBgColor,
        '--tagAngleColor': sellTagInfo.tagAngleColor,
      }"
    >
      <span class="whitespace-nowrap italic text-[#fff]">{{ sellTagInfo.tagName }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserInfo } from '~/stores/user';
import type { ItemType } from '~/types';
import { CONTENT_TYPE, SellTag } from '~/types';

type PropsType = {
  detail: ItemType;
};
const props = withDefaults(defineProps<PropsType>(), {});

const tagMap = {
  [SellTag.BestSeller]: {
    tagName: SellTag.BestSeller,
    tagBgColor: '#FF1D40',
    tagAngleColor: '#942F19',
  },
  [SellTag.New]: {
    tagName: SellTag.New,
    tagBgColor: '#79DA3E',
    tagAngleColor: '#0E800E',
  },
  [SellTag.Exclusive]: {
    tagName: SellTag.Exclusive,
    tagBgColor: '#367AFF',
    tagAngleColor: '#23549D',
  },
  [SellTag.Bundle]: {
    tagName: 'Bundle',
    tagBgColor: 'linear-gradient(91deg, #FCCD86 -6.42%, #F0A54D 56.02%, #F3BB66 101.49%)',
    tagAngleColor: '#B87514',
  },
};

const userInfoStore = useUserInfo();
const isAdmin = computed(() => userInfoStore.isAdmin);
const hasDiscount = computed(() => props.detail.hasDiscount);
const isBundle = computed(() => props.detail.type === CONTENT_TYPE.BUNDLE);
const discountPercent = computed(() => props.detail.discount?.discountPercent);
const sellTag = computed(() => props.detail.sellTag);
const sellTagInfo = computed(() => {
  // 管理员/作者
  if (isAdmin.value || props.detail.isCreator) {
    return {
      tagName: 'Premium',
      tagBgColor: 'linear-gradient(91deg, #FCCD86 -6.42%, #F0A54D 56.02%, #F3BB66 101.49%)',
      tagAngleColor: '#B87514',
    };
  }
  // 普通用户, 有折扣优先显示折扣标签
  if (hasDiscount.value) {
    return {
      tagName: `${discountPercent.value}% OFF`,
      tagBgColor: '#FF7C23',
      tagAngleColor: '#A94E11',
    };
  }
  // 普通用户, 无折扣，则根据标签显示对应内容
  if (sellTag.value) {
    return tagMap[sellTag.value];
  }
  // 如果是 bundle 类型，显示 bundle 标签
  if (isBundle.value) {
    return tagMap[SellTag.Bundle];
  }
  return {
    tagName: '',
    tagBgColor: '',
    tagAngleColor: '',
  };
});
</script>

<style lang="less" scoped>
.gradient-hot {
  background: linear-gradient(121deg, #ff696c 20.19%, #fa2831 79.96%);
  box-shadow: -2px -2px 4px 0 #fa2730 inset;
}

.seller-tag {
  position: absolute;
  z-index: 10;
  border-radius: 8px 20px 20px 0;
  &::before {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    border: 4px solid var(--tagAngleColor);
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
}
</style>
