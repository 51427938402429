<template>
  <a-extract-style>
    <a-config-provider :theme="antThemeConfig">
      <NuxtLayout>
        <NuxtLoadingIndicator :color="'repeating-linear-gradient(to right, #FF0035 0%, #FF4069 50%, #FF6699 100%)'" :throttle="0" :height="2" />
        <NuxtPage :keepalive="keepAlive" :max="max" />
      </NuxtLayout>
    </a-config-provider>
  </a-extract-style>
</template>

<script setup lang="ts">
import { antThemeConfig } from '@/config/ant-theme';
import { keepAlive, max } from '@/config/keep-alive';
import { isInIframe } from '@/stores/types';
import Shen from '@/utils/shen';
// import { useFont } from '@/utils/useFont'; // 提前加载设计文件字体
import { useCredit } from '~/stores/credit';
import { useUserInfo } from '~/stores/user';
import { usePageLeave } from '~/utils/shen/pageLeave';
import { useForceLogin } from '~/composables/force-login';

// useFont();
usePageLeave();

message.config({
  top: `80px`,
});

const route = useRoute();
// passport内嵌站点发生路由的变化，需要上报一个路由改变的消息。
watch(
  () => route.path,
  () => {
    window.parent.postMessage({ action: 'route-change' }, '*');
  },
);

// 监听xcs-app的reload-page事件，用于刷新页面
const ACTION: any = {
  ['reload-page'](e: MessageEvent) {
    if (e.data.from === 'xcs-app' && isInIframe()) {
      window.location.reload();
    }
  },
};

const listenerReloadPage = (e: MessageEvent) => {
  const { action } = e.data;
  ACTION[action] && ACTION[action](e);
};

// 初始化时 cid 写入 cookie
useDeviceFlags();

onMounted(() => {
  window.addEventListener('message', listenerReloadPage);
});
onUnmounted(() => {
  window.removeEventListener('message', listenerReloadPage);
});

// 初始化用户信息
const userInfoStore = useUserInfo();

const creditService = useCredit();

const isInit = useState('GLOBAL_USER', () => false);
if (getUToken() && !isInit.value) {
  // 首页加了缓存需要特殊处理
  const isHome = ['/', '/explore/featured'].includes(route.path);
  if (!isHome) {
    await Promise.all([userInfoStore.init(), creditService.refreshCreditCnt()]);
    isInit.value = true;
  } else {
    onMounted(async () => {
      await Promise.all([userInfoStore.init(), creditService.refreshCreditCnt()]);
      isInit.value = true;
    });
  }
}

// 未注册用户强制弹出登录弹窗
const { forceLogin } = useForceLogin();
onMounted(() => {
  if (route.path !== '/' && route.path !== '/explore/featured') {
    setTimeout(forceLogin, 3000);
  }
});

// 新用户提示
if (import.meta.client && getUToken() && localStorage.getItem('new-user-toast')) {
  localStorage.removeItem('new-user-toast');
  message.success('Obtained 200 credits successfully. Use it now!');
}

// 将保存埋点参数的实例挂载到 window 对象
Shen.mountToWindow();

// 在根组件中捕获错误，防止应用崩溃，返回 false
onErrorCaptured((err, instance, info) => {
  console.error(err);
  console.log('instance :>> ', instance);
  console.log('info :>> ', info);
  return false;
});
</script>
