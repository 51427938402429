<template>
  <div class="project-details-main bg-[#fff]">
    <a-spin class="spinning-box" wrapper-class-name="block-detail-spinning-box" :spinning="detailsStore.pageLoading">
      <template v-if="detail.id || isPreview">
        <ProjectDetailsHeader ref="headerRef" @scroll-base-remix="handleScrollToBaseRemix" />
        <ProjectDetailsTabs
          ref="tabRef"
          :has-instruction="hasInstruction"
          :for-sale="isForSale"
          :has-right="hasRight"
          @change="onTabChange"
          @click="onTabClick"
        />
        <ProjectDetailsContent :active-key="activeTabKey" />
        <ProjectDetailsFooterAction v-if="detail.status === 'published' && !isPreview" />
        <ProjectDetailsRecommend v-if="!isPreview" :id="detail.id" />
        <Transition name="footer-fade">
          <ProjectDetailsFooterBuy v-if="isFootBarShow && isForSale && !hasRight" />
        </Transition>
        <Transition name="footer-fade">
          <template v-if="hasRight">
            <ProjectDetailsFooterFileOperation v-show="isFootBarShow" :is-preview="isPreview" />
          </template>
        </Transition>
        <ProjectDetailsMbAction />
      </template>
    </a-spin>

    <ClientOnly>
      <DownloadXcsModal />
    </ClientOnly>

    <ClientOnly>
      <LikeModal
        v-if="detail && !isPreview"
        v-model:show="detailsStore.likeModalShow"
        :is-my-favorite="detail.userAction?.favorite > 0"
        :is-my-like="detail.userAction?.like > 0"
        :is-for-sale="isForSale"
        :detail="detail"
        @handle-comment="handleCommentClick"
        @handle-go-to-remix="handleGoRemix"
      />
    </ClientOnly>

    <LazyProjectDetailsCreditBuyModal />
  </div>
</template>

<script setup lang="ts">
import { postSignUrlsFetch } from '@/api/project';
import type { DetailProvide } from '@/components/project-details/types';
import { useDetailsStore } from '@/stores/details';
import { groupBy, isNil, merge } from 'lodash-es';
import qs from 'qs';
import { headInfo } from '~/config/seo';
import { useUserInfo } from '~/stores/user';
import { COMMUNITY_BASE_DATA, CONTENT_TYPE, type ItemType } from '~/types';
import { initStates } from './hook';
import { XthingsType } from './types';

type PropsType = {
  isPreview?: boolean;
  previewDetail?: ItemType;
  previewType?: string;
};
const props = withDefaults(defineProps<PropsType>(), {
  isPreview: false,
  previewDetail: undefined,
  previewType: undefined,
});
const { isPreview, previewDetail } = toRefs(props);

provide(
  'detail-common',
  readonly({
    isPreview: isPreview.value,
  }) as DetailProvide,
);

const { getBaseMaterialsData, getLicense, getBaseDevices, getDifficultyLevel, getBundleList, initBaseData, getDeviceInfo } = initStates();
const detailsStore = useDetailsStore();
const userInfoStore = useUserInfo();
const { sensorContentDetailView } = useSensors();
const route = useRoute();

const detail = computed(() => detailsStore.detail);
const isForSale = computed(() => detail.value?.forSale);
const hasInstruction = computed(() => detail.value.hasInstruction);
const hasRight = computed(() => detail.value.hasRight);
const myInfo = computed(() => userInfoStore.myInfo ?? {});

detailsStore.resetStore();
watchEffect(() => {
  if (detail.value.id) {
    setSeo();
  }
});

async function getData() {
  if (isPreview.value) {
    console.log('预览-传入的：', JSON.parse(JSON.stringify(previewDetail.value)));
    const newDetail = JSON.parse(JSON.stringify(previewDetail.value));
    detailsStore.togglePageLoading(true);
    const info = await handelPreviewInfo(newDetail);
    detailsStore.togglePageLoading(false);
    console.log('预览-处理完的：', JSON.parse(JSON.stringify(info)));
    detailsStore.setDetail(info);
    console.log('预览-最终计算属性：', JSON.parse(JSON.stringify(detail.value)));
    return;
  }
  const routeId = route.params.id as string;
  if (!routeId) {
    return;
  }
  const id = Number(routeId.split('-')[0]);
  if (isNaN(id)) {
    return;
  }
  await detailsStore.getProjectDetailById(id);
  if (import.meta.client) {
    const params = (window as any)?.df_shen?.getParams();
    const p = {
      content_id: detail.value?.id as number,
      content_name: detail.value?.title as string,
      content_tag: detail.value?.contentTags ?? ([] as string[]),
      publish_time: detail.value?.createdAt as number,
      author_name: detail.value?.createdByName as string,
      author_id: detail.value?.createdBy as number,
      service_type: detail.value?.type as string,
      is_AIproject: detail.value?.aiAssistance ? 1 : 0,
      content_type: isForSale.value ? 'sale' : 'free',
      latest_page_source: params.latest_page_source, // 最近一次页面来源
      latest_blockname_source: params.latest_blockname_source, // 最近一次栏目来源
      latest_keyword_source: params.latest_keyword_source, // 最近一次关键词来源
      latest_contentid_source: params.latest_contentid_source, // 最近一次案例id来源
      latest_contentid_algorithm_version: params.latest_contentid_algorithm_version, // 最近一次算法版本
      latest_contentid_algorithm_type: params.latest_contentid_algorithm_type, // 最近一次案例算法类型
    };
    sensorContentDetailView(p);
  }
}

/**
 * 处理详情页的预览模式信息（因为有些数据会被后端转换，预览时，要在前端提前转换）
 * @param newDetail ：编辑器传过来的原始数据
 * @returns ：处理后的展示数据
 */
async function handelPreviewInfo(newDetail: ItemType | undefined) {
  if (!newDetail) {
    return;
  }

  // 设置xthings的付费状态
  if (newDetail.type === CONTENT_TYPE.XTHINGS) {
    newDetail.hasRight = props.previewType === XthingsType.Content ? true : false;
  } else {
    newDetail.hasRight = true;
  }

  const queue: any[] = [getBaseMaterialsData(), getLicense(), getBaseDevices(), getDifficultyLevel(), getDeviceInfo()];
  const bundleIds = newDetail.bundles ?? [];
  if (bundleIds.length > 0) {
    queue.push(
      getBundleList({
        ids: bundleIds.join(','),
      }),
    );
  }
  const [materialList, licenseList, baseDevicesList, difficultyLevelList, deviceList, bundleList] = await Promise.all(queue);
  // console.log('materialList :>> ', materialList.value);
  // console.log('licenseList :>> ', licenseList.value);
  // console.log('baseDevicesList :>> ', baseDevicesList.value);
  // console.log('difficultyLevelList :>> ', difficultyLevelList.value);
  // console.log('deviceList.value :>> ', deviceList.value);
  // console.log('bundleList.value :>> ', bundleList.value);

  merge(newDetail, {
    // 设置用户信息
    createdByHeadpic: myInfo.value.headpic || '',
    createdByName: myInfo.value.name || '',
    createdBy: myInfo.value.id || 0,
    // 材料信息
    materialInfos: materialList.value,
  });

  // 设置价格
  if (!isNil(newDetail.origPriceCent)) {
    newDetail.origPrice = Number((newDetail.origPriceCent / 100).toFixed(2));
  }

  // 设置协议信息
  if (newDetail.licenseId) {
    const curLicense: any = licenseList.value.find((item: any) => item.id === newDetail.licenseId);
    if (curLicense) {
      merge(newDetail, {
        license: {
          code: curLicense.code,
          extra: curLicense.extra,
          id: curLicense.id,
          name: curLicense.name,
        },
      });
    }
  }

  // 设置机器信息(旧版)
  if (newDetail.devicePowers) {
    const deviceIds = newDetail.devicePowers.map((item: any) => item.deviceId);
    const group = groupBy(newDetail.devicePowers, 'deviceName');
    const curDeviceList = baseDevicesList.value.filter((item: any) => deviceIds.includes(item.id));
    const newDevicePowersInfos: any = curDeviceList.map((item: any) => {
      const powerNames = group[item.name]?.map((item: any) => item.powerName); // group[item.name] 有可能因为后台的机型配置错误而没有
      return {
        code: item.code,
        name: item.name,
        id: item.id,
        extra: item.extra,
        type: COMMUNITY_BASE_DATA.DEVICE,
        powerNames,
      };
    });
    merge(newDetail, {
      devicePowersInfos: newDevicePowersInfos,
    });
  }
  // 设置机器信息(新版)
  if (newDetail.machines) {
    newDetail.machines.forEach((item: any) => {
      const curDevice = deviceList.value.find((device: any) => device.id === item.id);
      if (curDevice) {
        item.icon = curDevice.icon;
      }
    });
  }

  // 设置难度信息
  if (newDetail.difficultyLevelId) {
    const curDifficultyLevel: any = difficultyLevelList.value.find((item: any) => item.id === newDetail.difficultyLevelId);
    if (curDifficultyLevel) {
      merge(newDetail, {
        difficultyLevel: curDifficultyLevel,
      });
    }
  }

  // 设置配件信息
  if (newDetail.accessories?.length) {
    newDetail.accessoryInfos = newDetail.accessories.map((item) => ({ name: item }));
  }

  // 设置标签信息
  if (newDetail.contentTags?.length) {
    merge(newDetail, {
      tagInfos: newDetail.contentTags.map((item) => ({
        tagName: item,
        tagJump: '',
        tagType: 'combined',
      })),
    });
  }

  // 处理bundles
  if (newDetail.bundles?.length) {
    merge(newDetail, {
      bundleInfos: bundleList.value,
    });
  }

  // 处理教程文件的链接
  if (newDetail.hasInstruction && newDetail.instructionType === 'file') {
    const tutorialFiles = newDetail.tutorialFiles ?? [];
    const queue: any = [];
    tutorialFiles.forEach((item: any) => {
      if (!item.url && !item.videoUrl) {
        message.info('please wait the file upload finish');
      }
      const p = new Promise((resolve) => {
        // 视频不转化
        if (item.type === 'video') {
          resolve(item.videoUrl);
        }
        // 地址中有?的不转化
        if (item.url?.includes('?')) {
          resolve(item.url);
        } else {
          const url = item.url;
          if (url) {
            postSignUrlsFetch.fetch({ urls: [url] }).then(({ data }) => {
              resolve(data[url]);
            });
          } else {
            resolve(url);
          }
        }
      });
      queue.push(p);
    });
    const resList = await Promise.all(queue);
    tutorialFiles.forEach((item: any, index) => {
      item.url = resList[index];
    });
  }

  // console.log('newDetail :>> ', newDetail);
  return newDetail;
}

const tabRef = ref(); // 用于设置tab选中
const activeTabKey = ref('Information'); // 用于显示content内容
function onSelectTab(key: string) {
  tabRef.value?.setActive(key);
  activeTabKey.value = key;
}

function onTabChange(key: string) {
  activeTabKey.value = key;
}

function onTabClick() {
  if (isPreview.value) {
    return;
  }
  const timer = setTimeout(() => {
    detailsStore.scrollToTab();
    clearTimeout(timer);
  }, 100);
}

// 滚动到 Comments 区域并聚焦评论框
function handleCommentClick() {
  onSelectTab('Comments');
  nextTick(() => {
    const commentAarea: HTMLElement | null = document.querySelector('.comment-area');
    const textarea: HTMLElement | null = document.querySelector('#comment-area');
    textarea && textarea.focus();
    commentAarea && commentAarea.focus();
  });
}
// 滚动到 Comments 的二创列表区域
function handleGoRemix() {
  onSelectTab('Comments');
  const timer = setTimeout(() => {
    detailsStore.scrollToRemix();
    clearTimeout(timer);
  }, 100);
}
// 滚动到 remix base on 区域
function handleScrollToBaseRemix() {
  onSelectTab('Information');
  const timer = setTimeout(() => {
    detailsStore.scrollToRemixBaseOn();
    clearTimeout(timer);
  }, 100);
}

function setSeo() {
  useHead({
    titleTemplate: `${detail.value.title} - DesignFind `,
    link: [{ rel: 'shortcut icon', type: 'image/x-icon', href: headInfo.favicon }],
    meta: [
      { name: 'title', content: `${detail.value.title} - DesignFind ` },
      { name: 'h1', content: `${detail.value.title} - DesignFind ` },
      { name: 'h2', content: `${detail.value.materialInfo}` },
      { name: 'description', content: detail.value.summary || headInfo.description },
      { property: 'og:title', content: `${detail.value.title} - DesignFind ` },
      { property: 'og:description', content: detail.value.summary || headInfo.description },
      { property: 'og:image', content: detail.value.extra?.blockCover || detail.value.cover || headInfo.ogImage },
    ],
  });
}

if (isPreview.value) {
  Promise.all([initBaseData(), getData()]);
} else {
  if (import.meta.server) {
    await Promise.all([initBaseData(), getData()]);
    setSeo();
  } else {
    Promise.all([initBaseData(), getData()]);
  }
}

/**
 * 观察顶部按钮是否被遮盖，确保不同时显示 同类按钮(header文件区按钮 和 footer悬浮条)
 */
const isFootBarShow = ref(false);
const headerRef = shallowRef<HTMLElement>();
const observer = useIntersectionObserver(
  ([entry]) => {
    isFootBarShow.value = !entry?.isIntersecting;
  },
  {
    threshold: [0.25],
  },
);
// 客户端进来时, 等到 headerRef 被渲染出来后, 再添加 IntersectionObserver
watch(headerRef, () => {
  addObserver();
});

function addObserver() {
  if (isPreview.value) {
    return;
  }
  const dom = !hasRight.value ? getComponentRootEl(headerRef.value) : getComponentRootEl(headerRef.value);
  if (dom) {
    observer.disconnect();
    observer.observe(dom);
  }
}

function init() {
  // 获取内容后更新路由中的 title
  if (detail.value.title) {
    const correctTitle = convertTitleToSlug(detail.value.title);
    const newPath = route.fullPath.replace(/\/[^\/]*$/, `/${detail.value.id}-${correctTitle}`);
    const newUrl = Object.keys(route.query).length ? `${newPath}?${qs.stringify(route.query)}` : newPath;
    history.replaceState(null, '', newUrl);
  }

  const commentId = route.params.commentId;
  if (commentId) {
    onSelectTab('Comments');
  }
}

onMounted(() => {
  init();
});
</script>

<style lang="less">
.spinning-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
}
</style>
<style lang="less" scoped>
.footer-fade-enter-active,
.footer-fade-leave-active {
  transition: all 0.3s ease;
}
.footer-fade-enter-from,
.footer-fade-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
