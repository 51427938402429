<template>
  <div :class="['link-item', item.supportRemix ? '' : 'notPermit', hasDelete ? 'selected-item' : '']">
    <div class="left">
      <img v-if="item.cover" :src="getOssProcessedImage(item.cover, 'm_mfit,w_100,h_75', 'webp')" />
      <div v-else class="icon">
        <icon-font type="icon-link" />
      </div>
    </div>
    <div class="text">
      <p class="title one-line-ellipsis">
        {{ item.title }}
      </p>
      <div v-if="item.objectType !== 'link'" class="info">
        <div class="user">
          <img v-if="item.createdByHeadpic" :src="item.createdByHeadpic" class="user-img" alt="" />
          <span v-else :src="item.createdByHeadpic" class="user-default-img"></span>
          <p class="user-name one-line-ellipsis">
            {{ item.createdByName }}
          </p>
        </div>
        <p class="permit-tip">
          <span class="pc">Remixing isn't allowed by the license policy.</span>
          <span class="xs">Remixing isn't allowed.</span>
        </p>
      </div>
      <div v-else>
        <span class="one-line-ellipsis">{{ item.link }}</span>
      </div>
    </div>
    <icon-font v-if="hasDelete" type="icon-Delete" class="icon-delete-font" @click.stop="() => emits('handleDelete')" />
  </div>
</template>
<script lang="ts" setup>
type PropsType = {
  item: {
    cover: string;
    createdByDeleted: boolean;
    createdByHeadpic: string;
    createdByName: string;
    license: string | null;
    link: string;
    objectId: number;
    objectType: string;
    title: string;
    supportRemix: boolean;
  };
  hasDelete: boolean;
};
defineProps<PropsType>();
type EmitType = {
  (event: 'handleDelete'): void;
};
const emits = defineEmits<EmitType>();
</script>
<style lang="less" scoped>
.one-line-ellipsis {
  word-break: break-all;
}
.link-item {
  position: relative;
  border-radius: 2px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  min-height: 85px;
  width: 100%;
  &:hover {
    background: #f4f4f4;
    border-color: rgba(0, 0, 0, 0.16);
  }
  .left {
    width: 100px;
    height: 75px;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2px;
    }
    .icon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      background: #e3e3e3;
      border-radius: 4px;
      color: #fff;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    width: calc(~'100% - 140px');
    .title {
      font-size: 16px;
      font-weight: theme('fontWeight.semibold');
      color: #212121;
      margin: 0;
      width: 100%;
      white-space: normal;
      word-break: break-all;
    }
    .info {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      display: flex;
      align-items: center;
      .user {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      img.user-img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        object-fit: cover;
      }
      span.user-default-img {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #e3e3e3;
      }
      p.user-name {
        margin: 0;
        color: #555;
        font-size: 14px;
        max-width: 300px;
        word-break: break-all;
        white-space: normal;
      }
      p.permit-tip {
        margin: 0;
        color: #ff7000;
        font-size: 14px;
        display: none;
        .pc {
          display: block;
        }
        .xs {
          display: none;
        }
      }
    }
  }
  .icon-delete-font {
    position: relative;
    z-index: 5;
    font-size: 24px;
    padding-right: 8px;
    color: rgba(33, 33, 33, 1);
    &:hover {
      color: rgba(255, 77, 79, 1);
    }
  }
}
.notPermit.selected-item {
  .text {
    .info {
      p.permit-tip {
        color: red;
      }
    }
  }
  &:after {
    cursor: pointer;
  }
}
.notPermit {
  &:hover {
    background: #f8f8f8;
    border-color: transparent;
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(248, 248, 248, 0.5);
    border-radius: 2px;
    cursor: not-allowed;
  }
  .text {
    .info {
      p.permit-tip {
        display: block;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .link-item .text {
    .title {
      -webkit-line-clamp: 2;
      font-size: 14px;
      line-height: 22px;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }
  .notPermit {
    .text {
      .title {
        -webkit-line-clamp: 1;
      }
      .info {
        p.permit-tip {
          .pc {
            display: none;
          }
          .xs {
            display: block;
          }
        }
      }
    }
  }
}
</style>
