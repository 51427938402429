<template>
  <div>
    <div v-for="(element, index) in settingList" :key="element.id">
      <div class="mb-2">
        <textSetting v-if="element.type == 'custom'" v-model:value="element.custom" :index="index" :setting="element" @emit-delete="deleteSetting" />
        <xcsSetting v-if="element.type == 'xcs'" :setting="element" :index="index" @emit-delete="deleteSetting" />
      </div>
    </div>
    <div class="flex gap-2">
      <df-btn
        v-if="hasSetting && !isMobile"
        :class="['add-btn flex flex-1 items-center text-left']"
        type="tertiary"
        size="large"
        @click="clickAddXcs"
      >
        <icon-font type="icon-add-line" class="text-[24px] text-text-primary" />
        <span class="font-semibold text-text-primary">Automatically fill in XCS file settings</span>
      </df-btn>
      <df-btn
        :class="['add-btn flex items-center mobile:items-start', hasSetting && !isMobile ? 'w-[154px]' : 'w-full']"
        type="tertiary"
        :size="!isMobile ? 'large' : 'middle'"
        @click="clickAddText"
      >
        <icon-font type="icon-edit-box" class="text-[24px] text-text-primary mobile:text-[20px]" />
        <span class="font-semibold text-text-primary">Add Settings</span>
      </df-btn>
    </div>

    <SelectDesignModal v-model:visible="showSelectListModal" :file-list="xcsFileList" @next="nextSelect" />
    <div v-if="showSearchSelect">
      <canvas-select-modal v-model:visible="showSearchSelect" :file="selectFile" @handle-select-display="handleSelectDisplay" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useShareProject } from '@/stores/shareProject';
import { v4 } from 'uuid';
import SelectDesignModal from './SelectDesignModal.vue';
import textSetting from './textSetting.vue';
import xcsSetting from './xcsSetting.vue';
const useShareProjectStore = useShareProject();
const settingList = defineModel('list', { type: Array, default: [] as any });
const showSearchSelect = ref(false);
const selectFile = ref() as any;
const showSelectListModal = ref(false);
const { isMobile } = useScreen();
const forSale = computed(() => useShareProjectStore.form.basicForm.forSale);
const id = computed(() => useShareProjectStore.form.id);
// const attachFiles = computed(() => useShareProjectStore.form.basicForm.attachFiles);
const clickAddXcs = () => {
  if (xcsFileList.value.length > 1) {
    showSelectListModal.value = true;
  } else if (xcsFileList.value.length === 1) {
    nextSelect(0);
  }
};
const { sensorEditorClick } = useSensors();
// 上传的设计文件是否有setting
const hasSetting = computed(() => {
  if (useShareProjectStore.form.basicForm.attachFiles) {
    console.log('useShareProjectStore.form.basicForm.attachFiles: ', useShareProjectStore.form.basicForm.attachFiles);
    return useShareProjectStore.form.basicForm.attachFiles.some((item: any) => item.extra?.allDisplay?.length > 0);
  } else {
    return false;
  }
});
// xcs文件列表
const xcsFileList = computed(() => {
  if (useShareProjectStore.form.basicForm.attachFiles) {
    return useShareProjectStore.form.basicForm.attachFiles.filter((item: any) => getFileType(item.name) === 'xcs');
  } else {
    return [];
  }
});

const clickAddText = () => {
  if (!settingList.value) {
    settingList.value = [];
  }
  settingList.value.push({
    type: 'custom',
    custom: '',
    uid: v4(),
  });
  sensorEditorClick({
    element_name: 'addsettings_XCS_enter',
    sevice_type: 'project',
    content_type: forSale.value ? 'sale' : 'free',
    content_id: id.value,
  });
};
const nextSelect = (fileIdx: number) => {
  const fileObj = xcsFileList.value[fileIdx];
  if (!fileObj.url) {
    message.info('Please wait for the file upload to complete');
  } else {
    selectFile.value = fileObj;
    showSearchSelect.value = true;
  }
};

const handleSelectDisplay = (items: any) => {
  settingList.value.push(...items);
  showSearchSelect.value = false;
  showSelectListModal.value = false;
};
const deleteSetting = (index: number) => {
  Modal.confirm({
    wrapClassName: 'confirm-modal',
    content: 'Are you sure you want to delete?',
    cancelText: 'No',
    okText: 'Yes',
    keyboard: false,
    onOk: () => {
      settingList.value.splice(index, 1);
    },
    onCancel: () => {},
  });
};
watch(
  // 监听xcs文件列表
  () => xcsFileList.value && xcsFileList.value.length,
  () => {
    setTimeout(() => {
      // 解决初始化的时候 props（settingList.value）和store的值更新异步问题
      handleSettingList();
    }, 200);
  },
);
// 设计文件删除时，需要删除对应的setting
const handleSettingList = () => {
  settingList.value = settingList.value.filter((item: any) => {
    if (item.type === 'xcs') {
      return xcsFileList.value.some((file: any) => file.name === item.file.name);
    } else {
      return true;
    }
  });
};
</script>
<style lang="less" scoped>
.add-btn {
  :deep(&.df-btn.df-btn--tertiary.df-btn--large),
  :deep(&.df-btn.df-btn--tertiary.df-btn--middle) {
    border-radius: 8px;
    // border: 1.5px solid rgba(0, 0, 0, 0.1);
    // height: 48px;
    // box-shadow: none;
    font-weight: 400;
    color: #1a1a1a;
    font-weight: theme('fontWeight.semibold');
  }
}
@media screen and (max-width: 768px) {
  .add-btn {
    :deep(&.df-btn.df-btn--tertiary.df-btn--middle) {
      justify-content: flex-start;
    }
  }
}
</style>
