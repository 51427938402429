<template>
  <div class="comment-img-item group relative">
    <RatioBox :padding-top="'100%'" @click="handlePreview(true)">
      <div v-if="!preview && imgInfo.percent < 100" class="flex h-full w-full items-center justify-center rounded-lg bg-[rgba(0,0,0,0.06)]">
        <a-progress type="circle" :percent="imgInfo.percent" :size="48" :stroke-color="'#FF0035'" />
      </div>
      <template v-else>
        <div class="h-full w-full overflow-hidden rounded-lg">
          <img v-lazy="localUrl.url" class="h-full w-full object-cover" alt="" />
        </div>
        <div
          :class="'mask absolute left-0 top-0 z-10 hidden h-full w-full cursor-pointer items-center justify-center gap-1 rounded-lg bg-uni-0/40 text-[#fff] group-hover:flex mobile:flex-col mobile:items-center'"
        >
          <icon-font type="icon-eye-line" class="text-[16px]" />
          <span class="text-level-7">Preview</span>
        </div>
        <icon-font
          v-if="!preview"
          :class="'absolute right-[5px] top-[5px] z-10 hidden rounded bg-[#ff4d4f] p-[2px] text-[20px] text-[#fff] group-hover:flex pad:flex pad:text-[16px] mobile:flex mobile:text-[16px]'"
          type="icon-Delete"
          title="Delete"
          @click.stop="handleDelete"
        />
        <a-image :preview="{ visible: visible, src: localUrl.previewUrl, onVisibleChange: handlePreview }" />
      </template>
    </RatioBox>
  </div>
</template>

<script setup lang="ts">
import LOADING from '~/assets/img/common/loading.gif';

type PropsType = {
  imgInfo?: Record<string, any>;
  preview?: boolean;
  url?: string;
};
const props = withDefaults(defineProps<PropsType>(), {
  imgInfo: () => ({}),
  preview: false,
  url: '',
});
const emit = defineEmits(['delete']);

const localUrl = computed(() => {
  const url = props.preview ? props.url : props.imgInfo.url;
  const p = checkImg(url, '.gif') ? '' : 'm_fill,w_154,h_154';
  return {
    url: getOssProcessedImage(url ?? LOADING, p, 'webp'),
    previewUrl: url,
  };
});

const visible = ref(false);
const handlePreview = (vis: boolean) => {
  visible.value = vis;
};

const handleDelete = () => {
  emit('delete', props.imgInfo);
};
</script>

<style lang="less" scoped>
:deep(.ant-image) {
  display: none;
}
</style>
