<template>
  <a-form-item :name="Name" :label="Label">
    <df-textarea v-model:value="summary" :placeholder="PlaceHolder" :auto-size="{ minRows: 3, maxRows: 20 }" :maxlength="4500" />
  </a-form-item>
</template>

<script setup>
const Name = 'summary';
const Label = 'Summary';
const PlaceHolder = 'Briefly describe what you made and why';
const summary = defineModel('summary', { type: String, default: '' });
</script>
