<template>
  <div class="pdf-preview-wrapper flex flex-col bg-[#fefeff]">
    <a-spin :spinning="option.pageRendering">
      <div ref="pdfContainerRef" class="border-0 border-t border-solid border-[rgba(0,0,0,0.1)] py-10">
        <canvas
          id="pdf-canvas"
          ref="firstCanvasRef"
          class="m-auto block rounded-2xl"
          :class="{
            'shadow-md': !option.pageRendering,
          }"
        ></canvas>
      </div>

      <div class="flex justify-center gap-8 border-0 border-t border-solid border-[rgba(0,0,0,0.1)] py-8">
        <div class="flex cursor-pointer gap-2 text-uni-1" @click="onDownload">
          <icon-font class="text-[24px]" type="icon-a-30-Download" />
          <span class="text-level-3 font-semibold">Download</span>
        </div>
        <span class="inline-flex w-[1px] bg-[rgba(0,0,0,0.1)]"></span>
        <div class="flex cursor-pointer gap-2 text-uni-1" @click="openPdf">
          <df-spin v-if="isSpinning" />
          <icon-font v-if="!isSpinning" class="text-[24px]" type="icon-eye-line" />
          <span class="text-level-3 font-semibold">Preview</span>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
import { usePdfJs } from '@/components/file-preview/pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';

type PropsType = {
  fileUrl: string;
  name?: string;
};
const props = defineProps<PropsType>();

const { getPdfJs } = usePdfJs();
const option = reactive({
  current: 1,
  scale: 0.8,
  pageRendering: false,
});
let pdfDoc: null | PDFDocumentProxy = null;
const totalPages = ref(0);
const firstCanvasRef = ref<HTMLCanvasElement | null>(null);
const ctx = ref<CanvasRenderingContext2D | null>(null);

// 下载PDF
function onDownload() {
  const link = document.createElement('a');
  link.setAttribute('download', props.name || 'file');
  link.setAttribute('href', props.fileUrl);
  link.click();
  link.remove();
}

const isSpinning = ref(false);
// 打开PDF
const openPdf = () => {
  if (isSpinning.value) {
    return;
  }
  isSpinning.value = true;
  const xhr = new XMLHttpRequest();
  xhr.open('GET', props.fileUrl, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (this.status === 200) {
      const blob = this.response;
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('target', '_blank');
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
      isSpinning.value = false;
    }
  };
  xhr.onerror = function () {
    isSpinning.value = false;
  };
  xhr.send();
};

let pdfjsLib = null;
// const firstRef = ref<HTMLCanvasElement | null>(null);
// 加载PDF文档
async function loadPdf() {
  try {
    pdfjsLib = await getPdfJs();
    // console.log('pdfjsLib :>> ', pdfjsLib);
    option.pageRendering = true;
    const loadingTask = pdfjsLib.getDocument({ url: props.fileUrl });
    pdfDoc = await loadingTask.promise;
    totalPages.value = pdfDoc.numPages;
    await renderPage(option.current);
  } catch (err) {
    console.log(err);
  } finally {
    option.pageRendering = false;
  }
}

// 渲染PDF页面
const renderPage = async (pageNumber: number) => {
  if (!pdfDoc) {
    return;
  }
  try {
    const page = await pdfDoc.getPage(pageNumber);
    const viewport = page.getViewport({ scale: option.scale });
    if (!firstCanvasRef.value) {
      return;
    }
    firstCanvasRef.value.width = viewport.width;
    firstCanvasRef.value.height = viewport.height;
    ctx.value = firstCanvasRef.value.getContext('2d');
    if (!ctx.value) {
      return;
    }
    const renderContext = {
      canvasContext: ctx.value,
      viewport: viewport,
    };
    page.render(renderContext);
  } catch (err) {
    console.log('err :>> ', err);
  }
};

// function zoomIn() {
//   if (option.scale >= 2.5) {
//     return;
//   }
//   option.scale += 0.2;
//   renderPage(option.current);
// }
// function zoomOut() {
//   if (option.scale <= 0.5) {
//     return;
//   }
//   option.scale -= 0.2;
//   renderPage(option.current);
// }

// 加载所有页面
// const pdfContainerRef = ref<HTMLDivElement | null>(null);
// const renderAllPages = async () => {
//   if (!pdfDoc) {
//     return;
//   }
//   const totalPages = pdfDoc.numPages;
//   const container = pdfContainerRef.value; // 用于承载所有页面的容器
//   if (!container) {
//     return;
//   }
//   // const fragment = document.createDocumentFragment;
//   console.dir(pdfContainerRef.value);
//   const frags = document.createDocumentFragment();
//   for (let pageNum = 2; pageNum <= totalPages; pageNum++) {
//     const page = await pdfDoc.getPage(pageNum);
//     const viewport = page.getViewport({ scale: option.scale });
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');
//     // console.log('context :>> ', context);
//     if (!context) {
//       return;
//     }
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;
//     frags.append(canvas);
//     // container.appendChild(canvas); // 将 canvas 添加到容器中
//     page.render({ canvasContext: context, viewport: viewport });
//   }
//   container.appendChild(frags);
// };

// 上一页
// const previousPage = () => {
//   if (option.current > 1) {
//     option.current--;
//     renderPage(option.current);
//   }
// };

// // 下一页
// const nextPage = () => {
//   if (option.current < totalPages.value) {
//     option.current++;
//     renderPage(option.current);
//   }
// };

// 滚动到底部时自动翻页的阈值（比如 100px）
// const pageHeight = ref(0);
// const scrollThreshold = ref(100);
// const computedPageHeight = computed(async () => {
//   // 确保只计算一次页面高度，这里假设页面大小一致
//   if (!pageHeight.value) {
//     if (!pdfDoc || !canvas.value) {
//       return;
//     }
//     const page = await pdfDoc.getPage(1);
//     const viewport = page.getViewport({ scale: 1.0 });
//     canvas.value.height = viewport.height;
//     return viewport.height;
//   }
//   return pageHeight.value;
// });

// const handleScroll = () => {
//   if (!pdfDoc || !canvas.value) {
//     return;
//   }
//   const scrollElement = document.querySelector('.pdf-container');
//   if (!scrollElement) {
//     return;
//   }
//   const pageHeight = computedPageHeight.value;
//   const isAtBottom = scrollElement.scrollHeight - scrollElement.scrollTop - pageHeight.value <= scrollThreshold.value;

//   if (isAtBottom && option.current < totalPages.value) {
//     nextPage();
//   }
// };

const open = () => {
  openPdf();
};
const download = () => {
  onDownload();
};

onMounted(() => {
  loadPdf();
});

defineExpose({
  open,
  download,
});
</script>

<style lang="less" scoped></style>
