<template>
  <div class="ad-cover" :class="{ zom, shadow }">
    <img :src="src || LOADING" alt="" class="ad-cover__img" />
    <div v-if="shadow" class="ad-cover__mask"></div>
    <div v-if="$slots.default" class="ad-cover__content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import LOADING from '~/assets/img/common/loading.gif';

interface CoverProps {
  src?: string;
  title?: string;
  format?: string;
  width?: string;
  height?: string;
  // eslint-disable-next-line vue/require-default-prop
  resize?: string;
  lazy?: boolean;
  shadow?: boolean;
  zom?: boolean;
}

const props = withDefaults(defineProps<CoverProps>(), {
  src: '',
  title: '',
  format: 'webp',
  width: '100%',
  height: '100%',
  lazy: true,
  zom: false,
  shadow: false,
});

const src = computed(() => getOssProcessedImage(props.src ?? '', props.resize, props.format) ?? '');
</script>

<style scoped lang="less">
.ad-cover {
  position: relative;
  width: 100%;
  height: 100%;
  .ad-cover__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition-property: scale, transform;
    transition-duration: 0.8s;
    cursor: pointer;
  }
  .ad-cover__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, rgba(33, 33, 33, 0) 62%, rgba(33, 33, 33, 0.8) 100%);
    transition: opacity 0.3s ease;
    cursor: pointer;
  }
  .ad-cover__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &.zom:hover {
    .ad-cover__img {
      transform: scale(1.05);
    }
  }
  &.shadow:hover {
    .ad-cover__mask {
      opacity: 1;
    }
  }
}
</style>
