<template>
  <div class="footer pb-[72px] pt-12">
    <div class="m-auto max-w-[900px]">
      <h3 class="mb-0 text-center text-xl font-bold text-text-primary pad:text-level-2 mobile:text-level-2">Do you like this {{ typeLabel }}?</h3>
      <div class="mt-6 flex justify-center gap-3">
        <a-tooltip placement="bottom" :title="isMyLike ? 'Unlike' : 'Like'">
          <ActionButton
            shape="default"
            class="action-btn like-btn"
            :size="48"
            :active="isMyLike"
            icon="icon-a-30-like"
            active-icon="icon-a-30-like-2"
            :text="likeCount"
            @click="handleToggleAction('like')"
          />
        </a-tooltip>
        <a-tooltip placement="bottom" :title="isMyFavorite ? 'Remove from your Favorites' : 'Add to Favorites'">
          <ActionButton
            shape="default"
            class="action-btn"
            :size="48"
            :active="isMyFavorite"
            icon="icon-bookmark-line2"
            active-icon="icon-a-bookmark-fill"
            :text="favoriteCount"
            @click="handleToggleAction('favorite')"
          />
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';

const detailsStore = useDetailsStore();

const articleTypes: any = {
  xthings: 'xTool DIY Kit',
  designs: 'project',
  project: 'project',
  howtos: 'project',
  inspiration: 'project',
  bundle: 'bundle',
};

const detail = computed(() => detailsStore.detail);
const articleType = computed(() => detail.value.type ?? '');
const typeLabel = computed(() => articleTypes[articleType.value]);
const favoriteCount = computed(() => detail.value.actionSum?.favorite ?? 0);
const likeCount = computed(() => detail.value.actionSum?.like ?? 0);
const isMyLike = computed(() => detail.value.userAction?.like > 0);
const isMyFavorite = computed(() => detail.value.userAction?.favorite > 0);

const handleToggleAction = (type: string) => {
  detailsStore.handleToolStatusClick(type, 'bottom');
};
</script>

<style lang="less" scoped>
:deep(.action-btn.action-button) {
  width: 120px !important;
  border-radius: 24px;
  transition: none;
  &:hover {
    border-width: 2px;
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    .text {
      margin-top: 0;
      color: theme('colors.text.primary');
    }
  }
}

// 移动端适配 start
@media screen and (max-width: 767px) {
  :deep(.action-btn.action-button) {
    width: 100px !important;
    max-width: unset;
    max-height: unset;
  }
}
</style>
