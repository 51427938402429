<template>
  <NuxtLink
    :to="`/collection/${makingItem.id}`"
    :draggable="draggable"
    class="block-collection-item group/cover p-2"
    :data-sensors-exposure-event-name="SENSORS_EVENT.COLLECTION_BLOCK_EXPOSURE"
    :data-sensors-exposure-property-collection_id="makingItem.id"
    :data-sensors-exposure-property-collection_name="makingItem.title"
    :data-sensors-exposure-property-show_source="source"
    :data-sensors-exposure-property-block_id="blockId"
    :data-sensors-exposure-property-block_name="blockTitle"
    @click="handleSensorCollectionBlock(makingItem)"
  >
    <!-- 操作项 -->
    <div class="absolute right-4 top-4 z-10 hidden group-hover/cover:block mobile:block">
      <a-popover
        v-model:open="toggle"
        trigger="hover"
        placement="bottom"
        :z-index="100"
        :arrow="false"
        :overlay-inner-style="{ padding: '4px', minWidth: makingItem.userAction?.favorite == 1 ? '220px' : '170px' }"
        :auto-adjust-overflow="true"
      >
        <icon-font
          type="icon-more-fill"
          class="flex h-6 w-6 rotate-90 items-center justify-center rounded-full bg-[#fff] text-text-primary opacity-90"
          @click.prevent.stop
        />
        <template #content>
          <div
            class="flex cursor-pointer flex-nowrap gap-2 rounded-lg px-3 py-2.5 text-text-primary hover:bg-background-2"
            @click.stop="handleFavorite"
          >
            <icon-font
              :type="makingItem.userAction?.favorite == 1 ? 'icon-a-bookmark-fill' : 'icon-bookmark-line2'"
              :class="['text-[20px]', makingItem.userAction?.favorite == 1 ? 'text-red-default' : '']"
            />
            <span>{{ makingItem.userAction?.favorite == 1 ? 'Remove from Favorites' : 'Add to Favorites' }}</span>
          </div>
        </template>
      </a-popover>
    </div>
    <RatioBox :padding-top="'66.66%'">
      <img-group :imgs="makingItem.makingCovers || []" :draggable="draggable" class="cover h-full overflow-hidden rounded-lg" />
    </RatioBox>
    <div class="flex w-full flex-col justify-center gap-2 p-2 mobile:gap-1">
      <div class="line-clamp-1 whitespace-pre-line break-words text-level-3 font-semibold text-text-primary" :title="makingItem.title">
        {{ makingItem.title }}
      </div>

      <div class="my-0 flex items-center font-sans text-[12px] text-[#9e9e9e]">
        <icon-font type="icon-Collection1" class="mr-1 text-[16px]" />
        <span class="mr-3 leading-[1]">{{ formatNumberWithUnit(makingItem.quantity) }} project{{ makingItem.quantity > 1 ? 's' : '' }}</span>
        <icon-font type="icon-eye-line" class="mr-1 text-[16px]" />
        <span class="leading-[1]">{{ formatNumberWithUnit(makingItem.actionSum?.view) }} view{{ makingItem.actionSum?.view > 1 ? 's' : '' }}</span>
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { OBJECT_TYPE, SENSORS_EVENT } from '@/types';
// import type { ItemType } from '@/types/common';
import { doActionApi } from '@/api';

import { formatNumberWithUnit } from '~/utils/fns/number';
type ItemType = {
  makingCovers: string[];
  cover: string;
  title: string;
  id: number;
  quantity: number;
  createdByDeleted: boolean;
  createdByName: string;
  userAction: {
    favorite: number;
  };
  actionSum: {
    view: number;
  };
};
type PropsType = {
  makingItem: ItemType;
  actions?: string[];
  blockTitle?: string;
  blockId?: number | undefined;
  source?: string | number; // 埋点，来源
  draggable?: boolean; // 是否可拖拽
};
type EmitsType = {
  (e: 'changeFavorite', type: 'remove' | 'add'): void;
};

const emits = defineEmits<EmitsType>();
const props = withDefaults(defineProps<PropsType>(), {
  draggable: false,
});

const handleFavorite = async () => {
  const item = props.makingItem;
  if (item.userAction.favorite === 1) {
    handleSecondConfirm({
      apiFun: doActionApi,
      apiParams: {
        objectType: OBJECT_TYPE.COLLECTION,
        objectId: item.id,
        contentType: 'favorite',
        revoke: true,
      },
      type: 'removeCollection',
      cb: () => {
        emits('changeFavorite', 'remove');
        setTimeout(() => {
          item.userAction.favorite = 0;
        }, 200);
        handleSensorCollectContent(item, true);
      },
    });
  } else {
    const { data } = await doActionApi('favorite', {
      objectType: OBJECT_TYPE.COLLECTION,
      objectId: item.id,
      contentType: 'favorite',
      revoke: false,
    });
    if (data.value?.code === 0) {
      message.success('Successfully added to Favorites');
      emits('changeFavorite', 'add');

      setTimeout(() => {
        item.userAction.favorite = 1;
      }, 200);
    }
    handleSensorCollectContent(item, false);
  }
  toggle.value = false;
};

const { sensorCollectionBlockClick, sensorCollectContentEvent } = useSensors();
const handleSensorCollectionBlock = (makingItem: ItemType) => {
  sensorCollectionBlockClick({
    collection_id: makingItem.id,
    collection_name: makingItem.title,
    clickSource: props.source,
  });
};
const handleSensorCollectContent = (item: any, revoke: boolean) => {
  sensorCollectContentEvent({
    entry: 'block',
    content_id: item.id,
    content_name: item.title,
    service_type: 'collection',
    action_revoke: revoke,
  });
};

const toggle = ref(false);
</script>

<style lang="less" scoped>
.block-collection-item {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 12px;
  transition-property: box-shadow, transform;
  transition-duration: 0.2s;
  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    transform: translateY(-4px);
  }
  .cover {
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;
    }
  }
  &:hover .cover::after {
    opacity: 0.3;
  }
}
</style>
