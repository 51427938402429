<template>
  <div
    v-if="!isForSale || isXthings"
    class="remix-wrapper m-auto max-w-[900px] border-0 border-t border-solid border-[rgba(0,0,0,0.1)] pb-12 pt-12 mobile:pt-8"
  >
    <div class="flex justify-between pad:px-5 mobile:px-5">
      <div class="text-[20px] font-bold mobile:text-level-2">
        <span>Remixes</span>
        <span v-if="paging.total > 0">({{ paging.total }})</span>
      </div>
      <div v-if="remixList.length > 0 && supportRemix" class="flex items-center gap-2">
        <span v-if="textInfo.desc" class="text-[16px] text-[#999] mobile:hidden">
          {{ textInfo.desc }}
        </span>
        <df-btn :title="textInfo.btnText" @click="onGoToRemix('Add Remix')">
          {{ textInfo.btnText }}
        </df-btn>
      </div>
    </div>

    <!-- remix list box -->
    <div class="list-wrap pad:px-5 mobile:px-5">
      <a-config-provider>
        <template #renderEmpty>
          <div class="empty-box rounded-xl border border-solid border-[rgba(0,0,0,0.1)] px-8 py-7 mobile:p-4">
            <!-- <div class="empty-img">
              <img :src="supportRemix ? EmptyImg : NotAllowPng" alt="" />
            </div> -->
            <template v-if="supportRemix">
              <!-- <div v-if="!isXthings" class="empty-title">Add Remix</div> -->
              <div class="flex justify-between text-[#999]">
                <div class="empty-desc text-[18px] mobile:text-[14px]">{{ textInfo.desc }}</div>
                <span
                  class="add-btn flex cursor-pointer items-center text-[14px] font-semibold text-text-primary hover:text-text-primary"
                  :title="textInfo.btnText"
                  @click="onGoToRemix('Add First Remix')"
                >
                  {{ textInfo.btnText }}
                </span>
              </div>
            </template>
            <template v-else>
              <div class="not-allow">Remixing is disallowed by project's license policy.</div>
            </template>
          </div>
        </template>
        <a-list
          :data-source="remixList"
          :grid="{
            gutter: 12,
            column: 4,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 4,
          }"
        >
          <template #renderItem="{ item }">
            <a-list-item>
              <BlockCardItem :making-item="item" />
            </a-list-item>
          </template>
        </a-list>
      </a-config-provider>

      <BottomLoading
        v-if="remixList.length"
        class="mt-10"
        is-hide-bottom-line
        :loading="loadingStatus.isBottomeLoading"
        :finished="isFinished"
        @infinite="handleInfiniteLoading"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { goToXthingsPage } from '@/components/project-details/hook';
import { useDetailsStore } from '@/stores/details';
import { useLayoutStore } from '@/stores/layout';
import { CONTENT_TYPE } from '@/types';
import { getRemixListApi } from '~/api';

const siteUrl = import.meta.env.VITE_PUBLIC_SITE_URL;

const layoutStore = useLayoutStore();
const detailsStore = useDetailsStore();
const { $client } = useNuxtApp();
const { isLogin } = useLoginStatus();
const { sensorsRemixClick } = useSensors();

const detail = computed(() => detailsStore.detail);
const supportRemix = computed(() => detail.value.supportRemix);
const hasRight = computed(() => detail.value.hasRight);
const isForSale = computed(() => detail.value.forSale);
const isXthings = computed(() => detail.value.type === CONTENT_TYPE.XTHINGS);
const textInfo = computed(() => {
  // 若未购买且是 xThings 付费案例
  if (!hasRight.value && isXthings.value) {
    return {
      btnText: 'Buy Now',
      desc: 'You can buy xTool DIY Kit and add your remix.',
    };
  }

  // 若已购买，判断是否已经有 remix
  if (remixList.value.length > 0) {
    return {
      btnText: 'Add Remix',
      desc: '',
    };
  } else {
    return {
      btnText: 'Add First Remix',
      desc: 'There are no remixes yet.',
    };
  }
});

const loadingStatus = reactive({
  isBottomeLoading: false,
});
const isFinished = ref(false);
const paging = ref({
  current: 1,
  pageSize: 10,
  total: 0,
});

const remixList = ref<any[]>([]);
async function getBaseRemixList() {
  const params = {
    id: detail.value.id,
    current: paging.value.current,
    pageSize: paging.value.pageSize,
  };

  try {
    loadingStatus.isBottomeLoading = true;
    const res = await getRemixListApi.fetch(params);
    if (res?.code !== 0) {
      return;
    }
    let { list, pagination } = res.data ?? {};
    if (!list) {
      return;
    }
    paging.value.total = pagination?.total ?? 0;

    // 若不是第一页，则 push 拼接
    if (paging.value.current !== 1) {
      const arr = handleDataDeduplication(remixList.value, list) as any;
      remixList.value.push(...arr);
    } else {
      remixList.value = list || [];
    }

    // 若当前页是最后一页，则加载完毕
    const { current, total, pageSize } = pagination;
    if (current >= Math.ceil(total / pageSize)) {
      isFinished.value = true;
    } else {
      isFinished.value = false;
    }
  } catch (err) {
    console.log('getRemixList-err :>> ', err);
  } finally {
    loadingStatus.isBottomeLoading = false;
  }
}
// 触底加载
const handleInfiniteLoading = async () => {
  if (loadingStatus.isBottomeLoading || isFinished.value) {
    return;
  }
  paging.value.current++;
  getBaseRemixList();
};

import { useCreateEntry } from '@/utils/createHook/hooks';
const { handleEntry } = useCreateEntry();

function onGoToRemix(text: string) {
  sensorsRemixClick({
    remix_text: text,
  });

  // 检查是否登录
  if (!isLogin.value) {
    goToLogin();
    return;
  }

  // 检查是否有已购买
  if (!hasRight.value) {
    goToXthingsPage(detail.value.productInfo?.purchaseLink as string);
    return;
  }

  layoutStore.setRemixBaseOnLink([`${siteUrl}/${detail.value.type}/${detail.value.id}`]);

  handleEntry();
}
function goToLogin() {
  $client.openModal(() => {
    location.reload();
  });
}

getBaseRemixList();
</script>

<style lang="less" scoped>
.remix-wrapper {
  background-color: #fff;
}

.list-wrap {
  width: 100%;
  margin-top: 20px;
  :deep(.ant-list-empty-text) {
    padding: 0;
  }
}

.empty-img {
  width: 180px;
  img {
    width: 100%;
    object-fit: cover;
  }
}
.empty-title {
  margin-top: 16px;
  font-weight: theme('fontWeight.semibold');
  font-size: 20px;
  color: #000;
}

.empty-material-link {
  font-size: 18px;
  font-weight: theme('fontWeight.bold');
  color: #00755f;
  span {
    text-decoration: underline;
    white-space: pre;
  }
}

.not-allow {
  font-size: 18px;
  color: #999;
}

.material-tip {
  font-size: 16px;
  color: #999;
  text-align: center;
}
.material-link {
  font-size: 18px;
  font-weight: theme('fontWeight.bold');
  color: #00755f;
  text-decoration: underline;
}

// 移动端
@media screen and (max-width: 768px) {
  .remix-wrapper {
    // padding: 20px;
    width: 100%;
  }

  .empty-material-link {
    font-size: 16px;
  }

  .empty-img {
    width: 120px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .empty-title {
    font-size: 16px;
  }

  .not-allow {
    font-size: 14px;
  }

  .material-tip {
    font-size: 14px;
  }
}
</style>
