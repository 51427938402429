<template>
  <div class="h-full w-full">
    <img v-lazy="item.mimetype === 'image/gif' ? item.url : item.thumbnailUrl" class="h-full w-full cursor-expand object-cover" alt="" />

    <!-- ai做同款按钮 -->
    <div
      v-if="item.mimetype === 'image/ai'"
      :class="'absolute bottom-8 left-1/2 inline-flex -translate-x-1/2 cursor-pointer items-center justify-center gap-2 rounded-full bg-[#fff] px-4 py-2.5 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.1)] hover:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.15)]'"
      @click.stop="handleGenerateSimilar"
    >
      <div class="inline-flex h-5 w-5">
        <img :src="FirePng" class="w-full object-cover" alt="" />
      </div>
      <span class="text-level-4 font-bold text-text-primary">Generate Similar Images</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import FirePng from '~/assets/img/project-detail/fire.png';

type ItemType = {
  type: string;
  url: string;
  thumbnailUrl: string;
  mimetype?: string;
};
type PropsType = {
  item: ItemType;
};
const props = defineProps<PropsType>();
const emit = defineEmits(['generateSimilar']);

function handleGenerateSimilar() {
  emit('generateSimilar', props.item);
}
</script>

<style lang="less" scoped></style>
