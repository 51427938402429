<template>
  <a-modal
    :open="show"
    :footer="null"
    :z-index="zIndex"
    destroy-on-close
    centered
    :width="modalOptions.width"
    class="like-modal-wrapper"
    :body-style="{ padding: '40px 32px' }"
    transition-name=""
    @cancel="closeModal"
  >
    <div class="modal-content activity-modal-content">
      <div class="mb-6 flex justify-center mobile:mb-4">
        <img :src="imgUrl" alt="" class="h-[140px] w-[140px] mobile:h-[80px] mobile:w-[80px]" />
      </div>

      <div class="modal-title text-center text-[20px] font-bold leading-7 text-text-primary mobile:text-[14px]">
        You've successfully downloaded the file
      </div>

      <div
        class="mt-2 flex items-center justify-center gap-1 text-center text-level-2 text-text-secondary mobile:text-[12px]"
        :class="[{ 'mb-6': isForSale }]"
      >
        Give a Like, add to your favorites, leave a comment or share with others.
        <a-tooltip
          :title="`Downloaded files can't be directly shared with other users. If you wish to share, please distribute the link to the project page instead.`"
        >
          <QuestionCircleOutlined class="text-[20px] mobile:hidden" />
        </a-tooltip>
      </div>

      <div class="mt-6 flex justify-center">
        <div class="relative inline-flex justify-center gap-6 mobile:gap-3">
          <a-tooltip placement="top" title="like">
            <ActionButton
              shape="default"
              class="action-btn !w-[120px]"
              :size="72"
              :active="isMyLike"
              icon="icon-a-30-like"
              active-icon="icon-a-30-like-2"
              @click="handleClick('like')"
            />
          </a-tooltip>

          <a-tooltip placement="top" title="favorite">
            <ActionButton
              shape="default"
              class="action-btn !w-[120px]"
              :size="72"
              :active="isMyFavorite"
              icon="icon-bookmark-line2"
              active-icon="icon-a-bookmark-fill"
              @click="handleClick('favorite')"
            />
          </a-tooltip>

          <a-tooltip placement="top" title="comment">
            <ActionButton :size="72" class="action-btn !w-[120px]" icon="icon-comment" @click="handleComment" />
          </a-tooltip>

          <a-tooltip placement="top" title="Share">
            <CommonSharePot :trigger="'click'" :title="shareTitle" :jump="false" :jump-el="jumpRef" :description="shareDescription" :cb="handleShare">
              <ActionButton :size="72" class="action-btn !w-[120px]" icon="icon-share-line" />
            </CommonSharePot>
          </a-tooltip>

          <!-- 跳跃的元素(会被克隆) -->
          <!-- <div
            id="jump-element"
            ref="jumpRef"
            class="jump-box absolute left-[100%] top-0 w-[56px] -translate-x-1/2 -translate-y-1/2 rounded-lg bg-status-alert py-0.5 text-[#fff]"
          >
            <div class="jump-inner flex items-center justify-center gap-1">
              <icon-font class="text-[16px]" type="icon-database-2-fill" />
              <span class="font-semibold">+10</span>
            </div>
          </div> -->
        </div>
      </div>

      <div v-if="!isForSale" class="mt-6 flex flex-col items-center">
        <div
          class="inline-flex cursor-pointer gap-1 px-4 py-1.5 text-level-4 font-semibold text-text-primary hover:font-bold"
          @click="onGoToRemix('Go to Remix')"
        >
          <span>Add Remix</span>
          <icon-font class="text-[16px]" type="icon-a-30-arrow-right-s-line" />
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script lang="ts" setup>
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import imgSrc from '~/assets/img/df-ip/download.png';
import { useSensors } from '~/composables/sensors';
import { OBJECT_TYPE } from '~/types';

const { sensorsDownpopLike, sensorsDownpopCollect, sensorsDownpopShare, sensorsDownpopComment, sensorsRemixClick } = useSensors();

type PropsType = {
  show: boolean;
  isMyLike: boolean;
  isMyFavorite: boolean;
  detail: any;
  zIndex?: number;
  isForSale?: boolean;
};
const props = withDefaults(defineProps<PropsType>(), {
  zIndex: 1002,
});
type EmitsType = {
  (event: 'update:show', value: boolean): void;
  (event: 'handleClick', type: string): void;
  (event: 'handleComment'): void;
  (event: 'handleGoToRemix'): void;
};
const emits = defineEmits<EmitsType>();

const { isMobile } = useScreen();

const shareTitle = computed(() => props.detail.title);
const shareDescription = computed(() => props.detail.summary);
const modalOptions = computed(() => {
  const width = isMobile.value ? 'auto' : '700px';
  return {
    width,
  };
});

function getBase64Image(img: any) {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx?.drawImage(img, 0, 0, img.width, img.height);
  const dataURL = canvas.toDataURL('image/jpg');
  return dataURL;
}
const imgUrl = ref('');
function initImg() {
  let img = document.createElement('img');
  img.src = imgSrc;
  img.crossOrigin = 'anonymous';
  img.onload = () => {
    imgUrl.value = getBase64Image(img);
  };
}
initImg();

const handleClick = (type: string) => {
  // emits('handleClick', type);
  handleActionClick(type);
  if (type === 'like') {
    sensorsDownpopLike({
      content_id: props.detail.id,
      content_name: props.detail.title,
      publish_time: props.detail.publishedAt,
    });
  } else if (type === 'favorite') {
    sensorsDownpopCollect({
      content_id: props.detail.id,
      content_name: props.detail.title,
      publish_time: props.detail.publishedAt,
    });
  }
};

function handleActionClick(type: string) {
  const detailInfo = props.detail;
  const hasCookie = !!useMiddleCookie('utoken')?.value;
  if (detailInfo.userAction[type as keyof typeof detailInfo.userAction] === 0) {
    handleToggleAction(OBJECT_TYPE.MAKING, detailInfo, type, 'dialog', hasCookie);
  } else {
    if (type === 'favorite') {
      message.info("you've added to favorites.");
    } else {
      message.info("you've given a like.");
    }
  }
}

const closeModal = () => {
  emits('update:show', false);
};

function handleShare() {
  sensorsDownpopShare({
    content_id: props.detail.id,
    content_name: props.detail.title,
    publish_time: props.detail.publishedAt,
  });
}

function handleComment() {
  closeModal();
  emits('handleComment');
  sensorsDownpopComment({
    content_id: props.detail.id,
    content_name: props.detail.title,
    publish_time: props.detail.publishedAt,
  });
}

function onGoToRemix(text: string) {
  sensorsRemixClick({
    remix_text: text,
  });
  emits('handleGoToRemix');
  closeModal();
}

const jumpRef = ref<HTMLElement | null>(null);
</script>

<style lang="less" scoped>
:deep(.action-btn.action-button) {
  border-radius: 12px;
  transition: none;
  &:hover {
    border-width: 2px;
  }
}
</style>
