<template>
  <div v-if="paraSettings.length > 0" class="setting-info mb-8">
    <h3 class="mb-0 text-xl font-bold text-text-primary pad:text-level-2 mobile:text-level-2">Setting</h3>

    <div class="mt-4 flex flex-col gap-2">
      <div v-for="(item, index) in paraSettings" :key="item.uid">
        <ClientOnly>
          <TextSetting v-if="item.type === 'custom'" v-model:value="item.custom" :setting="item" :index="index" is-preview :show-icon="false" />
          <XcsSetting v-if="item.type == 'xcs'" :setting="item" :index="index" is-preview />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextSetting from '@/components/share/project/instruction/textSetting.vue';
import XcsSetting from '@/components/share/project/instruction/xcsSetting.vue';
import { useDetailsStore } from '@/stores/details';

const detailsStore = useDetailsStore();

const detail = computed(() => detailsStore.detail);
const paraSettings = computed(() => detail.value.paraSettings ?? []);
</script>

<style lang="less" scoped></style>
