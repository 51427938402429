<template>
  <a-modal :open="visible" :footer="null" wrap-class-name="select-list-modal" width="800px" :mask-closable="false" @cancel="close">
    <div class="px-8 text-level-1 font-bold text-text-primary">Project file preview</div>
    <p class="px-8 text-text-tertiary">Select the file for which you want the parameters to be automatically filled in.</p>
    <div class="list-wrap my-6 pl-8 pr-[24px]">
      <client-only>
        <a-list
          ref="list"
          :data-source="showList"
          :grid="{
            gutter: 24,
            column: 1,
          }"
        >
          <template #renderItem="{ item, index }">
            <a-list-item class="rounded-lg bg-background-2">
              <design-item
                :ref="
                  (el: any) => {
                    listRef[index] = el;
                  }
                "
                :key="'modal' + index"
                :item="item"
                @radio-check="radioCheck"
                @preview-action="handlePreview(item)"
              />
            </a-list-item>
          </template>
        </a-list>
      </client-only>
    </div>
    <div class="footer py-5">
      <df-btn @click="close">Cancel</df-btn>
      <df-btn type="primary" :disabled="selectIdx == -1" style="width: 80px" @click="next">Next</df-btn>
    </div>
  </a-modal>
</template>
<script lang="ts" setup>
import designItem from './xsDesignItem.vue';
type Props = {
  visible: boolean;
  fileList: any[];
};
const props = defineProps<Props>();
type emitsType = {
  (event: 'update:visible', value: boolean): void;
  (event: 'goProjectType'): void;
  (event: 'next', value: any): void;
};
const emit = defineEmits<emitsType>();
const close = () => {
  emit('update:visible', false);
};
const next = async () => {
  // 选择后去画布弹窗 选择display
  emit('next', selectIdx.value);
};
const selectIdx = ref(-1);
const radioCheck = (item: any) => {
  if (selectIdx.value !== -1) {
    showList.value[selectIdx.value].selectStatus = false;
  }

  const idx = showList.value.findIndex((i: any, idx: number) => idx === item.idx);
  showList.value[idx].selectStatus = true;
  selectIdx.value = idx;
};
const showList = ref([] as any);
watch(
  () => props.visible,
  () => {
    showList.value = props.fileList.map((item: any, idx) => {
      return {
        ...item,
        idx,
        selectStatus: false,
      };
    });
    selectIdx.value = -1;
  },
);

const listRef = ref([]) as any;
const handlePreview = async (item: any) => {
  radioCheck(item);
  listRef.value[selectIdx.value].closePrev();
};
</script>

<style lang="less" scoped>
p {
  margin: 0;
}

.select-list-modal {
  .list-wrap {
    height: 365px;
    overflow-y: scroll;
    :deep(.ant-list-item) {
      margin-bottom: 8px;
    }
    /* 滚动条整体 */
    &::-webkit-scrollbar {
      width: 8px;
    }
    /* 两个滚动条交接处 -- x轴和y轴 */
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius: 22px;
      &:vertical {
        background-color: #eee;
      }
    }
    /* 滚动条轨道 */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    gap: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 768px) {
  .select-list-modal {
    .select-list-title {
      font-size: 18px;
    }
  }
}
</style>
<style lang="less">
.select-list-modal {
  .ant-modal-body {
    padding: 24px 0 0;
  }
  .ant-btn-primary:disabled {
    border-color: transparent;
  }
  div[aria-hidden='true'] {
    display: none !important;
  }
}
</style>
