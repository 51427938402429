import { defineStore } from 'pinia';
export const useUserDesignFile = defineStore('userDesignFile', {
  state: () => {
    return {
      showProjectTypeModal: false,
      designIds: [] as number[],
    };
  },
  actions: {
    toggleProjectTypeModal(bool: boolean) {
      this.showProjectTypeModal = bool;
    },
    updateId(id: number[]) {
      this.designIds = id;
    },
  },
});
