export enum ModeType {
  Free = 'free', // 免费
  Sale = 'sale', // 付费
}
export enum StatuType {
  New = 'new', // 未发布的
  Publish = 'published', // 已发布的
}
export enum SourceType {
  Original = 'original', // 原创
  Remix = 'remix', // 二创
}
export interface BundleItem {
  title: string; // 标题
  summary: string; // 概要
  cover: string; // 封面图
  images: string[]; // 图片
  videos: string[]; // 视频
  contentTags: string[]; // 内容标签
  forSale: boolean; // 是否售卖
  aiAssistance: boolean; // 是否有AI辅助生成
  status: StatuType; // 状态
  extra: any; // 其它信息
  bundles: number[]; // 捆绑商品（makingId集合）
}

export interface DesignItem {
  title: string; // 标题
  summary: string; // 概要
  cover: string; // 封面图
  images: string[]; // 图片
  videos: string[]; // 视频
  contentTags: string[]; // 内容标签
  forSale: boolean; // 是否售卖
  aiAssistance: boolean; // 是否有AI辅助生成
  status: string; // 状态
  extra: any; // 其它信息
  attachFiles: any[]; // 附件
  source: SourceType; // 来源
  licenseId: number | string; // 授权协议id
  scenarios: string[]; // 应用场景
  origPriceCent: number | undefined; // 价格/美分
}

export interface CoverAutoTag {
  initAi: boolean;
  loading: boolean;
  title: string;
  podTags: string[];
  tags: string[];
  shareAiImg: string;
  shareAiGif: string;
}

export enum MakingType {
  Project = 'project',
  Bundle = 'bundle',
}
