import { dispatchClick } from './click';

/**
 * 根据链接下载文件
 *
 * @date 21/05/2021
 * @param {string} url
 * @param {string} [fname]
 */
export const forceDownload = (url: string, fname?: string) => {
  const link = document.createElement('a');
  link.setAttribute('download', fname || 'image.png');
  link.setAttribute('href', url);
  link.setAttribute('target', '_blank');
  dispatchClick(link);
};

/**
 *
 *
 * @date 21/05/2021
 * @param {string} fname
 * @param {Blob} blob
 * @return {*}
 */
export const downloadBlob = (fname: string, blob: Blob): void => {
  const url = window.URL.createObjectURL(blob);
  forceDownload(url, fname);
  window.URL.revokeObjectURL(url);
};
