<template>
  <SecondaryAddBtn v-if="showSecondaryBtn" class="mb-8" title="Add Print on demand tags" @click="handleAdd" />
  <a-form-item v-else name="printTags" class="new-ant-style">
    <template #label>
      <span>
        Print on demand tags
        <a-tooltip :title="title">
          <icon-font class="relative top-[1px] ml-1 text-text-tertiary hover:text-black-hover" type="icon-question-line" />
        </a-tooltip>
      </span>
    </template>
    <ShareCommonSelectBtn v-if="printTags && printTags.length" class="mb-3" :list="printTags" @remove="handleRemove" />
    <df-select
      v-model:value="selected"
      placeholder="Press enter or add , or # to separate; for example : T-shirts ,  Greeting cards , Posters"
      mode="tags"
      :options="customTagList"
      :field-names="{ label: 'name', value: 'name' }"
      @change="handleChanged"
    />
    <div v-if="coverAutoTag.initAi" class="mt-3 flex flex-wrap items-center gap-2 text-[#191919]">
      <template v-if="coverAutoTag.loading">
        <img :src="coverAutoTag.shareAiGif" alt="" width="20" height="20" />
        <span>AI Generating...</span>
      </template>
      <div
        v-for="item in tagList"
        :key="item"
        class="cursor-pointer rounded-full bg-background-2 px-3 py-[6px] hover:bg-background-3 hover:text-black-hover"
        @click="handleAddTag(item)"
      >
        <icon-font class="mr-2" type="icon-Add" />
        <span>{{ item }}</span>
      </div>
    </div>
  </a-form-item>
</template>
<script setup lang="ts">
import { getBaseDataApi } from '@/api/common';
import SecondaryAddBtn from '@/components/share/common/SecondaryAddBtn.vue';
import { handleSensorEditClick } from '@/components/share/utils';
import type { CoverAutoTag } from '@/types/share';
import { type PropType } from 'vue';
import { COMMUNITY_BASE_DATA } from '~/types/constant';
const showSecondaryBtn = ref(true);

const handleAdd = () => {
  showSecondaryBtn.value = false;
};
const printTags = defineModel('printTags', { type: Array as PropType<any>, default: () => [] });
const customTagList = ref([]);
const selected = ref<string[]>([]);
const props = defineProps<{
  coverAutoTag: CoverAutoTag;
}>();
const handleChanged = (val: any) => {
  console.log(['vals', val]);
  // 输入#和,自动分割成两个tag
  let result = val.flatMap((item: string) => {
    if (item.includes('#') || item.includes(',')) {
      return item.split(/[#,]/).filter((part) => part !== '');
    } else {
      return item;
    }
  });

  printTags.value = [...new Set([...printTags.value, ...result])];
  selected.value = [];
};
const title =
  'Print on demand tags are often used to indicate that a pattern or design is suitable for use on a specific printed product. For example, if a pattern is suitable for printing on T-shirts, the POD tag could add T- Shirts.';
const handleRemove = (val: string) => {
  printTags.value = printTags.value.filter((item: string) => item !== val);
};
const getBaseDesignScenario = async () => {
  const { data: baseScenarioData, error: baseScenarioDataError } = await getBaseDataApi(COMMUNITY_BASE_DATA.SCENARIO);
  if (!baseScenarioDataError.value && baseScenarioData.value?.data?.length) {
    customTagList.value = baseScenarioData.value.data.map((item: any) => {
      return {
        ...item,
        value: item.name,
        label: item.name,
      };
    });
  }
};
const tagList = computed(() => {
  return [...props.coverAutoTag.podTags].filter((item: any) => {
    const matchTag = printTags.value.includes(item);
    return !matchTag;
  });
});
const handleAddTag = (tagName: string) => {
  printTags.value = [...new Set([...printTags.value, tagName])];
  handleSensorEditClick({ name: 'AI POD Use', content: tagName });
};
watchEffect(() => {
  if (!showSecondaryBtn.value) {
    return;
  }
  if (printTags.value?.length) {
    showSecondaryBtn.value = false;
  }
});
onMounted(() => {
  nextTick(() => {
    getBaseDesignScenario();
  });
});
</script>
<style lang="less" scoped>
.select-btn {
  :deep(&.ant-btn.ant-btn-round) {
    border-radius: 8px;
    box-shadow: none;
    border: 1.5px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    font-weight: 400;
  }
}
</style>
