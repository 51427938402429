// import { arrayBufferToBase64 } from './base64-arraybuffer';

// const makeCanvas = (width: number, height: number) => {
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
//   canvas.width = width;
//   canvas.height = height;
//   return { canvas, ctx };
// };

// export const base64ImageUrl = (data: ArrayBuffer, type = 'image/jpeg') => {
//   const base64 = arrayBufferToBase64(data);
//   return `data:${type};base64,${base64}`;
// };

// export const image2ImageData = (img: HTMLImageElement) => {
//   const { naturalWidth: width, naturalHeight: height } = img;
//   const { ctx } = makeCanvas(width, height);
//   ctx.drawImage(img, 0, 0, width, height);
//   return ctx.getImageData(0, 0, width, height);
// };

// export function imageDataToCanvas(imageData: ImageData) {
//   const canvas = document.createElement('canvas');
//   canvas.width = imageData.width;
//   canvas.height = imageData.height;
//   const ctx = canvas.getContext('2d');
//   ctx?.putImageData(imageData, 0, 0);
//   return canvas;
// }

// export const imageData2Image = (
//   data: ImageData,
//   type = 'image/jpeg',
// ): Promise<HTMLImageElement> => {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.src = imageData2DataURL(data, type);
//     img.onload = () => {
//       resolve(img);
//     };
//     img.onerror = reject;
//   });
// };

// export const imageData2DataURL = (data: ImageData, type = 'image/jpeg') => {
//   const { canvas, ctx } = makeCanvas(data.width, data.height);
//   ctx?.putImageData(data, 0, 0);
//   return canvas.toDataURL(type);
// };

// export const blob2Image = (file: Blob): Promise<HTMLImageElement> => {
//   return new Promise(async (resolve, reject) => {
//     const url = URL.createObjectURL(file);
//     const img = new Image();
//     img.src = url;
//     img.onload = () => {
//       URL.revokeObjectURL(url);
//       resolve(img);
//     };
//     img.onerror = reject;
//   });
// };

// File/Blob对象转DataURL
export const fileOrBlobToDataURL = (obj: Blob): Promise<string | boolean> => {
  return new Promise(async (resolve, reject) => {
    const a = new FileReader();
    a.readAsDataURL(obj);
    a.onload = function (e) {
      if (typeof e.target?.result === 'string') {
        resolve(e.target?.result);
      } else {
        return false;
      }
      a.onerror = reject;
    };
  });
};

// export const fileToImageData = async (file: Blob) => {
//   const img = await blob2Image(file);
//   return image2ImageData(img);
// };

// export const blackeningImageData = (imgData: Uint8ClampedArray) => {
//   const arr = new Uint8ClampedArray(imgData.length);
//   for (let i = 0; i < imgData.length; i += 4) {
//     const r = imgData[i];
//     const g = imgData[i + 1];
//     const b = imgData[i + 2];
//     const a = imgData[i + 3];
//     if (a === 0) {
//       arr.set([r, g, b, a], i);
//     } else if (r !== 255 || g !== 255 || b !== 255) {
//       arr.set([0, 0, 0, a], i);
//     } else {
//       arr.set([0, 0, 0, 0], i);
//     }
//   }
//   return arr;
// };
