<template>
  <a-button v-bind="bindProps">
    <Content />
    <template v-if="isRenderIcon" #icon>
      <Icon :icon="icon" />
    </template>
  </a-button>
</template>

<script setup lang="tsx">
import { ShapeFlags } from '@vue/shared';
import { isString, isFunction, isObject, isNil, omit, isArray } from 'lodash-es';
import type { ButtonType, ButtonSize, ButtonShape } from 'ant-design-vue/es/button';
import type { DfBtnProps, DfBtnIcon } from './types';
import { useNamespace } from '../_hooks/use-namespace';

const props = withDefaults(defineProps<DfBtnProps>(), {
  type: 'default',
  size: 'middle',
  shape: 'round',
});

const ns = useNamespace('btn');

const isRenderIcon = computed(() => !isNil(props.icon));

const aBtnTy = computed<ButtonType>(() => {
  if (isRenderIcon.value) {
    return 'text';
  } else if (['primary', 'highlight', 'filled'].includes(props.type)) {
    return 'primary';
  } else if (['default', 'tertiary', 'special'].includes(props.type)) {
    return 'default';
  } else {
    return props.type as ButtonType;
  }
});
const aBtnShape = computed<ButtonShape>(() => {
  if (isRenderIcon.value) {
    return 'circle';
  }
  if (props.type === 'link') {
    return undefined as any;
  }
  return props.shape !== 'square' ? props.shape : 'default';
});
const aBtnSize = computed<ButtonSize>(() => {
  if (props.size === 'mini' || props.size === 'tiny') {
    return 'small';
  } else {
    return props.size;
  }
});

const type = computed(() => (!isRenderIcon.value ? props.type : ''));
const shape = computed(() => {
  const shape = props.shape !== 'default' ? props.shape : 'square';
  return !isRenderIcon.value ? shape : '';
});

const cls = computed(() => [
  ns.b(),
  ns.m(type.value),
  ns.m(props.size),
  ns.m(shape.value),
  ns.is('block', props.block),
  ns.is('icon', isRenderIcon.value),
]);

const bindProps = computed(() => {
  return omit(
    {
      ...props,
      type: aBtnTy.value,
      size: aBtnSize.value,
      shape: aBtnShape.value,
      class: cls.value,
    },
    ['icon'],
  );
});

const slots = useSlots();
const Icon = (props: { icon?: DfBtnIcon; placement?: 'start' | 'end' }) => {
  if (isString(props.icon)) {
    return <icon-font type={props.icon} class={props.placement || ''} />;
  } else if (isFunction(props.icon) || isObject(props.icon)) {
    return h(props.icon);
  } else {
    return null;
  }
};
const Content = () => {
  if (isRenderIcon.value) {
    return null;
  } else {
    let vnodes: any = slots.default?.();
    if (isArray(vnodes) && vnodes.length > 0) {
      vnodes = vnodes.map((vnode) => {
        // 文本节点需要包裹 span
        if (vnode.shapeFlag === ShapeFlags.TEXT_CHILDREN) {
          return <span>{vnode}</span>;
        } else {
          return vnode;
        }
      });
    }
    return (
      <>
        {props.startIcon ? <Icon icon={props.startIcon} placement="start" /> : null}
        {vnodes ?? <span>{props.label}</span>}
        {props.endIcon ? <Icon icon={props.endIcon} placement="end" /> : null}
      </>
    );
  }
};
</script>

<style scoped lang="less">
.df-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0;
  font-size: 14px;
  line-height: 20px;

  &.is-block {
    display: flex;
    width: 100%;
  }

  &&--default,
  &&--tertiary,
  &&--special,
  &&--highlight,
  &&--primary,
  &&--filled {
    &.df-btn--large {
      height: 48px;
      line-height: 22px;
    }
    &.df-btn--middle {
      height: 40px;
    }
    &.df-btn--small {
      height: 36px;
    }
    &.df-btn--mini {
      height: 32px;
    }

    &.df-btn--large {
      font-size: 16px;
      font-weight: 700;
    }
    &.df-btn--middle,
    &.df-btn--small,
    &.df-btn--mini {
      font-size: 14px;
      font-weight: 600;
    }

    &.df-btn--large,
    &.df-btn--middle,
    &.df-btn--small {
      padding-inline-start: 16px;
      padding-inline-end: 16px;
    }
    &.df-btn--mini {
      padding-inline-start: 12px;
      padding-inline-end: 12px;
    }

    &.df-btn--large {
      border-radius: 24px;
    }
    &.df-btn--middle {
      border-radius: 20px;
    }
    &.df-btn--small {
      border-radius: 18px;
    }
    &.df-btn--mini {
      border-radius: 16px;
    }

    &.df-btn--square {
      border-radius: 6px;
    }

    &.df-btn--large,
    &.df-btn--middle {
      :deep(.anticon) {
        font-size: 24px;
      }
      :deep(span + .anticon) {
        font-size: 20px;
      }
    }
    &.df-btn--small {
      :deep(.anticon + span),
      :deep(span + .anticon) {
        margin-inline-start: 4px;
      }

      :deep(.anticon) {
        font-size: 20px;
      }
      :deep(span + .anticon) {
        font-size: 16px;
      }
    }
    &.df-btn--mini {
      :deep(.anticon + span),
      :deep(span + .anticon) {
        margin-inline-start: 4px;
      }

      :deep(.anticon) {
        font-size: 16px;
      }
    }
  }

  &&--highlight,
  &&--primary,
  &&--filled,
  &&--default,
  &&--tertiary,
  &&--special {
    border-width: 0;
  }

  &&--highlight {
    color: #fff;
    background-color: #ff0035;

    &:hover {
      background-color: #ff4159;
    }
    &:active {
      background-color: #ef0041;
    }
    &:disabled {
      background-color: #ff0035;
      opacity: 0.4;
    }
  }
  &&--primary {
    color: #fff;
    background-color: #1a1a1a;

    &:hover {
      background-color: #353538;
    }
    &:active {
      background-color: #000000;
    }
    &:disabled {
      background-color: #cdcdcd;
    }
  }
  &&--filled {
    border: none;
    border-radius: 24px;
    color: #1a1a1a;
    background-color: #f5f7fb;

    &.df-btn--square {
      border-radius: 6px;
    }

    &:hover {
      color: #353538;
      background-color: #f0f1f5;
    }
    &:active {
      color: #000;
      background-color: #e9eaf0;
    }
    &:disabled {
      color: #cdcdcd;
      background-color: #f0f1f5;
    }

    &.df-btn--tiny {
      padding-inline-start: 8px;
      padding-inline-end: 8px;

      height: 24px;
      font-size: 12px;
      font-weight: 600;

      :deep(.anticon + span),
      :deep(span + .anticon) {
        margin-inline-start: 4px;
      }

      :deep(.anticon) {
        font-size: 14px;
      }
      :deep(span + .anticon) {
        font-size: 12px;
      }
    }
  }
  &&--default {
    --color: #1a1a1a;
    --line-width: 2px;
    --bg: transparent;

    color: var(--color);
    border-color: var(--color);
    box-shadow: 0 0 0 var(--line-width) var(--color) inset;
    background-color: var(--bg);

    &:hover {
      --color: #353538;
      --bg: #f0f1f5;
    }
    &:active {
      --color: #000;
      --bg: #e9eaf0;
    }
    &:disabled {
      --color: #cdcdcd;
      --bg: #f0f1f5;
    }

    &.df-btn--large {
      --line-width: 2px;
    }
    &.df-btn--middle,
    &.df-btn--small,
    &.df-btn--mini {
      --line-width: 1.5px;
    }
  }
  &&--special {
    --color: #ff7c23;
    --line-width: 1.5px;
    --bg: transparent;

    color: var(--color);
    border-color: var(--color);
    box-shadow: 0 0 0 var(--line-width) var(--color) inset;
    background-color: var(--bg);

    &:hover {
      --color: #ff9432;
    }
    &:active {
      --color: #fd7326;
    }
    &:disabled {
      opacity: 0.4;
      --color: #ff7c23;
      --bg: #ff7c231a;
    }
  }
  &&--tertiary {
    color: var(--color, #1a1a1a);
    border-color: var(--color);
    box-shadow: 0 0 0 var(--line-width, 1.5px) var(--color, #1a1a1a1a) inset;
    background-color: var(--bg, transparent);

    &:hover {
      --color: #353538;
      --bg: #f0f1f5;
    }
    &:active {
      --color: #000;
      --bg: #e9eaf0;
    }
    &:disabled {
      --color: #cdcdcd;
      --bg: #f0f1f5;
    }
  }
  &&--text {
    border: none;
    border-radius: 24px;
    font-weight: 400;

    &.df-btn--large,
    &.df-btn--middle {
      height: 32px;
      padding-inline-start: 12px;
      padding-inline-end: 12px;
      font-size: 14px;
    }
    &.df-btn--small,
    &.df-btn--mini {
      height: 24px;
      padding-inline-start: 8px;
      padding-inline-end: 8px;
      font-size: 12px;
    }

    &:hover {
      color: #353538;
      background-color: #f0f1f5;
    }
    &:active {
      color: #000;
      background-color: #e9eaf0;
    }
    &:disabled {
      color: #cdcdcd;
      background-color: #f0f1f5;
    }
  }
  &&--link {
    display: inline-flex;
    border: none;
    height: auto;
    line-height: 16px;
    font-size: 14px;
    color: #287cf6;
    &:hover {
      color: #3e89f6;
      text-decoration: underline;
      text-decoration-color: #3e89f6;
    }
    &:active {
      color: #1e69d6;
      text-decoration: underline;
      text-decoration-color: #1e69d6;
    }
    &[disabled] {
      opacity: 0.4;
      text-decoration: none;
    }
  }
  &.is-icon {
    border-radius: 50%;
    color: #000;

    &:hover {
      background-color: #f0f1f5;
    }
    &:active {
      background-color: #e9eaf0;
    }
    &:disabled {
      background-color: transparent;
    }

    &.df-btn--large,
    &.df-btn--middle {
      width: 40px;
      height: 40px;
      font-size: 24px;
    }
    &.df-btn--small {
      width: 32px;
      height: 32px;
      font-size: 20px;
    }
    &.df-btn--mini {
      width: 24px;
      height: 24px;
      font-size: 16px;
    }
  }
}
</style>
