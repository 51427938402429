<template>
  <div v-if="status !== 'deactive'" class="absolute inset-0 z-[30] flex flex-col" @click.prevent.stop="">
    <div class="relative w-full overflow-hidden pt-[75%]">
      <img
        :key="item.id"
        :alt="item.title"
        :src="getOssProcessedImage(item?.extra?.blockCover || item.cover, 'm_fill,w_800,h_600', 'webp')"
        class="absolute left-0 top-0 h-full w-full rounded-[8px] object-cover blur-lg"
      />
      <div class="absolute inset-0 rounded-[8px] bg-[#000] opacity-70"></div>
      <div v-if="status === 'selecting'" class="absolute inset-0 flex flex-col items-center justify-center">
        <h5 class="mb-5 text-level-3 font-semibold text-[#FFF]">Tell us why you are closing</h5>
        <div
          class="mb-3 h-[36px] w-[160px] rounded-[24px] border-[1.5px] border-solid border-[#FFF] text-center text-level-4 leading-[36px] text-[#FFF]"
          @click="onSelect(Reason.IRRELEVANT)"
        >
          {{ ReasonMap[Reason.IRRELEVANT] }}
        </div>
        <div
          class="mb-3 h-[36px] w-[160px] rounded-[24px] border-[1.5px] border-solid border-[#FFF] text-center text-level-4 leading-[36px] text-[#FFF]"
          @click="onSelect(Reason.REPETITIVE)"
        >
          {{ ReasonMap[Reason.REPETITIVE] }}
        </div>
        <div
          class="mb-3 h-[36px] w-[160px] rounded-[24px] border-[1.5px] border-solid border-[#FFF] text-center text-level-4 leading-[36px] text-[#FFF]"
          @click="onSelect(Reason.DISLIKE_THIS_AUTHOR)"
        >
          {{ ReasonMap[Reason.DISLIKE_THIS_AUTHOR] }}
        </div>
      </div>
      <div v-if="['selected', 'locked'].includes(status)" class="absolute inset-0 flex flex-col items-center justify-center">
        <icon-font type="icon-unlike" class="mb-5 text-[32px] text-[#FFF]" />
        <div class="text-level-4 text-[#FFF] opacity-70">See fewer projects like this</div>
      </div>
    </div>
    <div class="flex flex-1 items-center justify-center space-x-2 bg-[#FFF] text-text-primary" @click="onRecall">
      <icon-font type="icon-retry" class="text-base" />
      <span class="text-level-4 font-semibold">Recall</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import type { PropType } from 'vue';
import { addMakingPreference, deleteMakingPreference } from '~/api/preference';

const props = defineProps({
  item: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
});
const status = defineModel('status', {
  type: String as PropType<'deactive' | 'selected' | 'selecting' | 'locked'>,
  default: 'deactive',
});

const { sensorForYouSeeFewerClick } = useSensors();
const track = (element_name: string) => {
  const item = props.item;
  sensorForYouSeeFewerClick({
    content_id: item.id + '',
    content_type: item.forSale ? 'paid' : 'free',
    content_name: item.title,
    service_type: item.type,
    element_name,
  });
};

const key = computed(() => `DF_PF_${props.item.id}`);
const cache = reactive({
  recordId: null as number | null,
  reason: null as Reason | null,
  setTime: 0,
});

const TIMEOUT = (2 * 60 + 5) * 1000;
const ticker = useTicker(() => {
  if (status.value === 'selected') {
    status.value = 'locked';
    localStorage.removeItem(key.value);
  }
}, TIMEOUT);
onMounted(() => {
  const json = localStorage.getItem(key.value);
  if (isNil(json)) {
    return;
  }
  const local = JSON.parse(json);
  if (isNil(local)) {
    localStorage.removeItem(key.value);
    return;
  }
  const star = local.setTime;
  const current = dayjs().unix();
  const period = TIMEOUT - (current - star);
  if (period <= 0) {
    localStorage.removeItem(key.value);
    return;
  }
  ticker.reset({ period });
  cache.recordId = local.id;
  cache.reason = local.reason;
  status.value = 'selected';
});

const enum Reason {
  IRRELEVANT = 'irrelevant',
  REPETITIVE = 'repetitive',
  DISLIKE_THIS_AUTHOR = 'dislike-this-author',
}
const ReasonMap = {
  [Reason.IRRELEVANT]: 'Irrelevant',
  [Reason.REPETITIVE]: 'Repetitive',
  [Reason.DISLIKE_THIS_AUTHOR]: 'Dislike this author',
};
const onSelect = async (reason: Reason) => {
  try {
    const res = await addMakingPreference.fetch({
      cid: Cookie.get('cid') as string,
      makingId: props.item.id,
      reason,
    });
    if (res.code !== RespCode.SUCCESS) {
      throw new Error(res.message);
    }

    cache.recordId = res.data.id;
    cache.reason = reason;
    localStorage.setItem(
      key.value,
      JSON.stringify({
        id: res.data.id,
        reason: reason,
        setTime: dayjs().unix(),
      }),
    );
    status.value = 'selected';
    ticker.reset();
    track(ReasonMap[reason]);
  } catch (err) {
    console.error(err);
  }
};
const onRecall = async () => {
  if (status.value === 'locked' || (status.value !== 'selecting' && isNil(cache.recordId))) {
    message.info('cannot be recalled after 2 minutes');
  } else if (status.value === 'selected' && !isNil(cache.recordId)) {
    try {
      const res = await deleteMakingPreference.fetch({ id: cache.recordId });
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message);
      }
      status.value = 'deactive';
      localStorage.removeItem(key.value);
      ticker.pause();

      track(cache.reason + '_recall');

      cache.recordId = null;
      cache.reason = null;
    } catch (err) {
      console.error(err);
    }
  } else {
    status.value = 'deactive';
  }
};
</script>
