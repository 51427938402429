import { initStates, isValidURL, useFileMethods } from '@/components/project-details/hook';
import { useDetailsStore } from '@/stores/details';
import { storeToRefs } from 'pinia';
import PictureCoverPng from '~/assets/img/project-detail/picture_cover.png';
import TxtCoverPng from '~/assets/img/project-detail/txt_cover.png';
import UnknownCoverPng from '~/assets/img/project-detail/unknown_cover.png';
import XcsCoverPng from '~/assets/img/project-detail/xcs_cover.png';
import ZipCoverPng from '~/assets/img/project-detail/zip_cover.png';
import { CONTENT_TYPE } from '~/types';

// 处理文件标记的 computed
export const attachFilesComputed = (files: any, isXthings: boolean) => {
  if (!files) {
    return [];
  }
  const newFiles = JSON.parse(JSON.stringify(files));
  // .xcs 文件标记
  newFiles.forEach((file: any) => {
    // 文件后缀名
    const suffix = file.name.split('.')?.pop()?.toLowerCase();
    const imgReg = /(gif|svg|jpg|jpeg|png|bmp|BMP|GIF|JPG|PNG|webp|WEBP)/;
    const zipReg = /(zip|rar|7z|tar|gz|bz2|xz)/;
    const docReg = /(txt|text|pdf)/;
    switch (suffix) {
      // xcs 文件
      case 'xcs':
        if (file.extra?.cover) {
          file.isXcsFile = true;
          file.fileCover = file.extra?.cover;
        } else {
          file.xcsFileNoCover = true;
          file.fileCover = XcsCoverPng;
        }
        // xthings要显示 设备+激光头，其余只显示设备
        if (isXthings) {
          const deviceName = file.extra?.deviceName;
          const powerName = file.extra?.powerName;
          file.xcsTags = powerName ? `${deviceName} - ${powerName}` : deviceName;
        } else {
          file.xcsTags = file.extra?.extId;
        }
        file.isXcsFile = true;
        break;
      // 图片
      case imgReg.test(suffix) && suffix:
        file.fileCover = PictureCoverPng;
        file.isImgFile = true;
        break;
      // 压缩包
      case zipReg.test(suffix) && suffix:
        file.fileCover = ZipCoverPng;
        break;
      // 文档
      case docReg.test(suffix) && suffix:
        file.fileCover = TxtCoverPng;
        break;
      // dxf
      case 'dxf':
        file.isDxfFile = true;
      default:
        file.fileCover = UnknownCoverPng;
        break;
    }
  });
  return newFiles;
};

// 提取xcs文件的 computed
export const xcsFileListComputed = (files: any) => {
  if (!files) {
    return [];
  }
  return files.filter((item: any) => {
    const arr = item.name.split('.');
    const length = arr.length;
    return arr[length - 1] === 'xcs';
  });
};

// 文件处理逻辑
export const useFile = () => {
  const isCollapsed = ref(true);
  const collapsedBtnRef = ref<HTMLElement | null>(null);
  function onClickCollapse() {
    isCollapsed.value = !isCollapsed.value;
    if (isCollapsed.value) {
      nextTick().then(() => {
        window.scrollTo({
          top: (collapsedBtnRef.value?.offsetTop || 0) - 70,
        });
      });
    }
  }

  const detailsStore = useDetailsStore();
  const { detail } = storeToRefs(detailsStore);
  const route = useRoute();
  const { goToLogin, isLogin, getPassportToken, callXcs, isCallingXcs } = initStates();
  const localToken = useMiddleCookie('utoken');
  const { downloadFile } = useFileMethods();

  const isDownloadLoading = ref(false);
  const isDownLoadAllLoading = ref(false);
  const importToCanvasLoading = ref(false);
  const p = ref(0);
  const currentFileUid = ref('');

  const isForSale = computed(() => detail.value.forSale);
  const isXthings = computed(() => detail.value.type === CONTENT_TYPE.XTHINGS);
  const projectTitle = computed(() => detail.value.title);
  const isFileAll = computed(() => {
    return !isAppWebkit() && moreThanOneFile.value;
  });
  const downloadBtnTitle = computed(() => {
    if (isDownloadLoading.value || isDownLoadAllLoading.value) {
      return `Downloading`;
    }
    return isFileAll.value ? 'Download All' : 'Download';
  });
  const newAttachFiles = computed(() => attachFilesComputed(detail.value?.attachFiles, isXthings.value));
  const moreThanOneFile = computed(() => newAttachFiles.value.length > 1);
  const displayFiles = computed(() => {
    const files = newAttachFiles.value;
    return isCollapsed.value ? files.slice(0, 2) : files;
  });
  const xcsFileList = computed(() => xcsFileListComputed(detail.value?.attachFiles));
  const isXcsFileAll = computed(() => xcsFileList.value.length > 1);
  const isOpenIconShow = computed(() => {
    // ipad浏览器中，不显示
    if (isIpad()) {
      return false;
    }
    return true;
  });
  const isOpenBtnShow = computed(() => {
    // 没有xcs文件，不显示
    if (xcsFileList.value.length <= 0) {
      return false;
    }
    // ipad浏览器中，不显示
    if (isIpad()) {
      return false;
    }
    // app中，xcs文件数量大于1，不显示
    if (isAppWebkit() && isXcsFileAll.value) {
      return false;
    }
    return true;
  });
  const isInPcXcs = computed(() => route.query.openFrom === 'xcs' && isInPCApp());
  const isImportToCanvasBtnShow = computed(() => {
    // 若当前素材为单一文件，且文件格式为位图或svg、dxf，则显示
    const files = newAttachFiles.value;
    const file = files[0];
    return isInPcXcs.value && files.length === 1 && (file.isImgFile || file.isDxfFile);
  });

  const importOneToCanvas = (file: any) => {
    if (!file.isImgFile && !file.isDxfFile) {
      return;
    }

    importToCanvasLoading.value = true;
    const payload = {
      url: file.url,
      ext: file.name.replace(/^[\s\S]+?(?:\.(\w+)$)/, '$1').toUpperCase(),
      source: 'designs',
      traceInfo: {
        name: file.name,
        content_id: detail.value.id,
      },
    };
    console.log('webCommandOpenImage >>>>>> ', payload);
    window.parent.postMessage(
      {
        type: 'webCommandOpenImage',
        token: getUToken(),
        payload,
      },
      '*',
    );
  };
  const onImportToCanvas = () => {
    importOneToCanvas(newAttachFiles.value[0]);
  };

  function onDownloadClick() {
    if (isFileAll.value) {
      downloadAll();
    } else {
      downloadOne(newAttachFiles.value[0]);
    }
  }
  function downloadAll() {
    if (!isLogin.value) {
      goToLogin();
      return;
    }

    try {
      isDownLoadAllLoading.value = true;
      // 提前检测下载地址是否可访问
      const url = detail.value.attachFiles[0]?.url as any;
      const urlObj = new URL(url);
      urlObj.searchParams.append('type_to_app', 'download');
      const urlString = urlObj.toString();
      const isValid = isValidURL(urlString);
      if (!isValid) {
        message.warning(`Download link expired. Please revisit project details and click 'Download' for a new link.`);
        isDownLoadAllLoading.value = false;
        return;
      }
    } catch (err) {
      console.log('download-zip-err :>> ', err);
      isDownLoadAllLoading.value = false;
    }

    const success = () => {
      isDownLoadAllLoading.value = false;
      p.value = 0;
      detailsStore.showLikeModal('zip', '顶部');
      handleDownloadCnt();
    };
    message.success(`File is downloading. It's large, so it may take a while. Please wait.`);
    downloadZip(detail.value.attachFiles, {
      fileName: detail.value.title,
      successFn: success,
      progressFn: (percent: number) => {
        p.value = +percent.toFixed(0);
      },
    });
  }
  async function downloadOne(file: any) {
    if (!isLogin.value) {
      goToLogin();
      return;
    }

    // 检查是否断网
    if (!globalThis.navigator.onLine) {
      message.error('No internet connection detected. Please check your network settings and try again.');
      return;
    }

    await handleDownloadCnt();
    isDownloadLoading.value = true;
    currentFileUid.value = file.uid;
    downloadFile(
      file,
      () => {
        isDownloadLoading.value = false;
        currentFileUid.value = '';
        p.value = 0;
        nextTick(() => {
          detailsStore.showLikeModal(file.name, '顶部');
        });
      },
      () => {
        isDownloadLoading.value = false;
        currentFileUid.value = '';
        p.value = 0;
      },
      (percent: number) => {
        p.value = +percent.toFixed(0);
      },
    );
  }
  function onOpenClick() {
    if (isXcsFileAll.value) {
      openAll();
    } else {
      openOne(xcsFileList.value[0]);
    }

    const { sensorsCreateYours } = useSensors();
    const isInXcs = isElectronXcs() || isAppWebkit();
    const params = (window as any)?.df_shen?.getParams();
    const p = {
      entry: '顶部',
      file_name: xcsFileList.value[0]?.name || '',
      content_id: detail.value.id,
      content_name: detail.value.title,
      service_type: detail.value.type,
      content_type: isForSale.value ? 'paid' : 'free',
      is_xcs: Boolean(isInXcs),
      latest_page_source: params.latest_page_source,
      latest_blockname_source: params.latest_blockname_source,
      latest_keyword_source: params.latest_keyword_source,
      latest_contentid_source: params.latest_contentid_source,
      latest_contentid_algorithm_version: params.latest_contentid_algorithm_version,
      latest_contentid_algorithm_type: params.latest_contentid_algorithm_type,
    };
    sensorsCreateYours(p);
  }
  function openOne(file: any) {
    if (!isLogin.value) {
      goToLogin();
      return;
    }
    callXcs({
      type: 'webCommandOpenProject',
      token: localToken.value,
      url: file.url,
      name: file.name,
    });
  }
  function openAll() {
    if (!isLogin.value) {
      goToLogin();
      return;
    }
    if (isCallingXcs.value) {
      return;
    }
    const xcsFiles: any[] = xcsFileList.value.map((item: any) => ({ url: item.url, name: item.name }));
    const firstFile = xcsFiles[0];
    callXcs({
      type: 'webCommandOpenProject',
      token: localToken.value,
      url: firstFile.url,
      name: firstFile.name,
      makingId: detail.value.id,
    });
  }
  async function handleDownloadCnt() {
    if (!localToken.value) {
      return;
    }
    const passportToken = getPassportToken();
    if (!passportToken.value) {
      return;
    }
    detailsStore.triggerAction('download');
  }

  return {
    collapsedBtnRef,
    isCollapsed,
    onClickCollapse,
    goToLogin,
    isLogin,
    getPassportToken,
    callXcs,
    isCallingXcs,
    downloadFile,
    isDownloadLoading,
    isDownLoadAllLoading,
    p,
    currentFileUid,
    isForSale,
    isXthings,
    projectTitle,
    isFileAll,
    downloadBtnTitle,
    newAttachFiles,
    moreThanOneFile,
    displayFiles,
    xcsFileList,
    isXcsFileAll,
    isOpenIconShow,
    isOpenBtnShow,
    importToCanvasLoading,
    isInPcXcs,
    isImportToCanvasBtnShow,
    importOneToCanvas,
    onImportToCanvas,
    onDownloadClick,
    downloadAll,
    downloadOne,
    onOpenClick,
    openOne,
    openAll,
  };
};
