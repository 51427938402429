import { findActivityListApi } from '@/api/activity';
import { useShareProject } from '~/stores/shareProject.js';

// 判断Ai活动的提示是否显示
export const useAITips = () => {
  const activityId = import.meta.env.VITE_ACTIVITY_AI_MAKE_ID;
  const isOpen = ref(false);
  const clickTips = ref(true);
  const useShareProjectStore = useShareProject();
  const isAIEditor = computed(() => useShareProjectStore.isAIEditor);
  const getDetail = async () => {
    const res = await findActivityListApi.fetch({ id: activityId });
    isOpen.value = res.data.status === 'open';
  };
  const clickCloseTips = () => {
    clickTips.value = false;
  };
  const showAiTips = computed(() => {
    // 是否打开 && 是否为AI编辑器 && 是否点击过提示
    return isOpen.value && isAIEditor.value && clickTips.value;
  });
  onMounted(() => {
    nextTick().then(() => {
      getDetail();
    });
  });
  return { showAiTips, clickCloseTips };
};
