<template>
  <div class="license-box mt-8">
    <h3 class="mb-0 text-xl font-bold text-text-primary pad:text-level-2 mobile:text-level-2">License</h3>

    <div class="mt-4 rounded-lg bg-background-2 px-6 py-4">
      <div class="flex items-center gap-3">
        <div class="h-[32px] w-[90px]">
          <img class="h-full w-full" :src="detail.license.extra?.icon" alt="" />
        </div>
        <div class="license-name">
          <NuxtLink
            class="license-link text-text-primary hover:text-text-primary"
            :class="[
              {
                'hover:underline': link,
                'cursor-default': !link,
              },
            ]"
            :to="link"
            target="_blank"
          >
            <span class="text-level-2 font-semibold">{{ isLicenseForsale ? 'Commercial' : abbrName }}</span>
          </NuxtLink>
        </div>
      </div>
      <div class="mt-3 text-[#999]">
        {{ isLicenseForsale ? 'All Products can be used for Commercial Use.' : copywriting }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';

const detailsStore = useDetailsStore();
const detail = computed(() => detailsStore.detail);
const licenseInfo = computed(() => detail.value?.license ?? {});
const isLicenseForsale = computed(() => licenseInfo.value?.extra?.forSale);
const abbrName = computed(() => licenseInfo.value.extra?.abbrName || '');
const copywriting = computed(() => licenseInfo.value.extra?.copywriting || '');
const link = computed(() => licenseInfo.value.extra?.link || '');
</script>

<style lang="less" scoped></style>
