<template>
  <a-form-item name="materials" label="Material use">
    <ShareCommonSelectBtn v-if="materials.length" :class="showSelectInput ? 'mb-3' : ''" :list="materials" @remove="handleRemove">
      <template #extra>
        <div
          v-if="!showSelectInput"
          class="flex h-12 w-12 cursor-pointer items-center justify-center rounded-lg bg-background-2 hover:opacity-90"
          @click="handleShowSelect"
        >
          <icon-font type="icon-Add" class="text-xl text-text-tertiary" />
        </div>
      </template>
    </ShareCommonSelectBtn>
    <div v-show="showSelectInput">
      <df-select
        v-model:value="selected"
        mode="tags"
        :options="materialList"
        placeholder="Please select or enter the name of material to confirm."
        @change="handleSelChange"
        @dropdown-visible-change="handleDropdownVisibleChange"
      />
    </div>
  </a-form-item>
</template>
<script lang="ts" setup>
import { getMaterialList } from '@/api/common';
import { FileEventType } from '@/components/share/project/type';
import type { SelectProps } from 'ant-design-vue';
import { type PropType } from 'vue';

const triggerAutoFill = (attachFiles: any, fileEventType: FileEventType | null) => {
  if (fileEventType === FileEventType.Remove) {
    materials.value = [];
  }
  nextTick(() => {
    if (attachFiles?.length) {
      const list = attachFiles
        .filter((item: any) => item?.materialList?.length)
        .reduce((prev: any, next: any) => {
          return prev.concat(next.materialList);
        }, []);
      if (Array.isArray(list)) {
        const officialMaterials = list
          .filter((item: string) => materialList.value?.find((material) => material.value === item.trim()))
          .map((item: string) => item?.trim());
        materials.value = [...new Set([...materials.value, ...officialMaterials])];
      }
    }
  });
};
defineExpose({
  triggerAutoFill,
});
const materials = defineModel('materials', { type: Array as PropType<any>, default: () => [] });
const selected = ref<string[]>([]);
const materialList = ref<SelectProps['options']>([]);
const addSelect = ref(false);
const dropdownVisible = ref(false);
const showSelectInput = computed(() => {
  if (dropdownVisible.value) {
    return true;
  } else {
    return !materials.value.length || addSelect.value;
  }
});
const handleDropdownVisibleChange = (visible: boolean) => {
  nextTick(() => {
    dropdownVisible.value = visible;
    if (materials.value.length && !visible) {
      toggleSelectInput(false);
    }
  });
};
const toggleSelectInput = (status: boolean) => {
  addSelect.value = status;
};
const handleShowSelect = () => {
  toggleSelectInput(true);
};

const handleSelChange = (val: any) => {
  materials.value = [...new Set([...materials.value, ...val])];
  selected.value = [];
};
const handleRemove = (val: string) => {
  materials.value = materials.value.filter((item: string) => item !== val);
};
const getBaseMaterialData = async () => {
  const { data: baseMaterialData, error: baseMaterialDataError } = await getMaterialList();
  if (!baseMaterialDataError.value && baseMaterialData.value?.data?.length) {
    materialList.value = baseMaterialData.value.data.map((item: any) => {
      return {
        ...item,
        value: item.name,
        label: item.name,
      };
    });
  }
};
onMounted(() => {
  nextTick(() => {
    getBaseMaterialData();
  });
});
</script>
