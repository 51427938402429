export default {
  color: Object,
  type: {
    type: String,
    default: 'default'
  },
  round: Boolean,
  size: {
    type: String,
    default: 'medium'
  },
  closable: Boolean,
  disabled: {
    type: Boolean,
    default: undefined
  }
};