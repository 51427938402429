<template>
  <div id="txt-container" class="txt-preview border-0 border-t border-solid border-[rgba(0,0,0,0.1)]">
    <div class="p-6">
      <div class="flex justify-center">
        <a-spin v-if="loading" />
      </div>
      <pre v-if="text && !loading" class="mb-5 font-sans text-level-2" v-html="text"></pre>

      <div class="inline-flex cursor-pointer gap-2 text-uni-1" @click="open">
        <icon-font class="text-[16px]" :class="[isCollapsed ? '' : 'rotate-180']" type="icon-a-30-arrow-down-s-line" />
        <span class="text-level-2">{{ isCollapsed ? 'View All' : 'Collapse' }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type PropsType = {
  fileUrl: string;
  name?: string;
};
const props = defineProps<PropsType>();

const isCollapsed = ref(true);
// 根据 url 获取 .txt文件内容，然后展示到 txt-preview 中
const text = ref('');
const loading = ref(false);
let str = '';
const openTxt = async () => {
  try {
    loading.value = true;
    const response = await fetch(props.fileUrl);
    text.value = await response.text();
    str = text.value;
  } catch (err) {
    console.log('err :>> ', err);
  } finally {
    loading.value = false;
  }
};

// 展开/折叠
const open = () => {
  console.log('open txt');
  isCollapsed.value = !isCollapsed.value;
  if (isCollapsed.value) {
    text.value = '';
  } else {
    str ? (text.value = str) : openTxt();
  }
};

// 下载
const download = () => {
  const link = document.createElement('a');
  link.setAttribute('download', props.name || 'file');
  link.setAttribute('href', props.fileUrl);
  link.click();
  link.remove();
};

onUnmounted(() => {
  str = '';
});

defineExpose({
  open,
  download,
});
</script>

<style lang="less" scoped></style>
