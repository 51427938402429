<template>
  <div
    class="df-lane-wrap"
    :style="{
      '--c': column,
      '--gap': `${gap}px`,
    }"
  >
    <div ref="elRef" :class="['df-lane', allowWheel ? 'allow-wheel' : '']">
      <div ref="trackRef" class="df-lane-track">
        <div v-for="(item, index) in items" :key="index" class="df-lane-item">
          <slot name="item" :item="item" :index="index"></slot>
        </div>
      </div>
    </div>
    <ClientOnly>
      <slot v-if="showArrow" name="arrow" :prev="prev" :next="next" :allow-prev="allowPrev" :allow-next="allowNext">
        <icon-font v-if="allowPrev" class="df-lane-arrow prev" type="icon-arrow-left-m" @click="prev" />
        <icon-font v-if="allowNext" class="df-lane-arrow next" type="icon-arrow-right-m" @click="next" />
      </slot>
    </ClientOnly>
  </div>
</template>

<script setup generic="T" lang="ts">
import type { PropType, VNode } from 'vue';
import type { Fn } from '~/types/fns';

const props = defineProps({
  items: {
    type: Array as PropType<T[]>,
    default: () => [],
  },
  gap: {
    type: Number,
    default: 16,
  },
  column: {
    type: Number,
    default: 5,
  },
  showArrow: {
    type: Boolean,
    default: true,
  },
  allowWheel: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits<{
  (event: 'change'): void;
}>();

defineSlots<{
  item: (props: { index: number; item: T }) => VNode[];
  arrow: (props: { prev: Fn<[], void>; next: Fn<[], void>; allowPrev?: boolean; allowNext?: boolean }) => VNode[];
}>();

const offset = ref(0);
const scrolling = ref(false);
const elRef = shallowRef<HTMLElement>();
const trackRef = shallowRef<HTMLElement>();

onActivated(() => {
  offset.value = 0;
});
useEventListener(elRef, 'scroll', () => {
  const el = elRef.value as HTMLElement;
  offset.value = el.scrollLeft;
  scrolling.value = true;
  // console.log('scroll >>> ', scrolling.value);
});
useEventListener(elRef, 'scrollend', () => {
  const el = elRef.value as HTMLElement;
  offset.value = el.scrollLeft;
  scrolling.value = false;
  // console.log(`[offset]: ${offset.value} [w]: ${w.value}, [cw]: ${cw.value}, [cw - w]: ${cw.value - w.value}`);
  emits('change');
});
useEventListener(elRef, 'wheel', (ev) => {
  if (!props.allowWheel) {
    return;
  }
  const el = elRef.value as HTMLElement;
  const { scrollLeft, scrollWidth, clientWidth } = el;
  const { deltaY } = ev;
  const maxScrollLeft = scrollWidth - clientWidth;
  const left = scrollLeft + deltaY;

  if ((deltaY < 0 && left >= 0) || (deltaY > 0 && left <= maxScrollLeft)) {
    ev.preventDefault();
  }

  el.scrollLeft = left;
});

// wrap 宽度
const [w] = useTargetSize(elRef, 'self');
// 内容宽度
const [cw] = useTargetSize(trackRef, 'content');
// 屏幕缩放比例
const ratio = useDevicePixelRatio();
// 监听 `wrap 宽度` 和 `屏幕缩放比例` 变化，更新组件状态
watch(
  () => [w.value, ratio.value],
  () => {
    elRef.value?.scrollTo({ left: 0 });
    offset.value = 0;
    scrolling.value = false;
  },
);

const allowPrev = computed(() => offset.value > 0);
const allowNext = computed(() => {
  if (Math.ceil(offset.value) === cw.value - w.value) {
    return false;
  }
  return offset.value < cw.value - w.value;
});

const prev = () => {
  if (!allowPrev.value || scrolling.value) {
    return;
  }

  elRef.value?.scrollTo({
    behavior: 'smooth',
    left: call(() => {
      const d = w.value + props.gap;
      const _offset = Math.floor(offset.value / d) * d;
      return Math.abs(offset.value - _offset) <= 1 ? _offset - d : _offset;
    }),
  });
};
const next = () => {
  if (!allowNext.value || scrolling.value) {
    return;
  }

  elRef.value?.scrollTo({
    behavior: 'smooth',
    left: call(() => {
      const d = w.value + props.gap;
      const _offset = (Math.floor(offset.value / d) + 1) * d;
      return Math.abs(offset.value - _offset) <= 1 ? _offset + d : _offset;
    }),
  });
};

defineExpose({
  prev,
  next,
  get allowPrev() {
    return allowPrev.value;
  },
  get allowNext() {
    return allowNext.value;
  },
});
</script>

<style scoped lang="less">
.df-lane-wrap {
  position: relative;
  width: 100%;
}
.df-lane {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow-x: auto; /* 保持可滚动 */
  scrollbar-width: none; /* 对于 Firefox 6 */
  &::-webkit-scrollbar {
    display: none; /* 对于 Chrome, Safari 和 Opera */
  }

  //--w: calc((100% - var(--gap) * (var(--c) - 1)) / var(--c));

  &.allow-wheel {
    overscroll-behavior: contain;
  }

  //font-size: calc(100vw - 80px);
  //--gap: 16px;
  //--w: calc((1em - var(--gap) * 4) / 5);
}
.df-lane-track {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.df-lane-item {
  flex-shrink: 0;
  width: calc((100% - var(--gap) * (var(--c) - 1)) / var(--c));
  margin-right: var(--gap);
  &:last-child {
    margin-right: 0;
  }
}
.df-lane-arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  color: theme('colors.text.primary');
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 4px 16px 0 #0000001a;

  &.prev {
    left: 0;
    transform: translate(-50%, -50%);
  }
  &.next {
    right: 0;
    transform: translate(50%, -50%);
  }
}
</style>
