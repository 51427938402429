export default {
  tabFontSizeSmall: '14px',
  tabFontSizeMedium: '14px',
  tabFontSizeLarge: '16px',
  tabGapSmallLine: '36px',
  tabGapMediumLine: '36px',
  tabGapLargeLine: '36px',
  tabGapSmallLineVertical: '8px',
  tabGapMediumLineVertical: '8px',
  tabGapLargeLineVertical: '8px',
  tabPaddingSmallLine: '6px 0',
  tabPaddingMediumLine: '10px 0',
  tabPaddingLargeLine: '14px 0',
  tabPaddingVerticalSmallLine: '6px 12px',
  tabPaddingVerticalMediumLine: '8px 16px',
  tabPaddingVerticalLargeLine: '10px 20px',
  tabGapSmallBar: '36px',
  tabGapMediumBar: '36px',
  tabGapLargeBar: '36px',
  tabGapSmallBarVertical: '8px',
  tabGapMediumBarVertical: '8px',
  tabGapLargeBarVertical: '8px',
  tabPaddingSmallBar: '4px 0',
  tabPaddingMediumBar: '6px 0',
  tabPaddingLargeBar: '10px 0',
  tabPaddingVerticalSmallBar: '6px 12px',
  tabPaddingVerticalMediumBar: '8px 16px',
  tabPaddingVerticalLargeBar: '10px 20px',
  tabGapSmallCard: '4px',
  tabGapMediumCard: '4px',
  tabGapLargeCard: '4px',
  tabGapSmallCardVertical: '4px',
  tabGapMediumCardVertical: '4px',
  tabGapLargeCardVertical: '4px',
  tabPaddingSmallCard: '8px 16px',
  tabPaddingMediumCard: '10px 20px',
  tabPaddingLargeCard: '12px 24px',
  tabPaddingSmallSegment: '4px 0',
  tabPaddingMediumSegment: '6px 0',
  tabPaddingLargeSegment: '8px 0',
  tabPaddingVerticalLargeSegment: '0 8px',
  tabPaddingVerticalSmallCard: '8px 12px',
  tabPaddingVerticalMediumCard: '10px 16px',
  tabPaddingVerticalLargeCard: '12px 20px',
  tabPaddingVerticalSmallSegment: '0 4px',
  tabPaddingVerticalMediumSegment: '0 6px',
  tabGapSmallSegment: '0',
  tabGapMediumSegment: '0',
  tabGapLargeSegment: '0',
  tabGapSmallSegmentVertical: '0',
  tabGapMediumSegmentVertical: '0',
  tabGapLargeSegmentVertical: '0',
  panePaddingSmall: '8px 0 0 0',
  panePaddingMedium: '12px 0 0 0',
  panePaddingLarge: '16px 0 0 0',
  closeSize: '18px',
  closeIconSize: '14px'
};