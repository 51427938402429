import { getBlockExtDataListApi, getCaseListApi } from '@/api/explore';
import { merge } from 'lodash-es';
import { ColumnTypeEnum } from '../const';

interface MakingExtMap {
  [key: number]: {
    id: number; // 作品id
    extra: {
      blockCover: string; // 运营配置的封面图
    };
    origPrice: number; // 原价
    sellTag: string; // 售卖标签
    hasDiscount: boolean; // 是否有折扣活动
    discount: {
      discountPercent: number; // 折扣比例
      disPrice: number; // 折扣价格
    };
  };
}

export function initHomeColumnData(opts: { type: ColumnTypeEnum }) {
  const columnType = opts.type;
  const makingIds = ref<number[]>([]);

  // 获取案例列表
  const makingList = ref<any[]>([]);
  const getMakingList = async () => {
    const params = { type: columnType };
    try {
      const [data] = await getCaseListApi.useFetch(params);
      makingList.value = data.value ?? [];
      updateBlockMakingIds(); // 收集making id
      updateMakingList(); // 更新案例最新数据
    } catch (err) {
      console.log('err :>> ', err);
    }
  };

  // 收集making id
  const updateBlockMakingIds = () => {
    const ids = makingList.value.map((item: any) => item.id);
    makingIds.value = Array.from(new Set([...makingIds.value, ...ids]));
  };

  const makingExtMapData = ref<MakingExtMap>({});
  const getLatestMakings = async (ids: Ref<number[]>) => {
    try {
      const res = await getBlockExtDataListApi.fetch({ ids: ids.value });
      makingExtMapData.value = res?.data ?? {};
    } catch (error) {
      console.error(error);
    }
  };

  // 更新案例最新数据
  const updateMakingList = async () => {
    try {
      await getLatestMakings(makingIds);
      makingList.value = remixMakingData(makingList.value);
    } catch (err) {
      console.error(err);
    }
  };
  // 合并案例数据, 去掉删除的数据
  const remixMakingData = (list: Array<any>) => {
    const newList = list
      .map((item: any) => {
        const makingExt = makingExtMapData.value?.[item.id] || {};
        return merge(item, makingExt);
      })
      .filter((item) => !Boolean(item.isDeleted));
    return newList;
  };

  return {
    getMakingList,
    makingList,
    updateMakingList,
  };
}

export function initHomeColumnMethods(opts: { type: ColumnTypeEnum }) {
  const columnType = opts.type;
  // const router = useRouter();

  const seeMoreLink = computed(() => {
    return columnType !== ColumnTypeEnum.Premium ? `/all?payTypes=${columnType}` : '/premium';
  });

  // 跳转到全部页面
  const onSeeMoreClick = () => {
    const { sensorsSeeMore } = useSensors();
    sensorsSeeMore({
      block_id: columnType === 'creations' ? 0 : 1,
      block_name: columnType,
      clickSource: 'homepage',
    });
    /*     if (opts.type === ColumnTypeEnum.Premium) {
      router.push({ path: '/premium' });
    } else {
      router.push({
        path: '/all',
        query: {
          payTypes: columnType,
        },
      });
    } */
  };
  return {
    seeMoreLink,
    onSeeMoreClick,
  };
}

export function useHomeColumn(opts: { type: ColumnTypeEnum }) {
  return {
    ...initHomeColumnData(opts),
    ...initHomeColumnMethods(opts),
  };
}
