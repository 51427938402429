<template>
  <div v-if="!isPreview && (showEdit || showDel)" class="sticky top-[230px] ml-10 flex h-fit flex-col gap-8 not-pc:hidden">
    <!-- 编辑 -->
    <a-tooltip v-if="showEdit" placement="left">
      <template #title>
        {{ !editable ? 'Your content has a version under review and cannot be edited.' : `Edit` }}
      </template>
      <ActionButton :size="60" icon="icon-edit-box" :disabled="!editable" @click="handleToggleAction" />
    </a-tooltip>

    <!-- 删除 -->
    <a-tooltip v-if="showDel" placement="left">
      <template #title>Delete</template>
      <ActionButton :size="60" class="del-btn" icon="icon-Delete" :hover-syle="{ borderColor: '#ff5a00', color: 'red' }" @click="handleDeleteClick" />
    </a-tooltip>

    <!-- 使用模板 -->
    <a-tooltip v-if="showCopy" placement="left">
      <template #title>Use as Template</template>
      <ActionButton :size="60" icon="icon-copy" @click="handleUseTemplateAction" />
    </a-tooltip>

    <!-- 浏览器打开 -->
    <!-- <ClientOnly>
      <a-tooltip v-if="showOpenInBrowser" placement="left">
        <template #title>Open in Browser</template>
        <ActionButton :size="60" icon="icon-liulanqi" @click="handleOpenInBrowser" />
      </a-tooltip>
    </ClientOnly> -->
  </div>
</template>

<script setup lang="ts">
import { useActions } from '@/components/project-details/common/action';
import type { AuditStatus } from '@/components/project-details/types';

type PropsType = {
  auditStatus?: AuditStatus;
};
const props = withDefaults(defineProps<PropsType>(), {
  auditStatus: undefined,
});

const {
  editable,
  showEdit,
  showDel,
  showCopy,
  // showOpenInBrowser,
  // handleOpenInBrowser,
  handleToggleAction,
  handleDeleteClick,
  handleUseTemplateAction,
  isPreview,
  hasRightOptions,
} = useActions({ auditStatus: props.auditStatus });

defineExpose({
  hasRightOptions,
});
</script>

<style lang="less" scoped></style>
