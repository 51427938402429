import { isObject, isNumber, noop, isFunction, isNil } from 'lodash-es';
import type { Fn } from '~/types/fns';
import { raf } from '~/utils/fns/raf';

type Cb = (ticker: Ticker) => void;

interface Ticker {
  /**
   * @description
   */
  readonly isActive: boolean;
  /**
   * @description 总的周期数，reset 后不会重置
   */
  readonly tickCount: number;
  /**
   * @description ticker 启动后经历的周期数，reset 后会重置
   */
  readonly delta: number;
  /**
   * @description 暂停 ticker
   */
  pause(): void;
  /**
   * @description 激活暂停的 ticker
   */
  reuse(): void;
  /**
   * @description 重置 ticker 状态
   */
  reset(opts?: TickerOptions): void;
}

interface Driver {
  run(opts: { callback: Fn<[], void>; period: number; immediate: boolean }): void;
  done(): void;
}
interface TickerOptions {
  period: number; // 周期长度
  callback?: Cb; // 回调函数
  driver?: 'timer' | 'raf' | Driver; // 驱动器类型，默认为 timer
  auto?: boolean; // 是否自动激活，即在组件挂载时自动激活，默认为 false
  immediate?: boolean; // 是否启动后立即执行回调，默认为 false
}

/**
 * @description 使用滴答器计数
 * @param period 周期长度
 */
export function useTicker(period: number): Ticker;
/**
 * @description 使用滴答器周期执行回调函数
 * @param opts ticker 配置
 */
export function useTicker(opts: TickerOptions): Ticker;
/**
 * @description 使用滴答器周期执行回调函数
 * @param callback 回调函数
 * @param period 周期长度
 */
export function useTicker(callback: Cb, period: number): Ticker;
export function useTicker(...args: any): Ticker {
  const opts = normalizeArgs(args);

  let driver: Driver;
  let previousTickCount = 0;
  const delta = ref(0);
  const tickCount = ref(0);
  const isActive = ref(false);

  const loop = () => {
    tickCount.value = tickCount.value + 1;
    delta.value = tickCount.value - previousTickCount;
    tryCall(opts.callback, ticker);
  };
  const reuse = () => {
    if (isNil(driver)) {
      driver = createDriver(opts.driver);
    }
    if (isActive.value) {
      return;
    }
    isActive.value = true;
    driver.run({
      period: opts.period,
      immediate: opts.immediate,
      callback: loop,
    });
  };
  const pause = () => {
    isActive.value = false;
    driver?.done();
  };
  const reset = (_opts?: Partial<TickerOptions>) => {
    previousTickCount = tickCount.value;
    if (isObject(_opts)) {
      Object.assign(opts, _opts);
    }
    reuse();
  };

  const ticker = {
    pause,
    reuse,
    reset,
    get isActive() {
      return isActive.value;
    },
    get tickCount() {
      return tickCount.value;
    },
    get delta() {
      return delta.value;
    },
  };

  if (opts.auto) {
    onMounted(reuse);
  }
  tryOnUnMounted(pause);

  return ticker;
}

/**
 * @description 参数格式化
 * @param args
 */
function normalizeArgs(args: any[]): Required<TickerOptions> {
  const opts: Required<TickerOptions> = {
    period: 0,
    callback: noop,
    driver: 'timer',
    auto: false,
    immediate: false,
  };
  if (args.length === 1 && isNumber(args[0])) {
    opts.period = args[0];
  } else if (args.length === 1 && isObject(args[0])) {
    const _opts = args[0] as TickerOptions;
    Object.assign(opts, _opts);
  } else if (args.length === 2 && isFunction(args[0]) && isNumber(args[1])) {
    opts.callback = args[0];
    opts.period = args[1];
  } else {
    throw new Error('[useTicker]: Invalid arguments');
  }
  return opts;
}

function createDriver(driver: 'raf' | 'timer' | Driver): Driver {
  if (driver === 'raf') {
    return createRafDriver();
  } else if (driver === 'timer') {
    return createTimerDriver();
  } else if (isObject(driver) && isFunction(driver.run) && isFunction(driver.done)) {
    return driver;
  } else {
    throw new Error('[useTicker]: Invalid driver');
  }
}
function createRafDriver(): Driver {
  let _done: Fn<[], void> | undefined;
  let cb: Fn<[], void> = noop;
  let isActive = false;
  let immediate = false;
  let period = 0;
  let counter = 0;

  const loop = () => {
    if (!isActive) {
      return;
    }
    counter++;
    if (counter === period) {
      counter = 0;
      tryCall(cb);
    }
  };

  const run = (opts: { callback: Fn<[], void>; period: number; immediate: boolean }) => {
    if (isActive) {
      done();
    }

    cb = opts.callback;
    period = opts.period;
    immediate = opts.immediate;
    isActive = true;

    if (immediate) {
      tryCall(cb);
    }

    _done = raf(loop);
  };
  const done = () => {
    isActive = false;
    _done && _done();
  };
  return { run, done };
}
function createTimerDriver(): Driver {
  let _timer: NodeJS.Timeout;
  let cb: Fn<[], void> = noop;
  let isActive = false;
  let immediate = false;
  let period = 0;

  const loop = () => {
    if (!isActive) {
      return;
    }
    if (immediate) {
      tryCall(cb);
    }
    _timer = setTimeout(() => {
      if (!immediate) {
        tryCall(cb);
      }
      loop();
    }, period);
  };
  const run = (opts: { callback: Fn<[], void>; period: number; immediate: boolean }) => {
    if (isActive) {
      done();
    }

    cb = opts.callback;
    period = opts.period;
    immediate = opts.immediate;
    isActive = true;
    loop();
  };
  const done = () => {
    isActive = false;
    if (_timer) {
      clearTimeout(_timer);
    }
  };
  return { run, done };
}
