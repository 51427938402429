import { LoginBanner } from '#components';
import PassportClient, { AREA, CLIENT_ENV, DOMAIN_SPACE, PAGE_MODE, XTOOL_PLATFORM, type DefaultConfigType } from '@makeblock/passport-client';
import { isFunction, isNil } from 'lodash-es';
import dayjs from 'dayjs';
import { createApp } from 'vue';
import { useUserInfo } from '~/stores/user';
import { SENSORS_EVENT } from '~/types';
import { useForceLogin } from '~/composables/force-login';

export default defineNuxtPlugin(() => {
  const config: DefaultConfigType = {
    domainSpace: DOMAIN_SPACE.XTOOL,
    platform: XTOOL_PLATFORM.DESIGN_FIND,
    mode: PAGE_MODE.WIN,
    lang: 'en',
    area: AREA.US,
    height: 700,
    preFetch: true,
    adaptWindow: true,
    oneTap: {
      enabled: true,
    },
  };
  if (import.meta.client) {
    const div = document.createElement('div');
    const vm = createApp(LoginBanner);
    vm.mount(div);
    config.advertisement = div;
    config.advertisementPosition = 'left' as any;
  }
  const client = new PassportClient(config);
  if (import.meta.env.VITE_PASSPORT_ENV === 'prod') {
    client.env = CLIENT_ENV.PROD;
    client.lang = 'en';
    client.area = AREA.US;
  } else if (import.meta.env.VITE_PASSPORT_ENV === 'test') {
    client.env = CLIENT_ENV.TEST;
    client.lang = 'zh';
    client.area = AREA.CN;
  } else if (import.meta.env.VITE_PASSPORT_ENV === 'testus') {
    client.env = CLIENT_ENV.TEST;
    client.lang = 'en';
    client.area = AREA.US;
  } else {
    client.env = CLIENT_ENV.DEV;
    client.lang = 'zh';
    client.area = AREA.CN;
  }

  if (import.meta.client) {
    client.on('oneTap', async () => {
      try {
        await traceLogin();
      } finally {
        window.location.reload();
      }
    });
  }

  const proxyClient = new Proxy(client, {
    get(target, key) {
      const value = Reflect.get(target, key);
      if (!isFunction(value)) {
        return value;
      }
      if (key === 'openModal') {
        return createLoginProxy(target);
      }
      return (...args: any) => {
        return Reflect.apply(value, target, args);
      };
    },
    set(target, key, value) {
      return Reflect.set(target, key, value);
    },
  });

  return {
    provide: {
      client: proxyClient,
    },
  };
});

const createLoginProxy = (client: PassportClient) => {
  const nuxtApp: any = getNuxtApp() ?? {};

  if (isNil(nuxtApp)) {
    console.warn('[login]: nuxtApp is null');
  }

  const { $sensors } = nuxtApp;
  const userSevice = useUserInfo();
  const { isRegistered, markRegistered } = useDeviceFlags();
  const { record } = useForceLogin();

  if (import.meta.client) {
    // 监听登录弹窗关闭事件
    window.addEventListener('message', (event) => {
      if (event.data?.action === 'close-passport-modal' && event.data?.reason === 'click-close-btn') {
        $sensors?.track(SENSORS_EVENT.LOGIN_POP_CLOSE);
      }
    });
  }

  let channel: BroadcastChannel | null = null;
  if (import.meta.client) {
    channel = new BroadcastChannel('login_status');
  }

  return (...args: any) => {
    record.value.isForceLogin = true;
    $sensors?.track(SENSORS_EVENT.LOGIN_POP_SHOW);

    userSevice.$reset();
    channel?.postMessage({ data: 'logout' });

    const onLoginSuccess = async () => {
      try {
        const isNewUser = !isRegistered.value;
        await traceLogin();

        if (!isNewUser) {
          return;
        }
        markRegistered();

        // 判断是否为新用户注册
        const t1 = dayjs(userSevice.myInfo.createTime * 1000)
          .add(1, 'minute')
          .unix();
        const t2 = dayjs().unix();
        if (t1 < t2) {
          return;
        }
        localStorage.setItem('new-user-toast', 'true');
      } finally {
        args?.[0]();
      }
    };
    Reflect.apply(client.openModal, client, [onLoginSuccess]);
  };
};

async function traceLogin() {
  try {
    const nuxtApp = getNuxtApp();

    if (isNil(nuxtApp)) {
      return;
    }

    const { $sensors } = nuxtApp;
    const userSevice = useUserInfo();

    await userSevice.init();

    const { cid } = useDeviceFlags();
    $sensors.track(SENSORS_EVENT.DESIGN_FIND_LOGIN, { uid: userSevice.myInfo.id, cid: cid.value });
  } catch (e) {
    console.error('[traceLogin]:', e);
  }
}
