<template>
  <img :src="imgSrc" class="max-h-full max-w-full object-contain" />
</template>
<script setup lang="ts">
import { fileOrBlobToDataURL } from '@/utils/canvas/image';
const props = defineProps<{
  currentId: string;
}>();
const imgSrc = ref('' as any);
const projectStore = ref(null) as any;

const init = async () => {
  if (!props.currentId) {
    return;
  }
  const { useProjectStore } = await import('@/stores/project');
  projectStore.value = useProjectStore();
  let b: any = await projectStore.value.projectShotByCanvasId(props.currentId);
  imgSrc.value = await fileOrBlobToDataURL(b);
};
watch(
  () => projectStore.value,
  () => {
    init();
  },
  { deep: true },
);
watch(
  () => props.currentId,
  () => {
    init();
  },
);
onMounted(() => {
  init();
});
</script>
