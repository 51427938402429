<template>
  <df-btn type="text" @click.stop="handleOpenXcs">
    <icon-font class="h-5 text-xl text-[#565656]" type="icon-folder-cloud-line" />
    <span class="text-level-4 font-semibold text-[#565656]">Select from XCS</span>
  </df-btn>
  <a-modal v-model:open="open" :width="800" :footer="null" :closable="false" :body-style="{ padding: 0 }">
    <div class="ml-8 h-20 text-level-1 font-bold leading-[80px]">Select from XCS</div>
    <a-spin :spinning="loadingStatus.isFirstLoading">
      <div class="xcs-content flex max-h-[540px] min-h-[300px] flex-wrap gap-4 overflow-auto px-8 py-6 mobile:gap-2">
        <a-empty v-if="xcsList.length == 0" :image="emptyImg" class="empty-img">
          <template #description>
            <span>No Data</span>
          </template>
        </a-empty>
        <div
          v-for="item in xcsList"
          :key="item.id"
          class="relative flex w-[calc(25%-16px*3/4)] cursor-pointer flex-col text-level-4 mobile:w-full"
          @click="handleChoose(item)"
        >
          <img
            v-if="item?.extra?.cover"
            class="h-[160px] w-full rounded-lg bg-background-3 object-contain mobile:h-[120px]"
            :src="item?.extra?.cover"
          />
          <img v-else class="h-[160px] w-full rounded-lg object-contain mobile:h-[120px]" src="/assets/img/xcs-file.svg" />
          <span class="two-line-ellipsis mt-1">{{ item.name }}</span>
          <span class="text-level-7 text-text-tertiary">{{ moment(item.updatedAt * 1000).format('YYYY/MM/DD') }}</span>
          <a-checkbox class="absolute left-2 top-2" :checked="choose.includes(item.id)" />
        </div>
        <bottom-loading
          v-if="xcsList.length > 0"
          :loading="loadingStatus.isListLoading"
          :finished="loadingStatus.isFinished"
          :is-hide-bottom-line="true"
          tips=""
          :style="'padding: 20px 0'"
          @infinite="handleInfiniteLoading"
        />
      </div>
    </a-spin>

    <div class="flex h-20 items-center justify-center">
      <df-btn type="default" class="w-20" @click="handleCancel">Cancel</df-btn>
      <df-btn type="primary" class="ml-4 w-20" @click="handleSelect">Select</df-btn>
    </div>
  </a-modal>
</template>
<script setup lang="ts">
import { getDesignFileList } from '@/api/user';
import emptyImg from '@/assets/img/no-data.png';
import { handleSensorEditClick } from '@/components/share/utils';
import moment from 'moment';
const emits = defineEmits(['change']);
const open = ref(false);
const xcsList = ref<any>([]);
const choose = ref<any>([]);
const selectXcsList = computed(() => xcsList.value.filter((item: any) => choose.value.includes(item.id)));
const handleChoose = (item: any) => {
  console.log('item: ', item);

  const xcsId = item?.id;
  if (choose.value.includes(xcsId)) {
    choose.value = choose.value.filter((id: any) => id !== xcsId);
  } else {
    choose.value.push(xcsId);
  }
};
const handleOpenXcs = () => {
  open.value = true;
  handleSensorEditClick({ name: 'uploadfile_Choose from XCS' });
};
const handleCancel = () => {
  open.value = false;
};
const handleSelect = () => {
  open.value = false;
  emits('change', selectXcsList.value);
  choose.value = [];
};
let curPage = 1;
const loadingStatus = reactive({
  isListLoading: false,
  isFirstLoading: true,
  isFinished: false,
});
const getXcsList = async () => {
  try {
    const res = await getDesignFileList({
      type: 'xcs',
      current: curPage,
      pageSize: 20,
    });
    const list = res.data.value.data.list;

    xcsList.value = xcsList.value.concat([...list]);
    const { current, pageSize, total } = res.data.value?.data.pagination;

    loadingStatus.isFinished = current >= Math.ceil(total / pageSize);

    loadingStatus.isFirstLoading = false;
    loadingStatus.isListLoading = false;
  } catch (err) {
    console.log(err);
  } finally {
    loadingStatus.isListLoading = false;
    loadingStatus.isFirstLoading = false;
  }
};
const handleInfiniteLoading = () => {
  if (loadingStatus.isFirstLoading || loadingStatus.isListLoading || loadingStatus.isFinished) {
    return;
  }
  curPage++;
  loadingStatus.isListLoading = true;
  getXcsList();
};
onMounted(() => {
  nextTick(() => {
    getXcsList();
  });
});
</script>
<style scoped lang="less">
.xcs-content {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.empty-img {
  margin: 20px auto 0;
  :deep(.ant-empty-description) {
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
