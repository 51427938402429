import axios from 'axios';
const prefix = '/efficacy/v1/oss';
// 获取临时令牌
export const getStsToken = (baseURL, utoken, configName) => {
    return axios({
        baseURL,
        url: prefix + `/ststoken/${configName}`,
        method: 'GET',
        timeout: 20000,
        headers: { 'utoken': utoken },
    });
};
export const getPublicToken = (baseURL, appKey) => {
    return axios({
        baseURL,
        url: prefix + `/public/token/${appKey}`,
        method: 'GET',
        timeout: 20000,
    });
};
export const getPublicStsToken = (baseURL, authToken) => {
    return axios({
        baseURL,
        url: prefix + `/public/ststoken`,
        method: 'GET',
        timeout: 20000,
        headers: { 'x-auth-token': authToken },
    });
};
