import { isNil } from 'lodash-es';
import { type ComponentPublicInstance, type MaybeRef, isRef, ref, watch } from 'vue';
import { useTargetSizeObserver } from './use-observer';

/**
 * @description - 监听目标尺寸变化
 * @param target - 监听目标
 * @param type - 监听类型，self：监听自身，content：监听内容
 * @return - [width, height]
 */
export function useTargetSize(target?: MaybeRef<HTMLElement | ComponentPublicInstance | undefined>, type: 'self' | 'content' = 'self') {
  let el: HTMLElement;

  const w = ref<number>(0);
  const h = ref<number>(0);

  if (!isInClient()) {
    return [w, h] as const;
  }

  const update = call(() => {
    if (type === 'self') {
      return () => {
        w.value = el.offsetWidth;
        h.value = el.offsetHeight;
      };
    } else {
      return () => {
        w.value = el.scrollWidth;
        h.value = el.scrollHeight;
      };
    }
  });
  const ob = useTargetSizeObserver(update, type);
  if (isRef(target)) {
    watch(target, () => {
      ob.disconnect();
      el = getComponentRootEl(target?.value);
      if (isNil(el)) {
        return;
      }
      ob.observe(el);
    });
    onMounted(() => {
      el = getComponentRootEl(target.value);
      update();
    });
  } else if (!isNil(target)) {
    ob.observe(target as any);
    onMounted(() => {
      el = getComponentRootEl(target);
      update();
    });
  } else {
    throw new Error('The target is nil');
  }

  return [w, h] as const;
}
