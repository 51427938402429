<template>
  <a-modal
    v-model:open="visible"
    centered
    destroy-on-close
    :mask-closable="false"
    :wrap-class-name="'report-shelf-modal'"
    :ok-text="'Send'"
    :cancel-text="'Cancel'"
    :ok-button-props="{
      disabled: !reportInfo.type || (reportInfo.type === 'other' && !reportInfo.remark),
    }"
    :confirm-loading="isConfirmLoading"
    @cancel="cancel"
    @ok="send"
  >
    <template #title>
      <a-space :class="'report-modal-title flex items-center gap-1'" :size="10">
        <icon-font :class="'text-[40px] text-[#212121]'" type="icon-delisted" />
        <h1 :class="'mb-0 text-[30px] font-semibold text-[#212121]'">Remove Project</h1>
      </a-space>
    </template>

    <div :class="'tips text-[16px] text-[#212121]'">
      Please review our
      <NuxtLink :class="'tips-guidelines underline hover:underline'" target="_blank" :to="'https://www.designfind.com/discussion/607'">
        <span>Community Guidelines</span>
      </NuxtLink>
      for additional information about what is and isn't permitted on DesignFind.
    </div>

    <a-radio-group v-model:value="reportInfo.type" :class="'report-type mt-4 flex flex-wrap gap-y-4 rounded-lg bg-[#f8f8f8] p-4'">
      <a-radio v-for="radio in OffShelfOptions" :key="radio.key" :value="radio.key" :class="'mr-0 basis-1/2'">
        <a-space>
          <span :class="'text-[16px] text-[#212121]'">{{ radio.label }}</span>
        </a-space>
      </a-radio>
    </a-radio-group>

    <a-textarea
      v-if="reportInfo.type === 'other'"
      v-model:value="reportInfo.remark"
      :class="'mt-4'"
      placeholder="Please describe the Problem"
      show-count
      :auto-size="{ minRows: 3, maxRows: 12 }"
      :maxlength="100"
    />
  </a-modal>
</template>

<script setup lang="ts">
import { offShelvesMakingApi } from '@/api/project-details';
import { message } from 'ant-design-vue';
import type { ItemType } from '~/types';
import { OffShelfOptions } from './const';

const emits = defineEmits(['cancel', 'sended']);

const visible = ref(false);
const detailInfo = ref<ItemType>();
let reportInfo = ref<any>({
  id: 0,
  type: '',
  remark: '',
});

const open = (detail: ItemType) => {
  detailInfo.value = detail;
  visible.value = true;
};

const isConfirmLoading = ref(false);
const send = async () => {
  const params = {
    id: detailInfo.value?.id as number,
    type: reportInfo.value.type,
    remark: reportInfo.value.type === 'other' ? reportInfo.value.remark : '',
  };

  try {
    isConfirmLoading.value = true;
    const { data } = await offShelvesMakingApi(params);
    if (data?.value?.code === 0) {
      message.success('remove success!');
      emits('sended');
      close();
    }
  } catch (err) {
    console.log('sended-err :>> ', err);
  } finally {
    isConfirmLoading.value = false;
  }
};

const cancel = () => {
  emits('cancel');
  close();
};
const close = () => {
  reportInfo.value = {
    id: 0,
    type: '',
    remark: '',
  };
  visible.value = false;
};

defineExpose({ open });
</script>

<style lang="less">
.report-shelf-modal {
  .ant-modal-header {
    border-radius: 16px;
    border-bottom: none;
  }
  .ant-modal-footer {
    border-top: none;
    padding-right: 24px;
  }
}
</style>
