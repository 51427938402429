<template>
  <div class="draft-mb-item">
    <div class="item-content relative" @click="handleToDraft">
      <div class="relative">
        <img v-if="draftItem.cover && draftItem.cover.length > 0" class="content-img" :src="draftItem.cover" :alt="draftItem.title" />

        <div v-else class="content-img flex items-center justify-center rounded-lg bg-background-2">
          <icon-font type="icon-Templates " class="text-[32px] text-background-4" />
        </div>

        <img
          v-if="draftItem.aiImages && draftItem.aiImages.length > 0"
          :src="getOssProcessedImage(draftItem.aiImages[0], 'm_mfit,h_200,w_200', 'webp')"
          alt=""
          class="absolute bottom-1 right-1 h-[40px] w-[40px] rounded bg-[#fff] p-[2px]"
        />
        <img v-if="draftItem.forSale && draftItem.type !== 'xthings'" :src="prime" alt="" srcset="" class="absolute left-[-6px] top-0 w-[64px]" />
      </div>
      <div class="content-info">
        <div class="info-title two-line-ellipsis">
          {{ draftItem.title || 'No Title' }}
        </div>
        <div>
          <img v-if="draftItem.type === 'xthings'" :src="XTHINGS_ICON" alt="" class="xthings-logo" />
          <span v-else class="text-[12px] text-text-tertiary">
            {{ getTypeLabel(draftItem.type) }} | {{ moment(draftItem.updatedAt * 1000).format('YYYY/MM/DD') }}
          </span>
        </div>
      </div>
      <div class="absolute bottom-[-2px] right-0 text-[20px] text-text-tertiary" @click.stop="handleDeleteDraft(draftItem.id)">
        <icon-font type="icon-Delete" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import prime from '@/assets/img/prime2.png';
import XTHINGS_ICON from '@/assets/img/xThings/xthings.svg';
import { useDetailsStore } from '@/stores/details';
import { useLayoutStore } from '@/stores/layout';
import type { ItemType } from '@/types/common';
import { Modal } from 'ant-design-vue';
import moment from 'moment';
import type { CONTENT_TYPE } from '~/types';
type PropsType = {
  draftItem: ItemType;
  hasAction?: boolean;
};
type EmitsType = {
  (event: 'deleteDraft', id: number): void;
};
const props = withDefaults(defineProps<PropsType>(), {
  hasAction: true,
});
const emit = defineEmits<EmitsType>();

const layoutStore = useLayoutStore();
const detailsStore = useDetailsStore();

const getTypeLabel = (type: string) => {
  const strObj: any = {
    project: 'project',
    designs: 'Design',
    bundle: 'Bundle',
  };
  return type && strObj[type];
};

const handleDeleteDraft = (id: number) => {
  Modal.confirm({
    content: 'Are you sure you want to delete this Project?',
    okText: 'Delete',
    zIndex: 9999,
    onOk: () => {
      emit('deleteDraft', id);
    },
  });
};

const { sensorDraftItemClick } = useSensors();
const handleToDraft = () => {
  const type = props.draftItem.type as CONTENT_TYPE;
  const id = props.draftItem.id;
  detailsStore.editMaking(type, id);

  layoutStore.setIsShowMbDraft(false);
  layoutStore.closeMbEditorEntryModal();
  const item = props.draftItem;
  sensorDraftItemClick({
    content_id: item.id, // 内容id
    content_name: item.title, // 内容名称
    content_tag: item.tags as string[], // 内容标签
    publish_time: item.publishedAt, // 发布时间
    author_name: item.createdByName, // 作者名称
    author_id: item.createdBy, // 作者id
    service_type: item.type,
  });
};
</script>

<style lang="less" scoped>
.draft-mb-item {
  padding: 8px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 12px;
  .item-content {
    display: flex;
    cursor: pointer;
    .content-img {
      width: 104px;
      height: 90px;
      border-radius: 4px;
      object-fit: cover;
    }
    .content-info {
      margin-left: 16px;

      .info-tag {
        display: inline-block;
        padding: 0px 8px;
        align-items: center;
        border-radius: 2px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: #000;
        font-weight: theme('fontWeight.semibold');
      }
      .info-type {
        background: theme('colors.background.2');
      }
      .info-sale {
        font-weight: theme('fontWeight.bold');
        border-radius: 4px;
        margin-left: 4px;
        background: linear-gradient(121deg, #ffe2b7 7.87%, #e2a054 45.13%, #ffbb6b 83.55%, #ffd66b 96.91%);
      }
      .info-title {
        font-size: 14px;
        font-style: normal;
        font-family: 'Inter semibold';
        line-height: 20px;
        color: #000;
      }
      .xthings-logo {
        width: 68px;
      }
    }
  }
  .item-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    .time {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #999;
    }
    .icon {
      color: #999999;
      font-size: 20px;
    }
  }
}
</style>
