<template>
  <NuxtLink :to="targetLink" target="_blank" class="bundle-card rounded-xl">
    <div class="image relative">
      <RatioBox>
        <img class="h-full w-full rounded-t-xl" :src="getOssProcessedImage(cover, 'm_fill,w_800,h_600', 'webp')" :alt="bundleItem.title" />
      </RatioBox>
      <div v-if="hasBundleRight" class="tag absolute left-0 top-4 flex h-9 items-center gap-1 rounded-r-full bg-status-success px-2 text-[#fff]">
        <icon-font class="text-[20px]" type="icon-Purchased" />
        <span class="text-level-7 font-semibold">Purchased</span>
      </div>
      <div v-if="!hasRight" class="price absolute bottom-0 right-0 flex h-7 items-center rounded-tl-xl px-2 text-level-4 font-bold text-text-primary">
        {{ price }}
      </div>
    </div>

    <div
      class="title two-line-ellipsis h-[68px] rounded-b-xl border border-t-0 border-solid border-[rgba(0,0,0,0.1)] px-4 pb-4 pt-3"
      :title="bundleItem.title"
    >
      <div class="two-line-ellipsis text-level-4 font-semibold text-text-primary">
        {{ bundleItem.title }}
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
type PropsType = {
  bundleItem: any;
  hasRight: boolean;
};
const props = withDefaults(defineProps<PropsType>(), {
  bundleItem: () => ({}),
  hasRight: false,
});

const cover = computed(() => props.bundleItem.blockCover || props.bundleItem.cover);
const price = computed(() => {
  const price = props.bundleItem.origPrice.toFixed(2);
  return `$${price}`;
});
const hasBundleRight = computed(() => {
  if (props.hasRight) {
    return false;
  }
  return props.bundleItem.hasRight;
});
const targetLink = computed(() => {
  return `/${props.bundleItem.type}/${props.bundleItem.id}`;
});
</script>

<style lang="less" scoped>
.bundle-card {
  &:hover {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
  }
}
.price {
  background: rgba(237, 237, 237, 0.8);
  backdrop-filter: blur(7.5px);
}
</style>
