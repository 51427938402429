<template>
  <div class="home-wrapper-xs">
    <Banner />
    <div v-if="items && items.length > 0" class="mobile-list">
      <div v-for="item in items" :key="item.id" class="pb-[20px]">
        <BlockCardItem
          :making-item="item"
          :search-opts="{
            algorithmVersion: item.recommend?.version,
            algorithmType: item.recommend?.strategy,
          }"
          :action-opts="{ showFeedback: true }"
        />
      </div>
      <bottom-loading :loading="status.loading" :finished="status.finished" @infinite="infiniteLoad" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useForYou } from '~/stores/for-you';
import Banner from './banner/index.vue';

const forYouService = useForYou();
const { init, infiniteLoad, updateMakingList } = forYouService;
const { items, status } = storeToRefs(forYouService);

await init();

// 激活时重新获取数据
onActivated(() => {
  updateMakingList();
});
</script>

<style lang="less" scoped>
.home-wrapper-xs {
  @media screen and (max-width: 767px) {
    display: block;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.mobile-list {
  padding: 0 20px 20px;
}
:deep(.ant-list-grid) .ant-col > .ant-list-item {
  margin-bottom: 20px;
}
</style>
