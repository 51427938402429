<template>
  <a-modal v-model:open="visible" class="video-upload" title="Paste YouTube URL" :footer="null" :closable="false" :centered="true">
    <df-input v-model:value="videoUrl" placeholder="Paste YouTube URL" />
    <div v-if="previewError" class="mt-2 text-status-warning">Fail to parse. Please confirm the link is correct and ensure the video is public.</div>
    <div class="flex items-center justify-center gap-4 pt-6">
      <df-btn class="w-[88px]" @click="handleCancel">Cancel</df-btn>
      <df-btn class="w-[88px]" type="primary" :disabled="!isVideoUrlValid" @click="handleConfirm">
        <LoadingOutlined v-if="loading" />
        OK
      </df-btn>
    </div>
  </a-modal>
</template>
<script setup lang="ts">
import type { VideoType } from '@/types/common';
import { LoadingOutlined } from '@ant-design/icons-vue';
import axios from 'axios';
import { v4 } from 'uuid';
const visible = defineModel('visible', { type: Boolean, default: false });
const videoUrl = ref('');
const videoUrlPattern = /^https:\/\/([\s\S])+/;
const youtubeHtmlReg = /<iframe[^>]*src="([^"]*)"/;
const replaceDesktop = /app=desktop&/g;
const isVideoUrlValid = computed(() => videoUrlPattern.test(videoUrl.value));
const previewError = ref(false);
const loading = ref(false);
const emits = defineEmits(['addVideo']);
const videoItem = ref<VideoType>({
  uid: '',
  videoUrl: '',
  thumbnailUrl: '',
  title: '',
  type: 'video',
});

const handleCancel = () => {
  visible.value = false;
};
const handleConfirm = async () => {
  try {
    loading.value = true;
    previewError.value = false;
    const url = videoUrl.value.replace(replaceDesktop, '');
    const resp: any = await axios.get(`https://youtube.com/oembed?url=${url}`);
    const { status, data } = resp;
    if (status === 200) {
      const htmlStr = data.html;
      const matchedArr = htmlStr.match(youtubeHtmlReg);
      if (matchedArr?.length === 2) {
        videoItem.value.uid = v4();
        videoItem.value.videoUrl = matchedArr[1];
        videoItem.value.thumbnailUrl = data.thumbnail_url;
        videoItem.value.title = data.title;
        emits('addVideo', videoItem.value);
        videoUrl.value = '';
        visible.value = false;
      } else {
        previewError.value = true;
        videoItem.value.uid = '';
      }
    } else {
      previewError.value = true;
      videoItem.value.uid = '';
    }
  } catch (error) {
    previewError.value = true;
    videoItem.value.uid = '';
  } finally {
    loading.value = false;
  }
};
watch(videoUrl, () => {
  previewError.value = false;
});
</script>
<style lang="less">
.video-upload {
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-input,
  .ant-select-selector {
    height: 48px;
    background-color: #f5f7fb;
    // border: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }
}
</style>
