<template>
  <div class="overflow-hidden rounded-xl">
    <div v-if="!keyword" class="suggest px-6 py-4">
      <p class="title">Hot Search</p>
      <div class="words flex flex-wrap gap-2">
        <button
          v-for="item in hotWords"
          :key="item"
          :class="[
            'px-[12px] py-[6px]',
            'bg-[#fff] active:bg-text-primary',
            'text-level-5 text-text-primary',
            'rounded-full border border-solid border-text-primary',
            'border-opacity-20 hover:border-opacity-100',
            'whitespace-pre-wrap break-all',
            'cursor-pointer transition-all',
          ]"
          @mousedown="chooseSuggest(item, 'hotSearch')"
        >
          {{ item }}
        </button>
        <!-- <button
          :class="'cursor-pointer whitespace-pre-wrap break-all rounded-full border border-solid border-text-primary border-opacity-20 bg-[#fff] px-[12px] py-[6px] text-level-5 text-text-primary transition-all hover:border-opacity-100 active:bg-text-primary'"
          @mousedown="chooseSuggest(' wooo', 'hotSearch')"
        >
          wooo
        </button> -->
        <!-- <button
          :class="'cursor-pointer whitespace-pre-wrap break-all rounded-full border border-solid border-text-primary border-opacity-20 bg-[#fff] px-[12px] py-[6px] text-level-5 text-text-primary transition-all hover:border-opacity-100 active:bg-text-primary'"
          @mousedown="chooseSuggest('weddinm', 'hotSearch')"
        >
          weddinm
        </button> -->
        <!-- 广告位 -->
        <button
          v-if="isShowAd"
          :class="[
            'px-[12px] py-[6px]',
            'bg-status-alert bg-opacity-10 hover:bg-[#fff]',
            'text-level-5 text-status-alert',
            'rounded-full border border-solid border-status-alert',
            'border-opacity-20 hover:border-opacity-100',
            'whitespace-pre-wrap break-all',
            'cursor-pointer transition-all',
          ]"
          @mousedown="handleAdClick"
        >
          <icon-font type="icon-local_fire_department1" />
          {{ adverts?.word }}
        </button>
      </div>
    </div>
    <div v-if="keyword" class="mind">
      <div class="px-3 py-2" :class="highLightIdx == -1 ? 'keyActivity' : ''" @mousedown="chooseSuggest(keyword, 'associate')">
        <icon-font class="pc-show mr-2 text-[20px] text-text-disabled" type="icon-search-line" />
        <p>
          <span class="text-auxiliary">{{ keyword }}</span>
        </p>
      </div>
      <div
        v-for="(item, idx) in suggestData"
        :key="item"
        class="px-3 py-2"
        :class="highLightIdx == idx ? 'keyActivity' : ''"
        @mousedown="chooseSuggest(item, 'associate')"
      >
        <icon-font class="pc-show mr-2 text-[20px] text-text-disabled" type="icon-search-line" />
        <p v-html="processHighLightKey(item)"></p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { isEmpty } from 'lodash-es';
import { getSearchHotWordsApi, getSearchSuggestApi } from '~/api/search';
import { useAdvertising } from '~/components/ad/ad.hook';
import type { AdvertsResponse } from '~/components/ad/interface';

const { sensorsRecommendClick } = useSensors();
type Props = {
  keyword: string;
  isFocus?: boolean;
  highLightIdx: number;
};
type EmitsType = {
  (e: 'emitSuggestData', data: any): void;
  (e: 'emitChangeKeyword', query: any): void;
  (e: 'emitReportToggleData', bool: boolean): void;
};
const emit = defineEmits<EmitsType>();
const props = defineProps<Props>();
const suggestWord = ref('');
const suggestData = ref([]);
const hotWords = ref([]);
const getHotWords = async () => {
  const { data } = await getSearchHotWordsApi();
  hotWords.value = data.value.data.slice(0, 10);

  const res = await getAdvertsApi({ type: ['search'] });
  adverts.value = res?.[0];
  isShowAd.value = !isEmpty(adverts.value);

  if (!props.keyword && props.isFocus) {
    emit('emitReportToggleData', hotWords.value.length !== 0);
  }
};
const getSuggest = async () => {
  if (!props.keyword) {
    if (props.isFocus) {
      emit('emitReportToggleData', true);
    }
    return;
  }
  const { data } = await getSearchSuggestApi({ q: props.keyword });
  const res = data?.value?.data ?? [];
  if (data?.value?.code === 0) {
    const idx = res.indexOf(props.keyword);
    if (idx > -1) {
      res.splice(idx, 1);
    }
    suggestData.value = res;
    emit('emitSuggestData', res);
  }
  if (props.isFocus) {
    emit('emitReportToggleData', suggestData.value.length !== 0);
  }
};
const chooseSuggest = (item: any, type: string) => {
  suggestWord.value = item;
  sensorsRecommendClick({
    recommend_type: type,
    recommend_word: item,
  });
  emit('emitChangeKeyword', item);
};

const processHighLightKey = (word: string) => {
  const str = word.replace(props.keyword, `<span class="text-auxiliary">${props.keyword}</span>`);
  return str;
};

var timer = null as any;
watch(
  () => props.keyword,
  () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(getSuggest, 200);
  },
  { immediate: true },
);
onMounted(() => {
  nextTick(() => {
    getHotWords();
  });
});

// 广告位处理
const isShowAd = ref(false);
const adverts = ref<AdvertsResponse>();
const { getAdvertsApi } = useAdvertising();
const handleAdClick = () => {
  sensorsRecommendClick({
    recommend_type: 'advertisement',
    recommend_word: adverts.value?.word ?? '',
  });
  navigateTo(adverts.value?.link ?? '', {
    open: { target: '_blank' },
  });
};
</script>
<style lang="less" scoped>
.suggest {
  .title {
    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
  }
}
.mind {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #f5f7fb;
    }
    &.keyActivity {
      background: #f5f7fb;
    }
  }
  p {
    margin: 0;
    display: flex;
    align-items: center;

    font-size: 16px;
    color: #999;
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.pc-show {
  display: block;
}
.xs-show {
  display: none;
}
@media screen and (max-width: 767px) {
  .pc-show {
    display: none;
  }
  .xs-show {
    display: block;
  }
}
</style>
