<template>
  <div>
    <div class="block-design-item cursor-pointer" @click="chooseItem">
      <div class="left-action ml-6">
        <a-radio v-model:checked="checked" />
      </div>
      <div class="center-wrap">
        <img v-if="item.extra && item.extra.cover" class="cover-img" :src="item.extra.cover" alt="" />
        <img v-else class="cover-img default" src="/assets/img/xcs-file.svg" />
      </div>
      <div class="right-wrap">
        <div class="two-line-ellipsis break-all text-level-2 text-text-primary">
          {{ item.name }}
        </div>
        <div class="text-text-tertiary">
          {{ moment(item?.lastModified).format('YYYY/MM/DD HH:mm:ss') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import moment from 'moment';
type DesignItem = {
  name: string;
  lastModified: number;
  url: string;
  selectStatus?: boolean;
  extra: {
    cover?: string;
    canvasIds?: any[];
  };
};
type PropsType = {
  item: DesignItem;
  actions?: string[];
};
type EmitsType = {
  (e: 'toggleActions', type: string): void;
  (e: 'radioCheck', item: any): void;
  (e: 'previewAction', item: any): void;
};

const emits = defineEmits<EmitsType>();
const props = withDefaults(defineProps<PropsType>(), {
  actions: () => [],
  selectable: false,
  multiple: false,
});

const checked = ref<boolean | undefined>(false);
const chooseItem = () => {
  emits('radioCheck', props.item);
};
const showPrev = ref(false);
const closePrev = () => {
  showPrev.value = false;
};
const isPc = ref(false);
const handleWindowResize = () => {
  isPc.value = window.innerWidth > 767;
};
watch(
  () => props.item.selectStatus,
  (val: boolean | undefined) => {
    checked.value = val;
  },
  { deep: true },
);
defineExpose({
  closePrev,
});
onMounted(() => {
  addEventListener('resize', handleWindowResize);
  isPc.value = window.innerWidth > 767;
  checked.value = props.item.selectStatus;
});
onBeforeUnmount(() => {
  removeEventListener('resize', handleWindowResize);
});
</script>
<style lang="less" scoped>
.block-design-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 8px 0 8px;

  .center-wrap {
    width: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    position: relative;
    margin: 0 16px 0 8px;
    background: #fff;
    border-radius: 8px;

    .cover-img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
      padding: 6px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    .default {
      width: 60px;
      margin: 0 auto;
    }
    &::after {
      content: '';
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: transparent;
    }
  }
  .right-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(~'100% - 118px - 8px - 32px - 20px');
    gap: 2px;
  }
}
.prev-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
</style>
