import type { ItemType } from '~/types';

export type XCS_TYPE = {
  type: any;
  token: string | null | undefined;
  url: string;
  name: string;
};

export enum SourceType {
  ORIGINAL = 'original',
  REMIX = 'remix',
}

export type DetailProvide = Readonly<{
  isPreview: boolean; // 是否预览页
  audit?: boolean; // 是否审核页
}>;

/**
 * xthings 详情页类型: content(已付费) product(未付费)
 */
export enum XthingsType {
  Content = 'content',
  Product = 'product',
}

/**
 * 审核信息
 */
export interface ParaSetting {
  uid: string;
  processType: number;
  materials: string;
  power: number;
  speed: number;
  lines: string;
  pass: number;
  bitmapMode?: any;
  other: string;
}

export interface AttachFile {
  id: number;
  uid: string;
  name: string;
  url: string;
  md5: string;
  fileSize: number;
  type: string;
  lastModified: number;
  createdAt: number;
  extra?: any;
}

export interface LatestAudit {
  status: AuditStatus;
  remark?: string;
  submitTime?: number;
}

/**
 * 审核状态
 */
export enum AuditStatus {
  Todo = 'todo',
  Approved = 'approved',
  Reject = 'reject',
  OffShelves = 'off_shelves',
}

export interface OriginFileObj {
  uid: string;
}

export interface Media {
  lastModified: number;
  lastModifiedDate: string;
  name: string;
  originFileObj: OriginFileObj;
  percent: number;
  size: number;
  status: string;
  type: string;
  uid: string;
  url: string;
}

export interface Extra {
  hasAttachFile: boolean;
  hasVideo: boolean;
  hasXcsFile: boolean;
  medias: Media[];
  referrer: string;
}

export interface Extra {
  extId: string;
  icon: string;
}

export interface Device {
  type: string;
  id: number;
  code: string;
  name: string;
  extra: Extra;
}

export interface Extra {
  icon: string;
}

export interface DifficultyLevel {
  type: string;
  id: number;
  code: string;
  name: string;
  extra: Extra;
}

export interface Extra {
  forSale: boolean;
  icon: string;
  link: string;
  supportRemix: boolean;
}

export interface License {
  type: string;
  id: number;
  code: string;
  name: string;
  extra: Extra;
}

export interface ActionSum {
  like: number;
  unlike: number;
  view: number;
  favorite: number;
  follow: number;
  comment: number;
  answer: number;
  download: number;
  remix: number;
  sales: number;
}

export interface UserAction {
  like: number;
  unlike: number;
  view: number;
  favorite: number;
  follow: number;
  comment: number;
  answer: number;
  download: number;
  remix: number;
  sales: number;
}

export interface RootType {
  id: number;
  createdAt: number;
  createdBy: number;
  updatedAt: number;
  updatedBy: number;
  deletedAt?: any;
  type: string;
  title: string;
  summary: string;
  description: string;
  content: string;
  deviceId: number;
  images: string[];
  cover: string;
  applyDevices: any[];
  videos: any[];
  powerId: number;
  materials: string[];
  paraSettings: ParaSetting[];
  steps: any[];
  materialInfo: string;
  softwareId: number;
  otherSoftware: string;
  makingTime: number;
  source: string;
  licenseId: number;
  originals: any[];
  forSale: boolean;
  origPriceCent: number;
  origPrice: number;
  difficultyLevelId: number;
  sellTag: string;
  attachFiles: AttachFile[];
  contentTags: any[];
  tags?: any;
  publishedAt: number;
  status: string;
  mstatus: string;
  latestAudit: LatestAudit;
  extra: Extra;
  deviceName: string;
  device: Device;
  powerName: string;
  softwareName: string;
  difficultyLevelName: string;
  difficultyLevel: DifficultyLevel;
  license: License;
  supportRemix: boolean;
  isCreator: boolean;
  createdByName: string;
  createdByHeadpic: string;
  createdByDeleted: boolean;
  actionSum: ActionSum;
  userAction: UserAction;
  hasDiscount: boolean;
  hasRight: boolean;
  discount?: any;
  hasLiveVersion: boolean;
}

export type AuditType = RootType & ItemType;
