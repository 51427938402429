import { Methods, request, REQUEST_SEVER } from '@/utils/request';
import { defineCommonApi } from '~/utils/fetch/define-api';

// 获取ai合集的作品列表
export const getPopularProjectsList = defineFindApi({
  url: `/community/v1/web/collection/${import.meta.env.VITE_ARTIMIND_PROJECT_COLLECTION_ID}/items`,
  method: HttpMethod.GET,
  respTy: RespTy.Json<any[]>(),
});

export const getHomePopularList = defineCommonApi({
  url: '/efficacy/v1/data/type/aimake_home_popular/items',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any[]>(),
});

// 获取用户当前算力值
export const getUserCurCredits = defineCommonApi({
  url: '/ai/v1/credit/getBalance',
  method: Methods.GET,
  respTy: RespTy.Json<any>(),
});

// 查询历史任务
export const getHistory = defineCommonApi({
  url: '/ai/v3/artimind/task/status',
  method: Methods.GET,
  respTy: RespTy.Json<any>(),
});

// 删除历史任务
export const deleteHistory = (data: { id: string }) => {
  return request[REQUEST_SEVER.COMMON]({
    url: `/ai/v2/artimind/task/${data.id}`,
    method: Methods.DELETE,
    data,
  });
};

// 查询历史任务状态
export const getHistoryStatus = (data: { task_ids: string[] }) => {
  return request[REQUEST_SEVER.COMMON]({
    url: `/ai/v3/artimind/task/status`,
    method: Methods.POST,
    data,
  });
};

// 提交生成
export const submitGenerate = (data: any) => {
  return request[REQUEST_SEVER.COMMON]({
    url: `/ai/v3/artimind/task/commit`,
    method: Methods.POST,
    data,
  });
};

// 更新任务结果状态
export const updateTaskResult = (data: any) => {
  return request[REQUEST_SEVER.COMMON]({
    url: `/ai/v3/artimind/task/${data.task_id}/results/0`,
    method: Methods.PUT,
    data,
  });
};
// 获取生成图片列表
export const getUserImgList = defineCommonApi({
  url: '/ai/v3/artimind/user/results',
  method: Methods.GET,
  respTy: RespTy.Json<any>(),
  paramTy: ParamTy.Query<{
    page_number: number;
    page_size: number;
  }>(),
});

// 新增Project草稿
export const createProjectApiV2 = (type: string, data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v2/web/making/${type}`,
    method: Methods.POST,
    data,
  });
};
