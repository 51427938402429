<template>
  <div id="docx-container" class="docx-preview">
    <div class="border-0 border-t border-solid border-[rgba(0,0,0,0.1)] py-10">
      <div class="flex justify-center">
        <div class="flex h-[400px] w-[310px] items-center justify-center overflow-hidden rounded-2xl text-[0]">
          <img class="block w-full rounded-2xl object-cover" :src="WordCover" alt="" />
        </div>
      </div>
    </div>

    <div class="flex justify-center gap-8 border-0 border-t border-solid border-[rgba(0,0,0,0.1)] py-8">
      <div class="flex cursor-pointer gap-2 text-uni-1" @click="onDownload">
        <icon-font class="text-[24px]" type="icon-a-30-Download" />
        <span class="text-level-3 font-semibold">Download</span>
      </div>
      <span class="inline-flex w-[1px] bg-[rgba(0,0,0,0.1)]"></span>
      <div class="flex cursor-pointer gap-2 text-uni-1" @click="open">
        <df-spin v-if="isSpinning" />
        <icon-font v-if="!isSpinning" class="text-[24px]" type="icon-eye-line" />
        <span class="text-level-3 font-semibold">Preview</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import WordCover from '@/assets/img/file-preview/word_cover.png';
import { renderAsync } from 'docx-preview';

type PropsType = {
  fileUrl: string;
  name?: string;
};
const props = defineProps<PropsType>();

const isSpinning = ref(false);
// 打开
async function open() {
  if (isSpinning.value) {
    return;
  }
  try {
    isSpinning.value = true;
    const docxBlob = await fetchDocxFile(props.fileUrl);
    const htmlElement = await convertDocxToHtml(docxBlob);
    convertCanvasToPdf(htmlElement);
  } catch (error) {
    console.error('open-Error:', error);
  } finally {
    isSpinning.value = false;
  }
}

// 1. 获取文件
async function fetchDocxFile(url: string) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.blob();
}
// 2. 使用 docx-preview 将 .docx 转换为 HTML
async function convertDocxToHtml(blob: Blob) {
  const element = document.createElement('div');
  await renderAsync(blob, element, undefined, {
    className: 'docx',
    inWrapper: true,
    ignoreWidth: false,
    ignoreHeight: true,
    ignoreFonts: true,
    breakPages: true,
    ignoreLastRenderedPageBreak: true,
    experimental: true,
    trimXmlDeclaration: true,
    useBase64URL: true,
    renderChanges: true,
    renderHeaders: true,
    renderFooters: true,
    renderFootnotes: true,
    renderEndnotes: true,
    renderComments: true,
    debug: false,
  });
  return element;
}

// 3. 把 html 写入到新窗口
function convertCanvasToPdf(el: HTMLDivElement) {
  const newWindow = window.open('', '_blank');
  if (!newWindow) {
    return;
  }
  newWindow.document.write(`<html><head><style>html,body{margin:0;padding:0;}</style><title>${props.name}</title></head><body>`);
  newWindow.document.write(el.innerHTML);
  newWindow.document.write('</body></html>');
  newWindow.document.close();
}

// 下载
function download() {
  onDownload();
}

function onDownload() {
  const link = document.createElement('a');
  link.setAttribute('download', props.name || 'file');
  link.setAttribute('href', props.fileUrl);
  link.click();
  link.remove();
}

defineExpose({
  open,
  download,
});
</script>

<style lang="less" scoped></style>
