import { type ItemType } from '@/types';
import dayjs from 'dayjs';

// 计算价格信息的 computed
export const priceComputed = (makingItem: ItemType) => {
  const discount = makingItem.discount;
  const hasDiscount = makingItem.hasDiscount;

  // 判断是否真的有折扣
  const hasDisc = makingItem.hasDiscount && discount && Object.keys(discount).length !== 0;

  // 原价
  const origPrice = +makingItem.origPrice;
  if (!hasDiscount || !discount || Object.keys(discount).length === 0) {
    return {
      hasDiscount: hasDisc,
      origPrice: `$ ${origPrice.toFixed(2)}`,
      disCountPrice: `$${0}`,
      savePrice: `$${0}`,
      startTime: 0,
      endTime: 0,
    };
  }
  // 折后价格
  const disCountPrice = discount.disPrice.toFixed(2);
  // 折扣百分比
  const discountPercent = discount.discountPercent;
  const disPercent = discount.discountPercent;
  // 节约了多少
  const savePrice = (origPrice - discount.disPrice).toFixed(2);
  // 处理 tips
  let tips = '';
  const endTime = discount.endTime ?? 0;
  if (endTime === -1) {
    tips = `long-term discount`;
  } else {
    const startTime = discount.startTime ?? 0;
    const startTimeStr = dayjs.unix(startTime).format('YYYY/MM/DD');
    const endTimeStr = dayjs.unix(endTime).format('YYYY/MM/DD');
    tips = `${startTimeStr} - ${endTimeStr}`;
  }
  return {
    hasDiscount: hasDisc,
    origPrice: `$${origPrice.toFixed(2)}`,
    disCountPrice: `$ ${disCountPrice}`,
    discountPercent: `-${discountPercent}%`,
    disPercent,
    savePrice: `$${savePrice}`,
    tips,
  };
};
