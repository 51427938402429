<template>
  <div class="feedback-wrap" @click.stop="">
    <div :class="`feedback ${type}`">
      <div class="message">
        <icon-font type="icon-a-Frame3" />
        <p>Tell us why you are closing.</p>
      </div>
      <div class="ops-btns" @click.stop="select">
        <button>Irrelevant</button>
        <button>Too repetitive</button>
        <button>Bad placement</button>
      </div>
    </div>
    <icon-font type="icon-icon-right-m" class="back-btn" @click.stop="back" />
  </div>
</template>

<script setup lang="ts">
import { useSensors } from '~/composables';

interface FeedbackProps {
  type: 'horizontal' | 'vertical';
}

withDefaults(defineProps<FeedbackProps>(), {
  type: 'horizontal',
});
const emits = defineEmits<{
  (e: 'back'): void;
  (e: 'feedback', ops: string): void;
}>();

const back = () => {
  emits('back');
};

const { sensorsFeedbackClick } = useSensors();
const select = (ev: MouseEvent) => {
  const el = ev.target as HTMLElement;
  const content = el.textContent ?? '';
  sensorsFeedbackClick({
    feedback_type: 'advertisement',
    feedback_content: content,
  });
  emits('feedback', content);
};
</script>

<style scoped lang="less">
.feedback-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.8);
  cursor: default;
  .feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    .message {
      .anticon {
        font-size: 24px;
      }
      p {
        margin-bottom: 0;
        text-align: center;
        font-size: 14px;
        font-weight: theme('fontWeight.semibold');
        line-height: 24px;
      }
    }
    button {
      outline: none;
      padding: 2px 8px 2px 8px;
      border: 1.2px solid #ffffff;
      border-radius: 4px;
      background-color: transparent;
      font-size: 14px;
      font-weight: theme('fontWeight.semibold');
      line-height: 24px;
      text-align: center;
      cursor: pointer;
    }

    &.horizontal {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      .message {
        display: inline-flex;
        align-items: center;
        p {
          margin-left: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ops-btns {
        display: inline-flex;
        margin-left: 8px;
        button {
          flex-shrink: 0;
        }
        button + button {
          margin-left: 16px;
        }
      }
    }
    &.vertical {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 64px);
      .message {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;

        .anticon {
          margin-bottom: 4px;
        }

        p {
          line-height: 24px;
        }
      }
      .ops-btns {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        button {
          width: 100%;
        }
        button + button {
          margin-top: 8px;
        }
      }
    }
  }
  .back-btn {
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 12px;
    font-size: 20px;
    color: #fff;
    box-sizing: border-box;
    background: #ffffff33;
  }
}
</style>
