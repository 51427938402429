import point5 from '~/assets/img/project-detail/effective_price.jpg';
import point6 from '~/assets/img/project-detail/for_everyone.jpg';
import point1 from '~/assets/img/project-detail/risk_free.jpg';
import point4 from '~/assets/img/project-detail/unlimited_downloads.jpg';

/**
 * 详情页 - Basic Information 的所有字段(普通案例-inspiration/howtos 共用)
 */
export const projectInfoItems = [
  { label: 'Software', key: ['softwareName', 'otherSoftware'] }, // otherSoftware：已下线该功能
  { label: 'Machine', key: ['deviceName', 'applyDevices'] }, // applyDevices：tip的试用机型，已下线该功能
  { label: 'Laser Module Output Power', key: ['powerName'] },
  { label: 'Material Used', key: ['materials'] },
  { label: 'Material Info', key: ['materialInfo'] },
  { label: 'Making Time(mins)', key: ['makingTime'] },
];

/**
 * 详情页 - Basic Information 的所有字段(xThings案例)
 */
export const xThingsInfoItems = [
  { label: 'Software', key: ['softwareName'] },
  { label: 'Machine & Laser Power', key: ['devicePowersInfos'] },
  { label: 'Material Used', key: ['materials'] },
  { label: 'Material info', key: ['materialInfo'] },
  { label: 'Making Time(mins)', key: ['makingTime'] },
];

/**
 * 详情页 - Settings 参数的所有字段
 */
export const paraSettingInfo = [
  { label: 'Process Method', key: ['processType'] },
  { label: 'Process Material', key: ['materials'] },
  { label: 'Power(%) or Cut Pressure(g)', key: ['power'] },
  { label: 'Speed(mm/s)', key: ['speed'] },
  { label: 'Lines per cm', key: ['lines'] },
  { label: 'Pass', key: ['pass'] },
  { label: 'Bitmap mode', key: ['bitmapMode'] },
  { label: 'Other Parameters', key: ['other'] },
  { label: 'Laser Type', key: ['laserType'] }, // 这个字段的值固定有 2 种: Red/Blue, 只有 F1 显示
];

/**
 * 详情页-付费案例图纸的卖点描述集合
 */
export const SellingPoint = {
  pointTips: [
    {
      icon: 'icon-downloadable',
      text: 'Instant access to files after payment.',
    },
    {
      icon: 'icon-no-returns',
      text: 'No returns/exchanges for instant download items.',
    },
  ],
  pointList: [
    { icon: point1, text: 'Risk-free Commercial Use' },
    { icon: point4, text: 'Unlimited Downloads' },
    { icon: point6, text: 'Perfect For Everyone' },
  ],
  pointListInfo: [
    `Risk-Free Commercial Use: Our license lets you sell your creations confidently, simplifying your business launch! <a href="https://www.designfind.com/discussion/608" target="_blank">Read more license</a>.`,
    `Unlimited Downloads: Secure access to your purchases to flexibly support your creativity.`,
    `Perfect For Everyone: Whether you're a beginner or a pro, select the high-quality design that suits your needs.`,
  ],
  // bundle 的卖点icon和描述
  bundlePointList: [
    { icon: point1, text: 'Risk-free Commercial Use' },
    { icon: point4, text: 'Unlimited Downloads' },
    { icon: point5, text: 'Cost-Effective Price' },
  ],
  bundlePointListInfo: [
    `Risk-free Commercial Use: Our commercial license lets you sell your creations worry-free, making business launch easy. Read the <a href="https://www.designfind.com/discussion/608" target="_blank">license</a>.`,
    'Unlimited Downloads: Each purchase can enjoy unlimited downloads and are always available online.',
    'Cost-Effective Price: Files are sold in combinations to offer greater value for money.',
  ],
};

export const SUBSCRIBE_TITLE = 'Free access to exclusive designs';
export const UPGRADE_TITLE = 'Available subscriber-only designs are used up.\n' + 'Upgrade to Pro+ membership for unlimited subscriber-only designs';
