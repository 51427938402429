<template>
  <div
    v-if="items && items.length > 0"
    :class="['df-banner-wrap', 'flex w-full flex-col items-center', 'overflow-x-hidden', items.length === 1 ? 'only-one' : '']"
  >
    <div :class="'df-banner'">
      <Carousel ref="carouselRef" :items="items" :gap="14" :auto="true" :auto-play-speed="5000" :show-indicator="false" @change="onChange">
        <template #item="{ item }">
          <a
            :href="item.link"
            target="_blank"
            :aria-label="`Read more about ${item.name}`"
            class="item"
            :data-sensors-exposure-event-name="SENSORS_EVENT.HOMEBANNEREXPOSURE"
            :data-sensors-exposure-property-content_id="item.id"
            :data-sensors-exposure-property-content_name="item.name"
            :data-sensors-exposure-property-publish_time="item.createdAt"
            :data-sensors-exposure-property-show_source="locationHref"
            data-sensors-exposure-property-belong_module="home"
            @click="handleBannerClick(item)"
          >
            <picture>
              <source :srcset="item.mbImg" media="(max-width: 768px)" />
              <img :src="item.pcImg" :alt="item.name" />
            </picture>
          </a>
        </template>
        <template #arrow="{ prev, next }">
          <ClientOnly>
            <div :class="['df-banner-btn-wrap', 'h-[64px] w-[32px]', 'absolute left-0 top-[50%] translate-y-[-50%]', 'overflow-hidden']">
              <div class="df-banner-btn prev" @click="prev">
                <icon-font type="icon-icon-right-m" class="text-[24px]" />
              </div>
            </div>
            <div :class="['df-banner-btn-wrap', 'h-[64px] w-[32px]', 'absolute right-0 top-[50%] translate-y-[-50%]', 'overflow-hidden']">
              <div class="df-banner-btn next" @click="next">
                <icon-font type="icon-a-icon-right-m1" class="text-[24px]" />
              </div>
            </div>
          </ClientOnly>
        </template>
      </Carousel>
    </div>
    <div v-if="items.length > 1" role="menu" class="df-banner-dots mt-3" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
      <span v-for="(_, index) in items" :key="index" role="menuitem" :class="index === activeIndex ? 'active' : ''" @click="onDotClick(index)"></span>
    </div>
  </div>
</template>

<script setup lang="ts">
import Carousel from '@/components/common/carousel/index.vue';
import { isNil } from 'lodash-es';
import type { CarouselInstance } from '~/components/common/carousel/types';
import { SENSORS_EVENT } from '~/types';
import { useBanner, useEventTrack } from './hooks';

const items = await useBanner();
const { handleBannerClick, locationHref } = useEventTrack();

const activeIndex = ref(0);
const onChange = (index: number) => {
  activeIndex.value = index;
};

const carouselRef = shallowRef<CarouselInstance>();
const onDotClick = (index: number) => {
  if (!isNil(carouselRef.value)) {
    carouselRef.value.move(index);
  }
};
const onMouseEnter = () => {
  if (!isNil(carouselRef.value)) {
    carouselRef.value.pause();
  }
};
const onMouseLeave = () => {
  if (!isNil(carouselRef.value)) {
    carouselRef.value.reuse();
  }
};
</script>

<style lang="less" scoped>
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;

  @media (min-width: 769px) {
    border-radius: 12px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.df-banner-wrap {
  &:not(.only-one) {
    padding: 12px 0;
    @media (min-width: 769px) and (max-width: 1599px) {
      padding: 12px 40px;
    }
    .df-banner {
      @media (min-width: 1728px) {
        width: 1392px;
      }
      @media (min-width: 1600px) and (max-width: 1727px) {
        width: 1264px;
      }
      @media (min-width: 1201px) and (max-width: 1599px) {
        width: 72%;
      }
      @media (max-width: 1200px) {
        width: 100%;
        overflow: hidden;
      }
    }
  }
  &.only-one {
    padding: 12px 40px;
    @media (max-width: 1200px) {
      padding: 12px 0;
    }
    .df-banner {
      width: 100%;
      overflow: hidden;
    }
  }

  :deep(.df-carousel-wrap) {
    overflow: initial;
  }
  :deep(.df-carousel) {
    height: auto;

    @media (min-width: 769px) {
      padding-top: 21.72%;
    }
    @media (max-width: 768px) {
      padding-top: 47.73%;
    }

    .df-carousel-item {
      @media (min-width: 769px) {
        opacity: 1;
        transition: opacity 0.3s;
        &.prev,
        &.next {
          opacity: 0.4;
        }
      }
    }
  }
  .df-banner-btn-wrap {
    @media (max-width: 768px) {
      display: none;
    }
    .df-banner-btn {
      width: 64px;
      height: 64px;
      border-radius: 32px;
      display: flex;
      align-items: center;
      color: #fff;
      background: rgba(0, 0, 0, 0.4);
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.prev {
        margin-left: -32px;
        .anticon {
          margin-left: 50%;
        }
      }
      &.next {
        .anticon {
          transform: translateX(50%);
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 1);
      }
    }
  }
  .df-banner-dots {
    display: inline-flex;
    span {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      padding: 0;
      margin-left: 6px;
      border: none;
      background: #212121;
      opacity: 0.1;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.active {
        width: 28px;
        background: #ff0036;
        opacity: 1;
      }
    }
  }
}
</style>
