// 导航菜单栏的锚点id
export enum FormIds {
  BasicInfo = 'basicInfoForm',
  ProjectFiles = 'projectFilesForm',
  ProductionInfo = 'productionInfoForm',
  Instruction = 'instructionForm',
  WrapUp = 'wrapUpForm',
}
// 菜单列表 全
export const StepsList: Readonly<
  {
    title: string;
    id: string;
  }[]
> = [
  {
    title: 'Basic Information',
    id: FormIds.BasicInfo,
  },
  {
    title: 'Project Files',
    id: FormIds.ProjectFiles,
  },
  {
    title: 'Production Info',
    id: FormIds.ProductionInfo,
  },
  {
    title: 'Instruction',
    id: FormIds.Instruction,
  },
  {
    title: 'Wrap Up',
    id: FormIds.WrapUp,
  },
];
// 每个表单收起展开的key
export enum CollapseKey {
  BasicInfo = 'basicInfoForm',
  ProjectFiles = 'projectFilesForm',
  ProductionInfo = 'productionInfoForm',
  Instruction = 'instructionForm',
  WrapUp = 'wrapUpForm',
}

// 新手引导的锚点id
export enum GuideIds {
  mediaLibrary = 'media-library',
  projectFiles = 'project-files',
  instruction = 'guide3',
}

export enum FileEventType {
  Add = 'add',
  Remove = 'remove',
}
