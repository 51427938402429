export enum FileStatus {
  Done = 'done',
  Success = 'success',
  Uploading = 'uploading',
  Error = 'error',
  Removed = 'removed',
}

export enum SourceType {
  Original = 'original',
  Remix = 'remix',
}
