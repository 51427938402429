<template>
  <div class="relative inline-block rounded bg-uni-6 text-sm">
    <a-popover overlay-class-name="popover-box" trigger="hover" :overlay-style="{ display: canHover ? 'block' : 'none' }" placement="top">
      <!-- 触发的容器 -->
      <div
        class="flex h-[42px] items-center gap-1.5 rounded border border-solid border-[rgba(0,0,0,0.1)] bg-uni-6 px-2 py-1"
        :class="canHover ? 'cursor-pointer hover:bg-background-2' : 'cursor-default'"
        @click="handleMobildShopNow"
      >
        <div v-if="iconSrc" :style="imageStyle">
          <img :src="getOssProcessedImage(iconSrc, `m_mfit,w_${size},h_${size}`, 'webp')" :width="size" />
        </div>
        <span class="two-line-ellipsis ml-1 flex items-center" :class="isMobile && adLink ? 'underline active:text-uni-10' : ''">
          {{ name }}
        </span>
        <div v-if="item.extraName" class="extra-name flex items-center gap-1.5">
          <span class="inline-block h-4 w-[1px] bg-text-disabled"></span>
          <span>{{ item.extraName }}</span>
        </div>
      </div>

      <!-- 气泡内容 -->
      <template v-if="isPc && canHover" #content>
        <div class="block-shadow rounded bg-uni-6 p-2">
          <div :class="contentClassName">
            <div v-if="previewImage" :style="previewStyle">
              <img class="rounded" :src="getOssProcessedImage(imgSrc, `m_mfit,w_${previewSize},h_${previewSize}`, 'webp')" :width="previewSize" />
            </div>
            <div class="mb-2 flex items-center justify-center gap-1.5">
              <div class="max-w-[160px]" :style="{ textAlign: popoverTitle.align || 'center' }">
                {{ popoverTitle.text ? popoverTitle.text : name }}
              </div>
              <div v-if="item.extraName" class="extra-name flex items-center gap-1.5">
                <span class="inline-block h-4 w-[1px] bg-text-disabled"></span>
                <span>{{ item.extraName }}</span>
              </div>
            </div>
            <div v-if="tags?.length" class="flex w-full flex-wrap gap-2">
              <span v-for="tag in tags" :key="tag" class="rounded bg-[#fff] px-2 py-[2px] text-[12px] font-semibold text-[#555]">
                {{ tag }}
              </span>
            </div>
            <a-button
              v-show="adLink"
              block
              :href="adLink"
              type="primary"
              target="_blank"
              class="mt-2 rounded-full font-semibold"
              @click="handleShopNow"
            >
              <div>
                <icon-font type="icon-a-shop1" class="w-4" />
                <span>Shop</span>
              </div>
            </a-button>
          </div>
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script setup lang="ts">
type Props = {
  name: string;
  extraName?: string;
  image?: string;
  icon?: string;
  adLink?: string;
  size: number;
  previewSize: number;
  hover?: boolean;
};
type popoverTitleType = {
  text: string;
  align: 'left' | 'center' | 'right';
};
type PropsType = {
  item: Props;
  popoverTitle?: popoverTitleType;
  previewImage?: boolean;
  tags?: string[];
  contentClassName?: any;
  type?: 'machine' | 'material';
};
const props = withDefaults(defineProps<PropsType>(), {
  popoverTitle: () => ({ text: '', align: 'center' }),
  contentClassName: undefined,
  previewImage: true,
  tags: undefined,
  type: 'machine',
});
const { item } = toRefs(props);

const { isMobile, isPc } = useScreen();
const { sensorButtonClick } = useSensors();
const { name, size, previewSize, adLink } = item.value;

const imgSrc = computed(() => item.value?.image || item.value?.icon || '');
const iconSrc = computed(() => item.value?.icon || '');
const canHover = computed(() => {
  return props.item.hover !== undefined ? props.item.hover : true;
});
const imageStyle = computed(() => {
  return {
    display: 'flex',
    height: `${size}px`,
    alignItems: 'center',
  };
});
const previewStyle = computed(() => {
  return {
    display: 'flex',
    height: `${previewSize}px`,
    alignItems: 'center',
    justifyContent: 'center',
  };
});
const handleMobildShopNow = () => {
  if (isMobile.value && adLink) {
    window.open(adLink, '_blank');
    handleShopNow();
  }
};
const handleShopNow = () => {
  if (!props.type) {
    return;
  }
  const element_id = props.type === 'machine' ? 'shop_machines' : 'shop_materials';
  sensorButtonClick({
    element_id,
  });
};
</script>

<style scoped lang="less">
.block-shadow {
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 20px 0px rgba(0, 0, 0, 0.1);
}
</style>

<style lang="less">
.popover-box {
  padding: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    padding: 0;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}
</style>
