<template>
  <div
    v-if="size.width > 767"
    ref="rootRef"
    :class="['relative flex h-full w-[446px] items-center justify-center overflow-hidden', isRegistered ? 'normal-container' : 'reward-container']"
  >
    <div v-if="isRegistered" class="flex w-[83.8%] flex-col items-start justify-center text-[#FFF]">
      <h5 class="mb-[42px] text-[28px] font-bold leading-[38px]">Create A Free Account</h5>
      <div class="flex flex-col gap-[26px]">
        <div v-for="(item, index) in config.content" :key="index" :class="`flex gap-[13px] text-[0px] ${item.cls || ''}`">
          <img :src="item.icon" alt="" class="h-[22px] w-[22px]" />
          <p class="mb-0 text-[16px] font-semibold leading-[22px]">{{ item.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DfConfig, AImakeConfig } from './config';
import REGISTERED_REWARD from '@/assets/img/login/register-reward.png';

const { screenSize: size } = useScreen();

const { isRegistered } = useDeviceFlags();

const rootRef = shallowRef<HTMLElement>();
const isAimakeDomain = ref(location.pathname.includes('/aimake'));
useTargetVisible(rootRef, () => {
  isAimakeDomain.value = location.pathname.includes('/aimake');
});

const config = computed(() => (isAimakeDomain.value ? AImakeConfig : DfConfig));
const normalBg = computed(() => `url(${config.value.bg})`);
// 注册引导背景
const rewardBg = computed(() => `url(${REGISTERED_REWARD})`);
</script>

<style scoped lang="less">
.normal-container {
  background-image: v-bind(normalBg);
  background-size: cover;
  background-position: center center;
}
.reward-container {
  background-image: v-bind(rewardBg);
  background-size: cover;
  background-position: center center;
}
</style>
