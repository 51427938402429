import { isNil } from 'lodash-es';

export function useDevicePixelRatio() {
  const ratio = ref(1);
  const windowRef = useGlobalThis();
  onActivated(() => {
    ratio.value = window.devicePixelRatio;
  });
  onMounted(() => {
    ratio.value = window.devicePixelRatio;
  });
  useEventListener(windowRef, 'resize', () => {
    if (isNil(windowRef.value)) {
      return;
    }
    ratio.value = windowRef.value.devicePixelRatio;
  });
  return computed(() => ratio.value);
}
