<template>
  <a-modal
    width="1000px"
    :open="visible"
    :footer="null"
    title="My Draft"
    closable
    :z-index="1006"
    destroy-on-close
    wrap-class-name="draft-modal"
    @cancel="handleCloseSelf"
  >
    <div class="h-[530px] overflow-y-auto p-6">
      <a-list
        v-if="!isEmptyResult"
        :data-source="draftList"
        :row-key="(item: any) => item.id"
        :grid="{ column: 3, gutter: 16 }"
        :loading="firstLoading"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <DraftItem :draft-item="item" @close="handleClose" @delete-draft="handleDelDraft" />
          </a-list-item>
        </template>
      </a-list>
      <div v-else class="empty-box">
        <a-empty class="empty" :image="emptyImg" />
      </div>
      <bottom-loading v-if="draftList.length > 0" class="mt-10" :loading="isLoading" :finished="isFinished" @infinite="handleInfiniteLoading" />
    </div>
  </a-modal>
</template>
<script setup lang="ts">
import { deleteDraftApi, getDraftListApi } from '@/api/explore';
import emptyImg from '~/assets/img/no-data.png';
import DraftItem from '../create/DraftItem.vue';
const isEmptyResult = ref(false);
const visible = defineModel('visible', { type: Boolean, default: false });
const draftList = ref([] as any[]);

const firstLoading = ref(false);
const isLoading = ref(false);
const isFinished = ref(false);
const pagination = reactive({
  current: 1,
  pageSize: 21,
  total: 0,
});

const handleInfiniteLoading = async () => {
  if (isLoading.value || isFinished.value) {
    return;
  }
  isLoading.value = true;
  pagination.current = pagination.current + 1;
  getDraftList();
};
const emit = defineEmits(['close', 'close-self']);

const handleClose = () => {
  visible.value = false;
  emit('close');
};
const handleCloseSelf = () => {
  visible.value = false;
};
watch(
  () => visible.value,
  () => {
    if (visible.value) {
      firstLoading.value = true;
      draftList.value = [];
      pagination.current = 1;
      getDraftList();
    }
  },
);
async function getDraftList() {
  try {
    const res = await getDraftListApi({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    const data = res?.data.value.data;
    if (res?.data.value.code === 0) {
      pagination.total = data?.pagination?.total;
      pagination.current = data?.pagination?.current;
      isFinished.value = pagination.current >= Math.ceil(pagination.total / pagination.pageSize);
      if (data.list && data.list.length > 0) {
        const arr = handleDataDeduplication(draftList.value, data.list);
        draftList.value = draftList.value.concat(arr);
      } else {
        draftList.value = [];
      }
      isEmptyResult.value = !draftList.value.length;
    }
  } catch (error) {
    console.log('getDraftList error: ', error);
  } finally {
    firstLoading.value = false;
    isLoading.value = false;
  }
}
async function handleDelDraft(id: number) {
  const { data } = await deleteDraftApi(id);
  if (data.value.code === 0) {
    const index = draftList.value.findIndex((item: { id: number }) => item.id === id);
    if (index >= 0) {
      draftList.value.splice(index, 1);
      isEmptyResult.value = !draftList.value.length;
    }
    message.success('Deleted successfully');
  }
}
</script>
<style lang="less">
.draft-modal {
  .ant-modal-body {
    padding: 0;
    background-color: #f5f7fb;
    border-radius: 8px;
  }
}
</style>
