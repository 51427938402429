<template>
  <a-modal
    destroy-on-close
    :width="1000"
    :open="visible"
    :z-index="zIndex"
    wrap-class-name="act-create-modal-box"
    :footer="null"
    centered
    :closable="false"
    @cancel="handleClose"
  >
    <div class=" ">
      <div class="flex justify-between border-0 border-b border-solid border-[#1A1A1A]/10 px-8 py-4 text-[24px]">
        <span class="font-bold text-text-primary">{{ title }}</span>
        <icon-font type="icon-close-line" class="cursor-pointer text-text-tertiary" @click="handleClose" />
      </div>
      <div class="h-[516px] overflow-auto">
        <div class="mb-4 mt-4 flex justify-center rounded-xl border">
          <df-btn type="highlight" size="large" class="flex items-center" @click="goCreate">
            <icon-font type="icon-Add" class="text-[24px]" />
            <span class="text-[16px]">Create New Project</span>
          </df-btn>
        </div>

        <a-spin class="" :spinning="isSpinning">
          <div class="mx-8 text-[16px] font-semibold text-text-primary">My Projects</div>
          <div class="items mx-8 mt-4 flex flex-wrap" :style="{ 'gap': 'var(--gap)' }">
            <CommonActItem
              v-for="item in items"
              :key="item.id"
              ref="itemRefs"
              :item="item"
              :style="{
                width: `calc(100% / var(--w) - var(--gap)*(var(--w) - 1)/var(--w))`,
              }"
              @change="onChange(item, $event)"
            />
          </div>
        </a-spin>
      </div>
    </div>
    <div
      :class="[
        'footer sticky bottom-[0px] flex items-center justify-center gap-4 rounded-b-2xl border-0 border-t border-solid border-[#1A1A1A]/10 bg-uni-6 py-3',
      ]"
    >
      <df-btn type="tertiary" @click="handleClose">Cancel</df-btn>
      <df-btn type="primary" :disabled="selectedItems.length < 1" :loading="loading" @click="onSelect">Select</df-btn>
    </div>
    <CommonActSuccessModal ref="modalRef" @confirm="onConfirm" />
  </a-modal>
</template>

<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout';
import { useAct } from './act/hook';

import { useCreateEntry } from '@/utils/createHook/hooks';
const { goEditorRoute } = useCreateEntry();
type PropType = {
  visible: boolean;
  zIndex?: number;
};
withDefaults(defineProps<PropType>(), {
  zIndex: 1006,
});
type EmitsType = {
  (event: 'update:visible', value: boolean): void;
};
const emits = defineEmits<EmitsType>();

const { title, selectedItems, loading, modalRef, onSelect, items, isSpinning, onChange, handleConfirm, itemRefs } = useAct();
const layoutStore = useLayoutStore();

const handleClose = () => {
  layoutStore.setActModalShow(false);
  // layoutStore.setRemixBaseOnLink([]);
  emits('update:visible', false);
};
const onConfirm = async () => {
  await handleConfirm();
  if (items.value.length <= 0) {
    handleClose();
  }
};
const goCreate = () => {
  handleClose();
  goEditorRoute();
};
</script>

<style lang="less">
.act-create-modal-box {
  .ant-modal .ant-modal-body {
    padding: 0;
  }
}
</style>
<style lang="less" scoped>
.items {
  --gap: 12px;
  --w: 4;
}
</style>
