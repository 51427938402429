export const purchaseApi = defineFindApi({
  url: '/community/v1/web/credits/buy',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json<{
    count: number;
    successUrl: string;
    cancelUrl: string;
    rParam: string;
  }>(),
  respTy: RespTy.Json<{
    payUrl: string;
    orderNumber: string;
  }>(),
});

export const getCreditsConfigApi = defineFindApi({
  url: '/community/v1/web/credits/package-config',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    current: number;
    pageSize: number;
  }>(),
  respTy: RespTy.Json<{
    list: {
      name: string;
      price: number;
      credits: number;
      giftCredits?: number;
      totalPrice: number;
    }[];
  }>(),
});

export const getMyCreditsApi = defineFindApi({
  url: '/community/v1/web/credits/my',
  method: HttpMethod.GET,
  respTy: RespTy.Json<{
    cnt: number;
  }>(),
});

// 查询credits订单
export const getOrderDetailApi = defineFindApi({
  url: `/community/v1/web/credits/order/:orderNumber`,
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    orderNumber: string;
  }>(),
  respTy: RespTy.Json<{
    orderNumber: string;
    amount: number;
    orderTime: string;
    status: string;
    price: number;
  }>(),
});
