<template>
  <div class="file-item group" :class="showProgress ? 'file-item__mask' : ''" :data-uid="data.uid" :data-url="data.url" :style="Style">
    <template v-if="data?.type === 'video'">
      <template v-if="isYoutube">
        <icon-font type="icon-video-fill" class="video-icon" @click="handleJumpVideo" />
        <!-- <img :src="getOssProcessedImage(data.url || data.thumbnailUrl, 'm_fill,w_100,h_100', 'webp')" /> -->
        <img v-lazy="data.url || data.thumbnailUrl" />
      </template>
      <template v-else>
        <img v-lazy="data.thumbnailUrl" />
        <icon-font v-if="data.videoUrl" type="icon-video-fill" class="video-icon" @click="handlePreviewVideo" />
        <a-progress
          v-if="showProgress"
          class="icon-progress"
          :size="25"
          type="circle"
          :show-info="false"
          :percent="data?.percent"
          status="exception"
        />
      </template>
      <icon-font class="icon-del" type="icon-Delete" @click.stop="handleFileDeleteClick" />
    </template>
    <template v-else-if="data?.type === 'aiImage'">
      <a-image loading="lazy" :src="data.url" :width="width" :height="height">
        <template #previewMask>
          <div class="flex flex-col items-center">
            <icon-font class="text-base" type="icon-eye-line" />
            <div>Preview</div>
          </div>
        </template>
      </a-image>
      <img class="aimake-icon" :src="AiIcon" />
    </template>
    <template v-else>
      <!-- <img class="object-cover" :src="getOssProcessedImage(data.url || data.thumbnailUrl, 'm_fill,w_100,h_100', 'webp')" /> -->
      <img v-if="data.url || data.thumbnailUrl" v-lazy="data.url || data.thumbnailUrl" class="object-cover" />
      <div
        :class="'view-mask absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center gap-4 text-level-7 text-uni-6 hover:bg-uni-7/50'"
      >
        <div class="hover-operate" @click="handlePreview">
          <icon-font class="text-base" type="icon-edit-box" />
          <span>Edit</span>
        </div>
        <div v-if="cover" class="h-12 w-[1px] bg-uni-6/50"></div>
        <div v-if="cover" class="hover-operate" @click="handleReplace">
          <icon-font class="text-base" type="icon-refresh-line" />
          <span>Replace</span>
        </div>
        <icon-font class="icon-del" type="icon-Delete" @click.stop="handleFileDeleteClick" />
      </div>
      <a-progress v-if="showProgress" class="icon-progress" :size="25" type="circle" :show-info="false" :percent="data?.percent" status="exception" />
    </template>

    <icon-font v-if="data.status === FileStatus.Error" class="icon-error" type="icon-information-line" />
  </div>
</template>
<script setup lang="ts">
import AiIcon from '@/assets/img/ai-icon.png';
import { isBlobUrl } from '@/components/share/utils';
import { FileStatus } from '../interface';
type PropType = {
  data: any;
  width?: number | string;
  height?: number | string;
  cover?: boolean;
};
const props = withDefaults(defineProps<PropType>(), {
  width: 84,
  height: 84,
  cover: false,
});
const Style = computed(() => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
  };
});
const isYoutube = computed(() => {
  return props.data?.videoUrl?.indexOf('youtube') > -1;
});
const emits = defineEmits(['delete', 'preview', 'previewVideo', 'refresh', 'replace']);
const handleJumpVideo = () => {
  if (props.data?.type === 'video') {
    window.open(props.data?.videoUrl, '_blank'); // youtube视频直接跳转youtube播放
  }
};
const handleFileDeleteClick = () => {
  emits('delete');
};

const handlePreview = () => {
  emits('preview');
};
const handlePreviewVideo = () => {
  emits('previewVideo');
};
const handleReplace = () => {
  emits('replace');
};
// const handleRefresh = () => {
//   emits('refresh');
// };
const showProgress = computed(() => {
  const { type, videoUrl, url } = props.data;
  // 如果是视频且有videoUrl，或者是其他类型且有url，则不显示进度条
  return !((type === 'video' && videoUrl) || (type !== 'video' && url && !isBlobUrl(url)));
});
</script>
<style scoped lang="less">
.file-item {
  :deep(.ant-image .ant-image-mask) {
    z-index: 10;
    font-size: 12px;
  }
  // width: 84px;
  // height: 84px;
  background: #eaeaea;
  border-radius: 8px;
  // margin-right: 8px;
  // margin-bottom: 8px;
  overflow: hidden;
  // cursor: pointer;
  position: relative;
  .video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 30px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .aimake-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 28px;
  }
  .icon-choose {
    position: absolute;
    left: 6px;
    top: 4px;
    display: block;
    z-index: 20;
  }
  .icon-del {
    background: #ff4d4f;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    padding: 2px;
    position: absolute;
    right: 6px;
    top: 6px;
    display: none;
    z-index: 20;
  }
  .icon-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
  }
  .icon-refresh {
    position: absolute;
    font-size: 16px;
    color: #000000;
    background-color: #ffffff;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
    padding: 1px;
    border-radius: 4px;
  }
  .icon-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff0035;
    font-size: 24px;
  }
  .view-mask {
    display: none;
  }
  &:hover {
    .icon-del {
      display: block;
    }
    .view-mask {
      display: flex;
    }
  }
  &__mask {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .hover-operate {
    @apply inline-flex w-12 cursor-pointer flex-col items-center gap-1 py-1;
  }
}
// 移动端
@media screen and (max-width: 768px) {
  .file-item {
    .icon-del {
      display: block;
    }
    .view-mask {
      display: flex;
    }
  }
}
</style>
