import { useLayoutStore } from '~/stores/layout';

/**
 *
 * @function useCreateEntry 创建案例入口处理hook。
 *  @params config 配置参数
 *  @return {handleEntry} 返回处理入口的方法
 *  逻辑处理：
 *  1.登录校验，
 *  2.是否显示普通全局弹窗提示
 *  @return {goEditorRoute} 返回直接跳转编辑器的方法
 */
type Config = {
  query?: {
    source?: 'user-center';
    id?: number | undefined;
    tag?: string;
  };
};
export const useCreateEntry = () => {
  const { isLogin } = useLoginStatus();
  const { sensorShareMyMakingClickEvent } = useSensors();
  const { $client } = useNuxtApp();
  const layoutStore = useLayoutStore();
  const router = useRouter();
  // 1.登录校验
  const handleEntry = (config = {}) => {
    if (isLogin.value) {
      handleCreateClick(config);
      return;
    }
    $client.openModal(() => {
      location.reload();
    });
  };
  // 选择显示弹窗
  const handleCreateClick = async (config: Config) => {
    sensorShareMyMakingClickEvent({ login_status: isLogin.value });
    const isShowTips = localStorage.getItem('createTipsModal');
    if (isShowTips && JSON.parse(isShowTips).value) {
      goEditorRoute(config);
    } else {
      layoutStore.setIsShowNewProject(true);
    }
  };

  /**
   * 路径参数处理，
   * 1. 活动页/话题页需要带上tag参数;
   * 2. 带xcs文件新建，需要带上source参数;
   */
  const route = useRoute();
  const queryParams = computed(() => {
    const queryObj: any = {};
    if (route.query.tag) {
      queryObj.tag = route.query.tag;
    }
    if (route.query.source) {
      queryObj.source = route.query.source;
    }
    return queryObj;
  });
  // set二创
  const setRemix = () => {
    const links = layoutStore.remixBaseOnLinks;
    if (links.length > 0) {
      const remixBaseOnInfo = {
        desc: '用于存储二次创作的来源链接',
        value: links,
      };
      window.sessionStorage.setItem('remixBaseOnInfo', JSON.stringify(remixBaseOnInfo));
    }
  };
  // 跳转编辑器
  const goEditorRoute = (config?: Config) => {
    console.log(111);
    setRemix();
    console.log(['queryParams.value', router, config, queryParams.value]);
    router.push({
      path: '/share/project',
      query: { ...queryParams.value, ...config?.query },
    });
    sensorsMakingOptionClickEvent({
      service_type: 'project',
      is_draft: false,
      content_type: 'free',
    });
  };

  const { sensorsMakingOptionClickEvent } = useSensors();
  return { handleEntry, goEditorRoute };
};
