<template>
  <div v-if="hasAiImage" :class="'ai-img absolute bottom-2 right-2 z-[3] w-[88px] rounded-lg bg-[#fff] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.1)]'">
    <RatioBox :padding-top="'100%'">
      <div class="img-box h-full w-full rounded-lg border-[3px] border-solid border-[#fff]">
        <img
          v-lazy="{
            src: getOssProcessedImage(image, 'm_fill,w_200,h_200', 'webp'),
            disabled: disabledLazyLoad,
          }"
          class="h-full w-full rounded-lg object-cover"
          alt=""
        />
      </div>
      <div class="absolute bottom-[3px] right-[3px] flex h-7 w-7">
        <img class="w-full object-cover" :src="AiIcon" alt="" />
      </div>
    </RatioBox>
  </div>
</template>

<script setup lang="ts">
import { type ItemType } from '@/types';
import AiIcon from '~/assets/img/ai-icon.png';

type PropsType = {
  makingItem: ItemType;
  disabledLazyLoad?: boolean;
};
const props = defineProps<PropsType>();

const aiImages = computed(() => props.makingItem.aiImages ?? []);
const hasAiImage = computed(() => aiImages.value.length > 0);
const image = computed(() => aiImages.value[0]);
</script>

<style lang="less" scoped>
.img-box {
  &::before {
    content: '';
    position: absolute;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.04);
  }
}
</style>
