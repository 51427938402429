<template>
  <div v-if="isBasicInfoShow" class="mb-8 bg-[#fff]">
    <h3 class="mb-0 text-xl font-bold text-text-primary pad:text-level-2 mobile:text-level-2">Production Info</h3>

    <div class="relative mt-4">
      <div v-if="forSale && !hasRight" class="relative mb-[-12px] rounded-t-xl border border-b-0 border-solid border-[rgba(0,0,0,0.1)] pb-3">
        <div class="my-8 flex justify-between">
          <div v-for="item in sellerPoints" :key="item.title" class="icon-item flex flex-[1_0_0%] flex-col items-center">
            <div class="text-text-secondary">{{ item.title }}</div>
            <div class="mt-4 w-[64px]">
              <img class="w-full" :src="item.icon" alt="" />
            </div>
            <div class="text-level-3 font-bold">{{ item.desc }}</div>
          </div>
        </div>
      </div>

      <InfoTable class="relative z-[1]" :for-sale="forSale" />
    </div>
  </div>
</template>

<script setup lang="ts">
import InstructionIconPng from '@/assets/img/project-detail/instruction_icon.png';
import LevelIcon1 from '@/assets/img/project-detail/level_icon1.png';
import LevelIcon2 from '@/assets/img/project-detail/level_icon2.png';
import LevelIcon3 from '@/assets/img/project-detail/level_icon3.png';
import TimeIconPng from '@/assets/img/project-detail/time_icon.png';
import { useDetailsStore } from '@/stores/details';
import { CONTENT_TYPE } from '~/types';
import InfoTable from './InfoTable.vue';

type PropsType = {
  forSale?: boolean;
};
withDefaults(defineProps<PropsType>(), {
  forSale: false,
});

const detailsStore = useDetailsStore();

const map: Record<number, any> = {
  1: {
    icon: LevelIcon1,
  },
  2: {
    icon: LevelIcon2,
  },
  3: {
    icon: LevelIcon3,
  },
};

const detail = computed(() => detailsStore.detail);
const hasRight = computed(() => detail.value.hasRight);
const hasInstruction = computed(() => detail.value.hasInstruction);
const isXthings = computed(() => detail.value.type === CONTENT_TYPE.XTHINGS);
const difficultyLevel = computed(() => detail.value.difficultyLevel);
const isBasicInfoShow = computed(() => {
  const { applyDevices, materials, softwareName, deviceName, materialInfo, scenarios, machines } = detail.value;
  return materials?.length || softwareName || deviceName || applyDevices?.length > 0 || materialInfo || scenarios?.length || machines?.length;
});
const sellerPoints = computed(() => {
  const levelIcon = map[difficultyLevel.value?.extra?.level]?.icon ?? LevelIcon1;
  const list = [{ title: 'Making Time', icon: TimeIconPng, desc: detail.value.makingTime + ' min' }];
  if (!isXthings.value) {
    list.push({ title: 'Difficulty Level', icon: levelIcon, desc: difficultyLevel.value?.name });
  }
  if (hasInstruction.value) {
    list.push({ title: 'Instruction', icon: InstructionIconPng, desc: 'Included' });
  }
  return list;
});
</script>

<style lang="less" scoped>
.icon-item + .icon-item {
  position: relative;
  &::before {
    content: '';
    width: 1px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
</style>
