<template>
  <SecondaryAddBtn v-if="showSecondaryBtn" class="mb-8" title="Add Total Time" @click="handleAdd" />
  <a-form-item v-else name="makingTime">
    <template #label>
      <span>
        Total time
        <a-tooltip title="Please enter the processing time + assemble time for your project">
          <icon-font class="ml-1" type="icon-question-line" />
        </a-tooltip>
      </span>
    </template>
    <div class="flex items-end">
      <a-input-number v-model:value="makingTime" size="large" class="w-[108px] border-none bg-[#f5f7fb] text-[#999999]" :min="0" :max="10000">
        <template #prefix><ClockCircleOutlined /></template>
      </a-input-number>
      <span class="ml-2 text-level-2 text-text-tertiary">min</span>
    </div>
  </a-form-item>
</template>
<script setup lang="ts">
import { ClockCircleOutlined } from '@ant-design/icons-vue';
import SecondaryAddBtn from '../SecondaryAddBtn.vue';
const showSecondaryBtn = ref(true);
const makingTime = defineModel('makingTime', { type: Number, default: 0 });
watchEffect(() => {
  if (!showSecondaryBtn.value) {
    return;
  }
  if (makingTime.value) {
    showSecondaryBtn.value = false;
  }
});
const handleAdd = () => {
  showSecondaryBtn.value = false;
};
</script>
