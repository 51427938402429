<template>
  <a-modal v-model:open="visible" destroy-on-close :z-index="zIndex" :footer="null" centered @cancel="handleClose">
    <div class="flex flex-col items-center">
      <div class="w-[200px] mobile:w-[160px]">
        <img class="w-full object-cover" :src="SuccessPng" alt="" />
      </div>
      <div class="mt-4 text-center text-level-2 font-semibold text-text-primary">You have successfully submitted your entry</div>
      <div class="mt-4">
        <p class="mb-0 text-center text-level-4 text-text-secondary mobile:text-level-7">
          All submitted entries are subject to review to ensure they meet the entry requirements.
        </p>
        <p class="mb-0 text-center text-level-4 text-text-secondary mobile:text-level-7">
          We will complete the review within 1 business day, please wait patiently.
        </p>
        <p class="mb-0 text-center text-level-4 text-text-secondary mobile:text-level-7">Thanks for your participation!</p>
      </div>
      <div class="mt-6 text-right">
        <df-btn class="w-[80px]" type="primary" @click="onConfirm">Ok</df-btn>
      </div>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import SuccessPng from '@/assets/img/df-ip/publish_success.png';

type PropType = {
  zIndex?: number;
};
withDefaults(defineProps<PropType>(), {
  zIndex: 1008,
});
type EmitsType = {
  (event: 'confirm'): void;
};
const emits = defineEmits<EmitsType>();

const visible = ref(false);
const handleClose = () => {
  emits('confirm');
  visible.value = false;
};
const onConfirm = () => {
  handleClose();
};
const open = () => {
  visible.value = true;
};

defineExpose({
  open,
});
</script>

<style lang="less" scoped></style>
