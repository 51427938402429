import { Methods, request, REQUEST_SEVER } from '@/utils/request';

const prefix = '/community/v1/web';

// 获取活动详情
export const getActivityDetailApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/activity/${id}`,
    method: Methods.GET,
  });
};
export const findActivityListApi = defineFindApi({
  url: '/community/v1/web/activity/:id',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    id: number;
  }>(),
  respTy: RespTy.Json<any>(),
});

/**
 * 首次赠送积分
 * activityOutOfCycle = "out_of_cycle"         // 不在活动周期
 * notInActivity      = "not_in_activity"      // 不属于该活动
 * awardedCredits     = "awarded_credits"      // 已经领取过奖励
 * awardCreditSuccess = "award_credit_success" // 领取奖励成功
 */
export const postFirstCredit = defineFindApi({
  url: '/community/v1/web/activity/:id/operation',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{ id: number }>(),
  respTy: RespTy.Json<{
    status: boolean;
    msg: 'out_of_cycle' | 'not_in_activity' | 'awarded_credits' | 'award_credit_success';
  }>(),
});

// 获取用户可选投稿列表
export const getActivityDeliveriesApi = (params: { activityId: number; current: number; pageSize: number; type?: string; sorter?: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/deliveries`,
    params,
    method: Methods.GET,
  });
};
export const getActivityDeliveries = defineFindApi({
  url: '/community/v1/web/deliveries',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    activityId: number; // 活动ID
    current: number; // 当前页
    pageSize: number; // 每页条数
    type?: string; // 类型
    sorter?: string; // 排序
    uid?: number; // 用户ID
    seed?: number; // 随机种子
    isStrictSorterMode?: boolean; // 是否按照活动期间严格的活动行为排名
    ignoreAuditStatus?: boolean; // 是否忽略审核状态
  }>(),
  respTy: RespTy.Json<any>(),
});

// 投稿
export const doPostApi = defineFindApi({
  url: '/community/v1/web/delivery',
  method: HttpMethod.POST,
  respTy: RespTy.Json<any>(),
  paramTy: ParamTy.Json<{
    activityId?: number;
    makingId?: number;
  }>(),
});

export const getActivityList = (params: { current: number; pageSize: number; status: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/activities',
    params,
    method: Methods.GET,
  });
};

/** 获取活动列表页 */
export const getActivityMakingOptionalListApi = defineFindApi({
  url: `/community/v1/web/activity/:id/makings/optional`,
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{ current: number; pageSize: number; id: number | string }>(),
  respTy: RespTy.Json<{
    list: any[];
    pagination: {
      current: number;
      pageSize: number;
      total: number;
    };
  }>(),
});

/**
 * 感恩节抽奖活动
 */
// 事件上报
export const postEvent = (data: { eventType: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/activity/event`,
    method: Methods.POST,
    data,
  });
};
// 抽奖
export const postLottery = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/activity/${id}/lottery`,
    method: Methods.POST,
    // data,
  });
};
// 我的奖品
export const getAwardList = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/activity/${id}/my/award`,
    method: Methods.GET,
    // data,
  });
};
// 我的任务
export const getMyTasks = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/activity/${id}/my/tasks`,
    method: Methods.GET,
  });
};
// 中奖名单
export const getAwardUsers = (data: { id: number; current: number; pagesize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/activity/${data.id}/award-users`,
    method: Methods.GET,
    params: {
      pageSize: data.pagesize,
      current: data.current,
    },
  });
};
// 剩余抽奖次数
export const getRemainingLottery = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/activity/${id}/remaining-lottery`,
    method: Methods.GET,
    // data,
  });
};
/** end */

/**
 * 2023圣诞节活动
 */
export const getEntryCountByActivityId = (params: { activityId: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/delivery/count`,
    method: Methods.GET,
    params,
  });
};

export const getEntryCountById = defineFindApi({
  url: `/community/v1/web/delivery/count`,
  method: HttpMethod.GET,
  respTy: RespTy.Json<{ total: number }>(),
  paramTy: ParamTy.Query<{
    activityId: number;
  }>(),
});

// 获取活动配置信息
export const getActivityConfigById = (activityId: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `${prefix}/act-config/${activityId}`,
    method: 'GET',
  });
};

/**
 * 获取任务完成度
 */
export const getTaskProgress = defineFindApi({
  url: '/community/v1/web/activity/bingo-party/tasks',
  method: HttpMethod.GET,
  respTy: RespTy.Json<{
    tasks: any[];
    completedTaskIds: number[];
    completedLines: number;
  }>(),
});

/**
 * 完成分享链接任务
 */
export const postShareTask = defineFindApi({
  url: '/community/v1/web/activity/bingo-party/event',
  method: HttpMethod.POST,
  respTy: RespTy.Json(),
});
